import { getEmailPopupCookie } from 'core/utils/helpers/emailPopupCookie';
import {
  EMAIL_OPT_IN_MODAL_SUBSCRIBE_TRIGGER,
} from 'core/modules/Modal/components/EmailOptInSubscribeModal/actions/types';
import { EMAIL_OPT_IN_MODAL_SUCCESS_TRIGGER } from 'core/modules/Modal/components/EmailOptInSuccessModal/actions/types';

export const emailOptInTriggerGaActionEvents = {
  [EMAIL_OPT_IN_MODAL_SUBSCRIBE_TRIGGER]: () => {
    const { seenCount = 0 } = getEmailPopupCookie();

    return {
      category: 'Email Opt In',
      action: 'Email Opt In Prompt Display',
      label: `Email Opt In Prompt Display|${seenCount + 1}`,
    };
  },

  [EMAIL_OPT_IN_MODAL_SUCCESS_TRIGGER]: () => {
    const { seenCount = 0 } = getEmailPopupCookie();

    return {
      category: 'Email Opt In',
      action: 'Email Success View',
      label: `Email Success View|${seenCount + 1}`,
    };
  },
};
