import { CATEGORY_MERCHANTS_TABLE_GET_SUCCESS, CATEGORY_MERCHANTS_TABLE_GET_FAILURE } from '../actions/types';
import categoryMerchantsTableConfig from '../configs';

/** @type {{config: object, placements: array.<object>}} */
const initialState = {
  config: categoryMerchantsTableConfig,
  data: [],
  metadata: {},
  isLoading: false,
  isLoaded: false,
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const categoryMerchantsTable = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_MERCHANTS_TABLE_GET_SUCCESS: {
      const { response, metadata } = action.payload;

      return {
        ...state,
        data: response,
        metadata,
        isLoading: false,
        isLoaded: true,
      };
    }
    case CATEGORY_MERCHANTS_TABLE_GET_FAILURE: {
      return {
        ...state,
        data: [],
        metadata: {},
        isLoading: false,
        isLoaded: true,
      }; // we'd have to decide what to do with error state
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === CATEGORY_MERCHANTS_TABLE_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default categoryMerchantsTable;
