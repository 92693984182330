import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { getId } from 'core/utils/analytics/sectionInfo';
import { giftGuide as orgConfig } from 'org/configs/modules.config';
import {
  GIFT_GUIDE_HEADLINE_GET_SUCCESS,
  GIFT_GUIDE_HEADLINE_GET_FAILURE,
  GIFT_GUIDE_FEATURED_DEALS_GET_SUCCESS,
  GIFT_GUIDE_FEATURED_DEALS_GET_FAILURE,
} from '../actions/types';

const defaultConfig = {
  defaults: {
    moduleConfigName: 'giftGuide',
    giftGuideHeadline: {
      api: {
        params: {
          offer_tag: 'mhp-gift-guide-headline',
          limit: 1,
          fields: [
            'assets.shortSummaryText',
            'assets.callToActionText',
            'clickUrl',
          ],
        },
        options: {
          success: GIFT_GUIDE_HEADLINE_GET_SUCCESS,
          failure: GIFT_GUIDE_HEADLINE_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
      },
    },
    giftGuideFeaturedDeals: {
      api: {
        params: {
          offer_tag: 'mhp-gift-guide-featured',
          section_id: getId('featured-gift-guide'),
          limit: 8,
        },
        options: {
          success: GIFT_GUIDE_FEATURED_DEALS_GET_SUCCESS,
          failure: GIFT_GUIDE_FEATURED_DEALS_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
      },
    },
    slick: {
      infinite: false,
      speed: 800,
      swipeToSlide: true,
      swipe: true,
      dots: false,
      arrows: false,
      variableWidth: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    },
    rebateOptions: {
      showRebateInline: true,
      hideElevationCurrency: true,
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
