import React from 'react';
import { useSelector } from 'react-redux';
import { selectSiteUrl, selectLoginUrl } from 'core/selectors/app';
import { selectUserName, selectDisplayableUserId, selectUserIsIdentified } from 'core/selectors/user';
import { selectActiveMQ } from 'core/modules/MediaQueries/selectors';
import { useDropdown } from 'core/hooks/useDropdown';

import AccountSummary from 'core/modules/AccountSummary/AccountSummary';

export function UserInfoSectionWithDropdown() {
  const siteUrl = useSelector(selectSiteUrl);
  const loginUrl = useSelector(selectLoginUrl);
  const userName = useSelector(selectUserName);
  const displayableUserId = useSelector(selectDisplayableUserId);
  const userIsIdentified = useSelector(selectUserIsIdentified);
  const {
    hideSubnav, showSubnav, isFocused, dropdownClassName,
  } = useDropdown();

  const activeMQ = useSelector(selectActiveMQ);

  const renderMessage = ({ lifetimeEarningFormatted }) => (
    <span>Lifetime shopping miles: <strong>{lifetimeEarningFormatted || 0}</strong></span>
  );

  if (!userIsIdentified) {
    return (
      <a href={loginUrl} className="mn_button">Sign in</a>
    );
  }

  if (['XL', 'XXL'].includes(activeMQ)) {
    return (
      <>
        <div className="mn_userInfo">
          <span className="mn_userNameWrap">Hi <span className="mn_userName">{userName}</span></span>
          <span className="mn_userId">#{displayableUserId}</span>
        </div>
        <div
          onMouseEnter={showSubnav}
          onMouseLeave={hideSubnav}
          onBlur={hideSubnav}
          className={`mn_navigationDropdownTrigger ${dropdownClassName}`}
          data-section-name="Account"
        >
          <button
            className="mn_navigationItem"
            aria-expanded={isFocused}
            aria-label="Account"
            onFocus={showSubnav}
          >
            <i className="mn_unitedIconUser" aria-hidden="true" />
          </button>
          <div className="mn_navigationDropdown">
            <div className="mn_highlitedSection">
              <p className="mn_userId">#{displayableUserId}</p>
              <p className="mn_lifetimeEarning">
                <AccountSummary renderData={data => (renderMessage(data))} />
              </p>
              <a href={`${siteUrl}/ai____.htm`}>My account</a>
            </div>
            <ul>
              <li><a href={`${siteUrl}/foryou____.htm`}>Offers for you</a></li>
              <li><a href={`${siteUrl}/favorites____.htm`}>Favorite stores</a></li>
              <li>
                <a
                  href="//united.com"
                  className="mn_externalLink"
                  rel="external"
                  target="_blank"
                  title="Opens new window"
                >
                  united.com
                </a>
              </li>
              <li><a href={`${siteUrl}/logoutUser.php?lUrl=/`}>Log out</a></li>
            </ul>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="mn_userInfo">
        <span className="mn_userNameWrap">Hi <span className="mn_userName">{userName}</span></span>
        <span className="mn_userId">#{displayableUserId}</span>
      </div>
      <div className="mn_navigationDropdownTrigger" data-section-name="Account">
        <a href={`${siteUrl}/ai____.htm`} className="mn_navigationItem" title="Opens my account page">
          <i className="mn_unitedIconUser" aria-hidden="true" />
        </a>
      </div>
    </>
  );
}

export default UserInfoSectionWithDropdown;
