import { browserName, platformType } from 'core/utils/helpers/browser';
import { BUTTON_BENEFITS_VIEW } from '../actions/types';

export const buttonBenefitsGaActionEvents = {
  [BUTTON_BENEFITS_VIEW]: () => (
    {
      category: 'VHP CTA',
      action: 'Get Button View',
      label: `${platformType} | ${browserName}`,
    }
  ),
};

export const buttonBenefitsClickEvents = [
  {
    selector: '.mn_buttonBenefits .mn_button',
    click: {
      category: 'VHP CTA',
      action: 'Get Button Click',
    },
    label: `${platformType} | ${browserName}`,
  },
];
