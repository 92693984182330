import { takeEvery, put } from 'redux-saga/effects';
import { showModal } from 'core/modules/Modal/actions';

import { MODAL_TYPE_INSTORE_OFFER } from 'core/modules/Modal/types';
import { INSTORE_OFFER_MODAL_TRIGGER } from 'core/modules/Modal/components/InstoreOfferModal/actions/types';

function* showInstoreOfferModal() {
  yield put(showModal(MODAL_TYPE_INSTORE_OFFER));
}

export default function* instoreOfferModalSaga() {
  yield takeEvery(INSTORE_OFFER_MODAL_TRIGGER, showInstoreOfferModal);
}
