/* eslint-disable no-undef */
import UrlParse from 'url-parse';
import Cookies from 'js-cookie';
import bowser from 'bowser';
import { getPageName } from './pageInfo';

const isDyEnabled = (initialState) => {
  const { app: { features: { dynamicYield } } } = initialState;

  return dynamicYield.isEnabled;
};

export const getBrowserDYInfo = () => {
  try {
    const { browser, platform } = bowser.parse(window.navigator.userAgent);
    const platformName = `${platform.type.charAt(0).toUpperCase()}${platform.type.slice(1)}`;
    const browserName = browser.name.replace(/Microsoft/ig, '').trim();

    return {
      platformName,
      browserName,
    };
  } catch (error) {
    console.log(`Can't parse data: ${error}`);
    return {
      platformName: '',
      browserName: '',
    };
  }
};

export const dyEvents = {
  sendKeyWordSearchEvent: ({ keywords }) => {
    DY.API('event', {
      name: 'Keyword Search',
      properties: {
        dyType: 'keyword-search-v1',
        keywords,
      },
    });
  },

  sendEmailOptinEvent: ({ emailPopupCookie }) => {
    const { seenCount, emailGlobalOptOut } = emailPopupCookie;

    DY.API('event', {
      name: 'Email OptIn',
      properties: {
        value: emailGlobalOptOut,
        seenCount,
      },
    });
  },

  sendFavoriteEvent: ({ merchantName, merchantId }) => {
    DY.API('event', {
      name: 'Favorite',
      properties: {
        merchantName,
        gmid: merchantId,
      },
    });
  },

  sendButtonUninstall: () => {
    DY.API('event', {
      name: 'Button Uninstall',
      properties: {
        browser: getBrowserDYInfo().browserName,
      },
    });
  },

  sendEvent(callbackEventFunction, info) {
    if (!window.initialState || !isDyEnabled(window.initialState)) {
      return;
    }

    callbackEventFunction(info);
  },
};

export const dySPA = {
  sendEvent: () => {
    dyEvents.sendEvent(() => {
      DY.API('spa', {
        context: DY.recommendationContext,
        url: window.location.href,
        countAsPageview: false,
      });
    });
  },
};

export const waitForDYLoad = () => {
  let count = 0;

  const dyLoadAwait = new Promise((resolve, reject) => {
    const intervalDY = setInterval(() => {
      if (count > 10) {
        clearInterval(intervalDY);
        reject();
      }

      if (DY && DY.API) {
        clearInterval(intervalDY);
        resolve();
      }

      count += 1;
    }, 200);
  });

  return dyLoadAwait;
};

const getCustomPageName = (initialState) => {
  let pageName;
  const currentUrl = new UrlParse(window.location.href, true);
  const { query } = currentUrl;
  const {
    app: { org: { orgId } },
  } = initialState;
  switch (getPageName()) {
    case 'h':
      pageName = 'home';
      break;
    case 'bc':
      pageName = 'category';
      break;
    case 'me':
      pageName = query.gmid.toString().split(',').length > 1 ? 'merchant-multiple' : 'merchant-direct';
      break;
    case 'button-landing':
      pageName = 'button';
      break;
    case 'extra-rewards':
      pageName = 'extra-rewards';
      break;
    case 'is':
      pageName = 'instore';
      break;
    case 'es':
      pageName = orgId === '201' || orgId === '80' ? 'home' : 'other'; // treat es page for AA and SWA as home
      break;
    case 'favorites':
      pageName = 'favorites';
      break;
    default:
      pageName = 'other';
  }

  return pageName;
};

const sendPageVisitEvent = (initialState) => {
  const pageType = getCustomPageName(initialState);

  if (pageType !== 'other') {
    DY.API('event', {
      name: 'Page',
      properties: {
        value: pageType,
      },
    });
  }

  const {
    user: { isIdentified },
  } = initialState;

  const { promoData } = window;

  if (getPageName() === 'sweeps' && isIdentified && !promoData.expired) {
    const { promoName, promoStatus } = promoData;

    /**
     * When user opts in there is always a page load
     * so we can leverage onload to send promo info event
     */
    DY.API('event', {
      name: 'Sweeps OptIn',
      properties: {
        promoName,
        value: promoStatus || false,
      },
    });
  }
};

const sendLoginOrRegistrationEvent = (initialState) => {
  /**
   * Login event
   */
  const isLoginEventSent = () => Cookies.get('isLoginDYEventSent') === 'yes';

  const hasToSendLoginEvent = () => {
    const {
      user: { isIdentified, firstTimeVisit },
    } = initialState;

    if (isIdentified && !firstTimeVisit && !isLoginEventSent()) {
      return true;
    }
    return false;
  };

  const sendAndTrackLoginEvent = () => {
    const { env: { cookieDomain, isHttps } } = initialState;

    DY.API('event', {
      name: 'Login',
      properties: {
        dyType: 'login-v1',
        cuid: mn.dyUserHashId,
        cuidType: 'recipientId',
      },
    });

    Cookies.set('isLoginDYEventSent', 'yes', {
      path: '/',
      domain: cookieDomain,
      secure: isHttps,
    });
  };

  if (hasToSendLoginEvent()) {
    const {
      user: { emailPopup },
    } = initialState;

    dyEvents.sendEmailOptinEvent({ emailPopupCookie: emailPopup });
    sendAndTrackLoginEvent();
    // dont send register event if login event is sent
    return;
  }

  /**
   * Registration event
   */
  const isRigisterEventSent = () => Cookies.get('isRegistrationDYEventSent') === 'yes';

  const hasToSendRegisterEvent = () => {
    const {
      user: { isIdentified, firstTimeVisit },
    } = initialState;

    if (isIdentified && firstTimeVisit && !isRigisterEventSent()) {
      return true;
    }
    return false;
  };

  const sendAndTrackRegisterEvent = () => {
    const { env: { cookieDomain, isHttps } } = initialState;

    DY.API('event', {
      name: 'Signup',
      properties: {
        dyType: 'signup-v1',
        cuid: mn.dyUserHashId, // re-using what we had in canjs - once we port over fully to react we can change this
        cuidType: 'recipientId',
      },
    });

    Cookies.set('isRegistrationDYEventSent', 'yes', {
      path: '/',
      domain: cookieDomain,
      secure: isHttps,
    });
  };

  if (hasToSendRegisterEvent()) {
    sendAndTrackRegisterEvent();
  }
};

const addScrollEventListener = () => {
  const percentagesArr = [25, 50, 75];
  let timeout;
  let previousPercentage;

  window.addEventListener('scroll', () => {
    const maxScrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrollVal = window.scrollY;
    const scrollPercentage = Math.round(scrollVal / maxScrollHeight * 100);
    let currentPercentage = 0;
    let i = 0;

    while (percentagesArr[i] <= scrollPercentage) {
      currentPercentage = percentagesArr[i];
      i += 1;
    }

    if (previousPercentage !== currentPercentage) {
      clearTimeout(timeout);
      timeout = currentPercentage !== 0
        ? setTimeout(() => {
          DY.API('event', {
            name: 'Scroll',
            properties: {
              value: currentPercentage,
            },
          });
        }, 1000)
        : null;
      previousPercentage = currentPercentage;
    }
  });
};

const dyAnalytics = {
  init: (initialState) => {
    if (!isDyEnabled(initialState)) {
      return;
    }

    waitForDYLoad().then(() => {
      sendLoginOrRegistrationEvent(initialState);
      sendPageVisitEvent(initialState);

      window.onload = () => {
        addScrollEventListener();
      };
    });
  },
};

export default dyAnalytics;
