import { NAV_CATEGORIES_GET_SUCCESS, NAV_CATEGORIES_GET_FAILURE } from '../actions/types';
import navCategoriesConfig from '../configs';

const initialState = {
  config: navCategoriesConfig,
  isLoading: false,
  isLoaded: false,
  categories: [],
};

const navCategories = (state = initialState, action) => {
  switch (action.type) {
    case NAV_CATEGORIES_GET_SUCCESS: {
      return {
        ...state,
        categories: action.payload.response,
        isLoading: false,
        isLoaded: true,
      };
    }
    case NAV_CATEGORIES_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;

      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === NAV_CATEGORIES_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }

      return state;
    }
  }
};

export default navCategories;
