import React, {
  useState, useRef, useMemo, forwardRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { dyEvents } from 'core/utils/analytics/dyAnalytics';
import { trackInsiderFavoriteEvent } from 'core/utils/analytics/insiderAnalytics';
import { selectFavoritesIsProcessed, makeSelectFavoriteByMerchantId } from 'core/selectors/favorites';
import { selectUserIsIdentified } from 'core/selectors/user';

import SpinIconSVG from './assets/images/spin.inline.svg';

import { favoriteIconClick } from './actions';
import config from './configs';

import './FavoriteIcon.scss';

import FavoriteIconTooltip from './components/FavoriteIconTooltip';

const FavoriteIcon = forwardRef((props, ref) => {
  const {
    merchantId, merchantName, showSpinner, labelFavorite, labelUnfavorite,
    tooltipEnabled, labelFavorited, labelUnfavorited, tabIndex,
    isTooltipPermanentForUnfavorited, isTooltipPermanentForFavorited,
    secondaryTooltipEnabled, secondaryLabelFavorited, secondaryLabelUnfavorited,
    isSecondaryTooltipPermanentForFavorited, isSecondaryTooltipPermanentForUnfavorited,
    tooltipClassName, forceSkipFirstFavoritesPopup,
  } = props;

  const { favoriteIconViewBox, useSvgIcon, FavoriteIconSvg } = config;

  const dispatch = useDispatch();

  const selectFavoriteByMerchantId = useMemo(makeSelectFavoriteByMerchantId, []);
  const isFavorited = useSelector(state => selectFavoriteByMerchantId(state, merchantId));
  const isProcessed = useSelector(selectFavoritesIsProcessed);
  const userIsIdentified = useSelector(selectUserIsIdentified);

  const buttonRef = useRef(null);

  /* The original image dimensions. Added here to fix svg path scaling */
  const favoriteIcon =
    useSvgIcon ?
      <FavoriteIconSvg viewBox={favoriteIconViewBox} aria-hidden />
      :
      <i className="mn_favoriteFontIcon" />;

  useImperativeHandle(ref, () => ({
    buttonRef: buttonRef.current,
  }));

  const [favoriteIconWasClicked, setFavoriteIconWasClicked] = useState(false);

  const getIsProcessing = () => (
    !isProcessed
  );

  const getClassName = () => (
    `mn_favoriteIcon ${showSpinner ? 'mn_spin' : ''} ${isFavorited ? 'mn_favorited' : ''}`
  );

  const getAriaLabel = () => {
    const customLabel = isFavorited ? labelUnfavorite : labelFavorite;

    if (customLabel) {
      return customLabel;
    }

    const labelPrefix = isFavorited ? 'Un-Favorite' : 'Favorite';
    return `${labelPrefix} ${merchantName}`;
  };

  const getTitle = () => (
    isFavorited ? `Remove ${merchantName} from Favorites` : `Add ${merchantName} to Favorites`
  );

  const handleFavoriteIconClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.focus();

    if (merchantId) {
      if (!isFavorited) {
        dyEvents.sendEvent(dyEvents.sendFavoriteEvent, { merchantId, merchantName });
        trackInsiderFavoriteEvent({ merchantId, merchantName });
      }

      dispatch(favoriteIconClick(merchantId, isFavorited, forceSkipFirstFavoritesPopup));
      // Determine which exactly merchant icon was clicked, so we can show tooltip
      // only for it even if there are two or more same merchants on the page
      setFavoriteIconWasClicked(true);
    }
  };

  if (!merchantId || !userIsIdentified) {
    return (null);
  }

  const iconTooltipProps = {
    isFavorited,
    buttonRef,
    favoriteIconWasClicked,
    setFavoriteIconWasClicked,
  };

  return (
    <button
      className={getClassName()}
      disabled={getIsProcessing()}
      onClick={handleFavoriteIconClick}
      data-test="favorite-btn"
      data-merchant-id={merchantId}
      data-merchant-name={merchantName}
      aria-label={getAriaLabel()}
      ref={buttonRef}
      tabIndex={tabIndex}
      title={getTitle()}
      data-is-favorite={isFavorited}
    >

      {showSpinner ? <SpinIconSVG /> : favoriteIcon}

      { tooltipEnabled && isProcessed &&
        <FavoriteIconTooltip
          className={`mn_balloonPrimary ${tooltipClassName}`}
          labelFavorited={labelFavorited}
          labelUnfavorited={labelUnfavorited}
          isTooltipPermanentForFavorited={isTooltipPermanentForFavorited}
          isTooltipPermanentForUnfavorited={isTooltipPermanentForUnfavorited}
          {...iconTooltipProps}
        />
      }

      { secondaryTooltipEnabled && isProcessed &&
        <FavoriteIconTooltip
          className={`mn_balloonSecondary ${tooltipClassName}`}
          labelFavorited={secondaryLabelFavorited}
          labelUnfavorited={secondaryLabelUnfavorited}
          isTooltipPermanentForFavorited={isSecondaryTooltipPermanentForFavorited}
          isTooltipPermanentForUnfavorited={isSecondaryTooltipPermanentForUnfavorited}
          {...iconTooltipProps}
        />
      }
    </button>
  );
});

FavoriteIcon.propTypes = {
  merchantId: PropTypes.number.isRequired,
  merchantName: PropTypes.string.isRequired,
  tooltipEnabled: PropTypes.bool,
  isTooltipPermanentForFavorited: PropTypes.bool,
  isTooltipPermanentForUnfavorited: PropTypes.bool,
  labelUnfavorited: PropTypes.string,
  labelFavorited: PropTypes.string,
  secondaryTooltipEnabled: PropTypes.bool,
  isSecondaryTooltipPermanentForUnfavorited: PropTypes.bool,
  isSecondaryTooltipPermanentForFavorited: PropTypes.bool,
  secondaryLabelFavorited: PropTypes.string,
  secondaryLabelUnfavorited: PropTypes.string,
  labelFavorite: PropTypes.string,
  labelUnfavorite: PropTypes.string,
  tabIndex: PropTypes.string,
  tooltipClassName: PropTypes.string,
  forceSkipFirstFavoritesPopup: PropTypes.bool,
};

FavoriteIcon.defaultProps = {
  tooltipEnabled: true,
  isTooltipPermanentForUnfavorited: false,
  isTooltipPermanentForFavorited: false,
  labelUnfavorited: 'Removed from your favorites',
  labelFavorited: 'Saved to your favorites',
  secondaryTooltipEnabled: false,
  isSecondaryTooltipPermanentForUnfavorited: false,
  isSecondaryTooltipPermanentForFavorited: false,
  secondaryLabelUnfavorited: '',
  secondaryLabelFavorited: '',
  labelFavorite: '',
  labelUnfavorite: '',
  tabIndex: '0',
  tooltipClassName: '',
  forceSkipFirstFavoritesPopup: false,
};

export default FavoriteIcon;
