/* eslint-disable camelcase */
import ReactGA from 'react-ga4';
import delegate from 'delegate';
import debounce from 'lodash/debounce';
import merge from 'lodash/merge';
import isObject from 'lodash/isObject';
import get from 'lodash/get';
import UrlParse from 'url-parse';
import Cookies from 'js-cookie';
import {
  setInitialCustomDimensions, setDefaultValuesToDimensions, customDimensionsMap,
} from 'core/utils/analytics/ga-dimensions';
import { OCAPI_ROOT_ACTION_PATH } from 'core/actions/ocapi';

/**
 * @typedef {Object} GAEvent
 * @property {string} category
 * @property {string} action
 * @property {string} label
 */

/**
 * @typedef {Object} EventTracker
 * @property {string} selector
 * @property {undefined|{category: {string}, action: {string}} click
 * @property {undefined|{category: {string}, action: {string}} hover
 * @property {string|function} label
 * @property {undefined|*} value
 */

/**
 * @typedef {Array.<EventTracker>} EventTrackerList
 */

/**
 * Number of milliseconds to delay for debounced hover events
 * @type {number}
 */
const defaultMouseHoverTrackDelay = 1500;

/**
 * @param {EventTrackerList} eventTrackers
 * @param {object} options
 */
export const trackClickAndHoverEvents = (eventTrackers = [], options = {}) => {
  const bodyEl = document.querySelector('body');

  const triggerGAEventForClickAndHoverTracker = ({ eventTracker, eventType, target }) => {
    const { dynamicProps = {} } = ReactGA;
    const { app: { features: { ga } } } = window.initialState;
    const { label, value, custom = {} } = eventTracker;
    const { category, action } = eventTracker[eventType];
    const customEventData = typeof custom === 'function' ? custom(target) : custom;

    /* eslint-disable no-nested-ternary */
    const eventData = merge({
      send_to: ga.tag,
      eventCategory: (typeof category === 'function' ? category(target) : category),
      eventAction: (typeof action === 'function' ? action(target) : action),
      eventLabel: (typeof label === 'function' ? label(target) : label),
      eventValue: (typeof value === 'function' ? value(target) : (typeof value !== 'undefined' ? value : 1)),
    }, customEventData, dynamicProps);
    /* eslint-enable  no-nested-ternary */

    // Trigger GA event
    ReactGA.event(eventData.eventCategory, eventData);
  };

  /**
   * @param {HTMLElement} target
   * @param {string} eventType
   * @returns {boolean}
   */
  const trackMouseEvent = (target, eventType) => {
    const eventTrackersForCurrentTarget = eventTrackers.filter(eventTracker => target.matches(eventTracker.selector));

    if (eventTrackersForCurrentTarget.length === 0 || !eventType) {
      return false;
    }

    eventTrackersForCurrentTarget.forEach((eventTracker) => {
      triggerGAEventForClickAndHoverTracker({ eventTracker, eventType, target });
    });

    return true;
  };

  /**
   * @param {HTMLElement} target
   */
  const trackHoverEvent = debounce((target) => {
    // If still hovering over the element
    if (target.parentElement.querySelector(':hover') === target) {
      trackMouseEvent(target, 'hover');
    }
  }, options.mouseHoverTrackDelay || defaultMouseHoverTrackDelay);

  /**
   * @param {string} eventType
   */
  const getTrackedElementsSelectors = eventType => eventTrackers
    .filter(el => el[eventType])
    .map(el => el.selector).join(',');

  /** @type {string} */
  const hoverSelectors = getTrackedElementsSelectors('hover');
  /** @type {string} */
  const clickSelectors = getTrackedElementsSelectors('click');
  hoverSelectors && delegate(bodyEl, hoverSelectors, 'mouseenter', (event) => {
    trackHoverEvent(event.delegateTarget);
  }, true);

  clickSelectors && delegate(bodyEl, clickSelectors, 'click', (event) => {
    trackMouseEvent(event.delegateTarget, 'click');
  }, true);
};
const analytics = {
  /**
   * We only need special treatment for the initialization
   * @param {string} accountId
   * @param {object} options
   * @param {EventTrackerList} eventTrackers
   */
  initializeGA: async (accountId = '', options = {}, initialState, eventTrackers) => {
    const { env: { isProductionMode }, app: { features: { ga } } } = initialState;

    // CLPL-16408
    // For error page path location(dl) being set to page error occured on, no page redirect
    // Set to correct page path location(dl) by overwriting
    const currentBaseUrl = window.location.origin;
    const currentPage = window.location.href;
    if (get(initialState, 'app.page.name') === 'e' && currentPage.indexOf('/e____.htm') === -1) {
      ReactGA.set({ page_location: `${currentBaseUrl}/e____.htm` });
    }

    const initialCustomDimensionsMap = await setInitialCustomDimensions(initialState);

    const isLoginEventSent = () => Cookies.get('isLoginEventSent') === 'yes';

    const hasToSendLoginEvent = (initialState) => {
      const {
        user: { isIdentified, firstTimeVisit },
      } = initialState;

      if (isIdentified && !firstTimeVisit && !isLoginEventSent()) {
        return true;
      }
      return false;
    };

    const sendAndTrackLoginEvent = (initialState) => {
      const currentUrl = new UrlParse(window.location.href, true);
      const { dynamicProps = {} } = ReactGA;

      const { query } = currentUrl;
      const { app: { features: { ga } }, env: { cookieDomain, isHttps } } = initialState;

      ReactGA.event('Login', {
        send_to: ga.tag,
        eventCategory: 'Login',
        eventAction: 'Login success',
        eventLabel: query.isource || Cookies.get('mn_isource') || 'none',
        eventValue: 1,
        ...dynamicProps,
      });

      Cookies.set('isLoginEventSent', 'yes', {
        path: '/',
        domain: cookieDomain,
        secure: isHttps,
      });
    };

    // Per the documentation: setting the 'debug_mode' parameter to false doesn't disable debug mode
    const debugMode = isProductionMode ? {} : { debug_mode: true };

    ReactGA.initialize(accountId, {
      gtagOptions: {
        custom_map: customDimensionsMap,
        user_properties: {
          member_id: options.isUserIdentified ? options.userId : '(not set)',
          apache_id: Cookies.get('Apache') || '(not set)',
        },
        send_page_view: false,
        ...debugMode,
        ...initialCustomDimensionsMap,
      },
    });

    if (hasToSendLoginEvent(initialState)) {
      sendAndTrackLoginEvent(initialState);
    }

    // Register click and hover event trackers
    trackClickAndHoverEvents(eventTrackers, options);

    // For 'Merchant Experience'/Sweeps page the 'pageview'
    // event is called asynchronously after merchants/Promo data is received
    if (get(initialState, 'app.page.name') === 'me' || get(initialState, 'app.page.name') === 'sweeps') {
      return false;
    }
    return ReactGA.send({ hitType: 'pageview', send_to: ga.tag });
  },
};

/**
 * Redux middleware function to handling firing of ga events based on actions
 * @param {object} gaActionEvents
 */
export const gaEventTracker = gaActionEvents => () => next => (action) => {
  const { app: { features: { ga } } } = window.initialState;

  const processActionEvent = (data) => {
    const { dynamicProps = {} } = ReactGA;

    if (data) {
      /* eslint-disable prefer-const */
      let {
        custom,
        action: eventAction,
        category: eventCategory,
        label: eventLabel,
        value: eventValue = 1, // Set the default GA event value to 1
        ...eventData
      } = data;
      /* eslint-enable prefer-const */

      // Set a default values for all event's custom dimensions
      if (custom && isObject(custom)) {
        const customDimensions = setDefaultValuesToDimensions(custom);
        eventData = { ...eventData, ...customDimensions };
      }

      if (eventData.type === 'pageView') {
        ReactGA.send({
          hitType: 'pageview', ...custom, ...dynamicProps, send_to: ga.tag,
        });
        return;
      }

      eventData = {
        send_to: ga.tag,
        eventAction,
        eventCategory,
        eventLabel,
        eventValue,
        ...dynamicProps,
        ...eventData,
      };

      ReactGA.event(eventData.eventCategory, eventData);
    }
  };

  const isOCAPIResponseAction = (action) => {
    const { context = {} } = action;
    const isOCAPIResponseAction = context.type && context.type.indexOf(OCAPI_ROOT_ACTION_PATH) !== -1;
    return isOCAPIResponseAction;
  };

  if (gaActionEvents[action.type] && !isOCAPIResponseAction(action)) {
    const eventData = gaActionEvents[action.type](action);
    if (eventData) {
      Array.isArray(eventData)
        ? eventData.forEach(data => processActionEvent(data))
        : processActionEvent(eventData);
    }
  }

  return next(action);
};

export default analytics;
