import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import OnlineMatches from './partials/OnlineMatches';
import SynonymsMatches from './partials/SynonymsMatches';
import InstoreMatches from './partials/InstoreMatches';
import NoMatches from './partials/NoMatches';
import RecentOrSimilarStoresMatches from './partials/RecentOrSimilarStoresMatches';

import {
  selectSynonymsQuickSearchMatches, selectOnlineQuickSearchMatches,
  selectInstoreQuickSearchMatches, selectQuickSearchMerchantsIsLoaded,
} from '../selectors';

export function QuickSearchMatches({
  config = {}, keywords, quickSearchResultsRef, merchantClickHandler, isPopUpView,
}) {
  const isMerchantsLoaded = useSelector(selectQuickSearchMerchantsIsLoaded);
  const onlineMatches = useSelector(state => selectOnlineQuickSearchMatches(state, keywords, config));
  const synonymsMatches = useSelector(state => selectSynonymsQuickSearchMatches(state, keywords, config));
  const instoreMatches = useSelector(state => (selectInstoreQuickSearchMatches(state, keywords, config)));

  const hasMatchesToShow = !isEmpty(synonymsMatches) || !isEmpty(onlineMatches) || !isEmpty(instoreMatches);
  const isMinChars = keywords.length >= config.minChars;

  if (!hasMatchesToShow && isMerchantsLoaded && isMinChars) {
    return (
      <>
        <div className="mn_quickSearchResults mn_quickSearchResultsNonGsp">
          <NoMatches text="– No matching stores –" />
        </div>
        <RecentOrSimilarStoresMatches config={config} keywords={keywords} />
      </>
    );
  }

  return (
    <>
      <div className="mn_quickSearchResults mn_quickSearchResultsNonGsp" ref={quickSearchResultsRef}>
        <SynonymsMatches
          config={config}
          synonymsMatches={synonymsMatches}
          keywords={keywords}
          merchantClickHandler={merchantClickHandler}
        />
        <OnlineMatches
          config={config}
          onlineMatches={onlineMatches}
          instoreMatches={instoreMatches}
          keywords={keywords}
          merchantClickHandler={merchantClickHandler}
          isPopUpView={isPopUpView}
        />
        <InstoreMatches
          config={config}
          instoreMatches={instoreMatches}
          isPopUpView={isPopUpView}
          merchantClickHandler={merchantClickHandler}
        />
      </div>
      <RecentOrSimilarStoresMatches config={config} keywords={keywords} />
    </>
  );
}

export default (props) => <ComponentErrorBoundary><QuickSearchMatches {...props} /></ComponentErrorBoundary>;
