import {
  FEATURED_OFFERS_120X90_GET_FAILURE,
  FEATURED_OFFERS_120X90_GET_SUCCESS,
} from '../actions/types';

import featuredOffers120x90Config from '../configs';

/** @type {{config: object, placements: array.<object>}} */
const initialState = {
  config: featuredOffers120x90Config,
  namespaceDefaults: {
    isLoaded: false,
    isLoading: false,
    data: [],
  },
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const featuredOffers120x90 = (state = initialState, action) => {
  const { context = {} } = action;
  const { namespace = 'default' } = action.namespace ? action : context.options || {};
  const { namespaceDefaults } = state;
  const { [namespace]: namespaceState = namespaceDefaults } = state;

  switch (action.type) {
    case FEATURED_OFFERS_120X90_GET_SUCCESS: {
      const { response } = action.payload;

      return {
        ...state,
        [`namespace-${namespace}`]: {
          ...namespaceState,
          data: response,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case FEATURED_OFFERS_120X90_GET_FAILURE: {
      return {
        ...state,
        [`namespace-${namespace}`]: {
          ...namespaceState,
          data: [],
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === FEATURED_OFFERS_120X90_GET_SUCCESS) {
        return {
          ...state,
          [`namespace-${namespace}`]: {
            ...namespaceState,
            isLoading: true,
          },
        };
      }

      return state;
    }
  }
};

export default featuredOffers120x90;
