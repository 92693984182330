import {
  INQUIRY_ORDER_CLICK_HISTORY_GET_SUCCESS,
  INQUIRY_ORDER_CLICK_HISTORY_GET_FAILURE,
} from '../actions/types';
import config from '../configs';

const { clickHistoryConfig } = config;

const initialState = {
  config: clickHistoryConfig,
  records: [],
  isLoading: false,
  isLoaded: false,
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const clickHistory = (state = initialState, action) => {
  switch (action.type) {
    case INQUIRY_ORDER_CLICK_HISTORY_GET_SUCCESS: {
      return {
        ...state,
        records: action.payload.response,
        isLoading: false,
        isLoaded: true,
      };
    }
    case INQUIRY_ORDER_CLICK_HISTORY_GET_FAILURE: {
      return {
        ...state,
        records: [],
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === INQUIRY_ORDER_CLICK_HISTORY_GET_SUCCESS) {
        return {
          ...initialState,
          isLoading: true,
        };
      }

      return state;
    }
  }
};

export default clickHistory;
