export const favoriteIconClickAndHoverEvents = [
  {
    selector: '.mn_favoriteIcon:not(.mn_favorited)',
    click: {
      category: 'Favorites',
      action: 'add-favorite',
    },
    label: ' ',
    custom: element => ({
      merchant: element.dataset.merchantName,
    }),
  },
  {
    selector: '.mn_favoriteIcon.mn_favorited',
    click: {
      category: 'Favorites',
      action: 'remove-favorite',
    },
    label: ' ',
    custom: element => ({
      merchant: element.dataset.merchantName,
    }),
  },
];
