import {
  HOME_SIGN_IN_NAG_CLOSE_LARGE_NAG_AND_OPEN_SMALL,
  HOME_SIGN_IN_NAG_OPEN_LARGE_WITH_ANIMATION,
  HOME_SIGN_IN_NAG_SHOW_TERMS,
} from '../actions/types';

const initialState = {
  showSmallSignInNag: false,
  showLargeSignInNag: false,
  renderLargeSignInNagWithAnimation: false,
  scrollToTerms: false,
};

const homeSignInNag = (state = initialState, action) => {
  switch (action.type) {
    case HOME_SIGN_IN_NAG_OPEN_LARGE_WITH_ANIMATION: {
      return {
        ...state,
        showLargeSignInNag: true,
        showSmallSignInNag: false,
        renderLargeSignInNagWithAnimation: true,
        scrollToTerms: false,
      };
    }
    case HOME_SIGN_IN_NAG_CLOSE_LARGE_NAG_AND_OPEN_SMALL: {
      return {
        ...state,
        showLargeSignInNag: false,
        showSmallSignInNag: true,
      };
    }
    case HOME_SIGN_IN_NAG_SHOW_TERMS: {
      return {
        ...state,
        showLargeSignInNag: true,
        showSmallSignInNag: false,
        renderLargeSignInNagWithAnimation: false,
        scrollToTerms: true,
      };
    }
    default:
      return state;
  }
};

export default homeSignInNag;
