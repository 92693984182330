import { QUICK_SEARCH_GET_SUCCESS, QUICK_SEARCH_GET_FAILURE } from '../actions/types';
import config from '../configs';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  merchants: [],
};

const quickSearchMerchants = (state = initialState, action) => {
  switch (action.type) {
    case QUICK_SEARCH_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        merchants: action.payload.response,
      };
    }
    case QUICK_SEARCH_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        merchants: [],
      };
    }

    default: {
      const { options = {} } = action;

      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === QUICK_SEARCH_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};

export default quickSearchMerchants;
