import { getEmailPopupCookie } from 'core/utils/helpers/emailPopupCookie';
import { EMAIL_OPT_IN_MODAL_SUCCESS_CLOSE } from '../actions/types';

export const emailOptInSuccessModalGaActionEvents = {
  [EMAIL_OPT_IN_MODAL_SUCCESS_CLOSE]: () => {
    const { seenCount = 0 } = getEmailPopupCookie();

    return {
      category: 'Email Opt In',
      action: 'Email Success Close',
      label: `Email Success Close|${seenCount + 1}`,
    };
  },
};
