import { SUCCESS_SUFFIX, FAILURE_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/favoriteStores';

export const FAVORITE_STORES_GET_SUCCESS = `${rootActionPath}/GET_${SUCCESS_SUFFIX}`;
export const FAVORITE_STORES_GET_FAILURE = `${rootActionPath}/GET_${FAILURE_SUFFIX}`;

export const CLEAR_FAVORITE_STORES_LIST = `${rootActionPath}/CLEAR_LIST`;

export const FAVORITE_STORES_VIEW = `${rootActionPath}/VIEW`;
export const FAVORITE_STORES_MANAGE_FAVORITES_TILE_VIEW = `${rootActionPath}/manageFavoritesTile/VIEW`;
