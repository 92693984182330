import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { dynamicMerchant as orgConfig } from 'org/configs/modules.config';
import { RANDOM, RECOMMENDED } from 'core/modules/DynamicMerchants/utils/sortTypes';
import { DYNAMIC_MERCHANTS_GET_SUCCESS, DYNAMIC_MERCHANTS_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: DYNAMIC_MERCHANTS_GET_SUCCESS,
        failure: DYNAMIC_MERCHANTS_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
      params: {
        sort_type: 'asc',
        sort_by: RECOMMENDED,
        content_type_id: 58,
        fields: [
          'id',
          'assets',
          'clickUrl',
          'merchant.id',
          'merchant.logoUrls',
          'merchant.rebate',
          'merchant.name',
          'merchant.showRebate',
          'merchant.offersCount',
          'merchant.categories',
        ].join(','),
        section_id: 10225,
      },
    },
    minMerchantsForSort: 13,
    checkShouldShowMenu: (shouldUseCarousel, merchantsNumberOnInitialLoad, minMerchantsForSort) => (
      !shouldUseCarousel && merchantsNumberOnInitialLoad >= minMerchantsForSort
    ),
    slick: {
      small: {
        infinite: false,
        swipeToSlide: true,
        slidesToShow: 6,
        dots: true,
        arrows: false,
        responsive: [
          { breakpoint: 1024, settings: { slidesToShow: 5 } },
          { breakpoint: 768, settings: { slidesToShow: 4 } },
          { breakpoint: 672, settings: { slidesToShow: 3.1 } },
          { breakpoint: 567, settings: { slidesToShow: 2.2 } },
          { breakpoint: 350, settings: { slidesToShow: 1.2 } },
        ],
      },
      medium: {
        infinite: false,
        swipeToSlide: true,
        slidesToShow: 1,
        dots: true,
        arrows: false,
        responsive: [
          { breakpoint: 1024, settings: { slidesToShow: 4 } },
          { breakpoint: 1008, settings: { slidesToShow: 3 } },
          { breakpoint: 768, settings: { slidesToScroll: 1, slidesToShow: 2.2 } },
          { breakpoint: 460, settings: { slidesToShow: 1.2 } },
        ],
      },
      large: {
        infinite: false,
        swipeToSlide: true,
        slidesToShow: 1,
        dots: true,
        arrows: false,
        responsive: [
          { breakpoint: 1024, settings: { slidesToShow: 3 } },
          { breakpoint: 768, settings: { slidesToShow: 2.2 } },
          { breakpoint: 672, settings: { slidesToShow: 1.1 } },
        ],
      },
    },
  },
  sweeps: {
    api: {
      params: {
        offer_tag: 'sweeps-merchant-module-small-logo',
        sort_by: RANDOM,
        sort_type: null,
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
