import React from 'react';
import PropTypes from 'prop-types';
import Pill from '../Pill/Pill';

import './NewPill.scss';

function NewPill({ label }) {
  return <Pill className="mn_newPill" label={label} />;
}

NewPill.propTypes = {
  label: PropTypes.string,
};

NewPill.defaultProps = {
  label: 'New',
};

export default NewPill;
