import {
  ONBOARDING_SCREEN_VIEW, EMAIL_OPT_IN, FAVORITES_DONE_CLICK, ONBOARDING_BUTTON_CHOICE_CLICK, CLOSE_BUTTON_CLICK,
} from '../actions/types';

const GA_EVENT_CATEGORY = 'Onboarding';

export const onboardingGaActionEvents = {
  [ONBOARDING_SCREEN_VIEW]: ({
    step, stepName, totalSteps, pageName,
  }) => {
    let action;
    let label = `${step} of ${totalSteps}`;
    switch (stepName) {
      case 'welcome':
        action = 'Welcome slide view';
        break;
      case 'email':
        action = 'Email slide view';
        break;
      case 'favorites':
        action = 'Favorites slide view';
        break;
      case 'button':
        action = 'Button slide view';
        break;
      case 'mobileCongratulations':
        action = 'Congratulations banner view';
        label = pageName;
        break;
      default:
        break;
    }

    return {
      category: GA_EVENT_CATEGORY,
      action,
      label,
      value: 1,
    };
  },
  [EMAIL_OPT_IN]: ({ sequenceValue, optedIn }) => {
    const action = optedIn ? 'Email opt-in' : 'Email opt-out';

    return {
      category: GA_EVENT_CATEGORY,
      action,
      label: sequenceValue,
      value: 1,
    };
  },
  [FAVORITES_DONE_CLICK]: ({ action, numFavorited }) => ({
    category: GA_EVENT_CATEGORY,
    action,
    label: `${numFavorited}`,
    value: 1,
  }),
  [ONBOARDING_BUTTON_CHOICE_CLICK]: ({ sequenceValue, action }) => ({
    category: GA_EVENT_CATEGORY,
    action,
    label: sequenceValue,
    value: 1,
  }),
  [CLOSE_BUTTON_CLICK]: ({ step, totalSteps }) => ({
    category: GA_EVENT_CATEGORY,
    action: 'Exit onboarding flow',
    label: `${step} of ${totalSteps}`,
    value: 1,
  }),
};

export const onboardingClickAndHoverEvents = [
  {
    selector: '.mn_onboarding .mn_stepWelcomeDone',
    click: {
      category: GA_EVENT_CATEGORY,
      action: 'Welcome next click',
      value: 1,
    },
    label: (element) => {
      const { sequenceValue } = element.dataset;
      return sequenceValue;
    },
  },
];
