import { SUCCESS_SUFFIX, FAILURE_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/HybridSearchForm';
export const HYBRID_SEARCH_REDIRECT_TYPE_PRODUCT = 'search.product';
export const HYBRID_SEARCH_REDIRECT_TYPE_MERCHANT_MULTIPLE = 'search.merchant.multiple';
export const HYBRID_SEARCH_REDIRECT_TYPE_MERCHANT_SINGLE = 'search.merchant.single';
export const HYBRID_SEARCH_REDIRECT_TYPE_MERCHANT_TOMBSTONE = 'search.merchant.tombstone';
export const HYBRID_SEARCH_REDIRECT_TYPE_NO_RESULTS = 'search.noResults';
export const HYBRID_SEARCH_REDIRECT_TYPE_URL = 'search.url';

export const HYBRID_SEARCH_FORM_GET_SUCCESS = `${rootActionPath}/GET_${SUCCESS_SUFFIX}`;
export const HYBRID_SEARCH_FORM_GET_FAILURE = `${rootActionPath}/GET_${FAILURE_SUFFIX}`;
export const HYBRID_SEARCH_FORM_SUBMIT = `${rootActionPath}/submit`;
export const HYBRID_SEARCH_FORM_REDIRECT = `${rootActionPath}/redirect`;
export const HYBRID_SEARCH_FORM_ACTIVATION = `${rootActionPath}/activation`;
export const HYBRID_SEARCH_FORM_QUICK_SEARCH_REDIRECT = `${rootActionPath}/quickSearch/redirect`;
