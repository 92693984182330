import {
  SET_PERMISSIONS_VIEW, NON_SAFARI_VIEW, ACTIVATION_REMINDER_A_VIEW, ACTIVATION_REMINDER_B_VIEW,
  ACTIVATION_REMINDER_C_VIEW, COUPON_FINDER_A_VIEW, COUPON_FINDER_B_VIEW, COUPON_FINDER_C_VIEW,
  PRICE_COMPARISON_VIEW, SEARCH_INTEGRATION_VIEW, STORE_OFFERS_SETTINGS_VIEW,
} from '../actions/types';

export const safariButtonInstallTutorialGaActionEvents = {
  [SET_PERMISSIONS_VIEW]: () => (
    {
      category: 'Desktop Safari Button',
      action: 'Set Permissions Screen View',
      value: '1',
    }
  ),
  [NON_SAFARI_VIEW]: () => (
    {
      category: 'Desktop Safari Button',
      action: 'Non-Safari Desktop Tutorial Screen view',
      value: '1',
    }
  ),
  [ACTIVATION_REMINDER_A_VIEW]: () => (
    {
      category: 'Desktop Safari Button',
      action: 'Step 1a - Activation Reminder view',
      value: '1',
    }
  ),
  [ACTIVATION_REMINDER_B_VIEW]: () => (
    {
      category: 'Desktop Safari Button',
      action: 'Step 1b - Activation Reminder view',
      value: '1',
    }
  ),
  [ACTIVATION_REMINDER_C_VIEW]: () => (
    {
      category: 'Desktop Safari Button',
      action: 'Step 1c - Activation Reminder view',
      value: '1',
    }
  ),
  [COUPON_FINDER_A_VIEW]: () => (
    {
      category: 'Desktop Safari Button',
      action: 'Step 2a - Coupon Finder view',
      value: '1',
    }
  ),
  [COUPON_FINDER_B_VIEW]: () => (
    {
      category: 'Desktop Safari Button',
      action: 'Step 2b - Coupon Finder view',
      value: '1',
    }
  ),
  [COUPON_FINDER_C_VIEW]: () => (
    {
      category: 'Desktop Safari Button',
      action: 'Step 2c - Coupon Finder view',
      value: '1',
    }
  ),
  [PRICE_COMPARISON_VIEW]: () => (
    {
      category: 'Desktop Safari Button',
      action: 'Step 3 - Price Comparison view',
      value: '1',
    }
  ),
  [SEARCH_INTEGRATION_VIEW]: () => (
    {
      category: 'Desktop Safari Button',
      action: 'Step 4 - Search Integration view',
      value: '1',
    }
  ),
  [STORE_OFFERS_SETTINGS_VIEW]: () => (
    {
      category: 'Desktop Safari Button',
      action: 'Step 5 - Store Offers & Settings view',
      value: '1',
    }
  ),
};

export const safariButtonInstallTutorialClickAndHoverEvents = [
  {
    selector: '.mn_safariButtonInstallTutorial .mn_nonSafariTutorial .mn_copyLinkButton',
    click: {
      category: 'Desktop Safari Button',
      action: 'Non-Safari Desktop Tutorial Screen click',
    },
    value: 1,
  },
  {
    selector: '.mn_safariButtonInstallTutorial .mn_tutorialVideos .mn_exitButton',
    click: {
      category: 'Desktop Safari Button',
      action: 'Tutorial Close with "X"',
    },
    value: 1,
  },
  {
    selector: '.mn_safariButtonInstallTutorial .mn_activationReminder .mn_activateButton',
    click: {
      category: 'Desktop Safari Button',
      action: 'Step 1a - Activation Reminder click',
    },
    value: 1,
  },
  {
    selector: '.mn_safariButtonInstallTutorial .mn_activationReminder .mn_nextButton',
    click: {
      category: 'Desktop Safari Button',
      action: 'Step 1c - Activation Reminder click',
    },
    value: 1,
  },
  {
    selector: '.mn_safariButtonInstallTutorial .mn_couponFinder .mn_applyButton',
    click: {
      category: 'Desktop Safari Button',
      action: 'Step 2a - Coupon Finder click',
    },
    value: 1,
  },
  {
    selector: '.mn_safariButtonInstallTutorial .mn_couponFinder .mn_nextButton',
    click: {
      category: 'Desktop Safari Button',
      action: 'Step 2c - Coupon Finder click',
    },
    value: 1,
  },
  {
    selector: '.mn_safariButtonInstallTutorial .mn_priceComparison .mn_nextButton',
    click: {
      category: 'Desktop Safari Button',
      action: 'Step 3 - Price Comparison click',
    },
    value: 1,
  },
  {
    selector: '.mn_safariButtonInstallTutorial .mn_searchIntegration .mn_nextButton',
    click: {
      category: 'Desktop Safari Button',
      action: 'Step 4 - Search Integration click',
    },
    value: 1,
  },
  {
    selector: '.mn_safariButtonInstallTutorial .mn_storeOfferSettings .mn_getStartedButton',
    click: {
      category: 'Desktop Safari Button',
      action: 'Step 5 - Store Offers & Settings click',
    },
    value: 1,
  },
  {
    selector: '.mn_safariButtonInstallTutorial .mn_tutorialSteps .mn_watchAgainButton',
    click: {
      category: 'Desktop Safari Button',
      action: 'Step 5 - Watch again',
    },
    value: 1,
  },
];
