import { SUCCESS_SUFFIX, FAILURE_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/InstoreCuratedOffers';

export const INSTORE_CURATED_OFFERS_GET_SUCCESS = `${rootActionPath}/Content/GET_${SUCCESS_SUFFIX}`;
export const INSTORE_CURATED_OFFERS_GET_FAILURE = `${rootActionPath}/Content/GET_${FAILURE_SUFFIX}`;

export const INSTORE_CURATED_OFFERS_HEADING_FILL = `${rootActionPath}/Heading/Fill`;
export const INSTORE_CURATED_OFFERS_HEADING_GET_SUCCESS = `${rootActionPath}/Heading/GET_${SUCCESS_SUFFIX}`;
export const INSTORE_CURATED_OFFERS_HEADING_GET_FAILURE = `${rootActionPath}/Heading/GET_${FAILURE_SUFFIX}`;
