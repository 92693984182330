import { BECAUSE_YOU_SHOPPED_GET_SUCCESS, BECAUSE_YOU_SHOPPED_GET_FAILURE } from '../actions/types';
import becauseYouShoppedConfig from '../configs';

/** @type {{config: object}} */

const initialState = {
  config: becauseYouShoppedConfig,
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const becauseYouShopped = (state = initialState, action) => {
  switch (action.type) {
    case BECAUSE_YOU_SHOPPED_GET_SUCCESS: {
      return {
        ...state,
        [action.context.data.id]: {
          merchants: action.payload.response,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case BECAUSE_YOU_SHOPPED_GET_FAILURE: {
      return {
        ...state,
        [action.context.data.id]: {
          merchants: [],
          isLoading: false,
          isLoaded: true,
        },
      }; // we'd have to decide what to do with error state
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === BECAUSE_YOU_SHOPPED_GET_SUCCESS) {
        return {
          ...state,
          [action.data.id]: {
            isLoading: true,
          },
        };
      }

      return state;
    }
  }
};

export default becauseYouShopped;
