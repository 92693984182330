import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { marketingMerchantsHome as orgConfig } from 'org/configs/modules.config';
import {
  MARKETING_MERCHANTS_HOME_STORES_GET_SUCCESS,
  MARKETING_MERCHANTS_HOME_STORES_GET_FAILURE,
  MARKETING_MERCHANTS_HOME_TITLE_GET_SUCCESS,
  MARKETING_MERCHANTS_HOME_TITLE_GET_FAILURE,
} from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    merchantsConfig: {
      api: {
        params: {
          content_group_id: 15529,
          with_offers: 1,
          limit: 24,
          sort_by: 'random',
          fields: [
            'merchant.logoUrls',
            'merchant.showRebate',
            'merchant.rebate',
            'merchant.id',
            'merchant.name',
            'merchant.clickUrl',
            'merchant.offers',
          ],
        },
        options: {
          success: MARKETING_MERCHANTS_HOME_STORES_GET_SUCCESS,
          failure: MARKETING_MERCHANTS_HOME_STORES_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
      },
    },
    titlesConfig: {
      api: {
        params: {
          content_group_id: 23757,
          fields: [
            'assets.shortSummaryText',
            'assets.callToActionText',
            'clickUrl',
          ],
        },
        options: {
          success: MARKETING_MERCHANTS_HOME_TITLE_GET_SUCCESS,
          failure: MARKETING_MERCHANTS_HOME_TITLE_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
      },
    },
    useAddToFavorite: true,
    useCarousel: true,
    checkShouldShowSliderCounter:
      (activeMQ, shouldRenderSliderCounter) => (['XS', 'S'].includes(activeMQ) && shouldRenderSliderCounter),
    shouldRenderSliderCounter: true,
    shouldRenderLinkInsideTileListOnMobile: true,
    secTitlePill: 'extra',
    slick: {
      slidesToShow: 7,
      centerMode: false,
      variableWidth: true,
      arrows: true,
      dots: false,
      infinite: false,
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            variableWidth: true,
            slidesToShow: 5,
            arrows: false,
            dots: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            arrows: false,
            dots: false,
          },
        },
      ],
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
