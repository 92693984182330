import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { featuredOffers502x272 as orgConfig } from 'org/configs/modules.config';
import {
  FEATURED_OFFERS_502X272_GET_SUCCESS,
  FEATURED_OFFERS_502X272_GET_FAILURE,
} from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: FEATURED_OFFERS_502X272_GET_SUCCESS,
        failure: FEATURED_OFFERS_502X272_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
      params: {
        sort_by: 'random',
        group_by_merchant: 0,
        content_type_id: 60, // Featured 502x272
        fields: [
          'id',
          'assets',
          'clickUrl',
          'merchant',
          'offerTitle',
          'offerDetails',
          'couponCode',
          'disclaimer',
          'stopDatetime',
          'expires',
        ].join(','),
        section_id: 10224,
      },
    },
    useCarousel: true,
    slick: {
      infinite: false,
      swipeToSlide: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    },
  },
  fs: {
    api: {
      params: {
        content_group_id: 17208,
      },
    },
  },
  sweeps: {
    useCarousel: false,
    api: {
      params: {
        offer_tag: 'sweeps-merchant-module-502-272',
        content_type_id: null,
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
