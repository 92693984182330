import config from '../configs';
import { BONUS_TIERS_GET_SUCCESS, BONUS_TIERS_GET_FAILURE } from '../actions/types';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  bonus: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BONUS_TIERS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        bonus: action.payload.response,
      };
    }
    case BONUS_TIERS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        bonus: [],
      };
    }
    default: {
      const { options = {} } = action;

      if (options.success === BONUS_TIERS_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};
