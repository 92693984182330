import isEmpty from 'lodash/isEmpty';

/**
 * Get host-name from url
 * @param {string} url
 * @return {string}
 */
export const extractHostname = (url = '') => {
  const hostnameRaw = url.indexOf('://') > -1 ? url.split('/')[2] : url.split('/')[0];
  const [hostnameNoPort] = hostnameRaw.split(':');
  const [hostname] = hostnameNoPort.split('?');

  return hostname.toLowerCase();
};

/**
 * Get root-level domain from url
 * @param {string} url
 * @return {string}
 */
export const extractRootDomain = (url = '') => {
  let domain = extractHostname(url);
  const domainParts = domain.split('.');
  const partsLength = domainParts.length;

  if (partsLength > 2) {
    domain = `${domainParts[partsLength - 2]}.${domainParts[partsLength - 1]}`;

    if (domainParts[partsLength - 2].length === 2 && domainParts[partsLength - 1].length === 2) {
      domain = `${domainParts[partsLength - 3]}.${domain}`;
    }
  }

  return domain;
};

/**
 * @param {string} url
 * @param {string} domain
 * @param {boolean} looseMatch
 * @return {boolean}
 */
export const isSameDomain = (url = '', domain = '', looseMatch = false) => {
  const hostname = extractHostname(url);
  const domainLower = domain.toLowerCase();
  const isExactMatch = isEmpty(hostname) || hostname === domainLower;

  if (isExactMatch) {
    return true;
  }

  return looseMatch && hostname.substr(-domainLower.length) === domainLower;
};

/**
 * @param {string} url
 * @return {boolean}
 */
export const isClickLink = url => url.indexOf('/click.php') > -1;

/**
 * Generate the url search string from passed object's parameters
 * @param {object} data
 * @return {string}
 */
export const encodeQueryString = (data) => {
  const ret = [];
  Object.entries(data).forEach(([key, value]) => {
    if (typeof value !== 'undefined') {
      ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  });
  return ret.join('&');
};

/**
 * Redirect to the specific page on the mall
 * @param {string} siteUrl
 * @param {string} pathname
 * @param {string} search
 */
export const redirectToTheTargetPage = (siteUrl, pathname, search) => {
  window.location.href = search ? `${siteUrl}/${pathname}?${search}` : `${siteUrl}/${pathname}`;
};
