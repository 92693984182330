import {
  NAV_SEASONAL_FLYOUT_PLACEMENTS_GET_SUCCESS,
  NAV_SEASONAL_FLYOUT_PLACEMENTS_GET_FAILURE,
} from '../actions/types';

import navSeasonalFlyoutConfig from '../configs';

const initialState = {
  config: navSeasonalFlyoutConfig,
  isLoaded: false,
  isLoading: false,
  placements: [],
};

const navSeasonalFlyout = (state = initialState, action) => {
  switch (action.type) {
    case NAV_SEASONAL_FLYOUT_PLACEMENTS_GET_SUCCESS: {
      const placements = action.payload.response;
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        placements,
      };
    }
    case NAV_SEASONAL_FLYOUT_PLACEMENTS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        placements: [],
      };
    }
    default: {
      const { options = {} } = action;

      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === NAV_SEASONAL_FLYOUT_PLACEMENTS_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};

export default navSeasonalFlyout;
