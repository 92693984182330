import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { categoryMerhcantsTable as orgConfig } from 'org/configs/modules.config';
import { CATEGORY_MERCHANTS_TABLE_GET_SUCCESS, CATEGORY_MERCHANTS_TABLE_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        section_id: 10217,
        sort_by: 'featured',
        sort_type: 'asc',
        limit: 40,
        with_offers: 1,
      },
      options: {
        success: CATEGORY_MERCHANTS_TABLE_GET_SUCCESS,
        failure: CATEGORY_MERCHANTS_TABLE_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    paginationOptions: {
      pageSizeList: [10, 20, 40],
      pageSize: 40,
      pageNumber: 1,
    },
    sortOptions: [
      {
        sortBy: 'featured',
        sortType: 'asc',
        viewName: 'Featured',
      },
      {
        sortBy: 'earn_rate',
        sortType: 'desc',
        viewName: 'Highest earn rate',
      },
      {
        sortBy: 'name',
        sortType: 'asc',
        viewName: 'Store (A-Z)',
      },
    ],
    rebateOptions: {
      showRebateInline: true,
      hideRebatePrefix: true,
      hideElevationCurrency: true,
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
