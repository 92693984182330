import { MARKETING_BANNER_VIEW } from '../actions/types';

export const marketingBannerGaActionEvents = {
  [MARKETING_BANNER_VIEW]: ({ placement, groupId }) => {
    const { assets: { imageAlternateText }, id } = placement;

    return {
      category: 'mktbanner',
      action: 'View',
      label: `${groupId}|${id}|${imageAlternateText}`,
    };
  },
};

export const marketingBannerClickAndHoverEvents = [
  {
    selector: '.mn_marketingBanner .mn_bannerLink',
    click: {
      category: 'mktbanner',
      action: 'Click ',
    },
    label: (element) => {
      const imgVideoEl = element.querySelectorAll('.mn_bannerImage, .mn_bannerVideo');
      const imgVideoAltText = imgVideoEl.length > 0 ? imgVideoEl[0].alt || '' : '';

      const { placementId, contentGroupId } = element.dataset;

      return `${contentGroupId}|${placementId}|${imgVideoAltText}`;
    },
  },
];
