const getShortSummarySplitText = (shortSummaryText) => {
  let main;
  let prefix;
  let suffix;

  if (shortSummaryText && shortSummaryText.length > 0) {
    const pattern = /\$?(\d+|\d+-\d+)%?\soff\.?/i; // matches ['$10 off', '500% OFF.', '1% off.', '20-40% off'], etc
    const regexResult = pattern.exec(shortSummaryText);

    if (!regexResult) {
      if (shortSummaryText.toLowerCase() === 'gift with purchase') {
        main = 'Gift';
        suffix = 'with purchase';
      } else if (shortSummaryText.toLowerCase() === 'free subscription') {
        main = 'Free';
        suffix = 'Subscription';
      } else if (shortSummaryText.match(/friends & family/i)) {
        // Handle "Friends & Family" case
        main = shortSummaryText.replace(/riends &/, 'riends <br>&');
      } else {
        // if no match then it is a simple string without prefix and suffix
        main = shortSummaryText;
      }
    } else {
      prefix = shortSummaryText.slice(0, regexResult.index) || undefined;
      main = regexResult[0] || undefined;
      suffix = shortSummaryText.slice(regexResult.index + main.length, shortSummaryText.length) || undefined;
    }
  }

  return {
    prefix,
    main,
    suffix,
    isLongString: main && main.length > 10,
  };
};

const assetsParser = (assets = {}) => {
  const { shortSummaryText } = assets;

  if (!(shortSummaryText && shortSummaryText.length)) {
    return assets;
  }

  const shortSummarySplitText = getShortSummarySplitText(shortSummaryText);

  return {
    ...assets,
    shortSummarySplitText,
  };
};

export default assetsParser;
