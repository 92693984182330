import {
  MEMBER_REGISTER_TOKEN,
  MEMBER_REGISTER_TOKEN_FAILURE,
  MEMBER_REGISTER_TOKEN_SUCCESS,
  MEMBER_SET_ONBOARDING_PROPERTY_SUCCESS,
} from 'core/actions/member';
import {
  INSTORE_ADD_CARD_SUCCESS_MESSAGE,
  INSTORE_ADD_CARD_SUCCESSFULL,
  INSTORE_ADD_NEW_CARD_MODAL_CLEAR,
  INSTORE_ADD_NEW_CARD_MODAL_TRIGGER,
} from '../actions/types';

const initialState = {};

const instoreAddNewCardModal = (state = initialState, action) => {
  switch (action.type) {
    case INSTORE_ADD_NEW_CARD_MODAL_TRIGGER: {
      return { ...state, dataInfo: action.data };
    }
    case INSTORE_ADD_NEW_CARD_MODAL_CLEAR: {
      return initialState;
    }
    case MEMBER_REGISTER_TOKEN: {
      return { ...state };
    }
    case MEMBER_REGISTER_TOKEN_FAILURE: {
      return { ...state, tokenFailureMessage: action.payload.name };
    }
    case MEMBER_REGISTER_TOKEN_SUCCESS: {
      return { ...state, tokenResponse: action.payload.response };
    }
    case INSTORE_ADD_CARD_SUCCESSFULL: {
      return {
        ...state,
        addCardSuccessMessage: INSTORE_ADD_CARD_SUCCESS_MESSAGE,
      };
    }
    case MEMBER_SET_ONBOARDING_PROPERTY_SUCCESS: {
      window.instoreData.isAddCardOnBoardingComplete = true;
      return { ...state };
    }
    default:
      return state;
  }
};

export default instoreAddNewCardModal;
