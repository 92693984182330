import { GIFT_GUIDE_HEADLINE_GET_SUCCESS, GIFT_GUIDE_HEADLINE_GET_FAILURE } from '../actions/types';

const initialState = {
  isLoading: false,
  isLoaded: false,
  placement: {},
};

const giftGuideHeadline = (state = initialState, action) => {
  switch (action.type) {
    case GIFT_GUIDE_HEADLINE_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        placement: action.payload.response[0],
      };
    }
    case GIFT_GUIDE_HEADLINE_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        placement: {},
      };
    }
    default: {
      const { options = {} } = action;

      if (options.success === GIFT_GUIDE_HEADLINE_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};

export default giftGuideHeadline;
