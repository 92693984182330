import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { seasonalFeatured as orgConfig } from 'org/configs/modules.config';
import { SEASONAL_FEATURED_GET_SUCCESS, SEASONAL_FEATURED_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        section_id: 10459,
      },
      options: {
        success: SEASONAL_FEATURED_GET_SUCCESS,
        failure: SEASONAL_FEATURED_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
