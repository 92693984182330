import keyBy from 'lodash/keyBy';

export const favoritesParser = (favoritesList) => {
  const favoritesIndex = keyBy(favoritesList, 'merchantId');
  const hasFavorited = !!favoritesIndex[0];

  if (hasFavorited) {
    delete favoritesIndex[0];
  }

  return {
    hasFavorited,
    favorites: Object.keys(favoritesIndex).reduce((index, merchantId) => {
      const merchantIdNum = Number(merchantId);
      index[merchantIdNum] = true; // eslint-disable-line no-param-reassign
      return index;
    }, {}),
  };
};
