import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { relatedCategoryFeaturedStores as orgConfig } from 'org/configs/modules.config';

/** @type {object} */
const defaultConfig = {
  defaults: {
    useCarousel: true,
    maxCategories: 2,
    maxFeaturedMerchantsPerCategory: 6,
    slick: {
      slidesToShow: 2,
      slidesToScroll: 2,
      centerMode: false,
      arrows: false,
      dots: true,
      responsive: [],
    },
    currency: 'points',
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
