import React from 'react';

function InstoreMerchantRebate({ offer, showInstoreRebatePrefix }) {
  if (!offer.displayRate) {
    return null;
  }

  const instoreRebatePrefix = showInstoreRebatePrefix ? 'Earn' : '';
  const { displayRate, currency } = offer;

  return (
    <div className="mn_instoreMerchantRebate">
      {instoreRebatePrefix} {displayRate} {currency}
    </div>
  );
}

InstoreMerchantRebate.defaultProps = {
  offer: {},
  showInstoreRebatePrefix: true,
};

export default InstoreMerchantRebate;
