import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { getId } from 'core/utils/analytics/sectionInfo';
import { featuredDeals as orgConfig } from 'org/configs/modules.config';
import {
  FEATURED_DEALS_GET_SUCCESS, FEATURED_DEALS_GET_FAILURE,
  FEATURED_DEALS_HEADING_GET_FAILURE, FEATURED_DEALS_HEADING_GET_SUCCESS,
} from '../actions/types';

const defaultConfig = {
  defaults: {
    shouldFetchHeadingFromAPI: false,
    checkShouldUseCarousel: () => false,
    useAddToFavorite: true,
    getShouldUseFeaturedDealsRoundedView: () => false,
    sectionTitle: 'Featured deals',
    getMotto: () => '',
    dateFormat: 'MMM DD, YYYY',
    getViewAllLinkText: (isMobileMQ) => (
      isMobileMQ ? 'View all offers' : 'View all deals'
    ),
    rebateDesktopOptions: {
      showRebateInline: true,
      hideElevationCurrency: true,
      showRebateTierName: true,
      hideRebatePrefix: true,
    },
    rebateMobileOptions: {
      showRebateTierName: true,
      hideElevationCurrency: true,
      showRebateInline: true,
    },
    contentApi: {
      params: {
        limit: 12,
        sort_by: 'random',
        fields: [
          'assets',
          'clickUrl',
          'couponCode',
          'stopDatetime',
          'id',
          'merchant.logoUrls',
          'merchant.showRebate',
          'merchant.rebate',
          'merchant.id',
          'merchant.name',
          'merchant.tiers',
          'highlightedMerchantTierId',
        ],
      },
      options: {
        success: FEATURED_DEALS_GET_SUCCESS,
        failure: FEATURED_DEALS_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    headingApi: {
      options: {
        success: FEATURED_DEALS_HEADING_GET_SUCCESS,
        failure: FEATURED_DEALS_HEADING_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
      params: {},
    },
    slick: {
      slidesToShow: 5,
      arrows: false,
      dots: false,
      variableWidth: true,
      infinite: false,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    },
  },
  h: {
    contentApi: {
      params: {
        offer_tag: 'home-featured-deals',
        section_id: getId('home-featured'),
      },
    },
    getShouldUseFeaturedDealsRoundedView: (isUserIdentified) => (isUserIdentified),
  },
  ai: {
    shouldFetchHeadingFromAPI: true,
    checkShouldUseCarousel: (isMobileMQ) => !isMobileMQ,
    contentApi: {
      params: {
        section_id: getId('home-featured'),
        content_group_id: 26699,
      },
    },
    headingApi: {
      params: {
        content_group_id: 26700,
        limit: 1,
        fields: [
          'assets.shortSummaryText',
          'assets.callToActionText',
          'clickUrl',
        ],
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
