import React, { useEffect, useRef, forwardRef } from 'react';

const AutomaticVideoPlayer = forwardRef((props, ref) => {
  const videoElement = useRef();

  const isInViewport = () => {
    if (!videoElement.current) return false;
    const bounding = videoElement.current.getBoundingClientRect();
    const stickyHeaderHeight = document.getElementsByClassName('mn_pageHeader')[0].offsetHeight || 0;

    return (
      bounding.bottom >= bounding.height + stickyHeaderHeight &&
      bounding.top <= (window.innerHeight || document.documentElement.clientHeight) - bounding.height
    );
  };

  const initVideo = () => {
    if (!isInViewport()) {
      videoElement?.current?.pause();
    } else {
      videoElement?.current?.play();
    }
  };

  useEffect(() => {
    initVideo();
    document.addEventListener('scroll', initVideo);

    return () => {
      document.removeEventListener('scroll', initVideo);
    };
  }, []);

  return (
    <video ref={ref} playsInline autoPlay loop muted {...props} />
  );
});

export default AutomaticVideoPlayer;
