import { SUCCESS_SUFFIX, FAILURE_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/recentStoresFlyout';

export const CLICK_HISTORY_GET_SUCCESS = `${rootActionPath}/clickHistory/GET_${SUCCESS_SUFFIX}`;
export const CLICK_HISTORY_GET_FAILURE = `${rootActionPath}/clickHistory/GET_${FAILURE_SUFFIX}`;

export const ALL_MERCHANTS_GET_SUCCESS = `${rootActionPath}/allMerchants/GET_${SUCCESS_SUFFIX}`;
export const ALL_MERCHANTS_GET_FAILURE = `${rootActionPath}/allMerchants/GET_${FAILURE_SUFFIX}`;

export const RECENT_STORES_FLYOUT_VIEW = `${rootActionPath}/flyout/VIEW`;
export const RECENT_STORES_FLYOUT_TOGGLE = `${rootActionPath}/flyout/TOGGLE`;

export const CLICK_HISTORY_FROM_CACHE_GET_SUCCESS = `${rootActionPath}/clickHistory/Cache/GET_${SUCCESS_SUFFIX}`;
export const ALL_MERCHANTS_FROM_CACHE_GET_SUCCESS = `${rootActionPath}/allMerchants/Cache/GET_${SUCCESS_SUFFIX}`;

export const CLICK_HISTORY_GET = `${rootActionPath}/clickHistory/GET`;
export const ALL_MERCHANTS_GET = `${rootActionPath}/allMerchants/GET`;
