import { findAncestorByClassName } from 'core/utils/helpers/dom';
import { getPageName, getDisplayPageName } from 'core/utils/analytics/pageInfo';
import { RECOMMENDED, ALPHABETICAL, EARN_RATE } from 'core/modules/DynamicMerchants/utils/sortTypes';
import { DYNAMIC_MERCHANTS_FILTER_CHANGED } from '../actions/types';

const getLabel = (element) => {
  const dynamicMerchant = findAncestorByClassName(element, 'mn_dynamicMerchant') || {};
  const { merchantName, logoTreatment, viewType } = dynamicMerchant.dataset;
  const dynamicMerchants = findAncestorByClassName(element, 'mn_dynamicMerchants') || {};
  const { title } = dynamicMerchants.dataset;

  return `${merchantName} | ${title} | ${logoTreatment}-${viewType} layout`;
};

const sortLabels = {
  [RECOMMENDED]: 'ranking',
  [EARN_RATE]: 'earn rate',
  [ALPHABETICAL]: 'store name',
};

export const dynamicMerchantsGaActionEvents = {
  [DYNAMIC_MERCHANTS_FILTER_CHANGED]: ({ selectedFilterName, filteredMerchantsNumber, title }) => (
    {
      category: getPageName(),
      action: `Filter Stores|${title}`,
      label: selectedFilterName,
      value: filteredMerchantsNumber,
    }
  ),
};

export const dynamicMerchantsClickAndHoverEvents = [
  {
    selector: '.mn_dynamicMerchant .mn_favoriteIcon',
    click: {
      category: `${getDisplayPageName()} | logo`,
      action: element => `${element.classList.contains('mn_favorited') ? 'Remove' : 'Add'} favorite`,
    },
    label: getLabel,
    custom: element => ({
      merchant: element.dataset.merchantName,
    }),
  },
  {
    selector: '.mn_dynamicMerchant .mn_dynamicMerchantLink',
    click: {
      category: `${getDisplayPageName()} | logo`,
      action: 'Click',
    },
    label: getLabel,
  },
  {
    selector: '.mn_dynamicMerchants .mn_sortSection button',
    click: {
      category: () => getPageName(),
      action: (element) => {
        const dynamicMerchants = findAncestorByClassName(element, 'mn_dynamicMerchants') || {};
        const { title } = dynamicMerchants.dataset;

        return `Sort Stores|${title}`;
      },
    },
    label: ({ dataset }) => sortLabels[dataset.sortType],
    value: (element) => {
      const dynamicMerchants = findAncestorByClassName(element, 'mn_dynamicMerchants') || {};
      const { merchantsCount } = dynamicMerchants.dataset;
      return merchantsCount;
    },
  },
];
