import { select, takeLatest, put } from 'redux-saga/effects';
import { fetchSearch } from 'core/actions/ocapi';
import { selectSearchFormConfig, selectSearchFormIsLoading, selectSearchFormIsLoaded } from '../selectors';
import { HYBRID_SEARCH_FORM_SUBMIT } from '../actions/types';

function* submitHybridSearch(action) {
  const config = yield select(selectSearchFormConfig);
  const isLoaded = yield select(selectSearchFormIsLoaded);
  const isLoading = yield select(selectSearchFormIsLoading);

  if (!isLoaded && !isLoading) {
    const { api: apiConfig } = config;
    const { options } = apiConfig;
    const { data: { keywords } } = action;

    yield put(fetchSearch({ q: keywords, fields: 'id,type,name' }, options));
  }
}

export default function* hybridSearchFormSaga() {
  yield takeLatest(HYBRID_SEARCH_FORM_SUBMIT, submitHybridSearch);
}
