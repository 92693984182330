export const navSeasonalFlyoutClickAndHoverEvents = [
  {
    selector: '.mn_seasonalFlyout .mn_offerOverlayLink',
    click: {
      category: 'Explore Stores - Flyout',
      action: 'Featured extra rewards merchant click',
    },
    label: (element) => {
      const { merchantId, merchantName } = element.dataset;
      return `${merchantId} | ${merchantName}`;
    },
  },
  {
    selector: '.mn_seasonalFlyoutContainer .mn_offerOverlayLink',
    click: {
      category: 'Seasonal Featured Flyout',
      action: 'Offer Click',
    },
    label: (element) => {
      const { merchantId, merchantName, offerId } = element.dataset;
      return `${merchantName}|gmid:${merchantId}|contentId:${offerId}`;
    },
  },
];
