import React from 'react';

import './SectionTitle.scss';

function SectionTitle({
  title, pill, useHtmlMarkup, viewAllLinkUrl, viewAllLinkTitle, children,
}) {
  return (
    <div className="mn_sectionTitle">
      { pill && <span>{pill}</span> }
      {useHtmlMarkup
        ? <h2 data-test="sectTitle-heading" dangerouslySetInnerHTML={{ __html: title }} />
        : <h2 data-test="sectTitle-heading">{title}</h2>
      }

      { viewAllLinkUrl && <a className="mn_viewAllLink" href={viewAllLinkUrl}>{viewAllLinkTitle}</a>}

      {children}
    </div>
  );
}

SectionTitle.defaultProps = {
  viewAllLinkTitle: 'View all',
};

export default SectionTitle;
