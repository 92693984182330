import dayjs from 'core/utils/dayjs';

export const FAVORITES_COOKIE_NAME = 'favorites';

// Cache key
export const FAVORITES_CACHE_KEY = `member/favorites/${window.initialState.user.userIdHashed}`;

// In order to track members that have previously used the favorites vs. those with currently empty favorites,
// the favorites endpoint returns a marker record with merchantId: 0 to flag those that have used favorites.
export const FAVORITES_USAGE_MARKER_MERCHANT_ID = 0;

// Favorites cache expiration timestamp. Set for 59:59 of the current hour
export const expirationTimestamp = dayjs().startOf('hour').add(3559, 'seconds').valueOf();
