import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { dyEvents } from 'core/utils/analytics/dyAnalytics';
import { trackInsiderKeywordSearchEvent } from 'core/utils/analytics/insiderAnalytics';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { triggerQuickSearchRedirectGaEvent } from 'core/modules/HybridSearchForm/actions';
import { selectQuickSearchConfig, selectQuickSearchMatches } from 'core/modules/HybridQuickSearch/selectors';
import { useHandleKeyboardNavigation } from 'core/hooks/useHandleKeyboardNavigation';
import { sanitizeString } from 'core/utils/helpers/sanitizeString';
import { useGetHybridQuickSearch } from './hooks';
import QuickSearchMatches from './components/QuickSearchMatches';
import QuickSearchMatchesGsp from './components/QuickSearchMatchesGsp';

import './HybridQuickSearch.scss';

function HybridQuickSearch({
  keywords: initialKeywords, innerRef, customConfig = {}, isPopUpView,
}) {
  const keywords = sanitizeString(initialKeywords);

  const dispatch = useDispatch();
  const quickSearchResultsRef = useRef();
  const config = useSelector(state => selectQuickSearchConfig(state, customConfig));
  const quickSearchMatches = useSelector(state => selectQuickSearchMatches(state, keywords, config));
  const { shouldUseGsp } = config;

  useGetHybridQuickSearch(keywords, config);
  useHandleKeyboardNavigation(quickSearchResultsRef, '.mn_quickSearchMatch');

  const merchantClickHandler = (name, type) => {
    dispatch(triggerQuickSearchRedirectGaEvent({
      quickSearchMatches,
      destinationMerchant: { name, type },
      keywords,
    }));
    dyEvents.sendEvent(dyEvents.sendKeyWordSearchEvent, { keywords: name });
    trackInsiderKeywordSearchEvent(name);
  };

  const componentProps = {
    keywords,
    config,
    quickSearchResultsRef,
    isPopUpView,
    merchantClickHandler,
  };

  return (
    <div className="mn_hybridQuickSearch" ref={innerRef}>
      { shouldUseGsp ? <QuickSearchMatchesGsp {...componentProps} /> : <QuickSearchMatches {...componentProps} /> }
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><HybridQuickSearch {...props} /></ComponentErrorBoundary>;
