import { FEATURED_OFFERS_LOGO_VIEW, FEATURED_OFFERS_LOGO_SWIPED_TRIGGER, VIEW_ALL_STORES_VIEW } from '../actions/types';

export const featuredOffersLogoGaActionEvents = {
  [FEATURED_OFFERS_LOGO_VIEW]: ({ merchants }) => (
    {
      category: 'Curated Acq Stores',
      action: 'View',
      label: merchants.map(({ name }) => name).join(','),
    }
  ),
  [FEATURED_OFFERS_LOGO_SWIPED_TRIGGER]: ({ slideIndex, storeCount }) => (
    {
      category: 'Curated Acq Stores',
      action: `Scroll ${slideIndex}`,
      label: `Earn at ${storeCount} stores`,
    }
  ),
  [VIEW_ALL_STORES_VIEW]: () => (
    {
      category: 'VHP CTA',
      action: 'View',
      label: 'View All Stores',
    }
  ),
};

export const featuredOffersLogoClickAndHoverEvents = [
  {
    selector: '.mn_featuredOffersLogo .mn_merchantExperienceLink',
    click: {
      category: 'Curated Acq Stores',
      action: 'Click',
    },
    label: element => element.dataset.merchantName,
  },
  {
    selector: '.mn_VHP .mn_featuredOffersLogo .mn_viewAllLink',
    click: {
      category: 'VHP CTA',
      action: 'Click',
    },
    label: 'View All Stores',
  },
];
