export const externalLinkClickAndHoverEvents = [
  {
    selector: 'a[rel="external"]',
    click: {
      category: 'Outbound',
      action: 'click',
    },
    label: element => element.href,
  },
];

export const forcedloginClickAndHoverEvents = [
  {
    selector: 'a[data-is-forced-login="true"]',
    click: {
      category: 'Login',
      action: 'Forced login start',
    },
    label: 'click',
    custom: element => ({
      merchant: element.dataset.merchantName,
    }),
  },
];
