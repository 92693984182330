import React from 'react';

function TransactionsTabDisclaimer() {
  return (
    <div className="mn_tabDisclaimer">
      <p>
        *Eligible Amount: Some merchants do not offer rewards for certain products and services such as gift
        certificates. Also, taxes and shipping fees are not eligible for rewards. You can get specific details on the
        information page for each merchant by searching for the merchant name.
      </p>
    </div>
  );
}

export default TransactionsTabDisclaimer;
