import * as actions from 'core/actions/gsp';
import responseParsers from './responseParsers';
import BaseApiRequestor from './baseRequestor';

export class GspRequestor extends BaseApiRequestor {
  responseParsersForAPI = {
    [actions.GSP_GET_STORES]: responseParsers.gspParser,
  };
}

export default new GspRequestor();
