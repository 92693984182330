import isEmpty from 'lodash/isEmpty';
import {
  RECENTLY_SHOPPED_MERCHANTS_GET_SUCCESS,
  RECENTLY_SHOPPED_MERCHANTS_GET_FAILURE,
} from '../actions/types';
import recentlyShoppedConfig from '../configs';

/** @type {{config: object, placements: array.<object>}} */
const initialState = {
  config: recentlyShoppedConfig,
  isLoaded: false,
  isLoading: false,
  merchants: [],
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const recentlyShopped = (state = initialState, action) => {
  switch (action.type) {
    case RECENTLY_SHOPPED_MERCHANTS_GET_SUCCESS: {
      let merchants = action.payload.response;

      if (!isEmpty(merchants)) {
        merchants = merchants.filter(({ type }) => type === 'online');
      }

      return {
        ...state,
        merchants,
        isLoading: false,
        isLoaded: true,
      };
    }
    case RECENTLY_SHOPPED_MERCHANTS_GET_FAILURE: {
      return {
        ...state,
        merchants: [],
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === RECENTLY_SHOPPED_MERCHANTS_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default recentlyShopped;
