import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { navCategoryFlyout as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    shouldShowMerchantName: false,
    rebateOptions: {
      hideRebatePrefix: true,
      hideElevationCurrency: true,
      tiered: {
        hideElevationCurrency: true,
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
