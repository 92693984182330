import mergeModuleConfigs, { mergeConfigs } from 'core/utils/mergeModuleConfigs';
import { topStoresPersonalized as orgConfig } from 'org/configs/modules.config';
import { mergedConfig as defaultMergedConfig } from './defaultConfig';

const topStoresPersonalizedConfig = {
  defaults: {
    slick: {
      arrows: true,
      centerMode: false,
      infinite: false,
      swipeToSlide: true,
      swipe: true,
      dots: false,
      slidesToShow: 7,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1420,
          settings: { slidesToShow: 9 },
        },
        {
          breakpoint: 1300,
          settings: { slidesToShow: 8 },
        },
        {
          breakpoint: 1180,
          settings: { slidesToShow: 7 },
        },
        {
          breakpoint: 1023,
          settings: { arrows: false, dots: true, slidesToShow: 6 },
        },
        {
          breakpoint: 890,
          settings: { arrows: false, dots: true, slidesToShow: 5 },
        },
        {
          breakpoint: 720,
          settings: { arrows: false, dots: true, slidesToShow: 4 },
        },
        {
          breakpoint: 580,
          settings: { arrows: false, dots: true, slidesToShow: 3 },
        },
        {
          breakpoint: 440,
          settings: { arrows: false, dots: true, slidesToShow: 2 },
        },
      ],
    },
  },
};

export default mergeModuleConfigs(
  mergeConfigs(defaultMergedConfig, topStoresPersonalizedConfig),
  orgConfig,
);
