import {
  PICK_FAVORITES_GET_SUCCESS, PICK_FAVORITES_GET_FAILURE, PICK_FAVORITES_QUEUE_UPDATE,
} from '../actions/types';
import pickFavoritesConfig from '../configs';

const initialState = {
  config: pickFavoritesConfig,
  merchants: [],
  pickFavoriteMerchantsQueue: [],
  isLoading: false,
  isLoaded: false,
};

const parseResponse = (response) => (
  response.map(({ merchant }) => ({
    ...merchant,
    id: `${merchant.id}`, // converting id into string
  }))
);

const filterFavoritedMerchants = (merchants, favoriteMerchantIndex) => (
  merchants.filter(({ id }) => !favoriteMerchantIndex.includes(id))
);

const updateMerchantsQueue = (merchants, merchantsFiltered, favoriteMerchantIndex) => {
  // filter queue from favorited merchants
  const filteredFromFavoritedQueue = filterFavoritedMerchants(merchantsFiltered, favoriteMerchantIndex);
  const merchantsFilteredIndex = filteredFromFavoritedQueue.map(({ id }) => id);
  // catch unfavorited merchants
  const unfavoritedMerchantsToAdd = merchants.filter(({ id }) => (
    !favoriteMerchantIndex.includes(id) && !merchantsFilteredIndex.includes(id)
  ));

  return ([
    ...filteredFromFavoritedQueue,
    ...unfavoritedMerchantsToAdd,
  ]);
};

const pickFavorites = (state = initialState, action) => {
  switch (action.type) {
    case PICK_FAVORITES_QUEUE_UPDATE: {
      const { merchants, merchantsFiltered, favoriteMerchantIndex } = action;
      return {
        ...state,
        pickFavoriteMerchantsQueue: updateMerchantsQueue(merchants, merchantsFiltered, favoriteMerchantIndex),
      };
    }
    case PICK_FAVORITES_GET_SUCCESS: {
      const merchants = parseResponse(action.payload.response);
      return {
        ...state,
        merchants,
        isLoading: false,
        isLoaded: true,
      };
    }
    case PICK_FAVORITES_GET_FAILURE: {
      return {
        ...state,
        merchants: [],
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === PICK_FAVORITES_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default pickFavorites;
