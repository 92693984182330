import { CREDIT_CARD_MARKETING_BANNER_GET_SUCCESS, CREDIT_CARD_MARKETING_BANNER_GET_FAILURE } from '../actions/types';
import config from '../configs';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  placement: {},
};

const creditCardMarketingBannerParser = (response = []) => {
  const CONTENT_ITEM_TYPE_WITH_TEXT = 59;
  const CONTENT_ITEM_TYPE_WITH_IMAGE = 40;

  const textContent = response.find(elem => (
    elem.contentItemTypeId === CONTENT_ITEM_TYPE_WITH_TEXT
  ));

  const imageContent = response.find(elem => (
    elem.contentItemTypeId === CONTENT_ITEM_TYPE_WITH_IMAGE
  ));

  if (!textContent || !imageContent) {
    return {};
  }

  const {
    clickUrl,
    assets: {
      text, callToActionText, rolloverCopy, shortSummaryText,
    },
  } = textContent;

  const { assets: { image, imageAlternateText } } = imageContent;

  return {
    clickUrl,
    text,
    callToActionText,
    rolloverCopy,
    shortSummaryText,
    image,
    imageAlternateText,
  };
};

const creditCardMarketingBanner = (state = initialState, action) => {
  switch (action.type) {
    case CREDIT_CARD_MARKETING_BANNER_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        placement: creditCardMarketingBannerParser(action.payload.response),
      };
    }
    case CREDIT_CARD_MARKETING_BANNER_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        placement: {},
      };
    }
    default: {
      const { options = {} } = action;

      if (options.success === CREDIT_CARD_MARKETING_BANNER_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }

      return state;
    }
  }
};

export default creditCardMarketingBanner;
