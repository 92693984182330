import { FEATURED_FAVORITES_GET_SUCCESS, FEATURED_FAVORITES_GET_FAILURE } from '../actions/types';
import featuredFavoritesConfig from '../configs';

/** @type {{config: object, placements: array.<object>}} */
const initialState = {
  config: featuredFavoritesConfig,
  merchants: [],
  isLoading: false,
  isLoaded: false,
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const featuredFavorites = (state = initialState, action) => {
  switch (action.type) {
    case FEATURED_FAVORITES_GET_SUCCESS: {
      const placements = action.payload.response;
      const merchants = placements.map(placement => placement.merchant);
      return {
        ...state,
        merchants,
        isLoading: false,
        isLoaded: true,
      };
    }
    case FEATURED_FAVORITES_GET_FAILURE: {
      return {
        ...state,
        merchants: [],
        isLoading: false,
        isLoaded: true,
      }; // we'd have to decide what to do with error state
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === FEATURED_FAVORITES_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default featuredFavorites;
