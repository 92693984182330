import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { searchForm as orgConfig } from 'org/configs/modules.config';
import {
  SEARCH_FORM_GET_SUCCESS,
  SEARCH_FORM_GET_FAILURE,
} from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: SEARCH_FORM_GET_SUCCESS,
        failure: SEARCH_FORM_GET_FAILURE,
      },
    },
    searchButtonClassName: 'fa fa-search',
    shouldUsePopUpOnMobile: false,
    shouldShowResultLinkOutside: false,
    tagHelper: () => {},
  },
  h: {
    shouldUsePopUpOnMobile: true,
    shouldShowResultLinkOutside: true,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
