import { findAncestorByClassName } from 'core/utils/helpers/dom';
import { getParamsFromSearch } from 'core/utils/helpers/getParamsFromSearch';

export const hybridCouponsClickAndHoverEvents = [
  {
    selector: '.mn_hybridCoupons .mn_viewAllLink',
    click: {
      category: 'Hybrid Search Results Page',
      action: 'See all coupons click',
    },
    label: getParamsFromSearch(window.location.search).keywords,
    value: 1,
  },
  {
    selector: '.mn_hybridCoupons .mn_couponInfo',
    click: {
      category: 'Hybrid Search Results Page',
      action: 'Coupon click',
    },
    label: (element) => {
      const merchantName = element.getElementsByTagName('img')[0].alt || '';
      const { keywords } = getParamsFromSearch(window.location.search);

      return `${merchantName} | ${keywords}`;
    },
    value: 1,
  },
  {
    selector: '.mn_hybridCoupons .mn_similarCouponsLink',
    click: {
      category: 'Hybrid Search Results Page',
      action: 'See similar coupons click',
    },
    label: (element) => {
      const coupon = findAncestorByClassName(element, 'mn_hybridCouponTile');
      const merchantName = coupon.getElementsByTagName('img')[0].alt || '';
      const { keywords } = getParamsFromSearch(window.location.search);

      return `${merchantName} | ${keywords}`;
    },
    value: 1,
  },
];
