import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { selectFormByName } from 'core/selectors/form';
import { selectSiteUrl } from 'core/selectors/app';
import { sanitizeString } from 'core/utils/helpers/sanitizeString';
import {
  SEARCH_REDIRECT_TYPE_PRODUCT,
  SEARCH_REDIRECT_TYPE_MERCHANT_MULTIPLE,
  SEARCH_REDIRECT_TYPE_MERCHANT_SINGLE,
  SEARCH_REDIRECT_TYPE_MERCHANT_TOMBSTONE,
  SEARCH_REDIRECT_TYPE_NO_RESULTS,
  SEARCH_REDIRECT_TYPE_URL,
} from '../actions/types';

export const selectSearchFormKeywords = (state) => {
  const searchForm = selectFormByName(state, 'searchForm') || {};
  const { values = {} } = searchForm;
  return sanitizeString(values.keywords) || '';
};

export const selectSearchForm = state => state.searchForm;
export const selectSearchFormConfig = createSelector(
  selectSearchForm,
  searchForm => searchForm.config,
);

export const selectSearchFormIsLoading = createSelector(
  selectSearchForm,
  searchForm => searchForm.isLoading,
);

export const selectSearchFormIsLoaded = createSelector(
  selectSearchForm,
  searchForm => searchForm.isLoaded,
);

export const selectSearchFormSearchResult = createSelector(
  selectSearchForm,
  searchForm => searchForm.searchResult,
);

export const selectSearchFormDestinationMerchants = createSelector(
  selectSearchFormSearchResult,
  (searchFormSearchResult) => {
    if (isEmpty(searchFormSearchResult) || searchFormSearchResult.metadata.type !== 'Merchant') {
      return [];
    }
    // ignore instore merchants for now
    return searchFormSearchResult.response.filter(merchant => merchant.type !== 'instore');
  },
);

export const selectSearchFormRedirectType = createSelector(
  selectSearchFormSearchResult,
  selectSearchFormDestinationMerchants,
  (searchFormSearchResult, searchFormDestinationMerchants) => {
    let searchRedirectType = null;

    if (!isEmpty(searchFormSearchResult)) {
      switch (searchFormSearchResult.metadata.type) {
        case 'DestinationUrl':
          searchRedirectType = SEARCH_REDIRECT_TYPE_URL;
          break;
        case 'Merchant':
          if (searchFormDestinationMerchants.length > 1) {
            searchRedirectType = SEARCH_REDIRECT_TYPE_MERCHANT_MULTIPLE;
          } else if (searchFormDestinationMerchants.length === 1) {
            const merchant = searchFormDestinationMerchants[0];
            if (merchant.type === 'Suspended Merchant' || merchant.type === 'Deactivated Merchant') {
              searchRedirectType = SEARCH_REDIRECT_TYPE_MERCHANT_TOMBSTONE;
            } else {
              searchRedirectType = SEARCH_REDIRECT_TYPE_MERCHANT_SINGLE;
            }
          }
          break;
        case 'Product':
          searchRedirectType = SEARCH_REDIRECT_TYPE_PRODUCT;
          break;
        case 'NoResults':
          searchRedirectType = SEARCH_REDIRECT_TYPE_NO_RESULTS;
          break;
        default:
          searchRedirectType = null;
      }
    }

    return searchRedirectType;
  },
);

export const selectSearchFormRedirectUrl = createSelector(
  selectSearchFormRedirectType,
  selectSearchFormSearchResult,
  selectSearchFormKeywords,
  selectSiteUrl,
  (searchFormRedirectType, searchFormSearchResult, searchFormKeywords, siteUrl) => {
    let redirectUrl = null;
    let gmidsArr;

    if (!isEmpty(searchFormSearchResult)) {
      switch (searchFormRedirectType) {
        case SEARCH_REDIRECT_TYPE_URL:
          redirectUrl = searchFormSearchResult.response.value;
          break;
        case SEARCH_REDIRECT_TYPE_MERCHANT_SINGLE:
        case SEARCH_REDIRECT_TYPE_MERCHANT_MULTIPLE:
        case SEARCH_REDIRECT_TYPE_MERCHANT_TOMBSTONE:
          gmidsArr = searchFormSearchResult.response.reduce((arr, merchant) => {
            if (merchant.type !== 'instore') { // ignore instore merchants for now
              arr.push(merchant.id);
            }
            return arr;
          }, []);
          redirectUrl =
            `${siteUrl}/me____.htm?` +
            `${`keywords=${encodeURIComponent(searchFormKeywords)}&gmid=${gmidsArr.join()}`}`;
          break;
        case SEARCH_REDIRECT_TYPE_PRODUCT:
          redirectUrl =
            `${siteUrl}/sr____.htm?` +
            `${`keywords=${encodeURIComponent(searchFormKeywords)}`}`;
          break;
        case SEARCH_REDIRECT_TYPE_NO_RESULTS:
          redirectUrl =
            `${siteUrl}/nr____.htm?` +
            `${`keywords=${encodeURIComponent(searchFormKeywords)}`}`;
          break;
        default:
          redirectUrl = null;
      }
    }

    return redirectUrl;
  },
);
