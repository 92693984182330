import { WATCH_HOW_IT_WORKS_VIEW } from '../actions/types';

export const howItWorksGaActionEvents = {
  [WATCH_HOW_IT_WORKS_VIEW]: () => (
    {
      category: 'VHP CTA',
      action: 'View',
      label: 'Watch how it works',
    }
  ),
};

export const howItWorksClickEvents = [
  {
    selector: '.mn_VHP .mn_shopWifiHiw .mn_watchVideoLinkWrapper',
    click: {
      category: 'VHP CTA',
      action: 'Click',
    },
    label: 'Watch how it works',
  },
];
