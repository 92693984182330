import isEmpty from 'lodash/isEmpty';
import { formatDate } from 'core/utils/apis/responseParsers/commonParsers/formatDateParser';
import {
  MERCHANT_OFFER_MODAL_TRIGGER,
  MERCHANT_OFFER_GET_SUCCESS,
  MERCHANT_OFFER_GET_FAILURE,
  MERCHANT_OFFER_MODAL_CLEAR,
} from '../actions/types';

import { merchantOffer as config } from '../configs';

const initialState = {
  config,
  clickData: null,
  merchant: null,
  selectedOffer: null,
};

const findSelectedOffer = (offerId, offers) => offers.find(offer => offer.id === offerId.toString());

/**
 * @param {object} offer
 * @return {object}
 */
const formatOffer = (offer) => {
  if (isEmpty(offer)) {
    return null;
  }

  const {
    expires, isOngoing, description, offerTitle, code, couponCode,
  } = offer;

  const { dateFormat } = config;

  const formattedOffer = expires && !isOngoing ? {
    ...offer,
    expires: formatDate(expires, dateFormat),
  } : offer;

  formattedOffer.description = description || offerTitle;
  formattedOffer.code = code || couponCode;

  return formattedOffer;
};

const merchantOfferModal = (state = initialState, action) => {
  switch (action.type) {
    case MERCHANT_OFFER_MODAL_TRIGGER: {
      return { ...state, clickData: action.data };
    }
    case MERCHANT_OFFER_GET_SUCCESS: {
      const { merchantId } = action.payload.response[0];

      if (merchantId && !isEmpty(state.clickData)) {
        const merchant = action.payload.response[0];
        const selectedOffer = findSelectedOffer(state.clickData.offerId, merchant.offers);
        const formattedOffer = state.selectedOffer || (selectedOffer && formatOffer(selectedOffer));

        return { ...state, merchant, selectedOffer: formattedOffer };
      }

      const formattedOffer = formatOffer(action.payload.response[0]);

      return { ...state, selectedOffer: formattedOffer };
    }
    case MERCHANT_OFFER_GET_FAILURE: {
      return state; // we'd have to decide what to do with error state
    }
    case MERCHANT_OFFER_MODAL_CLEAR: {
      return initialState;
    }
    default:
      return state;
  }
};

export default merchantOfferModal;
