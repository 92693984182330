import filter from 'lodash/filter';
import config from '../configs';
import { HYBRID_STORES_MERCHANTS_GET_SUCCESS, HYBRID_STORES_MERCHANTS_GET_FAILURE } from '../actions/types';

const initialState = {
  config,
  merchants: [],
  isLoading: false,
  isLoaded: false,
};

const hybridStoresMerchants = (state = initialState, action) => {
  switch (action.type) {
    case HYBRID_STORES_MERCHANTS_GET_SUCCESS: {
      const merchants = filter(action.payload.response, (merchant) => merchant.type === 'online');
      return {
        ...state,
        merchants,
        isLoading: false,
        isLoaded: true,
      };
    }
    case HYBRID_STORES_MERCHANTS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === HYBRID_STORES_MERCHANTS_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }

      return state;
    }
  }
};

export default hybridStoresMerchants;
