import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useDropdown } from 'core/hooks/useDropdown';
import { usePrevious } from 'core/hooks/usePrevious';

import NavBanner from 'core/modules/Navigation/NavBanner/NavBanner';
import NavPopularStores from 'core/modules/Navigation/NavPopularStores/NavPopularStores';
import NavSeasonalFlyout from 'core/modules/Navigation/NavSeasonalFlyout/NavSeasonalFlyout';
import NavCategoriesTOF from 'core/modules/Navigation/NavCategories/components/NavCategoriesTOF/NavCategoriesTOF';
import NavOffersTOF from 'org/modules/NavigationTOF/components/NavOffersTOF/NavOffersTOF';

import { triggerNavigationTOFIsToggled } from './actions';

import './NavigationTOF.scss';

function NavigationTOF() {
  const {
    hideSubnav, showSubnav, isFocused, dropdownClassName,
  } = useDropdown();
  const dispatch = useDispatch();
  const prevIsFocused = usePrevious(isFocused);

  useEffect(() => {
    // Ignore triggering on component load
    if (prevIsFocused !== undefined) {
      dispatch(triggerNavigationTOFIsToggled(isFocused));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  return (
    <nav className="mn_mainNavigationTOF" aria-label="Main navigation">
      <div
        className={`mn_navigationDropdownWrapper ${dropdownClassName}`}
        onMouseEnter={showSubnav}
        onMouseLeave={hideSubnav}
        onBlur={hideSubnav}
      >
        <button
          onFocus={showSubnav}
          className="mn_navigationDropdownTrigger"
          aria-expanded={isFocused}
        >
          <span>explore stores and more</span>
          <span className="mn_navArrow" />
        </button>

        <div className="mn_navigationDropdown">
          <div className="mn_leftMainNavPanelSect">
            <NavCategoriesTOF />
          </div>
          <div className="mn_middleMainNavPanelSect">
            <NavOffersTOF />
            <NavSeasonalFlyout />
          </div>
          <div className="mn_rightMainNavPanelSect">
            <NavPopularStores />
          </div>
          <div className="mn_bottomMainNavPanelSect">
            <NavBanner isNavigationOpen={isFocused} />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavigationTOF;
