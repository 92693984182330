import React from 'react';

export const highlightMatch = (name, matchText) => {
  if (!matchText) {
    return name;
  }

  const splitPattern = new RegExp(`(${matchText})`, 'i');
  const miniMatches = name.split(splitPattern).filter(mini => !!mini);

  /* eslint-disable react/no-array-index-key */
  return miniMatches.map((mini, index) => (
    splitPattern.test(mini) ? (
      <span key={`${name}-${index}`} className="mn_matchTextHighlight">{mini}</span>
    ) : (
      <span key={`${name}-${index}`}>{mini}</span>
    )
  ));
};
