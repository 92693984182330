import { formatDateWithFixedTimeZone } from './commonParsers/formatDateParser';

// Instore transaction state
const CLIENT_POSTED = 'CLIENT_POSTED';
const CLIENT_HARD_REJECT = 'CLIENT_HARD_REJECT';
const AUTH_VERIFIED = 'AUTH_VERIFIED';
const SCORED = 'SCORED';
const ACCRUAL_PENDING = 'ACCRUAL_PENDING';
const ACCRUAL_SENT = 'ACCRUAL_SENT';

const getStatus = transactionState => {
  switch (transactionState) {
    case CLIENT_POSTED:
      return 'Confirmed';
    case CLIENT_HARD_REJECT:
      return 'Rejected';
    case AUTH_VERIFIED:
      return 'In-store purchase';
    case SCORED:
    case ACCRUAL_PENDING:
    case ACCRUAL_SENT:
      return 'Pending';
    default: return '';
  }
};

export default response => response.map(transaction => {
  const status = getStatus(transaction.transactionState);

  return {
    ...transaction,
    status,
    isRejected: transaction.transactionState === 'CLIENT_HARD_REJECT',
    isSettled: transaction.transactionState !== 'AUTH_VERIFIED',
    transactionDate: formatDateWithFixedTimeZone(transaction.transactionDate),
  };
});
