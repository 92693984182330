import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { hybridQuickSearch as orgConfig } from 'org/configs/modules.config';
import { CACHE_STORAGE } from 'core/utils/cache/base';
import {
  HYBRYD_QUICK_SEARCH_MERCHANTS_GET_SUCCESS, HYBRYD_QUICK_SEARCH_MERCHANTS_GET_FAILURE,
  HYBRYD_QUICK_SEARCH_GSP_MERCHANTS_GET_SUCCESS, HYBRYD_QUICK_SEARCH_GSP_MERCHANTS_GET_FAILURE,
  HYBRYD_QUICK_SEARCH_GSP_PRODUCTS_GET_SUCCESS, HYBRYD_QUICK_SEARCH_GSP_PRODUCTS_GET_FAILURE,
} from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    minChars: 2,
    maxAnchorFront: 3,
    maxMatches: 4,
    showFavoriteIcon: true,
    rebateOptions: {
      showAdditionalElevationPrefix: false,
      hideRebatePrefix: true,
      tiered: {
        showAdditionalElevationPrefix: false,
        hideRebatePrefix: true,
      },
    },
    instoreIconImage: null,
    directMerchantName: '',
    quickSearchMerchantsConfig: {
      api: {
        params: {
          limit: 2000,
          sort_by: 'name',
          fields: [
            'logoUrls._88x31',
            'type',
            'name',
            'id',
            'clickUrl',
            'showRebate',
            'rebate',
            'offers',
            'synonyms',
            'relatedActiveMerchants',
            'isDirect',
            'specialConsiderationText',
            'flags.isTravelMerchant',
            'flags.storesPageApproved',
          ],
        },
        options: {
          success: HYBRYD_QUICK_SEARCH_MERCHANTS_GET_SUCCESS,
          failure: HYBRYD_QUICK_SEARCH_MERCHANTS_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true, type: CACHE_STORAGE },
        },
      },
    },
    quickSearchGspSuggestionsConfig: {
      api: {
        params: {
          sid: 'us_cartera_001',
          sortby: 'relevancy',
          column: 'storeId,storeName',
          hits: 20,
        },
        options: {
          success: HYBRYD_QUICK_SEARCH_GSP_MERCHANTS_GET_SUCCESS,
          failure: HYBRYD_QUICK_SEARCH_GSP_MERCHANTS_GET_FAILURE,
          cache: { isEnabled: false, isPublishedData: false },
        },
      },
    },
    quickSearchGspProductSuggestionsConfig: {
      api: {
        params: {
          h: 10, // limit
        },
        options: {
          success: HYBRYD_QUICK_SEARCH_GSP_PRODUCTS_GET_SUCCESS,
          failure: HYBRYD_QUICK_SEARCH_GSP_PRODUCTS_GET_FAILURE,
          cache: { isEnabled: false, isPublishedData: false },
        },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
