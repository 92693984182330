import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPlacements } from 'core/actions/ocapi';

import {
  selectNavSeasonalFlyoutConfig,
  selectNavSeasonalFlyoutLoading,
  selectNavSeasonalFlyoutIsLoaded,
  selectNavSeasonalFlyoutPlacements,
} from '../selectors';

export const useGetPlacements = () => {
  const dispatch = useDispatch();
  const config = useSelector(selectNavSeasonalFlyoutConfig);
  const isLoading = useSelector(selectNavSeasonalFlyoutLoading);
  const isLoaded = useSelector(selectNavSeasonalFlyoutIsLoaded);
  const placements = useSelector(selectNavSeasonalFlyoutPlacements);

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      const { api: apiConfig } = config;
      const { params, options } = apiConfig;
      dispatch(fetchPlacements(params, options));
    }
  }, []);

  return placements;
};
