import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { navCategories as orgConfig } from 'org/configs/modules.config';
import { NAV_CATEGORIES_GET_SUCCESS, NAV_CATEGORIES_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        fields: [
          'id',
          'name',
          'searchFriendlyName',
          'featured.merchant',
          'featured.id',
          'flyout',
        ].join(','),
      },
      options: {
        success: NAV_CATEGORIES_GET_SUCCESS,
        failure: NAV_CATEGORIES_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
