import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { holidayBorderConfig as orgConfig } from 'org/configs/modules.config';
import {
  HOLIDAY_BORDER_LEFT_GET_SUCCESS,
  HOLIDAY_BORDER_LEFT_GET_FAILURE,
  HOLIDAY_BORDER_RIGHT_GET_SUCCESS,
  HOLIDAY_BORDER_RIGHT_GET_FAILURE,
} from '../actions/types';

const defaultConfig = {
  defaults: {
    holidayBorderLeft: {
      api: {
        params: {
          offer_tag: 'holiday-border-left',
          fields: [
            'assets.image',
            'assets.imageAlternateText',
          ],
        },
        options: {
          success: HOLIDAY_BORDER_LEFT_GET_SUCCESS,
          failure: HOLIDAY_BORDER_LEFT_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
      },
    },
    holidayBorderRight: {
      api: {
        params: {
          offer_tag: 'holiday-border-right',
          fields: [
            'assets.image',
            'assets.imageAlternateText',
          ],
        },
        options: {
          success: HOLIDAY_BORDER_RIGHT_GET_SUCCESS,
          failure: HOLIDAY_BORDER_RIGHT_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
