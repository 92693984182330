import React, { useState } from 'react';
import { selectIsMobileMQ } from 'core/modules/MediaQueries/selectors';
import { useSelector } from 'react-redux';

function TransactionTableDisclaimer() {
  const [shouldShowFull, setShouldShowFull] = useState(false);
  const clickHandler = () => setShouldShowFull(true);
  const isMobileMQ = useSelector(selectIsMobileMQ);

  return (
    <div className="mn_tabDisclaimer">
      { (!shouldShowFull && isMobileMQ) ?
        <p>
          Please note that some transactions may take from 60 to 90 days in order to appear in the list above,
          and miles posted to&hellip; <button className="mn_showMore" onClick={clickHandler}>more</button>
        </p>
        :
        <>
          <p>
            Please note that some transactions may take from 60 to 90 days in order to appear in the list above,
            and miles posted to your MileagePlus Shopping<sup>SM</sup> Account may not be immediately available for
            redemption from your MileagePlus account.
          </p>
          <p>
            *Eligible Amount: Some merchants do not offer rewards for certain products and services such as gift
            certificates. Also, taxes and shipping fees are not eligible for rewards. You can get specific details
            on the information page for each merchant by searching for the merchant name.
          </p>
        </>
      }
    </div>
  );
}

export default TransactionTableDisclaimer;
