import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { marketingTopBanner as orgConfig } from 'org/configs/modules.config';
import { MARKETING_TOP_BANNER_GET_SUCCESS, MARKETING_TOP_BANNER_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        content_group_id: 34389,
        limit: 1,
        fields: ['id', 'assets', 'clickUrl'],
      },
      options: {
        success: MARKETING_TOP_BANNER_GET_SUCCESS,
        failure: MARKETING_TOP_BANNER_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
