import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { featuredFavorites as orgConfig } from 'org/configs/modules.config';
import { FEATURED_FAVORITES_GET_SUCCESS, FEATURED_FAVORITES_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        limit: 100,
        section_id: 10460,
      },
      options: {
        success: FEATURED_FAVORITES_GET_SUCCESS,
        failure: FEATURED_FAVORITES_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
