import { getParamsFromString } from './getParamsFromString';

export const getParamsFromSearch = (search = window.location.search) => {
  let result;
  if (search.indexOf('?') !== -1) {
    result = search.substr(search.indexOf('?') + 1);
  }

  return getParamsFromString(result);
};
