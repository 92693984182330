import React from 'react';

function ContentWrap({ ignoreWrap, children, className = '' }) {
  if (ignoreWrap) {
    return children;
  }

  return (
    <div className={`mn_contentWrap ${className}`}>
      {children}
    </div>
  );
}

export default ContentWrap;
