import { findAncestorByClassName } from 'core/utils/helpers/dom';
import { getDisplayPageName } from 'core/utils/analytics/pageInfo';

import { FEATURED_OFFERS_502X272_VIEW } from '../actions/types';

export const featuredOffers502x272GaActionEvents = {
  [FEATURED_OFFERS_502X272_VIEW]: ({ deal, dealsLength }) => {
    const { id, merchant, assets } = deal;

    return {
      category: `${getDisplayPageName()} - Featured Offers`,
      action: 'View',
      label: `${id} | ${merchant.name} | ${assets.rolloverCopy}`,
      value: dealsLength,
    };
  },
};

export const featuredOffers502x272ClickAndHoverEvents = [
  {
    selector: '.mn_featuredOffer502x272',
    click: {
      category: `${getDisplayPageName()} - Featured Offers`,
      action: 'Click',
    },
    label: (element) => {
      const { dealId, merchantName, rolloverCopy } = element.dataset;

      return `${dealId} | ${merchantName} | ${rolloverCopy}`;
    },
  },
  {
    selector: '.mn_dynamicRewardsPage .mn_featuredOffer502x272 .mn_favoriteIcon',
    click: {
      category: 'Flex Promotion | logo',
      action: element => `${element.classList.contains('mn_favorited') ? 'Remove' : 'Add'} favorite`,
    },
    label: (element) => {
      const featuredOffer = findAncestorByClassName(element, 'mn_featuredOffer502x272') || {};
      const { merchantName } = featuredOffer.dataset;
      const featuredOffers = findAncestorByClassName(element, 'mn_featuredOffers502x272') || {};
      const { title, viewName } = featuredOffers.dataset;

      return `${merchantName} | ${title} | ${viewName}`;
    },
    custom: element => ({
      merchant: element.dataset.merchantName,
    }),
  },
  {
    selector: '.mn_dynamicRewardsPage .mn_featuredOffer502x272',
    click: {
      category: 'Flex Promotion | logo',
      action: 'Click',
    },
    label: (element) => {
      const { merchantName } = element.dataset;
      const featuredOffers = findAncestorByClassName(element, 'mn_featuredOffers502x272') || {};
      const { title, viewName } = featuredOffers.dataset;

      return `${merchantName} | ${title} | ${viewName}`;
    },
  },
];
