/* eslint-disable object-curly-newline */
import Loadable from 'react-loadable';

const LoadableWrapper = PageComponent => Loadable({
  loader: () => import(`./pages${PageComponent}`),
  loading: () => (null),
});

export const routesConfig = [
  { route: '/h____.htm?', PageComponent: LoadableWrapper('/Home') },
  { route: '/sample____.htm', PageComponent: LoadableWrapper('/Sample') },
  { route: '/style____.htm', PageComponent: LoadableWrapper('/Style') },
  { route: '/hiw____.htm', PageComponent: LoadableWrapper('/Hiw') },
  { route: '/faq____.htm', PageComponent: LoadableWrapper('/Faq') },
  { route: '/p/:dynamicPage', PageComponent: LoadableWrapper('/DynamicRewards') },
  { route: '/sp____.htm', PageComponent: LoadableWrapper('/AllOffers') },
  { useRegExp: true, route: '/(fs____.*).htm', PageComponent: LoadableWrapper('/FreeShipping') },
  { route: '/terms____.htm', PageComponent: LoadableWrapper('/Terms') },
  { route: '/privacy____.htm', PageComponent: LoadableWrapper('/Privacy') },
  { route: '/eula____.htm', PageComponent: LoadableWrapper('/Eula') },
  { route: '/unsubscribe/eu____.htm', PageComponent: LoadableWrapper('/EmailUnsubscribe') },
  { route: '/me____.htm', PageComponent: LoadableWrapper('/MerchantExperience') },
  { route: '/nr____.htm', PageComponent: LoadableWrapper('/NoResults') },
  { route: '/pd____.htm', PageComponent: LoadableWrapper('/ProductDetails') },
  { route: '/pc____.htm', PageComponent: LoadableWrapper('/ProductDetails') },
  { useRegExp: true, route: '/.*/bc_.*___.htm', PageComponent: LoadableWrapper('/CategoryPage') },
  { route: '/button', PageComponent: LoadableWrapper('/ButtonLanding') },
  { route: '/button/uninstall', PageComponent: LoadableWrapper('/ButtonUninstall') },
  { route: '/sr____.htm', PageComponent: LoadableWrapper('/ProductSearchResults') },
  { route: '/acq____.htm', PageComponent: LoadableWrapper('/Acquisition') },
  { route: '/signin', PageComponent: LoadableWrapper('/Acquisition') },
  { route: '/instore', PageComponent: LoadableWrapper('/Instore') },
  { route: '/shopWifi____.htm', PageComponent: LoadableWrapper('/ShopWifi') },
  { route: '/favorites____.htm', PageComponent: LoadableWrapper('/Favorites') },
  { route: '/sweeps____.htm', PageComponent: LoadableWrapper('/Sweeps') },
  { route: '/sweepsrules____.htm', PageComponent: LoadableWrapper('/SweepsRules') },
  { route: '/foryou____.htm', PageComponent: LoadableWrapper('/ForYou') },
  { route: '/contact____.htm', PageComponent: LoadableWrapper('/ContactUs') },
  { route: '/b____.htm', PageComponent: LoadableWrapper('/Stores') },
  { route: '/ai____.htm', PageComponent: LoadableWrapper('/Profile') },
  { route: '/hsr____.htm', PageComponent: LoadableWrapper('/HybridSearchResults') },
  { route: '/msr____.htm', PageComponent: LoadableWrapper('/StoresSearchResults') },
  { route: '/csr____.htm', PageComponent: LoadableWrapper('/CouponSearchResults') },
  { route: '/button/safari/tutorial', PageComponent: LoadableWrapper('/SafariButtonInstallTutorial') },
];
