import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Loadable from 'react-loadable';
import { useSelector } from 'react-redux';
import { selectPageName } from 'core/selectors/app';

// A syntax sugar for a Loadable plugin call
// Note: the module path system.import can't be fully set as an expression
// so the ./pages folder is hardcoded here to define the correct context for webpack
const LoadableWrapper = PageComponent => Loadable({
  loader: () => import(`./pages${PageComponent}`),
  loading: () => (null),
});

// Note: PageComponent should be related to the ./pages folder
const coreRoutesConfig = [
  { route: '/e____.htm', PageComponent: LoadableWrapper('/Error') },
];

function DynamicUrls({ routesConfig }) {
  const { pathname } = useLocation();

  const { PageComponent } = routesConfig.find(({ useRegExp, route }) => (
    useRegExp && new RegExp(route).test(pathname)
  )) || {};

  if (PageComponent) {
    return <PageComponent />;
  }

  const ErrorComponent = LoadableWrapper('/Error');
  return <ErrorComponent />;
}

// React Router v6 started using a simplified path format.
// So more complicated dynamic URLs that cannot be properly parsed with React Router v6 patterns
// are marked with `useRegExp` flag and will be passed and manually parsed in DynamicUrls component
function RoutesWrap({ orgRoutesConfig }) {
  const pageName = useSelector(selectPageName);
  const routesConfig = [...coreRoutesConfig, ...orgRoutesConfig];

  return (
    <Routes>
      {pageName === 'e' && <Route component={LoadableWrapper('/Error')} />}
      {routesConfig.map(({ route, PageComponent, useRegExp }) => (
        useRegExp ? null : <Route key={route} exact path={route} element={<PageComponent />} />
      ))}
      <Route path="*" element={<DynamicUrls routesConfig={routesConfig} />} />
    </Routes>
  );
}

export default RoutesWrap;
