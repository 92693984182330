import { select, takeEvery, put } from 'redux-saga/effects';
import responseParsers from 'core/utils/apis/responseParsers';
import {
  MERCHANT_EXPERIENCE_FROM_SMARTY_GET_SUCCESS, MERCHANT_EXPERIENCE_GET_SUCCESS, MERCHANT_EXPERIENCE_GET_FAILURE,
} from '../actions/types';

function* parseMerchantExperienceDataAndPutResponseAction() {
  const rawMerchantData = window.merchantData ? window.merchantData.response : []; // Array hardcoded in page template
  if (rawMerchantData.length) {
    const state = yield select(state => state);
    const parsedPayload = responseParsers.merchantsOnlineParser(rawMerchantData, state);
    yield put({
      type: MERCHANT_EXPERIENCE_GET_SUCCESS,
      payload: { response: parsedPayload },
    });
  } else {
    yield put({
      type: MERCHANT_EXPERIENCE_GET_FAILURE,
    });
  }
}

export default function* merchantExperienceSaga() {
  yield takeEvery(MERCHANT_EXPERIENCE_FROM_SMARTY_GET_SUCCESS, parseMerchantExperienceDataAndPutResponseAction);
}
