import React from 'react';
import isEmpty from 'lodash/isEmpty';

import MerchantRebateV2 from 'core/modules/MerchantRebateV2/MerchantRebateV2';

import config from './configs';

import './NavCategoryFlyout.scss';

function NavCategoryFlyout({ flyout }) {
  const { shouldShowMerchantName, rebateOptions } = config;

  if (isEmpty(flyout)) {
    return null;
  }

  const {
    clickUrl, assets, merchant, offerTitle,
  } = flyout;

  const renderFlyoutWithRebate = () => (
    <>
      <h2>Offer of the day</h2>
      <a
        className="mn_categoryFlyoutLink"
        href={clickUrl}
        title={`Opens new window at ${merchant.name}`}
        target="_blank"
      >
        <img src={assets._300x250} alt={merchant.name} />
        <div className="mn_categoryFlyoutInfo">
          {shouldShowMerchantName && <h3>Shop {merchant.name}</h3>}
          <MerchantRebateV2 {...merchant.rebate} {...rebateOptions} />
        </div>
      </a>
    </>

  );

  const renderFlyoutWithoutRebate = () => (
    <a href={clickUrl} title="Opens in a new window" target="_blank">
      <img src={assets._300x250} alt={offerTitle} />
    </a>
  );

  return (
    <div className="mn_categoryFlyout">
      <div className="mn_categoryFlyoutInner">
        { isEmpty(merchant) ? renderFlyoutWithoutRebate() : renderFlyoutWithRebate() }
      </div>
    </div>
  );
}

export default NavCategoryFlyout;
