import { takeEvery, put } from 'redux-saga/effects';
import { showModal } from 'core/modules/Modal/actions';

import { MODAL_TYPE_FAVORITES_ALERT } from 'core/modules/Modal/types';
import { FAVORITES_ALERT_MODAL_TRIGGER } from '../actions/types';

function* showFavoritesAlertModal() {
  yield put(showModal(MODAL_TYPE_FAVORITES_ALERT));
}

export default function* favoritesAlertModalSaga() {
  yield takeEvery(FAVORITES_ALERT_MODAL_TRIGGER, showFavoritesAlertModal);
}
