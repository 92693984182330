import React, { useState } from 'react';
import Cookies from 'js-cookie';
import dayjs from 'core/utils/dayjs';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { formatDate } from 'core/utils/apis/responseParsers/commonParsers/formatDateParser';
import LocalStorageCache from 'core/utils/cache/localStorage';

import './PreviewMallTool.scss';

function PreviewMallTool() {
  const PREVIEW_COOKIE_NAME = 'preview';
  const MINUTES_OPTIONS_NUMBER = 60;
  const HOURS_OPTIONS_NUMBER = 24;
  const localStorageCache = new LocalStorageCache();

  const [cookieValue, setCookieValue] = useState(Cookies.get(PREVIEW_COOKIE_NAME));

  const cookieDomain = window.location.hostname;

  const addLeadingZero = (number) => (number < 10 ? `0${number}` : number.toString());

  const options = (number) =>
    Array.from(Array(number), (_, i) => ({ label: addLeadingZero(i), value: addLeadingZero(i) }));

  const renderOptions = options => (
    options.map(option => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    )));

  const transformDate = (date) => {
    const dateObj = new Date(date);
    const dateStr = dateObj.toString();
    if (dateStr === 'Invalid Date') {
      return '';
    }

    const day = addLeadingZero(dateObj.getDate());
    const month = addLeadingZero(dateObj.getMonth() + 1);
    const hours = addLeadingZero(dateObj.getHours());
    const minutes = addLeadingZero(dateObj.getMinutes());
    const year = dateObj.getFullYear();
    const timeZoneParts = dateStr.match(/GMT([+|-][\d]{2})([\d]{2})/);

    return `${year}-${month}-${day}T${hours}:${minutes}:00${timeZoneParts[1]}:${timeZoneParts[2]}`;
  };

  const [selectedHoursValue, setSelectedHoursValue] = useState('0');
  const [selectedMinutesValue, setSelectedMinutesValue] = useState('0');
  const [date, setDate] = useState(formatDate(new Date(), 'YYYY-MM-DD'));

  function handleDateChange(event) {
    setDate(formatDate(event.target.value, 'YYYY-MM-DD'));
  }

  const handleHoursSelectChange = (event) => {
    setSelectedHoursValue(event.target.value);
  };

  const handleMinutesSelectChange = (event) => {
    setSelectedMinutesValue(event.target.value);
  };

  const closeClickHandler = () => {
    Cookies.remove(PREVIEW_COOKIE_NAME, { path: '/', domain: cookieDomain });
    localStorageCache.clear();
    setCookieValue(null);
    window.location.reload(true);
  };

  const onRefreshButtonClickHandler = () => {
    const changedCookieValue = `${date} ${selectedHoursValue}:${selectedMinutesValue}:00`;

    const cookieValue = transformDate(changedCookieValue);

    Cookies.set(PREVIEW_COOKIE_NAME, cookieValue, { path: '/', domain: cookieDomain });

    localStorageCache.clear();
    window.location.reload(true);
  };

  const currentDate = dayjs().format('YYYY-MM-DD');

  if (!cookieValue) {
    return null;
  }

  return (
    <div className="mn_previewTool">
      <div className="mn_prevDateTxt">
        Offers displayed as of: <span className="mn_prevCookieDate">{cookieValue}</span>
      </div>
      <div className="mn_prevChangeDate">
        <div className="mn_prevLeft">Change</div>
        <div className="mn_prevMid">
          <input type="date" min={currentDate} onChange={handleDateChange} />
          <select className="mn_hourChange" value={selectedHoursValue} onChange={handleHoursSelectChange}>
            {renderOptions(options(HOURS_OPTIONS_NUMBER))}
          </select>
          <select className="mn_minChange" value={selectedMinutesValue} onChange={handleMinutesSelectChange}>
            {renderOptions(options(MINUTES_OPTIONS_NUMBER))}
          </select>
        </div>
        <button className="mn_refreshButton" onClick={onRefreshButtonClickHandler}>Refresh</button>
      </div>
      <button className="mn_prevClose" aria-label="Close" onClick={closeClickHandler} />
    </div>
  );
}

export default () => <ComponentErrorBoundary><PreviewMallTool /></ComponentErrorBoundary>;
