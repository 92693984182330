import { combineReducers } from 'redux';

import config from '../configs';
import featuredDealsContent from './featuredDealsContent';
import featuredDealsHeading from './featuredDealsHeading';

export default combineReducers({
  config: () => config,
  featuredDealsContent,
  featuredDealsHeading,
});
