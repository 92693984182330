import {
  MERCHANTS_WITH_OFFERS_GET_SUCCESS, MERCHANTS_WITH_OFFERS_GET_FAILURE,
} from '../actions/types';
import config from '../configs';

const initialState = {
  config: config.merchantsWithOffers,
  isLoading: false,
  isLoaded: false,
  placements: [],
};

const merchantsWithOffers = (state = initialState, action) => {
  switch (action.type) {
    case MERCHANTS_WITH_OFFERS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        placements: action.payload.response,
      };
    }
    case MERCHANTS_WITH_OFFERS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === MERCHANTS_WITH_OFFERS_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};

export default merchantsWithOffers;
