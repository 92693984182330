export const recommendedStoresClickAndHoverEvents = [
  {
    selector: '.mn_recommendedStores .mn_merchantTile',
    click: {
      category: 'Recommended stores',
      action: 'Tap merchant',
    },
    label: element => element.dataset.merchantName,
  },
];
