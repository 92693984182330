import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNavigation } from 'core/actions/ocapi';

import {
  selectNavItemsFeatured,
  selectNavItemsNonFeatured,
  selectNavIsLoaded,
  selectNavIsLoading,
  selectNavConfig,
} from '../selectors';

export const useGetNavigation = () => {
  const config = useSelector(selectNavConfig);
  const isLoading = useSelector(selectNavIsLoading);
  const isLoaded = useSelector(selectNavIsLoaded);
  const navItemsFeatured = useSelector(selectNavItemsFeatured);
  const navItemsNonFeatured = useSelector(selectNavItemsNonFeatured);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      const { api: { params, options } = {} } = config;
      dispatch(fetchNavigation(params, options));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    navItemsFeatured,
    navItemsNonFeatured,
  };
};
