import { HYBRID_PRODUCTS_GET_FAILURE, HYBRID_PRODUCTS_GET_SUCCESS } from '../actions/types';
import config from '../configs';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  response: [],
};

const hybridProducts = (state = initialState, action) => {
  switch (action.type) {
    case HYBRID_PRODUCTS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        response: action.payload,
      };
    }
    case HYBRID_PRODUCTS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === HYBRID_PRODUCTS_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};

export default hybridProducts;
