import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { profileClickHistoryTab as orgConfig } from 'org/configs/modules.config';
import { defineRequestStartDate, defineRequestEndDate } from 'core/modules/Profile/utils/defineRequestDate';

import {
  CLICK_HISTORY_TAB_RECORDS_GET_SUCCESS,
  CLICK_HISTORY_TAB_RECORDS_GET_FAILURE,
  CLICK_HISTORY_TAB_MERCHANTS_GET_SUCCESS,
  CLICK_HISTORY_TAB_MERCHANTS_GET_FAILURE,
} from '../actions/types';

const defaultConfig = {
  defaults: {
    recordsConfig: {
      getInitialLimit: (isMobileMQ) => (isMobileMQ ? 5 : 10),
      api: {
        options: {
          success: CLICK_HISTORY_TAB_RECORDS_GET_SUCCESS,
          failure: CLICK_HISTORY_TAB_RECORDS_GET_FAILURE,
        },
        params: {
          // limit value is handled from getInitialLimit function
          offset: 0,
          start_date: defineRequestStartDate(90),
          end_date: defineRequestEndDate(),
          sort_by: 'clickDate',
          sort_type: 'desc',
        },
      },
    },
    merchantsConfig: {
      api: {
        options: {
          success: CLICK_HISTORY_TAB_MERCHANTS_GET_SUCCESS,
          failure: CLICK_HISTORY_TAB_MERCHANTS_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
        params: {
          fields: [
            'id',
            'clickUrl',
            'logoUrls',
            'type',
            'name',
            'isDirect',
          ],
        },
      },
    },
    getPageLimits: (isMobileMQ) => {
      const mobilePageLimits = [
        { value: 5, label: '5 per page' },
        { value: 10, label: '10 per page' },
        { value: 25, label: '25 per page' },
        { value: 50, label: '50 per page' },
      ];

      const desktopPageLimits = [
        { value: 10, label: '10 per page' },
        { value: 25, label: '25 per page' },
        { value: 50, label: '50 per page' },
      ];

      return isMobileMQ ? mobilePageLimits : desktopPageLimits;
    },
    pastDateLimits: [
      { value: 30, label: 'Past 30 days' },
      { value: 60, label: 'Past 60 days' },
      { value: 90, label: 'Past 90 days', isDefaultValue: true },
      { value: 120, label: 'Past 120 days' },
    ],
    useFavorites: true,
    checkShouldShowFullDisclaimer: () => true,
    getBrandURL: (rootDomain) => rootDomain,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
