import { FAVORITED_STORES_GET_SUCCESS, FAVORITED_STORES_GET_FAILURE } from '../actions/types';
import favoritedStoresConfig from '../configs';

/** @type {{config: object, merchants: array.<object>}} */
const initialState = {
  config: favoritedStoresConfig,
  merchants: [],
  isLoading: false,
  isLoaded: false,
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const favoritedStores = (state = initialState, action) => {
  switch (action.type) {
    case FAVORITED_STORES_GET_SUCCESS: {
      return {
        ...state,
        merchants: action.payload.response,
        isLoading: false,
        isLoaded: true,
      };
    }
    case FAVORITED_STORES_GET_FAILURE: {
      return {
        ...state,
        merchants: [],
        isLoading: false,
        isLoaded: true,
      }; // we'd have to decide what to do with error state
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === FAVORITED_STORES_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default favoritedStores;
