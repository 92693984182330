import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { recommendedStores as orgConfig } from 'org/configs/modules.config';
import { RECOMMENDED_STORES_GET_SUCCESS, RECOMMENDED_STORES_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        limit: 4,
        personalized: 'rec',
      },
      options: {
        success: RECOMMENDED_STORES_GET_SUCCESS,
        failure: RECOMMENDED_STORES_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
