import { BONUS_TRACKER_CLICK_HISTORY_GET_SUCCESS, BONUS_TRACKER_CLICK_HISTORY_GET_FAILURE } from '../actions/types';
import config from '../configs';

const initialState = {
  config,
  bonusClickHistoryRecords: [],
  isLoading: false,
  isLoaded: false,
};

const bonusTracker = (state = initialState, action) => {
  switch (action.type) {
    case BONUS_TRACKER_CLICK_HISTORY_GET_SUCCESS: {
      return {
        ...state,
        bonusClickHistoryRecords: action.payload.response,
        isLoading: false,
        isLoaded: true,
      };
    }
    case BONUS_TRACKER_CLICK_HISTORY_GET_FAILURE: {
      return {
        ...state,
        bonusClickHistoryRecords: [],
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === BONUS_TRACKER_CLICK_HISTORY_GET_SUCCESS) {
        return {
          ...initialState,
          isLoading: true,
        };
      }

      return state;
    }
  }
};

export default bonusTracker;
