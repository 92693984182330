import { addFavorite, deleteFavorite } from 'core/actions/member';

/**
 * @param {number} merchantId
 * @param {boolean} isFavorited
 * @return {Object}
 */
export const favoriteIconClick = (merchantId, isFavorited, forceSkipFirstFavoritesPopup = false) => (
  isFavorited ? deleteFavorite(merchantId) : addFavorite(merchantId, {}, {}, { forceSkipFirstFavoritesPopup })
);
