import { findDataAttrInAncestorTree } from 'core/utils/helpers/dom';
import { EXTRA_REWARDS_DEALS_VIEW } from '../actions/types';

export const extraRewardsDealsGaActionEvents = {
  [EXTRA_REWARDS_DEALS_VIEW]: ({ payload }) => {
    const { category, ids } = payload;
    return {
      category,
      action: 'View',
      label: ids.join('|'),
    };
  },
};

export const extraRewardsDealsClickAndHoverEvents = [
  {
    selector: '.mn_extraRewardsDeals .mn_textOffer',
    click: {
      category: element => findDataAttrInAncestorTree(element, 'category'),
      action: element => `${findDataAttrInAncestorTree(element, 'type')} Click`,
    },
    label: (element) => {
      const { merchantName, merchantId, offerId } = element.dataset;
      return [merchantName, merchantId, offerId].join('|');
    },
  },
];
