import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { getId } from 'core/utils/analytics/sectionInfo';
import { navSeasonalFlyout as orgConfig } from 'org/configs/modules.config';
import {
  NAV_SEASONAL_FLYOUT_PLACEMENTS_GET_SUCCESS,
  NAV_SEASONAL_FLYOUT_PLACEMENTS_GET_FAILURE,
} from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: NAV_SEASONAL_FLYOUT_PLACEMENTS_GET_SUCCESS,
        failure: NAV_SEASONAL_FLYOUT_PLACEMENTS_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
      params: {
        limit: 4,
        sort_by: 'random',
        fields: [
          'id',
          'merchant.id',
          'merchant.name',
          'merchant.rebate',
          'merchant.logoUrls',
          'merchant.clickUrl',
        ].join(','),
        content_group_id: 5936,
        section_id: getId('seasonal-flyout'),
      },
    },
    isource: 'seasonal_featured_flyout',
    rebateOptions: {
      hideElevationCurrency: true,
      additionalRebatePrefix: 'Shop and ',
      tiered: {
        hideElevationCurrency: true,
        additionalRebatePrefix: 'Shop and earn ',
      },
    },
    secTitle: 'Featured extra miles deals',
    category: 'Premiere Elevations',
    tileLabel: '',
    getTitleText: (earnType) => `Shop featured extra ${earnType}`,
    getLinkTitle: (extraRewardsPageTitle) => `See all ${extraRewardsPageTitle}`,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
