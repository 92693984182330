import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { bonusTracker as orgConfig } from 'org/configs/modules.config';
import {
  BONUS_TRACKER_CLICK_HISTORY_GET_FAILURE,
  BONUS_TRACKER_CLICK_HISTORY_GET_SUCCESS,
} from '../actions/types';

const defaultConfig = {
  defaults: {
    clickHistoryConfig: {
      api: {
        options: {
          success: BONUS_TRACKER_CLICK_HISTORY_GET_SUCCESS,
          failure: BONUS_TRACKER_CLICK_HISTORY_GET_FAILURE,
        },
      },
    },
    trackerConfig: {
      indicators: {
        rewardsTextColor: '#68b3e7',
        dividerColor: '#7C868E',
        circleColor: '#fff',
        circleBorderColor: '#68b3e7',
        circleTextColor: '#000',
        circleFontStyle: '14',
        rewardsFontSize: '20',
        rewardsLanguageFontSize: '10',
        allTiersBackgroundColor: '#ccc',
        backgroundColor: [],
        borderWidth: 0,
        gaugeData: {
          value: 0,
          valueColor: '#68b3e7',
        },
        gaugeLimits: [],
        gaugeEarned: [],
        gaugeEarningColor: '',
        gaugeCurrency: 'mi.',
        options: {
          baseContainerId: 'mn_indicatorCanvasContainer',
          fontBase: 500,
          defaultFontFamily: '"OpenSans", sans-serif',
          defaultFontWeight: 'bold',
          events: [],
          responsive: true,
          maintainAspectRatio: false,
        },
      },
      animation: {
        circleColor: '#745ac4',
        circleBorderColor: '#fff',
        circleTextColor: '#fff',
        circleFontStyle: '14',
        activeBackgroundColor: '#745ac4',
        backgroundColor: ['#745ac4', '#745ac4', '#745ac4', 'transparent'],
        rewardsEarnedFontSize: '50',
        rewardsEarnedLabelFontSize: '16',
        bonusEarnedLanguage: 'MILES',
        bonusEarnedLanguagePrefix: 'BONUS',
        bonusEarnedLanguageSuffix: ' EARNED',
        borderWidth: 0,
        gaugeData: {
          value: 0,
          earnedValue: 0,
          earnedReward: 0,
          valueCircleColor: '#68b3e7',
          valueTextColor: '#000',
          valueCircleBorderColor: '#68b3e7',
          earnedCenterValueColor: '#68b3e7',
        },
        gaugeLimits: [],
        options: {
          baseContainerId: 'mn_indicatorCanvasContainer',
          fontBase: 491,
          defaultFontFamily: '"OpenSans", sans-serif',
          defaultFontWeight: 'bold',
          events: [],
          responsive: true,
          maintainAspectRatio: false,
          showEarnedMarkers: false,
          animation: {
            animateRotate: true,
            animateScale: false,
          },
        },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
