import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useDebounce } from 'core/hooks/useDebounce';

import {
  selectInstoreIsEnabled, selectHasNoRewardsEnabled, selectBrandId,
} from 'core/selectors/app';
import { selectGspConfig } from 'core/selectors/services';
import { fetchMerchants, fetchMerchantsAll } from 'core/actions/ocapi';
import { fetchGspStores, fetchGspProducts } from 'core/actions/gsp';
import { clearGspMerchants, clearGspProducts } from '../actions';

import {
  selectQuickSearchMerchantsIsLoaded, selectQuickSearchMerchantsIsLoading,
} from '../selectors';

export const useGetHybridQuickSearch = (keywords, config) => {
  const dispatch = useDispatch();

  const { merchantsUrl: merchantsGspUrl, productsUrl: productsGspUrl } = useSelector(selectGspConfig);
  const isLoading = useSelector(selectQuickSearchMerchantsIsLoading);
  const isLoaded = useSelector(selectQuickSearchMerchantsIsLoaded);
  const brandId = useSelector(selectBrandId);
  const instoreIsEnabled = useSelector(selectInstoreIsEnabled);
  const hasNoRewardsEnabled = useSelector(selectHasNoRewardsEnabled);
  const debouncedKeywords = useDebounce(keywords, 300); // GSP delay 300ms

  // Fetch merchants list
  useEffect(() => {
    if (keywords.length > 0 && !isLoaded && !isLoading) {
      const { quickSearchMerchantsConfig } = config;
      const { api: { params, options } } = quickSearchMerchantsConfig;

      const apiParams = {
        ...params,
        include_inactive: hasNoRewardsEnabled ? 1 : 0,
      };

      const merchantFetcher = instoreIsEnabled ? fetchMerchantsAll : fetchMerchants;

      dispatch(merchantFetcher(apiParams, options));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords]);

  // Fetch GSP suggested merchants
  useEffect(() => {
    const { minChars, quickSearchGspSuggestionsConfig, shouldUseGsp } = config;
    const { api: { params, options } } = quickSearchGspSuggestionsConfig;

    if (!shouldUseGsp) {
      return;
    }

    if (keywords.length >= minChars) {
      const apiParams = {
        ...params,
        query: `subPartnerId: ${brandId} AND cf_text:"${encodeURI(keywords).replace(/%20/g, ' ')}"`,
      };
      dispatch(fetchGspStores(merchantsGspUrl, apiParams, options));
    } else {
      dispatch(clearGspMerchants());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeywords]);

  // Fetch GSP suggested products
  useEffect(() => {
    const { minChars, quickSearchGspProductSuggestionsConfig, shouldUseGsp } = config;
    const { api: { params, options } } = quickSearchGspProductSuggestionsConfig;

    if (!shouldUseGsp) {
      return;
    }

    if (keywords.length >= minChars) {
      const apiParams = { ...params, q: keywords };
      dispatch(fetchGspProducts(productsGspUrl, apiParams, options));
    } else {
      dispatch(clearGspProducts());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeywords]);

  return {
    isLoaded,
  };
};
