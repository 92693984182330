import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { navCategoryFeatured as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    rebateOptions: {
      hideElevationCurrency: true,
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
