/* eslint-disable max-len */

import { requestActionGeneratorByType, SUCCESS_SUFFIX, FAILURE_SUFFIX } from './apis';

const rootActionPath = 'core/server';

// action names
export const SERVER_GET_INFORMATION = `${rootActionPath}/information/GET`;
export const SERVER_GET_INFORMATION_SUCCESS = `${SERVER_GET_INFORMATION}_${SUCCESS_SUFFIX}`;
export const SERVER_GET_INFORMATION_FAILURE = `${SERVER_GET_INFORMATION}_${FAILURE_SUFFIX}`;

export const SERVER_GET_INFORMATION_MERCHANTS_LIST = `${SERVER_GET_INFORMATION}_MERCHANTS_LIST`;
export const SERVER_SALESFORCE_CASE_VALIDATION = `${SERVER_GET_INFORMATION}_SALESFORCE_CASE_VALIDATION`;

// exported methods
export const fetchMerchantsList = requestActionGeneratorByType(SERVER_GET_INFORMATION_MERCHANTS_LIST);
export const fetchSalesforceCaseIdValidation = requestActionGeneratorByType(SERVER_SALESFORCE_CASE_VALIDATION);
