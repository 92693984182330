import dayjs from 'core/utils/dayjs';
import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { recentStoresFlyout as orgConfig } from 'org/configs/modules.config';
import { CACHE_STORAGE } from 'core/utils/cache/base';
import {
  CLICK_HISTORY_GET_SUCCESS, CLICK_HISTORY_GET_FAILURE,
  ALL_MERCHANTS_GET_SUCCESS, ALL_MERCHANTS_GET_FAILURE,
} from '../actions/types';

const defaultConfig = {
  defaults: {
    clickHistoryConfig: {
      api: {
        params: {
          start_date: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
          end_date: dayjs().add(1, 'days').format('YYYY-MM-DD'),
          fields: [
            'globalMerchantId',
          ],
        },
        options: {
          success: CLICK_HISTORY_GET_SUCCESS,
          failure: CLICK_HISTORY_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true, type: CACHE_STORAGE },
        },
      },
    },
    allMerchantsConfig: {
      api: {
        params: {
          // For caching purposes we use exactly the same parameters in same order as Quick Search does
        },
        options: {
          success: ALL_MERCHANTS_GET_SUCCESS,
          failure: ALL_MERCHANTS_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true, type: CACHE_STORAGE },
        },
      },
    },
    tagHelper: () => {},
    headingText: 'Recent stores',
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
