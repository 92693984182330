import config from '../configs';
import { POPULAR_OFFERS_GET_SUCCESS, POPULAR_OFFERS_GET_FAILURE } from '../actions/types';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  offers: [],
};

const formatOffer = (offer) => {
  const { assets, merchant } = offer;
  const imageAlternateTextCustom = assets.imageAlternateText ?
    `${merchant.name} - ${assets.imageAlternateText}` : `Offer from ${merchant.name}`;

  return {
    ...offer,
    assets: {
      ...assets,
      imageAlternateTextCustom,
    },
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POPULAR_OFFERS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        offers: action.payload.response.map(offer => formatOffer(offer)),
      };
    }
    case POPULAR_OFFERS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        offers: [],
      };
    }
    default: {
      const { options = {} } = action;

      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === POPULAR_OFFERS_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};
