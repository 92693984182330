import React from 'react';
import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { navBanner as orgConfig } from 'org/configs/modules.config';
import clientConfig from 'org/configs/org.config';

const { earnType } = clientConfig;

const defaultConfig = {
  defaults: {
    bannerText: {
      button: {
        title: `Earn ${earnType} with the push of a button`,
        text: () => <>Get the new button and never miss out on {earnType}.</>,
        ctaTitle: 'Get the button',
      },
      favorites: {
        title: 'Play favorites',
        text: () => <>Pick your favorite stores and get email alerts about extra {earnType}.</>,
        ctaTitle: 'Play Favorites',
      },
    },
    showFavoritesBanner: true,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
