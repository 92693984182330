import recentStoresConfig from '../configs';

/** @type {{config: object, merchants: array.<object>}} */
const initialState = {
  config: recentStoresConfig,
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const recentStores = (state = initialState) => state;

export default recentStores;
