import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { searchBar as orgConfig } from 'org/configs/modules.config';

/** @type {object} */
const defaultConfig = {
  defaults: {
    getPlaceholder: () => ('Search your favorite stores and products'),
    shouldUseHybridSearch: false,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
