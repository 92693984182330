import { FAVORITE_STORES_VIEW, FAVORITE_STORES_MANAGE_FAVORITES_TILE_VIEW } from '../actions/types';

const VALUE_NOT_SET = '(not set)';

export const favoriteStoresGaActionEvents = {
  [FAVORITE_STORES_VIEW]: ({ merchants = [] }) => {
    const merchantNames = merchants.map(merchant => merchant.name);

    return {
      category: 'MHP Shop Your Favorites',
      action: 'View (after scroll)',
      label: merchantNames.join(', '),
      value: merchantNames.length,
    };
  },
  [FAVORITE_STORES_MANAGE_FAVORITES_TILE_VIEW]: () => ({
    category: 'MHP Shop Your Favorites',
    action: 'Manage Your Favorites View (after scroll and page through)',
    label: VALUE_NOT_SET,
    value: 1,
  }),
};

export const favoriteStoresClickAndHoverEvents = [
  {
    selector: '.mn_favoriteStores .mn_merchantExperienceLink',
    click: {
      category: 'MHP Shop Your Favorites',
      action: 'Favorite Click',
    },
    label: element => element.dataset.merchantName,
    value: 1,
  },
  {
    selector: '.mn_favoriteStores .mn_manageFavoritesTileWrap',
    click: {
      category: 'MHP Shop Your Favorites',
      action: 'Manage Your Favorites Click',
    },
    label: VALUE_NOT_SET,
    value: 1,
  },
];
