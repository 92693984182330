import { takeEvery, put } from 'redux-saga/effects';
import { showModal } from 'core/modules/Modal/actions';

import { MODAL_TYPE_SEARCH } from 'core/modules/Modal/types';
import { SEARCH_MODAL_TRIGGER } from '../actions/types';

function* showSearchModal() {
  yield put(showModal(MODAL_TYPE_SEARCH));
}

export default function* searchModalSaga() {
  yield takeEvery(SEARCH_MODAL_TRIGGER, showSearchModal);
}
