import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { popularCategories as orgConfig } from 'org/configs/modules.config';
import { POPULAR_CATEGORIES_GET_SUCCESS, POPULAR_CATEGORIES_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    minCategories: 2,
    maxCategories: 4,
    api: {
      params: {
        content_group_id: 5969,
        fields: ['assets'],
        section_id: 10437,
      },
      options: {
        success: POPULAR_CATEGORIES_GET_SUCCESS,
        failure: POPULAR_CATEGORIES_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
