import { combineReducers } from 'redux';
import config from '../configs';

import records from './records';
import merchants from './merchants';

export default combineReducers({
  config: () => config,
  records,
  merchants,
});
