import isEmpty from 'lodash/isEmpty';
import { TRANSACTION_AD_UNIT_GET_SUCCESS, TRANSACTION_AD_UNIT_GET_FAILURE } from '../actions/types';
import transactionAdUnitConfig from '../configs';

/** @type {{config: object, merchants: array.<object>}} */
const initialState = {
  config: transactionAdUnitConfig,
  placement: null,
  isLoading: false,
  isLoaded: false,
};

const handleResponse = (placement) => {
  if (isEmpty(placement)) {
    return null;
  }

  return {
    ...placement,
    isAdUnit: true,
  };
};

const transactionAdUnit = (state = initialState, action) => {
  switch (action.type) {
    case TRANSACTION_AD_UNIT_GET_SUCCESS: {
      const placement = handleResponse(action.payload.response[0]);
      return {
        ...state,
        placement,
        isLoading: false,
        isLoaded: true,
      };
    }
    case TRANSACTION_AD_UNIT_GET_FAILURE: {
      return {
        ...state,
        placement: null,
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === TRANSACTION_AD_UNIT_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default transactionAdUnit;
