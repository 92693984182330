const REGEX_TIMEZONE_OFFSET_FORMAT = /^(.*)([+-])(\d{2}):(\d{2})|(Z)$/;

const parseOffset = dateString => dateString.match(REGEX_TIMEZONE_OFFSET_FORMAT);

const formatOffset = (parsedOffset) => {
  const [, , sign, tzHour, tzMinute] = parsedOffset;
  const uOffset = (parseInt(tzHour, 10) * 60) + parseInt(tzMinute, 10);
  return sign === '+' ? uOffset : -uOffset;
};

const pluginFunc = (
  option,
  dayjsClass,
  dayjsFactory,
) => {
  dayjsFactory.parseZone = (
    date,
    format,
    locale,
    strict,
  ) => {
    if (typeof format === 'string') {
      // eslint-disable-next-line
      format = { format }
    }
    if (typeof date !== 'string') {
      return dayjsFactory(date, format, locale, strict);
    }
    const match = parseOffset(date);
    if (match === null) {
      return dayjsFactory(date, {
        $offset: 0,
      });
    }
    if (match[0] === 'Z') {
      return dayjsFactory(
        date,
        {
          utc: true,
          ...format,
        },
        locale,
        strict,
      );
    }
    const [, dateTime] = match;
    const offset = formatOffset(match);

    return dayjsFactory(
      dateTime,
      {
        $offset: offset,
        ...format,
      },
      locale,
      strict,
    );
  };
};

export default pluginFunc;
