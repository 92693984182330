import { SUCCESS_SUFFIX, FAILURE_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/TransactionsTab';

export const TRANSACTIONS_TAB_RECORDS_GET = `${rootActionPath}/records/GET`;
export const TRANSACTIONS_TAB_RECORDS_GET_SUCCESS = `${TRANSACTIONS_TAB_RECORDS_GET}_${SUCCESS_SUFFIX}`;
export const TRANSACTIONS_TAB_RECORDS_GET_FAILURE = `${TRANSACTIONS_TAB_RECORDS_GET}_${FAILURE_SUFFIX}`;

export const TRANSACTIONS_TAB_MERCHANTS_GET = `${rootActionPath}/merchants/GET`;
export const TRANSACTIONS_TAB_MERCHANTS_GET_SUCCESS = `${TRANSACTIONS_TAB_MERCHANTS_GET}_${SUCCESS_SUFFIX}`;
export const TRANSACTIONS_TAB_MERCHANTS_GET_FAILURE = `${TRANSACTIONS_TAB_MERCHANTS_GET}_${FAILURE_SUFFIX}`;
