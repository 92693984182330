import React from 'react';
import { useSelector } from 'react-redux';
import PremierMerchantRebate from 'core/modules/PremierMerchantRebate/PremierMerchantRebate';

import { selectSiteUrl } from 'core/selectors/app';

import config from './configs';

import './NavCategoryFeatured.scss';

function NavCategoryFeatured({ featured, title, url }) {
  const siteUrl = useSelector(selectSiteUrl);

  const { rebateOptions } = config;

  return (
    <div className="mn_navCategoryFeaturedSection">
      <h2>Featured</h2>
      {
        featured && featured.map(({ merchant, id }) => (
          <a
            key={id}
            className="mn_navCategoryFeaturedItem"
            href={`${siteUrl}/me____.htm?gmid=${merchant.id}`}
          >
            <h3>{merchant.name}</h3>
            <PremierMerchantRebate {...merchant.rebate} {...rebateOptions} />
          </a>
        ))
      }
      <div className="mn_navCategoryFeaturedFooter">
        <a href={url} className="mn_seeAllLink" data-category-name={title}>
          See all <span>{title}</span>
        </a>
      </div>
    </div>
  );
}

export default NavCategoryFeatured;
