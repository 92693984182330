import { STICKY_FOOTER_GET_SUCCESS, STICKY_FOOTER_GET_FAILURE } from '../actions/types';
import stickyFooterConfig from '../configs';

const initialState = {
  config: stickyFooterConfig,
  namespaceDefaults: {
    isLoaded: false,
    isLoading: false,
    placement: {},
  },
};

const parseResponse = (offer) => {
  if (!offer) return {};

  const { _150x150, _115x175 } = offer.assets;
  const image = _150x150 || _115x175;

  return ({
    ...offer,
    assets: {
      ...offer.assets,
      image,
      isImage150By150: !!_150x150,
    },
  });
};

const getNamespaceData = (action, state) => {
  const { namespace = 'default' } = action?.context?.options || action?.options || {};
  const namespaceState = state[namespace] || state.namespaceDefaults;

  return { namespace, namespaceState };
};

const stickyFooter = (state = initialState, action) => {
  switch (action.type) {
    case STICKY_FOOTER_GET_SUCCESS: {
      const { namespace, namespaceState } = getNamespaceData(action, state);
      return {
        ...state,
        [namespace]: {
          ...namespaceState,
          placement: parseResponse(action.payload.response[0]),
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case STICKY_FOOTER_GET_FAILURE: {
      const { namespace, namespaceState } = getNamespaceData(action, state);
      return {
        ...state,
        [namespace]: {
          ...namespaceState,
          placement: {},
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === STICKY_FOOTER_GET_SUCCESS) {
        const { namespace, namespaceState } = getNamespaceData(action, state);
        return {
          ...state,
          [namespace]: {
            ...namespaceState,
            isLoading: true,
          },
        };
      }

      return state;
    }
  }
};

export default stickyFooter;
