import { INSTORE_LOCATION_OFFERS_GET_FAILURE, INSTORE_LOCATION_OFFERS_GET_SUCCESS } from '../actions/types';
import config from '../config/config';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  locations: [],
};

const map = (state = initialState, action) => {
  switch (action.type) {
    case INSTORE_LOCATION_OFFERS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        locations: action.payload.response,
      };
    }
    case INSTORE_LOCATION_OFFERS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        locations: [],
      };
    }
    default:
      return state;
  }
};

export default map;
