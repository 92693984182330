import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { flipTile as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    rebateOptions: {
      showRebateInline: true,
      hideElevationCurrency: true,
      tiered: {
        hideElevationCurrency: true,
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
