import {
  INSTORE_HIW_MODAL_TRIGGER,
  INSTORE_HIW_MODAL_CLEAR,
} from '../actions/types';

const initialState = {};

const instoreHiwModal = (state = initialState, action) => {
  switch (action.type) {
    case INSTORE_HIW_MODAL_TRIGGER: {
      return { ...state, data: action.data };
    }
    case INSTORE_HIW_MODAL_CLEAR: {
      return initialState;
    }
    default:
      return state;
  }
};

export default instoreHiwModal;
