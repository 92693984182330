import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { headerExtraRewards as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    viewAllLanguage: 'View all stores',
    getTitle: (earnType) => `Stores offering extra ${earnType}`,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
