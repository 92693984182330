import React from 'react';
import PropTypes from 'prop-types';

import MainNavItemWithSubnav from '../MainNavItemWithSubnav/MainNavItemWithSubnav';
import MainNavItemWithoutSubnav from '../MainNavItemWithoutSubnav/MainNavItemWithoutSubnav';

import './MainNavItem.scss';

function MainNavItem({ children, ...attrs }) {
  return children ?
    <MainNavItemWithSubnav {...attrs}>
      {children}
    </MainNavItemWithSubnav>
    :
    <MainNavItemWithoutSubnav {...attrs} />;
}

MainNavItem.propTypes = {
  url: PropTypes.string,
  title: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  className: PropTypes.string,
  newPillEnabled: PropTypes.bool,
  children: PropTypes.node,
};

MainNavItem.defaultProps = {
  url: null,
  className: '',
  newPillEnabled: false,
  children: null,
};

export default MainNavItem;
