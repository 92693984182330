import React from 'react';

export function SearchSection({ clickHandler, isSearchExpanded }) {
  return (
    <button
      className="mn_navigationItem mn_searchTrigger"
      aria-expanded={isSearchExpanded}
      aria-label="Toggle search"
      onClick={clickHandler}
    >
      <i className="mn_searchTriggerOpen mn_unitedIconSearch" aria-hidden="true" />
    </button>
  );
}

export default SearchSection;
