import { INSTORE_SMS_VIEW } from '../actions/types';

export const instoreSMSNumberActionEvents = {
  [INSTORE_SMS_VIEW]: () => ({
    category: 'In-store',
    action: 'Prompted for number',
  }),
};

export const instoreSMSNumberClickAndHoverEvents = [
  {
    selector: '.mn_instoreSMSOptInConfirmButtonContainer .mn_confirmButtonEnable',
    click: {
      category: 'In-store',
      action: 'Entered number',
    },
  },
  {
    selector: '.mn_instoreSMSOptInReenterMobileNumberContainer',
    click: {
      category: 'In-store',
      action: 'Mobile number opt-in confirmation',
    },
  },
];
