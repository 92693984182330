import LocalStorageCache from 'core/utils/cache/localStorage';

const localStorageCache = new LocalStorageCache();
const HAS_PREVIOUSLY_LOGGED_IN = 'hasPreviouslyLoggedIn';

export const getHasPreviouslyLoggedIn = () => (
  localStorageCache.getItem(HAS_PREVIOUSLY_LOGGED_IN)
);

export const setHasPreviouslyLoggedIn = () => {
  if (!getHasPreviouslyLoggedIn()) {
    localStorageCache.setItem(HAS_PREVIOUSLY_LOGGED_IN, true, {
      persist: true,
    });
  }
};
