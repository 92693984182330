import Cookies from 'js-cookie';

const RAVEN_COOKIE_NAME = 'mn_raven';

export default function addRavenIndicatorToBody() {
  const isRaven = Cookies.get(RAVEN_COOKIE_NAME) != null;

  if (isRaven) {
    const ravenIndicatorBar = document.createElement('div');
    ravenIndicatorBar.id = 'mn_ravenIndicatorBar';
    ravenIndicatorBar.innerHTML =
      `<div id="mn_ravenIndicatorBarIn">
        <div>You are currently using Raven!</div>
        <div id="mn_ravenBarClearCache">
          <button>Clear Local Storage Cache And Reload</button>
        </div>
        <div id="mn_ravenBarClose">
          <button>Remove Raven Cookie</button>
        </div>
      </div>`.replace(/\n/g, '');

    document.body.prepend(ravenIndicatorBar);

    document.getElementById('mn_ravenBarClearCache').querySelector('button').addEventListener('click', () => {
      localStorage.clear();
      window.location.reload();
    });

    document.getElementById('mn_ravenBarClose').querySelector('button').addEventListener('click', () => {
      Cookies.set(RAVEN_COOKIE_NAME, null, { expires: -1 });
      document.getElementById('mn_ravenIndicatorBar').style.display = 'none';
      window.location.reload();
    });
  }
}
