import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { transactionAdUnit as orgConfig } from 'org/configs/modules.config';
import { TRANSACTION_AD_UNIT_GET_SUCCESS, TRANSACTION_AD_UNIT_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        offer_tag: 'my-account-page-inline-offer',
        fields: [
          'id',
          'clickUrl',
          'merchant',
          'offerDetails',
          'couponCode',
          'stopDatetime',
          'isOngoing',
        ],
      },
      options: {
        success: TRANSACTION_AD_UNIT_GET_SUCCESS,
        failure: TRANSACTION_AD_UNIT_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
