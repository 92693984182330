import { takeEvery, put } from 'redux-saga/effects';
import { showModal } from 'core/modules/Modal/actions';

import { MODAL_TYPE_BONUS_ROLLING } from 'core/modules/Modal/types';
import { BONUS_ROLLING_MODAL_TRIGGER } from '../actions/types';

function* showBonusRollingModal() {
  yield put(showModal(MODAL_TYPE_BONUS_ROLLING));
}

export default function* buttonScrollingModalSaga() {
  yield takeEvery(BONUS_ROLLING_MODAL_TRIGGER, showBonusRollingModal);
}
