import React from 'react';
import isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';

import { selectInstoreIsEnabled } from 'core/selectors/app';

import OnlineMatches from './partials/OnlineMatches';
import InstoreMatches from './partials/InstoreMatches';
import NoMatches from './partials/NoMatches';
import ProductResultLink from './partials/ProductResultLink';

import {
  selectQuickSearchConfig,
  selectOnlineQuickSearchMatches,
  selectInstoreQuickSearchMatches,
} from '../selectors';

export function QuickSearchMatchesGSP({
  inquiryOrderStoreInputClickHandler, keywords, quickSearchResultsRef, getTitle,
  config, localMerchantClickHandler, isPopUpView, shouldShowResultLinkOutside,
}) {
  config.shouldUseGsp = true;
  const instoreIsEnabled = useSelector(state => (!isNil(config && config.instoreIsEnabled)
    ? config.instoreIsEnabled : selectInstoreIsEnabled(state)));
  const localConfig = useSelector(state => selectQuickSearchConfig(state, config));
  const onlineMatches = useSelector(state => selectOnlineQuickSearchMatches(state, keywords, config));
  const instoreMatches =
    useSelector(state => (instoreIsEnabled ? selectInstoreQuickSearchMatches(state, keywords, config) : []));

  if (onlineMatches.length === 0 && instoreMatches.length === 0) {
    return (
      <div className="mn_quickSearchResults mn_noResult">
        <ProductResultLink keywords={keywords} />
        <NoMatches inquiryOrderStoreInputClickHandler={inquiryOrderStoreInputClickHandler} />
      </div>
    );
  }

  return (
    <>
      <div className="mn_quickSearchResults mn_quickSearchResultsGsp" ref={quickSearchResultsRef}>
        { isPopUpView && <h3 className="mn_quickSearchMatchingTitle">Matching stores</h3> }
        <OnlineMatches
          onlineMatches={onlineMatches}
          localConfig={localConfig}
          keywords={keywords}
          localMerchantClickHandler={localMerchantClickHandler}
          isPopUpView={isPopUpView}
          instoreMatches={instoreMatches}
          getTitle={getTitle}
        />
        <InstoreMatches
          instoreMatches={instoreMatches}
          isPopUpView={isPopUpView}
          getTitle={getTitle}
        />
        {!shouldShowResultLinkOutside && <ProductResultLink keywords={keywords} />}
      </div>
      {shouldShowResultLinkOutside && <ProductResultLink keywords={keywords} />}
    </>
  );
}

export default QuickSearchMatchesGSP;
