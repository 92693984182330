import {
  SERVER_VALIDATE_SALESFORCE_CASEID_GET_SUCCESS, SERVER_VALIDATE_SALESFORCE_CASEID_GET_FAILURE,
} from '../actions/types';

const initialState = {
  isValid: null,
  isLoading: false,
  isLoaded: false,
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const validateCaseId = (state = initialState, action) => {
  switch (action.type) {
    case SERVER_VALIDATE_SALESFORCE_CASEID_GET_SUCCESS: {
      const { isValid } = action.response;

      return {
        ...state,
        isValid,
        isLoading: false,
        isLoaded: true,
      };
    }
    case SERVER_VALIDATE_SALESFORCE_CASEID_GET_FAILURE: {
      return {
        ...state,
        isValid: null,
        isLoading: false,
        isLoaded: true,
      }; // we'd have to decide what to do with error state
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === SERVER_VALIDATE_SALESFORCE_CASEID_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default validateCaseId;
