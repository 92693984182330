import { SUCCESS_SUFFIX, FAILURE_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/merchantExperience';

export const MERCHANT_EXPERIENCE_GET_SUCCESS = `${rootActionPath}/GET_${SUCCESS_SUFFIX}`;
export const MERCHANT_EXPERIENCE_GET_FAILURE = `${rootActionPath}/GET_${FAILURE_SUFFIX}`;
export const MERCHANT_EXPERIENCE_CLEAR = `${rootActionPath}/CLEAR`;
export const MERCHANT_EXPERIENCE_FROM_SMARTY_GET_SUCCESS = `${rootActionPath}/FROM_SMARTY_GET_${SUCCESS_SUFFIX}`;

export const MERCHANT_EXPERIENCE_TYPE_SINGLE = 'Merchant Details - single search results';
export const MERCHANT_EXPERIENCE_TYPE_MULTIPLE = 'Merchant Details - multiple search results';
export const MERCHANT_EXPERIENCE_TYPE_SUSPENDED = 'Merchant Details - suspended';
export const MERCHANT_EXPERIENCE_TYPE_DIRECT_LINK = 'Merchant Details - direct link';

export const MERCHANT_EXPERIENCE_PAGE_VIEW = 'Merchant Experience page view';

export const MERCHANT_EXPERIENCE_BONUS_VIEW = 'Merchant Experience bonus view';

export const MERCHANT_EXPERIENCE_SUSPENDED_MERCHANT_VIEW = `${rootActionPath}/suspendedMercant/VIEW`;
