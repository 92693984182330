import dayjs from 'core/utils/dayjs';
import config from '../configs';
import { FEATURED_DEALS_GET_SUCCESS, FEATURED_DEALS_GET_FAILURE } from '../actions/types';

const initialState = {
  isLoading: false,
  isLoaded: false,
  offers: [],
};

const formatOffer = offer => (
  offer.expires && !offer.isOngoing ? {
    ...offer,
    expires: dayjs.parseZone(offer.expires).format(config.dateFormat),
  } : offer
);

export default (state = initialState, action) => {
  switch (action.type) {
    case FEATURED_DEALS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        offers: action.payload.response.map(offer => formatOffer(offer)),
      };
    }
    case FEATURED_DEALS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        offers: [],
      };
    }
    default: {
      const { options = {} } = action;

      if (options.success === FEATURED_DEALS_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};
