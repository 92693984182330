import { takeEvery, put } from 'redux-saga/effects';
import { showModal } from 'core/modules/Modal/actions';

import { MODAL_TYPE_BUTTON_SCROLLING } from 'core/modules/Modal/types';
import { BUTTON_SCROLLING_MODAL_TRIGGER } from '../actions/types';

function* showSearchModal() {
  yield put(showModal(MODAL_TYPE_BUTTON_SCROLLING));
}

export default function* buttonScrollingModalSaga() {
  yield takeEvery(BUTTON_SCROLLING_MODAL_TRIGGER, showSearchModal);
}
