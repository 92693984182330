import { sanitizeString } from 'core/utils/helpers/sanitizeString';

export const getParamsFromString = (string = '') => string.split('&').reduce((params, param) => {
  const [key, value] = param.split('=');
  if (typeof value === 'undefined') {
    return params;
  }

  return {
    ...params,
    [key]: sanitizeString(decodeURIComponent(value.replace(/\+/g, ' '))),
  };
}, {});
