import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { headerOnboardingCongratulations as orgConfig } from 'org/configs/modules.config';

/** @type {object} */
const defaultConfig = {
  defaults: {
    getCongratulationText: (earnType) => (`You're ready to start shopping and earning ${earnType}.`),
    image: null,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
