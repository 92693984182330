import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPlacements } from 'core/actions/ocapi';

import {
  selectCreditCardMarketingBannerConfig,
  selectCreditCardMarketingBannerIsLoading,
  selectCreditCardMarketingBannerIsLoaded,
  selectCreditCardMarketingBannerPlacement,
} from '../selectors';

export const useGetCreditCardMarketingBanner = () => {
  const config = useSelector(selectCreditCardMarketingBannerConfig);
  const isLoading = useSelector(selectCreditCardMarketingBannerIsLoading);
  const isLoaded = useSelector(selectCreditCardMarketingBannerIsLoaded);
  const placement = useSelector(selectCreditCardMarketingBannerPlacement);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      const { api: { params, options } } = config;
      dispatch(fetchPlacements(params, options));
    }
  }, []);

  return {
    placement,
  };
};
