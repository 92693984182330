import merge from 'lodash/merge';
import { takeEvery } from 'redux-saga/effects';

import { APP_UPDATE } from '../actions/types';

function updateWindowInitialState(action) {
  merge(window.initialState, action.payload);
}

export default function* appSaga() {
  yield takeEvery(APP_UPDATE, updateWindowInitialState);
}
