const VALUE_NOT_SET = '(not set)';

export const orderInquiryModalClickAndHoverEvents = [
  {
    selector: '.mn_orderInquiryModal .mn_continue',
    click: {
      category: 'Contact Us - Order inquiry',
      action: 'No store visit - continue',
    },
    label: VALUE_NOT_SET,
  },
  {
    selector: '.mn_orderInquiryModal .mn_modalClose',
    click: {
      category: 'Contact Us - Order inquiry',
      action: 'No store visit - cancel',
    },
    label: VALUE_NOT_SET,
  },
];
