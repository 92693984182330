import { takeEvery, put } from 'redux-saga/effects';
import { hideModal, showModal } from 'core/modules/Modal/actions';

import { MODAL_TYPE_EMAIL_OPT_IN_SUCCESS } from 'core/modules/Modal/types';
import { EMAIL_OPT_IN_MODAL_SUCCESS_CLOSE, EMAIL_OPT_IN_MODAL_SUCCESS_TRIGGER } from '../actions/types';

function* showEmailOptInSuccessModal() {
  yield put(showModal(MODAL_TYPE_EMAIL_OPT_IN_SUCCESS));
}

function* hideEmailOptInSuccessModal() {
  yield put(hideModal());
}

export default function* emailOptInSuccessModalSaga() {
  yield takeEvery(EMAIL_OPT_IN_MODAL_SUCCESS_TRIGGER, showEmailOptInSuccessModal);
  yield takeEvery(EMAIL_OPT_IN_MODAL_SUCCESS_CLOSE, hideEmailOptInSuccessModal);
}
