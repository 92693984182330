import { createSelector } from 'reselect';
import { selectConfig } from './app';

export const selectUser = createSelector(selectConfig, config => config.user);
export const selectUserId = createSelector(selectUser, user => user.userId);
export const selectUserIdHashed = createSelector(selectUser, user => user.userIdHashed);
export const selectUserIsIdentified = createSelector(selectUser, user => user.isIdentified);
export const selectUserIsAuthenticated = createSelector(selectUser, user => user.isAuthenticated);
export const selectUserIsFirstTimeVisit = createSelector(selectUser, user => user.firstTimeVisit);
export const selectUserName = createSelector(selectUser, user => user.userName);
export const selectDisplayableUserId = createSelector(selectUser, user => user.displayableUserId);
export const selectEmailPopup = createSelector(selectUser, user => user.emailPopup || {});
export const selectHasDonotShareEnabled = createSelector(selectUser, user => user.hasDonotShareEnabled);
export const selectInstoreInfo = createSelector(selectUser, user => user.instoreInfo);
// browser
export const selectBrowser = createSelector(selectUser, user => user.browser || {});
export const selectBrowserIsMobile = createSelector(selectBrowser, browser => browser.isMobile);
// properties
export const selectProperties = createSelector(selectUser, user => user.properties || {});
export const selectLifecycleSegment = createSelector(selectProperties, properties => properties.lifecycleSegment);
export const selectReferralId = createSelector(selectProperties, properties => properties.referralId);
export const selectButtonInd = createSelector(selectProperties, properties => properties.buttonInd);
