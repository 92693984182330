import { combineReducers } from 'redux';
import storeBundleMerchants from './storeBundleMerchants';
import storeBundleAssets from './storeBundleAssets';
import config from '../configs';

export default combineReducers({
  config: () => config,
  storeBundleMerchants,
  storeBundleAssets,
});
