import dayjs from 'core/utils/dayjs';
import { selectCurrentDate } from 'core/selectors/env';
import { setDateEndOfDayIfEmpty } from 'core/utils/helpers/date';
/**
 * Check if expiration date is 1 year away
 * @param {string} endDate
 * @param {string} currentDate
 * @return {string}
 */
export const checkIfOfferOngoing = ({ endDate, currentDate }) => {
  if (!currentDate) return undefined;
  return !endDate || dayjs(endDate).diff(currentDate, 'years') >= 1;
};

/**
 * Check if expiration date is 'daysOffset' days away
 * @param {string} endDate
 * @param {string} currentDate
 * @param {number} daysOffset
 * @return {string}
 */
export const checkIfOfferEndsSoon = ({ endDate, currentDate, daysOffset = 2 }) => {
  if (!currentDate || !endDate) return undefined;
  return dayjs(endDate).diff(currentDate, 'days') <= daysOffset;
};

/**
 * @param {string} expires
 * @return {string|undefined}
 */
export const formatExpires = expires => (
  expires ? dayjs(expires, 'YYYY-MM-DD').toISOString() : expires
);

export const parseOfferDateProperties = (offer, state) => {
  try {
    const { stopDatetime, expires } = offer;
    const endDate = stopDatetime || formatExpires(expires);

    const currentDate = selectCurrentDate(state);
    const isOngoing = checkIfOfferOngoing({ endDate, currentDate });
    const isEndingSoon = checkIfOfferEndsSoon({ endDate, currentDate });

    return {
      stopDatetime: endDate,
      isOngoing,
      expires: isOngoing ? 'Ongoing' : endDate,
      isEndingSoon,
    };
  } catch (error) {
    console.log(`Can't parse date: ${offer.stopDatetime}`);
    return offer;
  }
};

// offer countdown date
export const validateCountdownStopDate = (dateString) => {
  if (!dateString) {
    return false;
  }

  return dayjs(dateString).isValid() && dayjs(dateString).year() < 2099;
};

export const getOfferCountdownStopDate = (offer) => {
  // Return formatted 'offer.assets.countdownClockStopDate' is set
  const countdownCustomStopDateFormatted = offer.assets && offer.assets.countdownClockStopDate
    && validateCountdownStopDate(offer.assets.countdownClockStopDate)
    && setDateEndOfDayIfEmpty(offer.assets.countdownClockStopDate);

  if (countdownCustomStopDateFormatted) {
    return countdownCustomStopDateFormatted;
  }

  // If even 'offer.assets.countdownClockPrefixText' is not set then these is no countdown setup for this offer
  if (!(offer.assets && offer.assets.countdownClockPrefixText)) {
    return null;
  }

  // If 'offer.assets.countdownClockPrefixText' exists then return formatted offer.stopDatetime
  const offerStopDateFormatted = offer.stopDatetime
    && validateCountdownStopDate(offer.stopDatetime)
    && setDateEndOfDayIfEmpty(offer.stopDatetime);

  return offerStopDateFormatted;
};

export const checkIfOfferHasCountdown = offer => !!(offer.assets && getOfferCountdownStopDate(offer));

export default parseOfferDateProperties;
