import { SUCCESS_SUFFIX, FAILURE_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/ProductSearchResults';

export const PRODUCT_SEARCH_RESULTS_GET_SUCCESS = `${rootActionPath}/GET_${SUCCESS_SUFFIX}`;
export const PRODUCT_SEARCH_RESULTS_GET_FAILURE = `${rootActionPath}/GET_${FAILURE_SUFFIX}`;

export const PRODUCT_SEARCH_RESULTS_UPDATE_GET_SUCCESS = `${rootActionPath}/update/GET_${SUCCESS_SUFFIX}`;
export const PRODUCT_SEARCH_RESULTS_UPDATE_GET_FAILURE = `${rootActionPath}/update/GET_${FAILURE_SUFFIX}`;

export const PRODUCT_SEARCH_RESULTS_FILTER_GET_SUCCESS = `${rootActionPath}/filter/GET_${SUCCESS_SUFFIX}`;
export const PRODUCT_SEARCH_RESULTS_FILTER_GET_FAILURE = `${rootActionPath}/filter/GET_${FAILURE_SUFFIX}`;

export const PRODUCT_SEARCH_RESULTS_CLEAR = `${rootActionPath}/CLEAR`;

// GA
export const PRODUCT_SEARCH_RESULTS_VIEW = `${rootActionPath}/VIEW`;
export const PRODUCT_SEARCH_RESULTS_CLEAR_FILTER = `${rootActionPath}/CLEAR_FILTER`;
export const PRODUCT_SEARCH_RESULTS_APPLY_FILTER = `${rootActionPath}/APPLY_FILTER`;
export const PRODUCT_SEARCH_RESULTS_APPLY_SORT = `${rootActionPath}/APPLY_SORT`;
