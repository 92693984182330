/* eslint-disable max-len */
import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { errorMessage as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    returnUrl: '',
    errorCode: undefined,
    showErrorCodes: [], // an array of error codes which could be rendered in template if page error match any on them
    errorMessages: {
      default: { // 'default' message has a lowest priority in messages merging
        title: 'Sorry, an internal error occurred!',
        message: 'Your request could not be processed. <br>Please try again later.',
      },
      custom: {
        // 'custom' has a highest proirity in messages merging
        // If set in org level mn-config file it will override all error code titles except the one passed as a data attribute via markup
        title: undefined,
        message: undefined,
      },
      2: {
        message: 'Your request could not be processed. <br>Please try again later.',
      },
      4: {
        message: 'Your request could not be processed due to incorrect request params. <br>Please try again later.',
      },
      5: {
        message: 'Your request could not be processed, due to illegal access to page. <br>Please try again later.',
      },
      42: {
        message: 'Your request could not be processed, due to response error from product webservice. <br>Please try again later.',
      },
      43: {
        message: 'Your request could not be processed, due to response error from offers webservice. <br>Please try again later.',
      },
      44: {
        message: 'Your request could not be processed, due to response error from merchant webservice. <br>Please try again later.',
      },
      45: {
        message: 'Your request could not be processed, due to response error from category webservice. <br>Please try again later.',
      },
      46: {
        message: 'Your request could not be processed (no chase UID). <br>Please try again later.',
      },
      47: {
        message: 'Your request could not be processed (no chase RPC). <br>Please try again later.',
      },
      49: {
        message: 'We are unable to process your request at this time. The system is currently unavailable. Please try again later.',
      },
      50: {
        message: 'Your request could not be processed (due to wrong relay-state). <br>Please try again later.',
      },
      52: {
        message: 'Your request could not be processed (due to incorrect account id). <br>Please try again later.',
      },
      53: {
        message: 'Your request could not be processed (due to invalid dm). <br>Please try again later.',
      },
      54: {
        message: 'Your request could not be processed (due to united web service error). <br>Please try again later.',
      },
      55: {
        message: 'Your request could not be processed (due to incorrect brand). <br>Please try again later.',
      },
      56: {
        message: 'Your request could not be processed (due to incorrect member id). <br>Please try again later.',
      },
      57: {
        message: 'Your request could not be processed (due to technical difficulties with web service). <br>Please try again later.',
      },
      58: {
        message: 'Your request could not be processed (due to system errors on profile webservice). <br>Please try again later.',
      },
      59: {
        message: 'Your request could not be processed (due to login process error). <br>Please try again later.',
      },
      62: {
        message: 'Your request could not be processed (due to wrong query code). <br>Please try again later.',
      },
      63: {
        message: 'Your request could not be processed (the payload is expired). <br>Please try again later.',
      },
      64: {
        message: 'Your request could not be processed, since was missed brand or org id for member id. <br>Please try again later.',
      },
      65: {
        message: 'Your request could not be processed, due to invalid client program id. <br>Please try again later.',
      },
      66: {
        message: 'Your request could not be processed, due to invalid brand id. <br>Please try again later.',
      },
      67: {
        message: 'Your request could not be processed, since can\'t detect brand organization. <br>Please try again later.',
      },
      102: {
        message: 'Your request could not be processed, since can\'t record T&C. <br>Please try again later.',
      },
      115: {
        message: 'Your request could not be processed, due to no eligible rpc. <br>Please try again later.',
      },
      120: {
        message: 'Please try back again later.',
      },
      404: {
        message: 'We\'re sorry, but our server is experiencing a problem with your request. Our support team has been notified. Please check the URL and try again. We are sorry for the inconvenience.',
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
