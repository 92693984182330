import { createSelector } from 'reselect';

export const favoritedStores = state => state.favoritedStores;

export const selectFavoritedStoresConfig = createSelector(
  favoritedStores,
  favoritedStores => favoritedStores.config,
);

export const selectFavoritedStoresMerchants = createSelector(
  favoritedStores,
  favoritedStores => favoritedStores.merchants,
);

export const selectFavoritedStoresIsLoading = createSelector(
  favoritedStores,
  favoritedStores => favoritedStores.isLoading,
);

export const selectFavoritedStoresIsLoaded = createSelector(
  favoritedStores,
  favoritedStores => favoritedStores.isLoaded,
);
