import { createSelector } from 'reselect';

const navigationSelector = state => state.nav;

export const selectNavConfig = createSelector(navigationSelector, (navigation = {}) => navigation.config);

export const selectNavItems = createSelector(navigationSelector, (navigation = {}) => navigation.navItems);

export const selectNavIsLoaded = createSelector(navigationSelector, (navigation = {}) => navigation.isLoaded);

export const selectNavIsLoading = createSelector(navigationSelector, (navigation = {}) => navigation.isLoading);

export const selectNavItemsFeatured = createSelector(selectNavItems, (navItems = []) =>
  navItems.filter(navItem => navItem.showInTopNavigation));

export const selectNavItemsNonFeatured = createSelector(selectNavItems, (navItems = []) =>
  navItems.filter(navItem => !navItem.showInTopNavigation));
