/* eslint-disable max-len */

import { requestActionGeneratorByType, SUCCESS_SUFFIX, FAILURE_SUFFIX } from './apis';

export const OCAPI_ROOT_ACTION_PATH = 'core/apis/ocapi';

// action names
export const OCAPI_GET_CATEGORIES = `${OCAPI_ROOT_ACTION_PATH}/categories/GET`;
export const OCAPI_GET_FLYOUT_CATEGORIES = `${OCAPI_ROOT_ACTION_PATH}/flyout-categories/GET`;
export const OCAPI_GET_CLICK_HISTORY = `${OCAPI_ROOT_ACTION_PATH}/click-history/GET`;
export const OCAPI_GET_CONTENT_PAGE_BY_ID = `${OCAPI_ROOT_ACTION_PATH}/content-pages/:id/GET`;
export const OCAPI_GET_INSTORE_LOCATIONS = `${OCAPI_ROOT_ACTION_PATH}/in-store/locations/GET`;
export const OCAPI_GET_INSTORE_OFFERS = `${OCAPI_ROOT_ACTION_PATH}/in-store/offers/GET`;
export const OCAPI_GET_INSTORE_TRANSACTIONS = `${OCAPI_ROOT_ACTION_PATH}/in-store/transactions/GET`;
export const OCAPI_GET_MEMBER_ACCOUNT_SUMMARY = `${OCAPI_ROOT_ACTION_PATH}/member/account-summary/GET`;
export const OCAPI_GET_MEMBER_ACCOUNT_SUMMARY_SUCCESS = `${OCAPI_GET_MEMBER_ACCOUNT_SUMMARY}_${SUCCESS_SUFFIX}`;
export const OCAPI_GET_MEMBER_ACCOUNT_SUMMARY_FAILURE = `${OCAPI_GET_MEMBER_ACCOUNT_SUMMARY}_${FAILURE_SUFFIX}`;
export const OCAPI_GET_MEMBER_ORDERS = `${OCAPI_ROOT_ACTION_PATH}/member/orders/GET`;
export const OCAPI_GET_MEMBER_TRANSACTIONS = `${OCAPI_ROOT_ACTION_PATH}/member/transactions/GET`;
export const OCAPI_GET_MERCHANTS = `${OCAPI_ROOT_ACTION_PATH}/merchants/GET`;
export const OCAPI_GET_MERCHANTS_ALL = `${OCAPI_ROOT_ACTION_PATH}/merchants/all/GET`;
export const OCAPI_GET_MERCHANTS_BY_IDS = `${OCAPI_ROOT_ACTION_PATH}/merchants/:ids/GET`;
export const OCAPI_GET_MERCHANT_BY_ID = `${OCAPI_ROOT_ACTION_PATH}/merchant/:id/GET`;
export const OCAPI_GET_SIMILAR_MERCHANTS_BY_ID = `${OCAPI_ROOT_ACTION_PATH}/merchants/:id/similar/GET`;
export const OCAPI_GET_NAVIGATION = `${OCAPI_ROOT_ACTION_PATH}/navigation/GET`;
export const OCAPI_GET_PLACEMENTS = `${OCAPI_ROOT_ACTION_PATH}/placements/GET`;
export const OCAPI_GET_PLACEMENTS_BY_ID = `${OCAPI_ROOT_ACTION_PATH}/placements/:id/GET`;
export const OCAPI_GET_SEARCH = `${OCAPI_ROOT_ACTION_PATH}/search/GET`;
export const OCAPI_GET_SEARCH_PRODUCTS = `${OCAPI_ROOT_ACTION_PATH}/searchProducts/GET`;
export const OCAPI_GET_SEARCH_PRODUCTS_BY_ID = `${OCAPI_ROOT_ACTION_PATH}/searchProducts/:id/GET`;
export const OCAPI_GET_SWEEPS = `${OCAPI_ROOT_ACTION_PATH}/sweeps/GET`;

// action generator methods
const generateGetContentPageByIdAction = requestActionGeneratorByType(OCAPI_GET_CONTENT_PAGE_BY_ID);
const generateGetMerchantsByIdsAction = requestActionGeneratorByType(OCAPI_GET_MERCHANTS_BY_IDS);
const generateGetMerchantByIdAction = requestActionGeneratorByType(OCAPI_GET_MERCHANT_BY_ID);
const generateGetSimilarMerchantsByIdAction = requestActionGeneratorByType(OCAPI_GET_SIMILAR_MERCHANTS_BY_ID);
const generateGetSearchPropuctsByIdAction = requestActionGeneratorByType(OCAPI_GET_SEARCH_PRODUCTS_BY_ID);
const generateGetPlacementsByIdAction = requestActionGeneratorByType(OCAPI_GET_PLACEMENTS_BY_ID);

// fetch methods
export const fetchCategories = requestActionGeneratorByType(OCAPI_GET_CATEGORIES);
export const fetchFlyoutCategories = requestActionGeneratorByType(OCAPI_GET_FLYOUT_CATEGORIES);
export const fetchClickHistory = requestActionGeneratorByType(OCAPI_GET_CLICK_HISTORY);

/**
 * @param {number} id
 * @param {object} params
 * @param {object} options
 * @returns {function}
 */
export const fetchContentPageById = (id, params, options) => (
  generateGetContentPageByIdAction(params, options, { id })
);

export const fetchInstoreLocations = requestActionGeneratorByType(OCAPI_GET_INSTORE_LOCATIONS);
export const fetchInstoreOffers = requestActionGeneratorByType(OCAPI_GET_INSTORE_OFFERS);
export const fetchInstoreTransactions = requestActionGeneratorByType(OCAPI_GET_INSTORE_TRANSACTIONS);
export const fetchMemberAccountSummary = requestActionGeneratorByType(OCAPI_GET_MEMBER_ACCOUNT_SUMMARY);
export const fetchMemberOrders = requestActionGeneratorByType(OCAPI_GET_MEMBER_ORDERS);
export const fetchMemberTransactions = requestActionGeneratorByType(OCAPI_GET_MEMBER_TRANSACTIONS);
export const fetchMerchants = requestActionGeneratorByType(OCAPI_GET_MERCHANTS);

/**
 * @param {array.<number>} ids
 * @param {object} params
 * @param {object} options
 * @returns {function}
 */
export const fetchMerchantsByIds = (ids, params, options) => (
  generateGetMerchantsByIdsAction(params, options, { ids })
);

/**
 *
 * @param id
 * @param params
 * @param options
 * @returns {*}
 */
export const fetchPlacementsById = (id, params, options) => (
  generateGetPlacementsByIdAction(params, options, { id })
);

/**
 * @param {number} id
 * @param {object} params
 * @param {object} options
 * @returns {function}
 */
export const fetchMerchantById = (id, params, options) => (
  generateGetMerchantByIdAction(params, options, { id })
);

/**
 * @param {number} id
 * @param {object} params
 * @param {object} options
 * @returns {function}
 */
export const fetchSimilarMerchantsById = (id, params, options) => (
  generateGetSimilarMerchantsByIdAction(params, options, { id })
);

export const fetchMerchantsAll = requestActionGeneratorByType(OCAPI_GET_MERCHANTS_ALL);
export const fetchNavigation = requestActionGeneratorByType(OCAPI_GET_NAVIGATION);
export const fetchPlacements = requestActionGeneratorByType(OCAPI_GET_PLACEMENTS);
export const fetchSearch = requestActionGeneratorByType(OCAPI_GET_SEARCH);
export const fetchSearchProducts = requestActionGeneratorByType(OCAPI_GET_SEARCH_PRODUCTS);
export const fetchSweeps = requestActionGeneratorByType(OCAPI_GET_SWEEPS);

/**
 * @param {number} id
 * @param {object} params
 * @param {object} options
 * @returns {function}
 */
export const fetchSearchProductsById = (id, params, options) => (
  generateGetSearchPropuctsByIdAction(params, options, { id })
);
