import {
  OCAPI_GET_MEMBER_ACCOUNT_SUMMARY,
  OCAPI_GET_MEMBER_ACCOUNT_SUMMARY_SUCCESS,
  OCAPI_GET_MEMBER_ACCOUNT_SUMMARY_FAILURE,
} from 'core/actions/ocapi';

import config from '../configs';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OCAPI_GET_MEMBER_ACCOUNT_SUMMARY: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    }

    case OCAPI_GET_MEMBER_ACCOUNT_SUMMARY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: action.payload.response,
      };
    }

    case OCAPI_GET_MEMBER_ACCOUNT_SUMMARY_FAILURE: {
      return {
        ...state,
        ...initialState,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
};
