import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { hybridSearchResults as orgConfig } from 'org/configs/modules.config';
import { CACHE_STORAGE } from 'core/utils/cache/base';
import {
  HYBRID_GSP_STORES_GET_SUCCESS, HYBRID_GSP_STORES_GET_FAILURE,
  MERCHANTS_WITH_OFFERS_GET_SUCCESS, MERCHANTS_WITH_OFFERS_GET_FAILURE,
} from '../actions/types';

const defaultConfig = {
  defaults: {
    gspStores: {
      api: {
        params: {
          sid: 'us_cartera_001',
          sortby: 'relevancy',
          column: 'storeId,storeName',
          hits: 200,
        },
        options: {
          success: HYBRID_GSP_STORES_GET_SUCCESS,
          failure: HYBRID_GSP_STORES_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: false },
        },
      },
    },
    merchantsWithOffers: {
      api: {
        params: {
          with_offers: 1,
          limit: 2000,
          sort_by: 'name',
          fields: [
            'logoUrls._120x60',
            'type',
            'name',
            'id',
            'clickUrl',
            'showRebate',
            'rebate',
            'offers',
          ],
        },
        options: {
          success: MERCHANTS_WITH_OFFERS_GET_SUCCESS,
          failure: MERCHANTS_WITH_OFFERS_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true, type: CACHE_STORAGE },
        },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
