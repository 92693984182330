import React from 'react';
import PropTypes from 'prop-types';

import NewPill from 'core/modules/NewPill/NewPill';
import { useDropdown } from 'core/hooks/useDropdown';

function MainNavItemWithSubnav({ title, newPillEnabled, children }) {
  const { toggleSubnav, dropdownClassName } = useDropdown();

  return (
    <div className={`mn_navigationListItem mn_hasDropdown ${dropdownClassName}`}>
      <span className="mn_navItemDropdownTrigger" onClick={toggleSubnav} role="button" tabIndex="0">
        <span className="mn_navItemLabel">
          {newPillEnabled && <NewPill />}
          {title}
        </span>
      </span>
      <ul className="mn_navDropdownMenu">{children}</ul>
    </div>
  );
}

MainNavItemWithSubnav.propTypes = {
  title: PropTypes.string.isRequired,
  newPillEnabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

MainNavItemWithSubnav.defaultProps = {
  newPillEnabled: false,
};

export default MainNavItemWithSubnav;
