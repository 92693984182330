import React from 'react';
import { useSelector } from 'react-redux';
import InstoreMerchantRebate from 'core/modules/InstoreMerchantRebate/InstoreMerchantRebate';
import { redirectToTheTargetPage } from 'core/utils/helpers/url';
import { selectSiteUrl } from 'core/selectors/app';
import { selectIsMobileMQ } from 'core/modules/MediaQueries/selectors';

import { highlightMatch } from '../../utils/highlightMatch';

export function InstoreMatches({ instoreMatches, isPopUpView, getTitle }) {
  const siteUrl = useSelector(selectSiteUrl);
  const isMobileMQ = useSelector(selectIsMobileMQ);

  const clickOfferHandler = (offer) => {
    const { offerId } = offer;

    // CanJs and React use different url params to show the overlay
    const redirectUrl = isMobileMQ
      ? `instore#modalType=mt_instoreOffer&offerId=${offerId}`
      : `instore#lightboxType=instoreLinkCard&checkForFirstTimeEnrollCard=false&offerId=${offerId}`;

    redirectToTheTargetPage(siteUrl, redirectUrl);
  };

  if (instoreMatches.length === 0) {
    return null;
  }

  // Pop up view: show a maximum of 1 in-store offer
  const instoreMatchesToShow = isPopUpView ? instoreMatches.slice(0, 1) : instoreMatches;

  return (
    <>
      {!isPopUpView && <h3 className="mn_quickSearchInstoreMatchingTitle">In-store offers</h3>}
      <div className="mn_instoreMatchesWrapper">
        {instoreMatchesToShow.map((match) => {
          const { merchant, matchText } = match;
          const {
            id, name, offers = [], href,
          } = merchant;
          const instoreOffer = offers[0];
          const highlighted = highlightMatch(name, matchText);

          return (
            <a
              href={href}
              key={`${name}-instore`}
              className="mn_quickSearchMatch mn_instoreMatch"
              data-merchant-name={name}
              data-merchant-id={id}
              data-merchant-type="instore"
              title={getTitle(name, true)}
              onClick={(e) => {
                e.preventDefault();
                clickOfferHandler(instoreOffer);
              }}
            >
              <span className="mn_matchText" key={id}>{highlighted}</span>
              <span className="mn_dash">&nbsp;-&nbsp;</span>
              <InstoreMerchantRebate offer={instoreOffer} />
            </a>
          );
        })}
      </div>
      {isPopUpView && <h3 className="mn_quickSearchInstoreMatchingTitle">In-store offers</h3>}
    </>
  );
}

export default InstoreMatches;
