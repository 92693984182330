import * as actions from 'core/actions/server';
import responseParsers from './responseParsers';
import BaseApiRequestor from './baseRequestor';

export class ServerRequestor extends BaseApiRequestor {
  responseParsersForAPI = {
    [actions.SERVER_GET_INFORMATION_MERCHANTS_LIST]: responseParsers.serverParser,
    [actions.SERVER_SALESFORCE_CASE_VALIDATION]: responseParsers.serverParser,
  };
}

export default new ServerRequestor();
