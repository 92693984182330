import { findAncestorByClassName, findDataAttrInAncestorTree } from 'core/utils/helpers/dom';
import { SEASONAL_FEATURED_VIEW } from '../actions/types';

export const seasonalFeaturedGaActionEvents = {
  [SEASONAL_FEATURED_VIEW]: ({ data }) => {
    const {
      seasonalFeaturedDeal,
      isExtraRewardsPage,
      placementsToDisplay,
      sectionId,
    } = data;
    const { title: pageName } = seasonalFeaturedDeal;
    const placementIds = placementsToDisplay.map(placement => placement.id);

    return [
      {
        category: 'MKT seasonal featured',
        action: 'Display block',
        label: `${pageName}|${isExtraRewardsPage}`,
      },
      {
        category: 'Mobile Seasonal Featured Flyout',
        action: 'Mobile Flyout Display',
        label: `${sectionId}|${placementIds[0] || ' '}|${placementIds[1] || ' '}`,
      },
    ];
  },
};

export const seasonalFeaturedClickAndHoverEvents = [
  {
    selector: '.mn_seasonalFeatured .mn_arrowLink',
    click: {
      category: 'MKT seasonal featured',
      action: 'Tap block',
    },
    label: (element) => {
      const seasonalFeaturedBlock = findAncestorByClassName(element, 'mn_seasonalFeatured');
      const pageName = seasonalFeaturedBlock.getElementsByClassName('mn_seasonalFeaturedTitle')[0].innerText;
      const isExtraRewardsPage = seasonalFeaturedBlock.dataset.isExtraRewards;

      return `${pageName}|${isExtraRewardsPage}`;
    },
  },
  {
    selector: '.mn_seasonalFeatured .mn_premierOfferTile',
    click: {
      category: 'Mobile Seasonal Featured Flyout',
      action: 'Offer click',
    },
    label: (element) => {
      const { merchantName, merchantId } = element.dataset;
      const placementId = findDataAttrInAncestorTree(element, 'offerId');

      return `${merchantName}|${merchantId}|${placementId}`;
    },
  },
];
