import { takeEvery, put } from 'redux-saga/effects';
import { showModal } from 'core/modules/Modal/actions';

import { MODAL_TYPE_EDIT_EMAIL_PREFERENCES } from 'core/modules/Modal/types';
import { EMAIL_PREFERENCES_MODAL_TRIGGER } from '../actions/types';

function* showEditEmailPreferencesModal() {
  yield put(showModal(MODAL_TYPE_EDIT_EMAIL_PREFERENCES));
}

export default function* editEmailPreferencesModalSaga() {
  yield takeEvery(EMAIL_PREFERENCES_MODAL_TRIGGER, showEditEmailPreferencesModal);
}
