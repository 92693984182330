import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { fetchMerchants } from 'core/actions/ocapi';

import NavItem from 'core/modules/Navigation/NavPartials/NavItem/NavItem';

import { selectSiteUrl } from 'core/selectors/app';

import {
  selectNavPopularStoresConfig,
  selectNavPopularStoresLoading,
  selectNavPopularStoresIsLoaded,
  selectNavPopularStoresMerchants,
} from './selectors';

import './NavPopularStores.scss';

function NavPopularStores() {
  const dispatch = useDispatch();
  const siteUrl = useSelector(selectSiteUrl);
  const config = useSelector(selectNavPopularStoresConfig);
  const isLoading = useSelector(selectNavPopularStoresLoading);
  const isLoaded = useSelector(selectNavPopularStoresIsLoaded);
  const merchants = useSelector(selectNavPopularStoresMerchants);

  const { secTitle } = config;

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      const { api: apiConfig } = config;
      const { params, options } = apiConfig;
      dispatch(fetchMerchants(params, options));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isEmpty(merchants)) {
    return null;
  }

  return (
    <div className="mn_navPopularStoresSection">
      <h2>{secTitle}</h2>
      <ul className="mn_navDropdownMenu">
        {
          merchants.map(({ name, id, href }) => (
            <NavItem
              key={id}
              id={id}
              title={name}
              url={`${siteUrl}${href}`}
            />
          ))
        }
        <NavItem
          label="Opens all stores page"
          title="View all stores"
          url={`${siteUrl}/b____.htm`}
          className="mn_viewAllPopularStoresLink"
        />
      </ul>
    </div>
  );
}

export default NavPopularStores;
