import { BUTTON_SCROLLING_MODAL_BUTTON_VIEW } from '../actions/types';

export const buttonScrollingModalGaActionEvents = {
  [BUTTON_SCROLLING_MODAL_BUTTON_VIEW]: ({ buttonNumber, buttonText }) => ({
    category: 'Button Lightbox Scrolling Content',
    action: `View CTA ${buttonNumber}`,
    label: buttonText,
    value: 1,
  }),
};

export const buttonScrollingModalClickAndHoverEvents = [
  {
    selector: '.mn_buttonScrollingModal .mn_buttonBlock .mn_button',
    click: {
      category: 'Button Lightbox Scrolling Content',
      action: (element) => `Click CTA ${element.dataset.number}`,
    },
    label: (element) => element.textContent,
    value: 1,
  },
];
