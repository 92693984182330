import { selectUrlPrefix } from 'core/selectors/env';
import { selectClickUrlPrefix, selectPageId } from 'core/selectors/app';
import replaceQueryParam from 'core/utils/apis/responseParsers/commonParsers/replaceQueryParam';
import { isClickLink } from 'core/utils/helpers/url';

const clickUrlParser = (clickUrl, state) => {
  const envUrlPrefix = selectUrlPrefix(state);
  const clickUrlPrefix = selectClickUrlPrefix(state);
  const pageId = selectPageId(state);
  const addClickUrlPageIdParam = url => replaceQueryParam(url, 'p', pageId);

  const generateFullClickUrl = (url) => {
    if (isClickLink(url)) {
      url = addClickUrlPageIdParam(url); // eslint-disable-line no-param-reassign
    }
    return url;
  };

  const fullClickUrl = clickUrl && generateFullClickUrl(clickUrl.trim());

  if (
    !(
      fullClickUrl &&
      fullClickUrl.length > 0 &&
      clickUrlPrefix &&
      clickUrlPrefix.length > 0 &&
      fullClickUrl.indexOf(clickUrlPrefix) !== -1
    )
  ) {
    return fullClickUrl;
  }

  const clickUrlWithoutPrefix = fullClickUrl.slice(
    fullClickUrl.indexOf(clickUrlPrefix) + clickUrlPrefix.length,
    fullClickUrl.length,
  );

  return `${window.location.protocol}//${envUrlPrefix}${clickUrlWithoutPrefix}`;
};

export default clickUrlParser;
