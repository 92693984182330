import { FAILURE_SUFFIX, SUCCESS_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/HybridStoresOfferModal';

export const HYBRID_STORES_OFFER_MODAL_TRIGGER = `${rootActionPath}/TRIGGER`;
export const HYBRID_STORES_OFFER_MODAL_CLEAR = `${rootActionPath}/CLEAR`;
export const HYBRID_STORES_OFFER_MODAL_VIEW = `${rootActionPath}/VIEW`;

export const INSTORE_OFFERS_GET_SUCCESS = `${rootActionPath}/GET_${SUCCESS_SUFFIX}`;
export const INSTORE_OFFERS_GET_FAILURE = `${rootActionPath}/GET_${FAILURE_SUFFIX}`;
