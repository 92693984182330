import {
  ALL_MERCHANTS_GET_SUCCESS, ALL_MERCHANTS_GET_FAILURE, ALL_MERCHANTS_FROM_CACHE_GET_SUCCESS,
} from '../actions/types';
import config from '../configs';

const { allMerchantsConfig } = config;

const initialState = {
  config: allMerchantsConfig,
  merchants: [],
  isLoading: false,
  isLoaded: false,
};

// Convert merchants array into an object with merchant id as a key and filter useless data.
// We cannot filter these unnecessary fields via API request,
// because the same call is used in other places, so we should have the identic fields for a proper caching
const proceedMerchants = (response) => {
  const merchants = {};

  response.forEach((item) => {
    const {
      href, id, isDeactivated, logoUrls, name,
    } = item;

    merchants[id] = {
      href,
      id,
      isDeactivated,
      logoUrls,
      name,
    };
  });

  return merchants;
};

const allMerchants = (state = initialState, action) => {
  switch (action.type) {
    case ALL_MERCHANTS_FROM_CACHE_GET_SUCCESS: {
      return {
        ...state,
        merchants: action.merchants,
        isLoading: false,
        isLoaded: true,
      };
    }
    case ALL_MERCHANTS_GET_SUCCESS: {
      return {
        ...state,
        merchants: proceedMerchants(action.payload.response),
        isLoading: false,
        isLoaded: true,
      };
    }
    case ALL_MERCHANTS_GET_FAILURE: {
      return {
        ...state,
        merchants: [],
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === ALL_MERCHANTS_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default allMerchants;
