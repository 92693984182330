import React from 'react';
import { useSelector } from 'react-redux';
import { useDropdown } from 'core/hooks/useDropdown';

import { selectSiteUrl, selectStoreCount, selectLoginUrl } from 'core/selectors/app';
import { selectUserName, selectDisplayableUserId, selectUserIsIdentified } from 'core/selectors/user';

import AccountSummary from 'core/modules/AccountSummary/AccountSummary';

import './AcquisitionPageNavigation.scss';

function AcquisitionPageNavigation() {
  const storeCount = useSelector(selectStoreCount);
  const siteUrl = useSelector(selectSiteUrl);
  const loginUrl = useSelector(selectLoginUrl);
  const userName = useSelector(selectUserName);
  const userIsIdentified = useSelector(selectUserIsIdentified);
  const displayableUserId = useSelector(selectDisplayableUserId);

  const {
    hideSubnav, showSubnav, isFocused, dropdownClassName,
  } = useDropdown();

  const renderMessage = ({ lifetimeEarningFormatted }) => (
    <span>Shopping miles earned: <strong>{lifetimeEarningFormatted || 0}</strong></span>
  );

  return (
    <div className="mn_accountNavigation">
      <div className="mn_acqNavWrap">
        <nav aria-label="Secondary navigation" className="mn_secondaryNavigation">
          <div className="mn_itemWrap">
            <a href={`${siteUrl}/b____.htm`} title="All stores page">{storeCount}+ stores</a>
          </div>
          <div className="mn_itemWrap">
            <a href={`${siteUrl}/p/extra-rewards.htm`} title="Extra rewards page">Extra miles</a>
          </div>
          <div className="mn_itemWrap">
            <a href={`${siteUrl}/hiw____.htm`} title="How it works page">How it works</a>
          </div>
        </nav>
      </div>
      {userIsIdentified ? (
        <div className="mn_userSection">
          <div
            onMouseEnter={showSubnav}
            onMouseLeave={hideSubnav}
            aria-expanded={isFocused}
            onFocus={showSubnav}
            onBlur={hideSubnav}
            className={`mn_navigationDropdownTrigger ${dropdownClassName}`}
            data-section-name="Account"
          >
            <div className="mn_userInfo">
              <span className="mn_userNameWrap">Hi <span className="mn_userName">{userName}</span></span>
              <p className="mn_lifetimeEarning">
                <AccountSummary renderData={data => (renderMessage(data))} />
              </p>
            </div>
            <div className="mn_navigationDropdown">
              <div className="mn_highlitedSection">
                <p className="mn_userId">#{displayableUserId}</p>
                <ul>
                  <li><a href={`${siteUrl}/ai____.htm`}>View account</a></li>
                  <li><a href={`${siteUrl}/logoutUser.php?lUrl=/`}>Log out</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <a href={loginUrl} className="mn_button">Sign in</a>
      )}
    </div>
  );
}

export default AcquisitionPageNavigation;
