import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { selectCookieDomain, selectIsRavenCookied } from 'core/selectors/env';
import './RavenIndicator.scss';

const RAVEN_COOKIE_NAME = 'mn_raven';

function RavenIndicator() {
  const cookieDomain = useSelector(selectCookieDomain);
  const isRavenCookied = useSelector(selectIsRavenCookied);
  const [isCookied, setIsCookied] = useState(isRavenCookied);

  useEffect(() => {
    setIsCookied(isRavenCookied);
  }, [isRavenCookied]);

  const onClearCache = () => {
    localStorage.clear();
    window.location.reload();
  };

  const onCloseBar = () => {
    Cookies.remove(RAVEN_COOKIE_NAME, { path: '', domain: cookieDomain });
    window.location.reload();
  };

  return isCookied ? (
    <div id="mn_ravenIndicatorBar">
      <div id="mn_ravenIndicatorBarIn">
        <div>You are currently using Raven!</div>
        <div id="mn_ravenBarClearCache">
          <button onClick={onClearCache}>Clear Local Storage Cache And Reload</button>
        </div>
        <div id="mn_ravenBarClose">
          <button onClick={onCloseBar}>Remove Raven Cookie</button>
        </div>
      </div>
    </div>
  ) : null;
}

export default RavenIndicator;
