import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { recentlyShopped as orgConfig } from 'org/configs/modules.config';
import {
  RECENTLY_SHOPPED_MERCHANTS_GET_SUCCESS,
  RECENTLY_SHOPPED_MERCHANTS_GET_FAILURE,
} from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: RECENTLY_SHOPPED_MERCHANTS_GET_SUCCESS,
        failure: RECENTLY_SHOPPED_MERCHANTS_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    slick: {
      slidesToShow: 2,
      arrows: false,
      dots: true,
      centerMode: false,
      centerPadding: '22px',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    },
    minimumCarouselSize: 2,
    minimumMerchants: 2,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
