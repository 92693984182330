import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import {
  INSTORE_CURATED_OFFERS_GET_SUCCESS,
  INSTORE_CURATED_OFFERS_GET_FAILURE,
  INSTORE_CURATED_OFFERS_HEADING_GET_FAILURE,
  INSTORE_CURATED_OFFERS_HEADING_GET_SUCCESS,
} from 'core/modules/Instore/InstoreCuratedOffers/actions/types';
import { OffersSection as orgConfig } from 'org/configs/modules.config';
import { INSTORE_OFFERS_GET_FAILURE, INSTORE_OFFERS_GET_SUCCESS } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: INSTORE_OFFERS_GET_SUCCESS,
        failure: INSTORE_OFFERS_GET_FAILURE,
        cache: { isEnabled: false, isPublishedData: false },
      },
      params: {
        active: 1,
      },
    },
    checkShouldShowViewAllLink: (isMobileMQ) => isMobileMQ,
    shouldFetchHeadingFromAPI: false,
    shouldUseGoogleMap: true,
    sectionTitle: '',
    showOffersSectionTitle: false,
    offersSectionTitle: '',
  },
  // Curated instore offers on MHP
  h: {
    api: {
      params: {
        content_group_id: 20575,
        type: 'instore',
      },
    },
    curatedOffersApi: {
      params: {
        content_group_id: 20575,
        type: 'instore',
        active: 1,
      },
      options: {
        success: INSTORE_CURATED_OFFERS_GET_SUCCESS,
        failure: INSTORE_CURATED_OFFERS_GET_FAILURE,
        cache: { isEnabled: false, isPublishedData: false },
      },
    },
    sectionTitle: 'Earn miles when you shop in-store',
  },
  is: {
    curatedOffersApi: {
      params: {
        content_group_id: 35261,
        type: 'instore',
        active: 1,
      },
      options: {
        success: INSTORE_CURATED_OFFERS_GET_SUCCESS,
        failure: INSTORE_CURATED_OFFERS_GET_FAILURE,
        cache: { isEnabled: false, isPublishedData: false },
      },
    },
    headingApi: {
      params: {
        content_group_id: 35261,
        type: 'online',
        limit: 1,
        fields: [
          'assets.text',
        ],
      },
      options: {
        success: INSTORE_CURATED_OFFERS_HEADING_GET_SUCCESS,
        failure: INSTORE_CURATED_OFFERS_HEADING_GET_FAILURE,
        cache: { isEnabled: false, isPublishedData: false },
      },
    },
    checkShouldShowViewAllLink: () => false,
    shouldFetchHeadingFromAPI: true,
    shouldUseGoogleMap: false, // Script is appended in googleMap.tpl for Instore Page Only
    sectionTitle: '',
    showOffersSectionTitle: true,
    offersSectionTitle: 'All in-store offers',
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
