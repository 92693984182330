export const tabbedContentClickAndHoverEvents = [
  {
    // CLPL-12461: GA event is fired only for the first tab click
    selector: '.mn_tabbedContentTabs [data-is-tab-clicked="false"]',
    click: {
      category: 'Segmented control',
      action: 'Tap segment',
    },
    label: element => element.dataset.key,
  },
];
