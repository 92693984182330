import { ADBLOCK_IS_INSTALLED_CONFIRM } from '../actions/types';

import config from '../configs';

const initialState = {
  config,
  isAdblockInstalled: false,
};

const adBlockWarning = (state = initialState, action) => {
  switch (action.type) {
    case ADBLOCK_IS_INSTALLED_CONFIRM: {
      return {
        ...state,
        isAdblockInstalled: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default adBlockWarning;
