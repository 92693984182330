import { HOME_VALUE_BANNER_VIEW } from '../actions/types';

export const homeValueBannerGaActionEvents = {
  [HOME_VALUE_BANNER_VIEW]: () => (
    {
      category: 'VHP CTA',
      action: 'View',
      label: 'Sign in - primary',
    }
  ),
};

export const homeValueBannerClickEvents = [
  {
    selector: '.mn_homeValueBanner .mn_button',
    click: {
      category: 'VHP CTA',
      action: 'Click',
    },
    label: 'Sign in - primary',
  },
];
