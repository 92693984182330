import { getParamsFromSearch } from 'core/utils/helpers/getParamsFromSearch';
import { findAncestorByClassName } from 'core/utils/helpers/dom';
/* eslint-disable max-len */
import {
  HYBRID_SEARCH_FORM_REDIRECT,
  HYBRID_SEARCH_FORM_ACTIVATION,
  HYBRID_SEARCH_REDIRECT_TYPE_PRODUCT,
  HYBRID_SEARCH_REDIRECT_TYPE_MERCHANT_MULTIPLE,
  HYBRID_SEARCH_REDIRECT_TYPE_MERCHANT_SINGLE,
  HYBRID_SEARCH_REDIRECT_TYPE_MERCHANT_TOMBSTONE,
  HYBRID_SEARCH_REDIRECT_TYPE_NO_RESULTS,
  HYBRID_SEARCH_REDIRECT_TYPE_URL,
  HYBRID_SEARCH_FORM_QUICK_SEARCH_REDIRECT,
} from '../actions/types';

const VALUE_NOT_SET = '(not set)';

export const buildSuggestionsString = (querySuggestions = {}) => {
  const { synonyms = [], online = [], instore = [] } = querySuggestions;
  const synonymsMerchantsNames = synonyms.map(match => match.merchant.name);
  const onlineMerchantsNames = online.map(match => match.merchant.name);
  const instoreMerchantsNames = instore.map(match => `${match.merchant.name} (Instore)`);

  return [...synonymsMerchantsNames, ...onlineMerchantsNames, ...instoreMerchantsNames].join(', ') || VALUE_NOT_SET;
};

export const getDestinationMerchantsNames = (destinationMerchants = []) => (
  destinationMerchants.map(merchant => merchant.name).join(', ') || VALUE_NOT_SET
);

const recordSearchFormActivation = (actionData) => {
  const { quickSearchMatches, keywords } = actionData.data;

  return {
    category: 'Searchbox-Header',
    action: 'Activation',
    label: `${keywords} | ${buildSuggestionsString(quickSearchMatches)}`,
    custom: {
      merchant: null,
    },
  };
};

const recordSearchFormRedirect = (actionData) => {
  const {
    searchFormRedirectType, searchFormDestinationMerchants, quickSearchMatches,
    keywords, searchFormRedirectUrl,
  } = actionData.data;
  let label;
  let action;

  switch (searchFormRedirectType) {
    case HYBRID_SEARCH_REDIRECT_TYPE_URL:
      action = 'Url redirect';
      label = `${searchFormRedirectUrl}`;
      break;
    case HYBRID_SEARCH_REDIRECT_TYPE_MERCHANT_SINGLE:
      action = 'Merchant result - single';
      label = `${keywords} | ${buildSuggestionsString(quickSearchMatches)} | ${getDestinationMerchantsNames(searchFormDestinationMerchants)}`;
      break;
    case HYBRID_SEARCH_REDIRECT_TYPE_MERCHANT_MULTIPLE:
      action = 'Merchant result - multiple';
      label = `${keywords} | ${buildSuggestionsString(quickSearchMatches)} | ${getDestinationMerchantsNames(searchFormDestinationMerchants)}`;
      break;
    case HYBRID_SEARCH_REDIRECT_TYPE_MERCHANT_TOMBSTONE:
      action = 'Tombstone result';
      label = `${keywords} | ${buildSuggestionsString(quickSearchMatches)} | ${getDestinationMerchantsNames(searchFormDestinationMerchants)}`;
      break;
    case HYBRID_SEARCH_REDIRECT_TYPE_PRODUCT:
      action = 'Hybrid page redirect';
      label = keywords;
      break;
    case HYBRID_SEARCH_REDIRECT_TYPE_NO_RESULTS:
      action = 'No result page redirect';
      label = `${keywords} | ${buildSuggestionsString(quickSearchMatches)}`;
      break;
    default:
      action = VALUE_NOT_SET;
      label = VALUE_NOT_SET;
  }

  return {
    category: 'Searchbox-Header',
    action,
    label,
    custom: {
      merchant: null,
    },
    transport: 'beacon',
  };
};

const recordQuickSearchRedirect = (actionData) => {
  const { quickSearchMatches, destinationMerchant, keywords } = actionData.data;

  return {
    category: 'Searchbox-Header',
    action: `typeahead click - ${destinationMerchant.type} merchant`,
    label: `${keywords} | ${buildSuggestionsString(quickSearchMatches)} | ${destinationMerchant.name}`,
    custom: {
      merchant: null,
    },
    transport: 'beacon',
  };
};

export const hybridSearchFormGaActionEvents = {
  [HYBRID_SEARCH_FORM_ACTIVATION]: recordSearchFormActivation,
  [HYBRID_SEARCH_FORM_REDIRECT]: recordSearchFormRedirect,
  [HYBRID_SEARCH_FORM_QUICK_SEARCH_REDIRECT]: recordQuickSearchRedirect,
};

export const hybridSearchFormClickAndHoverEvents = [
  {
    selector: '.mn_quickSearchGspProductsWrap .mn_quickSearchLink',
    click: {
      category: 'Searchbox-Header',
      action: 'Product search result redirect',
    },
    label: (element) => getParamsFromSearch(element.href).keywords,
    value: 1,
  },
  {
    selector: '.mn_quickSearchOnlineWrap .mn_quickSearchLink',
    click: {
      category: 'Searchbox-Header',
      action: 'Merchant result - multiple',
    },
    label: (element) => getParamsFromSearch(element.href).keywords,
    value: 1,
  },
  {
    selector: '.mn_quickSearchGspProductsWrap .mn_quickSearchItem .mn_quickSearchMatchHighlight',
    click: {
      category: 'Searchbox-Header',
      action: 'Typeahead click - product suggestion',
    },
    label: (element) => {
      const product = findAncestorByClassName(element, 'mn_quickSearchItem');
      const { category, search } = product.dataset || {};
      const productSuggestionText = element.innerText;
      return `${search} | ${productSuggestionText} | ${category}`;
    },
    value: 1,
  },
];
