import { createSelector } from 'reselect';

export const selectAdBlockWarning = state => state.adBlockWarning;

export const selectAdblockIsInstalled = createSelector(
  selectAdBlockWarning,
  adBlockWarning => adBlockWarning.isAdblockInstalled,
);

export const selectAdblockConfig = createSelector(
  selectAdBlockWarning,
  adBlockWarning => adBlockWarning.config,
);
