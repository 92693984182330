import { TESTIMONIALS_VIEW, TESTIMONIALS_SWIPED_TRIGGER } from '../actions/types';

export const testimonialsGaActionEvents = {
  [TESTIMONIALS_VIEW]: () => ({
    category: 'Mobile MHP: Testimonials',
    action: 'Testimonials View',
    label: '',
    value: 1,
  }),

  [TESTIMONIALS_SWIPED_TRIGGER]: ({ slideIndex }) => ({
    category: 'VHP CTA',
    action: `Scroll ${slideIndex}`,
    label: 'Testimonials',
  }),
};
