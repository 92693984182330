import { createSelector } from 'reselect';

export const selectNavPopularStores = state => state.navPopularStores;
export const selectNavPopularStoresConfig = createSelector(
  selectNavPopularStores,
  navPopularStores => navPopularStores.config,
);

export const selectNavPopularStoresLoading = createSelector(
  selectNavPopularStores,
  navPopularStores => navPopularStores.isLoading,
);

export const selectNavPopularStoresIsLoaded = createSelector(
  selectNavPopularStores,
  navPopularStores => navPopularStores.isLoaded,
);

export const selectNavPopularStoresMerchants = createSelector(
  selectNavPopularStores,
  navPopularStores => navPopularStores.merchants,
);
