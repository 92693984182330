import merge from 'lodash/merge';
import {
  EXTRA_REWARDS_DEALS_GET_FAILURE,
  EXTRA_REWARDS_DEALS_GET_SUCCESS,
} from '../actions/types';

import extraRewardsDealsConfig from '../configs';

/** @type {{config: object, placements: array.<object>}} */
const initialState = {
  config: extraRewardsDealsConfig,
  isLoaded: false,
  isLoading: false,
  offers: [],
};

const formatOffer = offer => merge({}, offer, {
  assets: {
    text: offer.assets.rolloverCopy || '',
  },
});

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const extraRewardsDeals = (state = initialState, action) => {
  switch (action.type) {
    case EXTRA_REWARDS_DEALS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        offers: action.payload.response.map(offer => formatOffer(offer)),
      };
    }
    case EXTRA_REWARDS_DEALS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        offers: [],
      };
    }
    default: {
      const { options = {} } = action;

      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === EXTRA_REWARDS_DEALS_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};

export default extraRewardsDeals;
