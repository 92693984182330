import React from 'react';
import { useSelector } from 'react-redux';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { selectActiveMQ } from 'core/modules/MediaQueries/selectors';
import SearchForm from 'core/modules/SearchForm/SearchForm';
import HybridSearchForm from 'core/modules/HybridSearchForm/HybridSearchForm';

import './SearchBar.scss';

function SearchBar({ setRef, getFocusWasRemoved, hasFocusOnInit }) {
  const { config } = useSelector(state => state.searchBar);
  const activeMQ = useSelector(selectActiveMQ);
  const { getPlaceholder, shouldUseHybridSearch } = config;
  const placeholder = getPlaceholder(activeMQ);

  return (
    <ComponentErrorBoundary>
      <div className="mn_searchBar" ref={setRef}>
        { shouldUseHybridSearch
          ? <HybridSearchForm
              placeholder={placeholder}
              getFocusWasRemoved={getFocusWasRemoved}
              hasFocusOnInit={hasFocusOnInit}
          />
          : <SearchForm
              placeholder={placeholder}
              getFocusWasRemoved={getFocusWasRemoved}
              hasFocusOnInit={hasFocusOnInit}
          />
        }
      </div>
    </ComponentErrorBoundary>
  );
}

export default SearchBar;
