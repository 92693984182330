const rootActionPath = 'core/InstoreSMSNumberModal';

export const INSTORE_SMS_NUMBER_MODAL_TRIGGER = `${rootActionPath}/TRIGGER`;
export const INSTORE_SMS_NUMBER_MODAL_CLEAR = `${rootActionPath}/CLEAR`;

export const INSTORE_SMS_VIEW = `${rootActionPath}/VIEW`;

export const INSTORE_ADD_CARD_ADDED = `${rootActionPath}/ADDED`;

export const INSTORE_ADD_CARD_POST = `${rootActionPath}/POST`;
