import {
  BUTTON_SCROLLING_MODAL_LOGOS_GET_SUCCESS, BUTTON_SCROLLING_MODAL_LOGOS_GET_FAILURE,
} from '../actions/types';

const initialState = {
  logos: [],
  isLoading: false,
  isLoaded: false,
};

const buttonScrollingModalLogos = (state = initialState, action) => {
  switch (action.type) {
    case BUTTON_SCROLLING_MODAL_LOGOS_GET_SUCCESS: {
      return {
        ...state,
        logos: action.payload.response,
        isLoading: false,
        isLoaded: true,
      };
    }
    case BUTTON_SCROLLING_MODAL_LOGOS_GET_FAILURE: {
      return {
        ...state,
        logos: [],
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === BUTTON_SCROLLING_MODAL_LOGOS_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default buttonScrollingModalLogos;
