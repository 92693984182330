import find from 'lodash/find';
import {
  MEMBER_POST_UI_COMPONENT_METADATA,
  MEMBER_POST_UI_COMPONENT_METADATA_SUCCESS,
  MEMBER_POST_UI_COMPONENT_METADATA_FAILURE,
} from 'core/actions/member';

import {
  EMAIL_OPT_IN_UI_COMPONENT_NAME,
  EMAIL_OPT_IN_MODAL_USER_CHOICE_UNDECIDED,
} from '../constants';
import config from '../configs';

const initialState = {
  config,
  userChoice: EMAIL_OPT_IN_MODAL_USER_CHOICE_UNDECIDED,
  userChoiceSubmitted: false,
};

const emailOptInSubscribeModal = (state = initialState, action) => {
  const { component } = action;

  // Limit action to email_popup component
  if (component && component !== EMAIL_OPT_IN_UI_COMPONENT_NAME) {
    return state;
  }

  switch (action.type) {
    case MEMBER_POST_UI_COMPONENT_METADATA: {
      const { data: { metadata = [] } } = action;
      const { value = EMAIL_OPT_IN_MODAL_USER_CHOICE_UNDECIDED } = find(metadata, { name: 'userChoice' });

      return {
        ...state,
        userChoice: value,
      };
    }
    case MEMBER_POST_UI_COMPONENT_METADATA_SUCCESS:
    case MEMBER_POST_UI_COMPONENT_METADATA_FAILURE:
      return {
        ...state,
        userChoiceSubmitted: true,
      };
    default:
      return state;
  }
};

export default emailOptInSubscribeModal;
