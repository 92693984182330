import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import parseZone from 'core/utils/dayjs/plugin/parseZone';

dayjs
  .extend(minMax)
  .extend(isBetween)
  .extend(utc)
  .extend(timezone)
  .extend(duration)
  .extend(localizedFormat)
  .extend(parseZone);

export default dayjs;
