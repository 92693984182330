import {
  FEATURED_STORES_MODAL_TRIGGER,
  FEATURED_STORES_GET_SUCCESS,
  FEATURED_STORES_GET_FAILURE,
  FEATURED_STORES_MODAL_CLEAR,
} from '../actions/types';

import { featuredStoresConfig } from '../configs';

const initialState = {
  config: featuredStoresConfig,
  clickData: null,
  featuredMerchants: [],
  isLoading: false,
  isLoaded: false,
};

const featuredStoresModal = (state = initialState, action) => {
  switch (action.type) {
    case FEATURED_STORES_MODAL_TRIGGER: {
      return { ...state, clickData: action.data };
    }
    case FEATURED_STORES_GET_SUCCESS: {
      const merchants = action.payload.response;
      const featuredMerchants = merchants.filter(merchant => merchant.isFeatured);

      return {
        ...state,
        featuredMerchants,
        isLoading: false,
        isLoaded: true,
      };
    }
    case FEATURED_STORES_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    }
    case FEATURED_STORES_MODAL_CLEAR: {
      return initialState;
    }
    default: {
      const { options = {} } = action;
      if (options.success === FEATURED_STORES_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};

export default featuredStoresModal;
