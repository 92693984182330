import { findAncestorByClassName } from 'core/utils/helpers/dom';
import { RECENT_STORES_FROM_SEARCHBAR_VIEW } from '../actions/types';

export const recentStoresGaActionEvents = {
  [RECENT_STORES_FROM_SEARCHBAR_VIEW]: ({ payload }) => {
    const { isRecommended, merchants } = payload;
    return {
      category: 'Searchbox-Header',
      action: isRecommended ? 'Recommended stores view' : 'Recent stores view',
      label: merchants.map(m => m.name).join(),
      value: merchants.length,
    };
  },
};

export const recentStoresClickAndHoverEvents = [
  {
    selector: '.mn_recentStores .mn_flipTile .mn_button',
    click: {
      category: 'Searchbox-Header',
      action: (element) => {
        const recentStoresElem = findAncestorByClassName(element, 'mn_recentStores') || {};
        const isRecommended = recentStoresElem.dataset.is_recommended === 'true';
        return isRecommended ? 'Recommended stores click' : 'Recent stores click';
      },
    },
    label: element => `${element.dataset.merchantId} | ${element.dataset.merchantName}`,
  },
];
