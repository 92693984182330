import { takeEvery, put } from 'redux-saga/effects';
import { showModal } from 'core/modules/Modal/actions';

import { MODAL_TYPE_MERCHANT_OFFER } from 'core/modules/Modal/types';
import { MERCHANT_OFFER_MODAL_TRIGGER } from 'core/modules/Modal/components/MerchantOfferModal/actions/types';

function* showMerchantOfferModal() {
  yield put(showModal(MODAL_TYPE_MERCHANT_OFFER));
}

export default function* merchantOfferModalSaga() {
  yield takeEvery(MERCHANT_OFFER_MODAL_TRIGGER, showMerchantOfferModal);
}
