import dayjs from 'core/utils/dayjs';
import { addLeadingZero } from 'core/utils/apis/responseParsers/commonParsers/formatDateParser';

export const convertDateTimeToETTimezone = dateTime => dayjs.parseZone(dateTime).tz('America/New_York');

export const getDateDiffFromCurrentDateDiff = (endDate, currentDate, type) => {
  const endDateTimeToETTimeZone = endDate && convertDateTimeToETTimezone(endDate);
  const currentDateTimeToETTimeZone = currentDate && convertDateTimeToETTimezone(currentDate);
  return endDateTimeToETTimeZone && endDateTimeToETTimeZone.diff(currentDateTimeToETTimeZone, type);
};

export const isTimeEnded = (endDate, currentDate) => {
  const secondsLeft = getDateDiffFromCurrentDateDiff(endDate, currentDate, 'seconds');
  return Number.isNaN(secondsLeft) || (secondsLeft && secondsLeft <= 0);
};

export const setDateEndOfDayIfEmpty = (dateString) => {
  if (!dateString) {
    return false;
  }
  // If portal returned stopDate as a string without time (hours, minutes)
  // set then we should set time manually as the end of that day.
  // Note, the the server timezone is set manually
  // as by default dayjs library will set time zone relative to user's local setup.
  // Without the timezone correction the result is the end of day of users local
  // which could have a few hours offset with the server's end of day.
  // It could produce issues in calculating time differense in countdowns
  // as offers's stop dates are set with the server time zone.
  return dateString.length > 10 ? dateString : convertDateTimeToETTimezone(dateString).endOf('day').format();
};

export const getTimeRemaining = (date) => {
  const currentDate = convertDateTimeToETTimezone();
  const endDate = convertDateTimeToETTimezone(date);
  const duration = dayjs.duration(endDate.diff(currentDate));

  if (duration.asSeconds() <= 0) return false;

  return {
    days: addLeadingZero(parseInt(duration.asDays(), 10)),
    hours: addLeadingZero(duration.hours()),
    minutes: addLeadingZero(duration.minutes()),
    seconds: addLeadingZero(duration.seconds()),
  };
};

export const runTimer = ({ endDate, onComplete }) => {
  let timeinterval;
  const updateClock = () => {
    const timeRemaining = getTimeRemaining(endDate);
    if (!timeRemaining) {
      clearInterval(timeinterval);
      if (onComplete && typeof onComplete === 'function') {
        onComplete();
      }
    }
  };
  timeinterval = setInterval(updateClock, 1000);
  updateClock();
};
