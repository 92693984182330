/*
All fetching and caching logic for RecentStoresFlyout section is processed in this Saga.

The first API call is triggered to click-history endpoint, which returns list of visited merchants gmids.
This list is stored in the corresponding reducer AND saved into localStorage,
so it can be used in the future without an extra call to the same endpoint.

The second API call is triggered to merchants/all endpoint, which returns a list of all available merchants.
This list is also stored in the corresponding reducer AND saved into localStorage for future needs.

This localStorage record has a timestamp, which set an expiration date for this cache - 59 minutes 30 seconds
of the hour when it was created. When localStorage cache is expired and removed, new api calls are triggered.
*/

import {
  select, takeEvery, put, all, take, call,
} from 'redux-saga/effects';
import dayjs from 'core/utils/dayjs';
import {
  selectRecentStoresFlyoutCacheKey, selectHasNoRewardsEnabled, selectInstoreIsEnabled,
} from 'core/selectors/app';
import { selectQuickSearchConfig } from 'core/modules/QuickSearch/selectors';
import { cacheStorageCache } from 'core/utils/cache/cacheStorage';

import { fetchClickHistory, fetchMerchantsAll, fetchMerchants } from 'core/actions/ocapi';
import { getClickHistoryDataFromCache, getAllMerchantsDataFromCache } from '../actions';

import {
  CLICK_HISTORY_GET, ALL_MERCHANTS_GET, CLICK_HISTORY_GET_SUCCESS, ALL_MERCHANTS_GET_SUCCESS,
} from '../actions/types';

import {
  selectAllMerchantsConfig, selectClickHistoryConfig, selectClickHistoryGmids, selectAllMerchantsPlacements,
} from '../selectors';

function* getClickHistory() {
  const recentStoresFlyoutCacheKey = yield select(selectRecentStoresFlyoutCacheKey);
  const recentStoresFlyoutCache = yield call(cacheStorageCache.getItem, recentStoresFlyoutCacheKey);
  const { gmids } = recentStoresFlyoutCache || {};

  if (gmids) {
    yield put(getClickHistoryDataFromCache(gmids));
    return;
  }

  const clickHistoryConfig = yield select(selectClickHistoryConfig);
  const { api: { options, params } } = clickHistoryConfig;
  yield put(fetchClickHistory(params, options));
}

function* getAllMerchants() {
  const recentStoresFlyoutCacheKey = yield select(selectRecentStoresFlyoutCacheKey);
  const recentStoresFlyoutCache = yield call(cacheStorageCache.getItem, recentStoresFlyoutCacheKey);
  const { merchants } = recentStoresFlyoutCache || {};

  if (merchants) {
    yield put(getAllMerchantsDataFromCache(merchants));
    return;
  }

  const hasNoRewardsEnabled = yield select(selectHasNoRewardsEnabled);
  const instoreIsEnabled = yield select(selectInstoreIsEnabled);

  // For caching purposes we use exactly the same parameters in same order as Quick Search does
  const quickSearchConfig = yield select(selectQuickSearchConfig);
  const { api: { params } } = quickSearchConfig.quickSearchMerchantsConfig;

  const allMerchantsConfig = yield select(selectAllMerchantsConfig);
  const { api: { options } } = allMerchantsConfig;

  const apiParams = {
    ...params,
    include_inactive: hasNoRewardsEnabled ? 1 : 0,
  };

  const merchantFetcher = instoreIsEnabled ? fetchMerchantsAll : fetchMerchants;

  yield put(merchantFetcher(apiParams, options));
}

function* setRecentStoresFlyoutCache() {
  yield all([
    take(CLICK_HISTORY_GET_SUCCESS),
    take(ALL_MERCHANTS_GET_SUCCESS),
  ]);
  const recentStoresFlyoutCacheKey = yield select(selectRecentStoresFlyoutCacheKey);

  const merchants = yield select(selectAllMerchantsPlacements);
  const gmids = yield select(selectClickHistoryGmids);

  // 59 minutes + 30 seconds from start of hour
  const expirationDate = dayjs().startOf('hour').add(3570, 'seconds').valueOf();
  const recentStoresFlyoutCache = {
    merchants,
    gmids,
  };

  cacheStorageCache.setItem(
    recentStoresFlyoutCacheKey,
    recentStoresFlyoutCache,
    { expirationTimestamp: expirationDate, persist: true },
  );
}

export default function* recentStoresFlyoutSaga() {
  yield takeEvery(CLICK_HISTORY_GET, getClickHistory);
  yield takeEvery(ALL_MERCHANTS_GET, getAllMerchants);

  yield setRecentStoresFlyoutCache();
}
