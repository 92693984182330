import { findDataAttrInAncestorTree } from 'core/utils/helpers/dom';

import { CONTACT_US_DASHBOARD_VIEW, CONTACT_US_DASHBOARD_ORDER_INQUIRY_STEP_VIEW } from '../actions/types';

const VALUE_NOT_SET = '(not set)';

export const contactUsDashboardGaActionEvents = {
  [CONTACT_US_DASHBOARD_VIEW]: ({ inquiryGaName }) => ({
    category: `Contact Us${inquiryGaName ? ` - ${inquiryGaName}` : ''}`,
    action: 'View',
    label: VALUE_NOT_SET,
  }),

  [CONTACT_US_DASHBOARD_ORDER_INQUIRY_STEP_VIEW]: ({ inquiryGaName, orderInquiryStepGaName }) => ({
    category: `Contact Us - ${inquiryGaName}`,
    action: `${orderInquiryStepGaName} - view`,
    label: VALUE_NOT_SET,
  }),
};

export const contactUsDashboardClickAndHoverEvents = [
  {
    selector: '.mn_dashboardList .mn_dashboardItem',
    click: {
      category: 'Contact Us',
      action: element => `${element.dataset.inquiryGaName} click`,
    },
    label: VALUE_NOT_SET,
  },
  {
    selector: '.mn_dashboardInquiry #mn_regSubmit',
    click: {
      category: element => {
        const inquiryName = findDataAttrInAncestorTree(element, 'inquiryName');
        return `Contact Us - ${inquiryName}`;
      },
      action: 'Form submit',
    },
    label: VALUE_NOT_SET,
  },
  {
    selector: '.mn_dashboardInquiry .mn_contineButton',
    click: {
      category: element => {
        const inquiryName = findDataAttrInAncestorTree(element, 'inquiryName');
        return `Contact Us - ${inquiryName}`;
      },
      action: 'Continue click',
    },
    label: VALUE_NOT_SET,
  },
  {
    selector: '.mn_dashboardInquiry .mn_hiwPageLink, .mn_dashboardInquiry .mn_myAccountPageLink',
    click: {
      category: element => {
        const inquiryName = findDataAttrInAncestorTree(element, 'inquiryName');
        return `Contact Us - ${inquiryName}`;
      },
      action: element => {
        const inquiryName = findDataAttrInAncestorTree(element, 'inquiryName');
        return `${inquiryName} link click`;
      },
    },
    label: VALUE_NOT_SET,
  },
  {
    selector: '.mn_orderInquiry .mn_button.mn_next',
    click: {
      category: 'Contact Us - Order inquiry',
      action: element => {
        const orderInquiryStepName = findDataAttrInAncestorTree(element, 'stepName');
        return (
          `${orderInquiryStepName} - next`
        );
      },
    },
    label: VALUE_NOT_SET,
  },
  {
    selector: '.mn_orderInquiry .mn_button.mn_prev',
    click: {
      category: 'Contact Us - Order inquiry',
      action: element => {
        const orderInquiryStepName = findDataAttrInAncestorTree(element, 'stepName');
        return (
          `${orderInquiryStepName} - back`
        );
      },
    },
    label: VALUE_NOT_SET,
  },
  {
    selector: '.mn_orderInquiry .mn_button.mn_submit',
    click: {
      category: 'Contact Us - Order inquiry',
      action: element => {
        const orderInquiryStepName = findDataAttrInAncestorTree(element, 'stepName');
        return (
          `${orderInquiryStepName} - submit`
        );
      },
    },
    label: VALUE_NOT_SET,
  },
  {
    selector: '.mn_orderInquiry .mn_continueInfo button',
    click: {
      category: 'Contact Us - Order inquiry',
      action: element => {
        const orderInquiryStepName = findDataAttrInAncestorTree(element, 'stepName');
        return (
          `${orderInquiryStepName} - continue`
        );
      },
    },
    label: VALUE_NOT_SET,
  },
];
