import { takeEvery, put } from 'redux-saga/effects';

import { PRODUCT_OVERLAY_LINK_CLICK } from 'core/modules/ProductOverlayLink/actions/types';
import { PRODUCT_DETAILS_MODAL_TRIGGER } from 'core/modules/Modal/components/ProductDetailsModal/actions/types';

function* putProductDetailsModalAction(action = {}) {
  const { data } = action;
  yield put({
    type: PRODUCT_DETAILS_MODAL_TRIGGER,
    data,
  });
}

export default function* productOverlayLinkSaga() {
  yield takeEvery(PRODUCT_OVERLAY_LINK_CLICK, putProductDetailsModalAction);
}
