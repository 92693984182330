import { combineReducers } from 'redux';
import transactions from './transactions';
import clickHistory from './clickHistory';
import selectedMerchant from './selectedMerchant';

export default combineReducers({
  transactions,
  clickHistory,
  selectedMerchant,
});
