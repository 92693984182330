import compact from 'lodash/compact';

import { getDisplayPageName } from 'core/utils/analytics/pageInfo';
import { BONUS_OFFERS_FLEX_PAGE_VIEW, BONUS_OFFERS_FLEX_PAGE_SECTION_VIEW } from '../actions/types';

export const bonusOffersFlexPageGaActionEvents = {
  [BONUS_OFFERS_FLEX_PAGE_SECTION_VIEW]: ({ sectionHeadline, bonusOffersLength }) => ({
    category: `${getDisplayPageName()} Merchant Bonus Promotion`,
    action: 'Bonus offers section view',
    label: sectionHeadline,
    value: bonusOffersLength,
  }),
  [BONUS_OFFERS_FLEX_PAGE_VIEW]: ({ offer }) => {
    const {
      merchant, bonusId, heading, description,
    } = offer;
    const { name: merchantName } = merchant || {};
    return {
      category: `${getDisplayPageName()} Merchant Bonus Promotion`,
      action: 'Bonus promotion view',
      label: compact([bonusId, merchantName, heading, description]).join('|'),
    };
  },
};

export const bonusOffersFlexPageClickAndHoverEvents = [
  {
    selector: '.mn_bonusMerchantOffersFlexPage .mn_bonusMerchantOffer',
    click: {
      category: `${getDisplayPageName()} Merchant Bonus Promotion`,
      action: 'Bonus promotion click',
    },
    label: (element) => {
      const {
        merchantName, bonusId, heading, description,
      } = element.dataset;
      // ignore 'description' if it has a 'false' value set as a string type,
      // (boolead <false> value was converded to the string type when attached to the DOM element as data attribute).
      // Lodash 'compact' method will remove falsy values from the resulting array in the next line
      const descriptionParsed = description === 'false' ? false : description;
      return compact([bonusId, merchantName, heading, descriptionParsed]).join('|');
    },
  },
];
