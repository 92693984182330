import { takeEvery, put } from 'redux-saga/effects';

import { OFFER_OVERLAY_LINK_CLICK } from 'core/modules/OfferOverlayLink/actions/types';
import { MERCHANT_OFFER_MODAL_TRIGGER } from 'core/modules/Modal/components/MerchantOfferModal/actions/types';

function* putMerchantOfferModalAction(action = {}) {
  const { data } = action;
  yield put({
    type: MERCHANT_OFFER_MODAL_TRIGGER,
    data,
  });
}

export default function* offerOverlayLinkSaga() {
  yield takeEvery(OFFER_OVERLAY_LINK_CLICK, putMerchantOfferModalAction);
}
