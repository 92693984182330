import React from 'react';
import ExtraRewardsPill from 'core/modules/ExtraRewardsPill/ExtraRewardsPill';

function NavItem({
  url, title, label, hideExtraRewardsLabel, extraRewardsLabel, className, id, name,
}) {
  return (
    <li>
      <a
        href={url}
        title={label || title}
        className={className}
        data-merchant-id={id}
        data-merchant-name={title}
        data-pt-name={name}
      >
        {title}
        { !hideExtraRewardsLabel && <ExtraRewardsPill url={url} label={extraRewardsLabel} /> }
      </a>
    </li>
  );
}

export default NavItem;
