import { findAncestorByClassName } from 'core/utils/helpers/dom';
import { BECAUSE_YOU_SHOPPED_VIEW } from 'core/modules/ForYou/actions/types';

export const forYouBecauseYouShoppedActionEvents = {
  [BECAUSE_YOU_SHOPPED_VIEW]: ({ data }) => ({
    category: 'Offers for you page',
    action: `Because you shopped at ${data.merchantName} view`,
    label: `${data.merchantNames.toString()}`,
    value: data.merchantNames.length,
  }),
};

export const forYouBecauseYouShoppedClickAndHoverEvents = [
  {
    selector: '.mn_becauseYouShopped .mn_merchantTile',
    click: {
      category: 'Offers for you page',
      action: (element) => {
        const becauseYouShopped = findAncestorByClassName(element, 'mn_becauseYouShopped') || {};
        const { title } = becauseYouShopped.dataset;

        return `${title} click`;
      },
    },
    label: (element) => {
      const { merchantName } = element.dataset;

      return merchantName;
    },
  },
];
