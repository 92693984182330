import BaseCache from './base';

export default class SessionStorageCache extends BaseCache {
  /** @type {bool} */
  cacheInMemory = true;

  constructor() {
    super(window.sessionStorage);
  }
}
