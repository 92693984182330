import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { merchantOffer as orgConfig } from 'org/configs/modules.config';

import { MERCHANT_OFFER_GET_SUCCESS, MERCHANT_OFFER_GET_FAILURE } from '../actions/types';

export const defaultConfig = {
  defaults: {
    api: {
      params: {
        with_offers: 1,
      },
      options: {
        success: MERCHANT_OFFER_GET_SUCCESS,
        failure: MERCHANT_OFFER_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    rebateOptions: {
      hideElevationCurrency: true,
      hideRebateCurrencyPrefixInWas: false,
    },
    getCopyButtonText: () => ('Copy'),
    getCopyButtonDisclaimerText: () => ('Copy and paste this code and use at checkout.'),
    getExpiresText: () => ('Offer valid through'),
    useRebateInlineOnMediaQueriesOnly: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
    dateFormat: 'MMM D, YYYY',
    shouldRenderDisclaimerInsideDescription: true,
    tagHelper: () => {},
  },
};

export const merchantOffer = mergeModuleConfigs(defaultConfig, orgConfig);
