export const recentlyShoppedClickAndHoverEvents = [
  {
    selector: '.mn_recentlyShopped .mn_merchantTile',
    click: {
      category: 'Recently shopped at',
      action: 'Tap merchant',
    },
    label: element => element.dataset.merchantName,
  },
];
