import isObject from 'lodash/isObject';

// Create in-memory cache global
window.mnCache = window.mnCache || {};

export const LOCAL_STORAGE = 'localStorage';
export const SESSION_STORAGE = 'sessionStorage';
export const CACHE_STORAGE = 'cacheStorage';

/**
 * @typedef {object} CacheOptions
 * @property {number} expirationTimestamp
 * @property {bool} persist
 */

/**
 * @typedef {object} CacheEntry
 * @property {*} value
 * @property {number} expirationTimestamp
 * @property {bool} persist
 * @property {bool} _mncached
 */

export default class BaseCache {
  /** @type {bool} */
  debugMode = false;

  /** @type {bool} */
  cacheInMemory = false;

  /** @type {object} */
  inMemoryStorage = null;

  /** @type {Storage|null} */
  storage = null;

  constructor(storage) {
    this.setStorage(storage);
  }

  /**
   * Delete non-persisted cache keys
   * @param {Object} source
   * @param {bool} decode
   */
  static clearNonPersistedItems(source = {}, decode = true) {
    /* eslint-disable no-param-reassign */
    Object.keys(source).forEach((key) => {
      if (!decode) {
        !source[key].persist && delete source[key];
      } else {
        try {
          const decoded = JSON.parse(source[key]);
          !decoded.persist && delete source[key];
        } catch (e) {
          delete source[key]; // can't parse, can't persist
        }
      }
    });
    /* eslint-enable no-param-reassign */
  }

  /**
   * @returns {Storage}
   */
  getStorage() {
    return this.storage;
  }

  /**
   * @param {Storage} storage
   * @returns {BaseCache}
   */
  setStorage(storage) {
    if (storage && storage instanceof Storage) {
      this.storage = storage;

      this.testSupport();
      this.setInMemoryStorage();
    }

    return this;
  }

  /**
   * @returns {string}
   */
  getStorageType() {
    if (!this.storage) {
      return '';
    }

    switch (this.storage) {
      case window.localStorage:
        return LOCAL_STORAGE;
      case window.sessionStorage:
        return SESSION_STORAGE;
      default:
        return 'otherStorage';
    }
  }

  getItem(key) {
    if (!this.storage) {
      return null;
    }

    let cacheHitRaw;
    let cacheHit;
    let isMemHit = false;

    const debugPrefix = `getItem(${key})`;

    this.debugLog(`${debugPrefix} :: Checking for cached entry`);

    // Return data from memory (if present and enabled)
    if (this.cacheInMemory) {
      cacheHit = this.getItemFromMemory(key) || null; // force the empty value to null
      isMemHit = !!cacheHit;
    }

    // Fail over to browser cache
    if (!cacheHit) {
      try {
        cacheHitRaw = this.storage.getItem(key);

        if (cacheHitRaw !== null) {
          this.debugLog(`${debugPrefix} :: Found item - parsing as json!`);
          cacheHit = JSON.parse(cacheHitRaw);
        } else {
          cacheHit = cacheHitRaw;
        }
      } catch (e) {
        this.debugLog(`getItem(${key}) :: Json parsing exception`, e);
        cacheHit = null;
      }
    }

    // Handle value expiration (for both browser session and memory)
    try {
      if (cacheHit && cacheHit.expirationTimestamp) {
        const cacheExpirationTimeStamp = new Date(cacheHit.expirationTimestamp).getTime();
        const timeNow = Date.now();

        if (cacheExpirationTimeStamp < timeNow) {
          this.debugLog(`getItem(${key}) :: Removing item because it expired on ${cacheHit.expirationTimestamp}`);
          this.storage.removeItem(key);
          this.removeItemFromMemory(key);
          cacheHit = null;
        }
      }
    } catch (e) {
      this.debugLog(`getItem(${key} :: Cache exception`, e);
      cacheHit = null;
    }

    // Convenience info for debugging
    const storageType = this.getStorageType();
    const hitType = isMemHit ? 'memory' : storageType;

    if (cacheHit !== null) {
      hitType !== 'memory' && this.setItemInMemory(key, cacheHit);
      this.debugLog(`getItem(${key}) :: Returning the value of item from ${hitType}`, cacheHit);
    } else {
      this.debugLog(`getItem(${key}) :: Item not found in ${storageType}!`);
    }

    this.debugLog();
    return (isObject(cacheHit) && Object.hasOwnProperty.call(cacheHit, 'value')) ? cacheHit.value : cacheHit;
  }

  /**
   *
   * @param {string} key
   * @param {*} value
   * @param {CacheOptions} options
   * @returns {BaseCache}
   */
  setItem(key, value, options = {}) {
    const { expirationTimestamp, persist = false } = options;

    /** @type {CacheEntry} */
    const cacheEntry = {
      value,
      expirationTimestamp,
      persist,
      _mncached: true,
    };

    const debugPrefix = `setItem(${key}, ..., ${expirationTimestamp})`;

    this.debugLog(`${debugPrefix} :: Trying to set item with value`, value);

    try {
      const serializedValue = JSON.stringify(cacheEntry);

      this.storage.setItem(key, serializedValue);
      this.setItemInMemory(key, cacheEntry);

      this.debugLog(`${debugPrefix} :: Item written to ${this.getStorageType()}`);
    } catch (e) {
      this.debugLog(`${debugPrefix} :: Error writing to cache`, e);
    }

    this.debugLog();

    return this;
  }

  /**
   * @param {string} key
   * @returns {BaseCache}
   */
  removeItem(key) {
    const { storage } = this;
    const debugPrefix = `removeItem(${key})`;

    this.debugLog(`${debugPrefix} :: Trying to remove item`);

    try {
      if (storage.getItem(key)) {
        this.storage.removeItem(key);
        this.removeItemFromMemory(key);
        this.debugLog(`${debugPrefix} :: Item removed from ${this.getStorageType()}`);
      } else {
        this.debugLog(`${debugPrefix} :: Item not found in cache`);
      }
    } catch (e) {
      this.debugLog(`${debugPrefix} :: Error removing cache entry`, e);
    }

    this.debugLog();

    return this;
  }

  /**
   * @returns {BaseCache}
   */
  clear(force = false) {
    this.debugLog(`clear() :: Clearing entire ${this.getStorageType()} cache`);
    this.clearFromStorage(force);
    this.clearFromMemory(force);
    this.debugLog();

    return this;
  }

  clearPublishedData() {
    this.debugLog(`clearPublishedData() :: Clearing published ${this.getStorageType()} cache`);
    try {
      Object.keys(this.storage).forEach((key) => {
        if (key.includes('##pubID')) {
          this.storage.removeItem(key);
          this.removeItemFromMemory(key);
        }
      });
    } catch (e) {
      this.debugLog('cache exception', e);
    }
  }

  /*= ==================*
   * In Memory Methods *
   *=================== */

  /**
   * @returns {BaseCache}
   */
  setInMemoryStorage() {
    if (this.storage) {
      const storageType = this.getStorageType();

      if (!window.mnCache[storageType]) {
        window.mnCache[storageType] = {};
      }

      this.inMemoryStorage = window.mnCache[storageType];
    }

    return this;
  }

  /**
   * @returns {object}
   */
  initInMemoryCache() {
    const { storage, inMemoryStorage } = this;

    this.debugLog(`initInMemoryCache() :: Loading ${this.getStorageType()} into memory for quick access`);

    Object.keys(storage).forEach((key) => {
      try {
        const parsedValue = JSON.parse(storage[key]);

        if (parsedValue && Object.hasOwnProperty.call(parsedValue, 'value')) {
          inMemoryStorage[key] = parsedValue;
        }
      } catch (e) {
        this.debugLog(
          `initInMemoryCache() :: [WARNING] json parse exception for key=${key} (probably a string)`,
          e.toString(),
        );
      }
    });

    return inMemoryStorage;
  }

  /**
   * @params {bool} initOnEmpty
   * @returns {object}
   */
  getMemCache(initOnEmpty = true) {
    if (!this.storage || !this.cacheInMemory) {
      return {};
    }

    const { inMemoryStorage } = this;

    return !inMemoryStorage && initOnEmpty ? this.initInMemoryCache() : inMemoryStorage;
  }

  /**
   * @param {string} key
   * @returns {object}
   */
  getItemFromMemory(key) {
    if (!this.cacheInMemory) {
      return null;
    }

    const debugPrefix = `getItemFromMemory(${key})`;

    this.debugLog(`${debugPrefix} :: Checking in-memory cache for item`);

    const memCacheHit = this.getMemCache()[key];

    if (memCacheHit) {
      this.debugLog(`${debugPrefix} :: Item found in cache!`);
    } else {
      this.debugLog(`${debugPrefix} :: Item not found in memory!`);
    }

    return memCacheHit;
  }

  /**
   * @param {string} key
   * @param {*} value
   * @returns {BaseCache}
   */
  setItemInMemory(key, value) {
    if (this.cacheInMemory) {
      this.debugLog(`setItemInMemory(${key}, ...) :: Trying to set in-memory item with value`, value);

      try {
        this.getMemCache()[key] = value;
      } catch (e) {
        this.debugLog('cache exception', e);
      }
    }

    return this;
  }

  /**
   * @param {string} key
   * @returns {BaseCache}
   */
  removeItemFromMemory(key) {
    if (this.cacheInMemory) {
      try {
        delete this.getMemCache()[key];
        this.debugLog(`removeItemFromMemory(${key}) :: Item removed from in-memory cache`);
      } catch (e) {
        this.debugLog(`removeItemFromMemory(${key}) :: Error removing item from in-memory cache`, e);
      }
    }

    return this;
  }

  /**
   * @param {bool} force
   * @return {BaseCache}
   */
  clearFromStorage(force = false) {
    force ? this.storage.clear() : BaseCache.clearNonPersistedItems(this.storage);

    return this;
  }

  /**
   * @returns {BaseCache}
   */
  clearFromMemory(force = false) {
    if (this.cacheInMemory) {
      this.debugLog('clearFromMemory() :: Clearing out in-memory cache');

      let { inMemoryStorage } = this;

      if (inMemoryStorage) {
        force ? inMemoryStorage = {} : BaseCache.clearNonPersistedItems(inMemoryStorage, false);
      }
    }

    return this;
  }

  /*= =============*
   * Misc Methods *
   *============== */

  /**
   * @returns {BaseCache}
   */
  testSupport() {
    if (this.storage) {
      try {
        const test = 'test4support';
        this.storage.setItem(test, test);
        this.storage.removeItem(test);
      } catch (error) {
        this.debugLog('testSupport() :: Invalid storage type', error);
        this.storage = null;
      }
    }

    return this;
  }

  debugLog(...args) {
    const consoleArgs = [...args];

    if (!this.debugMode) {
      return;
    }

    const debugPrefix = '[DEBUG]';

    if (consoleArgs.length === 0) {
      console.log(`${debugPrefix} ---`);
    } else if (typeof consoleArgs[0] === 'string') {
      consoleArgs[0] = `${debugPrefix} ${this.constructor.name}::${consoleArgs[0]}`;
    }

    console.log(...consoleArgs);
  }
}
