import { DYNAMIC_REWARDS_TAB_CONTENT_VIEW } from '../actions/types';

export const dynamicRewardsTabContentGaActionEvents = {
  [DYNAMIC_REWARDS_TAB_CONTENT_VIEW]: (action) => {
    const { activeTabLink } = action;

    return {
      type: 'pageView',
      custom: {
        page_name: activeTabLink, // eslint-disable-line camelcase
      },
    };
  },
};
