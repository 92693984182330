import merge from 'lodash/merge';
import parseClickUrl from './commonParsers/clickUrlParser';
import parseRebate from './commonParsers/rebateParser';
import { parseFlyout } from './categoriesParser';

const parseFeatured = (featured, state) =>
  merge(featured, {
    clickUrl: parseClickUrl(featured.clickUrl, state),
    merchant: featured.merchant
      ? {
        clickUrl: parseClickUrl(featured.merchant.clickUrl, state),
        rebate: parseRebate(featured.merchant.rebate, featured.merchant.tiers, state),
      }
      : null,
  });

export default (response, state) => (
  response.map(category =>
    merge(category, {
      featured: category.featured
        ? category.featured.map(featured => parseFeatured(featured, state))
        : null,
      flyout: category.flyout
        ? parseFlyout(category.flyout, state)
        : null,
    }))
);
