import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { infoBar as orgConfig } from 'org/configs/modules.config';
import {
  INFOBAR_DOTW_GET_SUCCESS,
  INFOBAR_DOTW_GET_FAILURE,
  INFOBAR_SALES_GET_SUCCESS,
  INFOBAR_SALES_GET_FAILURE,
} from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    shouldUseSliderView: true,
    useAddToFavorite: true,
    tagHelper: () => {},
    slick: {
      centerMode: false,
      infinite: false,
      swipeToSlide: true,
      slidesToShow: 1,
      dots: false,
      arrows: false,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 560,
          settings: { slidesToShow: 1.6, variableWidth: false },
        },
        {
          breakpoint: 460,
          settings: { slidesToShow: 1.35, variableWidth: false },
        },
        {
          breakpoint: 389,
          settings: { slidesToShow: 1.2, variableWidth: false },
        },
      ],
    },
    infoBarAccordion: {
      couponCodeLanguage: 'Use code:',
      shopNowCTALinkLabel: '',
      dateFormat: 'MMM DD, YYYY',
      rebateOptions: {
        showAdditionalElevationPrefix: true,
        showRebateInline: true,
        hideElevationCurrency: true,
      },
    },
    dealOfTheWeek: {
      api: {
        params: {
          offer_tag: 'deal-of-the-week',
          limit: 1,
          section_id: 10441, // mn.sectionInfo.getId('dotw-v2'),
        },
        options: {
          success: INFOBAR_DOTW_GET_SUCCESS,
          failure: INFOBAR_DOTW_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
      },
      dateFormat: 'MM/DD/YY',
    },
    salesInfoBar: {
      api: {
        params: {
          content_group_id: 1068,
          content_type_id: 59,
          section_id: 10433, // mn.sectionInfo.getId('info-bar-v2'),
        },
        options: {
          success: INFOBAR_SALES_GET_SUCCESS,
          failure: INFOBAR_SALES_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
      },
      dateFormat: 'MM/DD/YY',
    },
  },
  sp: {
    shouldUseSliderView: false,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
