import { SUCCESS_SUFFIX, FAILURE_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/InstoreTab';

export const INSTORE_TAB_GET = `${rootActionPath}/records/GET`;
export const INSTORE_TAB_GET_SUCCESS = `${INSTORE_TAB_GET}_${SUCCESS_SUFFIX}`;
export const INSTORE_TAB_GET_FAILURE = `${INSTORE_TAB_GET}_${FAILURE_SUFFIX}`;

export const INSTORE_TAB_MERCHANTS_GET = `${rootActionPath}/merchants/GET`;
export const INSTORE_TAB_MERCHANTS_GET_SUCCESS = `${INSTORE_TAB_MERCHANTS_GET}_${SUCCESS_SUFFIX}`;
export const INSTORE_TAB_MERCHANTS_GET_FAILURE = `${INSTORE_TAB_MERCHANTS_GET}_${FAILURE_SUFFIX}`;
