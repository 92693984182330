import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  isChrome, isDesktop, isEdge, isTabletOrDesktop,
} from 'core/utils/helpers/browser';
import { addExternalAttributesIfNeeded } from 'core/utils/helpers/externalLink';
import getPageName from 'core/utils/getPageName';
import { redirectToTheTargetPage } from 'core/utils/helpers/url';
import {
  selectSiteUrl,
  selectInstoreIsMarketingEnabled,
  selectInstoreIsNavNewEnabled,
  selectPrivacyLinkEnabled,
  selectDataPrivacyPageEnabled,
  selectClientHostedDPSRedirectURL,
  selectTermsAndConditionsURL,
  selectPrivacyPolicyUrl,
  selectToolbarConfig,
} from 'core/selectors/app';
import { useGetNavigation } from 'core/modules/Navigation/Nav/hooks';
import { useGetCategories } from 'core/modules/Navigation/NavCategories/hooks/useGetCategories';
import { selectUserIsIdentified, selectBrowserIsMobile } from 'core/selectors/user';
import { showModal } from 'core/modules/Modal/actions';
import { MODAL_TYPE_INSTORE_HIW } from 'core/modules/Modal/types';
import { MANAGE_CARDS_HIW_TYPE } from 'core/modules/Modal/components/InstoreHiwModal/types/types';
import { instoreManageCardsOverlayClick } from 'core/modules/Instore/OffersSection/actions';
import MainNavItem from 'core/modules/Navigation/NavPartials/MainNavItem/MainNavItem';
import NavItem from 'core/modules/Navigation/NavPartials/NavItem/NavItem';
import './Navigation.scss';

function Navigation({ sectionId }) {
  const siteUrl = useSelector(selectSiteUrl);
  const userIsIdentified = useSelector(selectUserIsIdentified);
  const enableInstoreMarketing = useSelector(selectInstoreIsMarketingEnabled);
  const enableInstoreNavNew = useSelector(selectInstoreIsNavNewEnabled);
  const enablePrivacyNoticeLink = useSelector(selectPrivacyLinkEnabled);
  const enableDonotSharePage = useSelector(selectDataPrivacyPageEnabled);
  const clientHostedDPSRedirectURL = useSelector(selectClientHostedDPSRedirectURL);
  const termsAndConditionsURL = useSelector(selectTermsAndConditionsURL);
  const privacyPolicyUrl = useSelector(selectPrivacyPolicyUrl);
  const toolbarConfig = useSelector(selectToolbarConfig);
  const { navItemsFeatured, navItemsNonFeatured } = useGetNavigation();
  const { categories } = useGetCategories();
  const isMobile = useSelector(selectBrowserIsMobile);
  const { chromeDownloadUrl, enableButtonMarketing } = toolbarConfig;

  const dispatch = useDispatch();

  const showChromeButton = !!(isDesktop && (isChrome || isEdge) && chromeDownloadUrl && enableButtonMarketing);

  const manageCardsClickHandler = () => {
    const pageName = getPageName();
    const isInstorePage = pageName === 'is';

    if (isInstorePage) {
      if (window.instoreData && !window.instoreData.isAddCardOnBoardingComplete) {
        dispatch(showModal(MODAL_TYPE_INSTORE_HIW,
          {
            type: MANAGE_CARDS_HIW_TYPE,
            header: 'Get set up to earn in-store miles',
          }));
      } else {
        dispatch(instoreManageCardsOverlayClick());
      }
    } else {
      const instoreModalUrl = isMobile ?
        'instore#modalType=mt_instoreManageCards' : 'instore#lightboxType=instoreManageCards';

      redirectToTheTargetPage(siteUrl, instoreModalUrl);
    }
  };

  return (
    <div className="mn_navBar">
      <div className="mn_mainNav mn_contentSection" data-section-id={sectionId}>
        <MainNavItem title="Home" url={`${siteUrl}`} />

        {categories &&
          <MainNavItem className="mn_navCategories" title="Categories">
            {categories.map(category => (
              <NavItem
                key={category.id}
                title={category.name}
                label={`Visit the ${category.name} page`}
                url={`${siteUrl}/${category.searchFriendlyName}/bc_${category.id}___.htm`}
              />
            ))}
          </MainNavItem>
        }

        <MainNavItem title="Offers">
          {navItemsFeatured && navItemsFeatured.map(navItem => (
            <NavItem key={navItem.title.toString()} title={navItem.title} url={navItem.url} hideExtraRewardsLabel />
          ))}
          {navItemsNonFeatured && navItemsNonFeatured.map(navItem => (
            <NavItem key={navItem.title.toString()} title={navItem.title} url={navItem.url} hideExtraRewardsLabel />
          ))}
          <NavItem title="All offers" url={`${siteUrl}/sp____.htm`} />
          <NavItem title="Free shipping" url={`${siteUrl}/fs____Free%20Shipping.htm`} />
        </MainNavItem>

        <MainNavItem title="Stores" url={`${siteUrl}/b____.htm`} />

        {enableInstoreMarketing && (
          <MainNavItem
            newPillEnabled={enableInstoreNavNew}
            className="mn_instoreLinkWrap"
            title="In-store"
            url={`${siteUrl}/is____.htm`}
          />
        )}

        {enableInstoreMarketing && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div onClick={manageCardsClickHandler}>
            <MainNavItem title="Manage cards" url="#" />
          </div>
        )}

        {navItemsFeatured && navItemsFeatured.map(navItem => (
          <Fragment key={navItem.title.toString()}>
            <MainNavItem title={navItem.title} url={navItem.url} label="Extra" />
          </Fragment>
        ))}

        <MainNavItem title="How it works" url={`${siteUrl}/hiw____.htm`} />
        <MainNavItem title="Favorite stores" url={`${siteUrl}/favorites____.htm`} />
        <MainNavItem title="My account" url={`${siteUrl}/ai____.htm`} />
        <MainNavItem title="Offers for you" url={`${siteUrl}/foryou____.htm`} />
        <MainNavItem title="FAQs" url={`${siteUrl}/faq____.htm`} />
        <MainNavItem title="Contact us" url={`${siteUrl}/contact____.htm`} />
        <MainNavItem
          title="united.com/mileageplus"
          url="https://united.com/mileageplus"
          target="_blank"
          rel="external"
        />

        <MainNavItem
          {...addExternalAttributesIfNeeded(privacyPolicyUrl, siteUrl)}
          title="Privacy policy"
          url={`${privacyPolicyUrl}`}
        />
        <MainNavItem
          {...addExternalAttributesIfNeeded(termsAndConditionsURL, siteUrl)}
          title="Terms"
          url={`${termsAndConditionsURL}`}
        />
        {enablePrivacyNoticeLink && (
        <MainNavItem
          title={<>MileagePlus<sup>&reg;</sup> Shopping terms and privacy notice</>}
          url={`${siteUrl}/p/privacy-notice.htm`}
        />
        )}
        {enableDonotSharePage && !userIsIdentified && (
        <MainNavItem
          title="Privacy preferences"
          url={`${siteUrl}/dps____.htm`}
        />
        )}
        {enableDonotSharePage && userIsIdentified && clientHostedDPSRedirectURL && (
          <MainNavItem
            title="Privacy preferences"
            url={clientHostedDPSRedirectURL}
            target="_blank"
          />
        )}
        {userIsIdentified && isTabletOrDesktop && (
          <MainNavItem title="Log Out" url={`${siteUrl}/logoutUser.php?lUrl=/`} />
        )}
        {showChromeButton && (
          <MainNavItem newPillEnabled title="The Button" url={`${siteUrl}/button`} />
        )}
      </div>
    </div>
  );
}

Navigation.defaultProps = {
  sectionId: '10',
};

export default Navigation;
