import { EARNINGS_SNAPSHOT_VIEW } from '../actions/types';

export const earningsSnapshotGaActionEvents = {
  [EARNINGS_SNAPSHOT_VIEW]: () => ({
    category: 'Mobile MHP: Earnings Snapshot',
    action: 'Earnings Snapshot View',
    label: '',
    value: 1,
  }),
};

export const earningsSnapshotClickAndHoverEvents = [
  {
    selector: '.mn_earningsSnapshot .mn_button',
    click: {
      category: 'Mobile MHP: Earnings Snapshot',
      action: 'Earnings Snapshot My Account Click',
    },
    label: '',
    value: 1,
  },
];
