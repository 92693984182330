import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { buttonScrollingModal as orgConfig } from 'org/configs/modules.config';
import { BUTTON_SCROLLING_MODAL_LOGOS_GET_SUCCESS, BUTTON_SCROLLING_MODAL_LOGOS_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    components: {
      // Content for Button Scrolling Modal is very different for each client so is imported at the org level
      ButtonScrollingModalContent: () => null,
    },
    logos: {
      api: {
        params: {
          fields: ['assets._460x250', 'merchant.name', 'id'],
          content_group_id: 22481,
          limit: 15,
        },
        options: {
          success: BUTTON_SCROLLING_MODAL_LOGOS_GET_SUCCESS,
          failure: BUTTON_SCROLLING_MODAL_LOGOS_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
