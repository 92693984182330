import { combineReducers } from 'redux';
import giftGuideFeaturedDeals from './giftGuideFeaturedDeals';
import giftGuideHeadline from './giftGuideHeadline';
import config from '../configs';

export default combineReducers({
  config: () => config,
  giftGuideHeadline,
  giftGuideFeaturedDeals,
});
