import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useGetAccountSummary } from './hooks/useGetAccountSummary';

function AccountSummary({ renderData }) {
  const { accountSummaryData } = useGetAccountSummary();
  if (isEmpty(accountSummaryData)) {
    return null;
  }

  return renderData(accountSummaryData);
}

AccountSummary.propTypes = {
  renderData: PropTypes.func.isRequired,
};

export default AccountSummary;
