import React from 'react';

import './ChromeRating.scss';

import config from './configs';

function ChromeRating({ chromeText }) {
  const { browserLogoImage, chromeDescription, storeRatingImage } = config;

  return (
    <div className="mn_buttonRating">
      <img className="mn_browserLogo" src={browserLogoImage} alt="Chrome" />
      <span className="mn_ratingText">{chromeText || chromeDescription}</span>
      <img className="mn_stars" src={storeRatingImage} alt="5 stars" />
    </div>
  );
}

export default ChromeRating;
