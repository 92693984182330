import { HYBRID_SEARCH_FORM_GET_SUCCESS, HYBRID_SEARCH_FORM_GET_FAILURE } from '../actions/types';
import searchFormConfig from '../configs';

/** @type {{config: object, merchants: array.<object>}} */
const initialState = {
  config: searchFormConfig,
  searchResult: {},
  isLoading: false,
  isLoaded: false,
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const hybridSearchForm = (state = initialState, action) => {
  switch (action.type) {
    case HYBRID_SEARCH_FORM_GET_SUCCESS: {
      return {
        ...state,
        searchResult: action.payload,
        isLoading: false,
        isLoaded: true,
      };
    }
    case HYBRID_SEARCH_FORM_GET_FAILURE: {
      return {
        ...state,
        searchResult: {},
        isLoading: false,
        isLoaded: true,
      }; // we'd have to decide what to do with error state
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === HYBRID_SEARCH_FORM_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default hybridSearchForm;
