import React from 'react';
import { useSelector } from 'react-redux';
import { selectSiteUrl } from 'core/selectors/app';

export function ProductResultLink({ keywords }) {
  const siteUrl = useSelector(selectSiteUrl);
  const productLink = `${siteUrl}/sr____.htm?keywords=${encodeURIComponent(keywords)}`;

  return (
    <p className="mn_productLinkWrap">
      <a className="mn_productLink" href={productLink}>
        View product results for "<span className="mn_keywords">{keywords}</span>"
      </a>
    </p>
  );
}

export default ProductResultLink;
