import { PERSONALIZED_TOP_OFFERS_VIEW } from '../actions/types';

export const personalizedTopOffersGaActionEvents = {
  [PERSONALIZED_TOP_OFFERS_VIEW]: () => ({
    category: 'Mobile MHP: Personalized Top Stores',
    action: 'Personalized Top Stores View',
    label: '',
    value: 1,
  }),
};

export const personalizedTopOffersClickAndHoverEvents = [
  {
    selector: '.mn_personalizedTopOffers .mn_featuredDeal .mn_offerOverlayLink',
    click: {
      category: 'Mobile MHP: Personalized Top Stores',
      action: 'Personalized Top Stores Click',
    },
    label: (element) => element.dataset.merchantId,
    value: 1,
  },
];
