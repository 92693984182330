import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { hybridSearchForm as orgConfig } from 'org/configs/modules.config';
import { HYBRID_SEARCH_FORM_GET_SUCCESS, HYBRID_SEARCH_FORM_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: HYBRID_SEARCH_FORM_GET_SUCCESS,
        failure: HYBRID_SEARCH_FORM_GET_FAILURE,
      },
    },
    searchButtonClassName: 'fa fa-search',
    shouldUsePopUpOnMobile: false,
    shouldShowResultLinkOutside: false,
  },
  h: {
    shouldUsePopUpOnMobile: true,
    shouldShowResultLinkOutside: true,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
