import {
  INQUIRY_ORDER_SELECTED_MERCHANT_DATA_UPDATE,
  INQUIRY_ORDER_SELECTED_MERCHANT_DATA_CLEAR,
} from '../actions/types';

const initialState = {};

const selectedMerchant = (state = initialState, action) => {
  switch (action.type) {
    case INQUIRY_ORDER_SELECTED_MERCHANT_DATA_UPDATE: {
      const { merchant = {} } = action;
      return {
        ...merchant,
      };
    }
    case INQUIRY_ORDER_SELECTED_MERCHANT_DATA_CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default selectedMerchant;
