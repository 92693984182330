import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { getId } from 'core/utils/analytics/sectionInfo';
import { stickyFooter as orgConfig } from 'org/configs/modules.config';
import { STICKY_FOOTER_GET_SUCCESS, STICKY_FOOTER_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      contentParams: [{
        content_group_id: 7279,
        content_type_id: 81,
      }, {
        content_group_id: 7697,
        content_type_id: 49,
      }],
      otherParams: {
        limit: 1,
        with_offers: 0,
        sort_by: 'random',
        section_id: getId('sticky-footer'),
        fields: [
          'id',
          'merchant.classification',
          'merchant.logoUrls',
          'merchant.showRebate',
          'merchant.rebate',
          'merchant.id',
          'merchant.name',
          'merchant.clickUrl',
          'merchant.tiers',
          'assets',
          'couponCode',
          'disclaimer',
          'offerTitle',
          'clickUrl',
          'stopDatetime',
          'highlightedMerchantTierId',
        ],
      },
      options: {
        success: STICKY_FOOTER_GET_SUCCESS,
        failure: STICKY_FOOTER_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    stickyFooterDesktop: {
      rebateOptions: {
        showRebateTierName: true,
        showRebateInline: true,
        hideElevationCurrency: true,
      },
    },
    stickyFooterMobile: {
      rebateOptions: {
        showRebateTierName: true,
        showRebateInline: true,
        hideElevationCurrency: true,
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
