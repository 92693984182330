import {
  TRANSACTIONS_TAB_RECORDS_GET_FAILURE, TRANSACTIONS_TAB_RECORDS_GET_SUCCESS,
} from '../actions/types';

const initialState = {
  isLoading: false,
  isLoaded: false,
  data: [],
};

const records = (state = initialState, action) => {
  switch (action.type) {
    case TRANSACTIONS_TAB_RECORDS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: action.payload,
      };
    }
    case TRANSACTIONS_TAB_RECORDS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: {},
      };
    }
    default: {
      const { options = {} } = action;

      if (options.success === TRANSACTIONS_TAB_RECORDS_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};

export default records;
