import { BUTTON_UNINSTALL_VIEW } from '../actions/types';

export const buttonUninstallGaActionEvents = {
  [BUTTON_UNINSTALL_VIEW]: () => (
    {
      category: 'ExtensionInstalled',
      action: 'Uninstall',
      label: 'Open',
    }
  ),
};
