import { findAncestorByClassName } from 'core/utils/helpers/dom';
import { NAV_BANNER_IS_VIEWED } from '../actions/types';

export const navBannerGaActionEvents = {
  [NAV_BANNER_IS_VIEWED]: ({ title }) => ({
    category: 'Explore Stores - Flyout',
    action: 'Conditional banner view',
    label: title,
  }),
};

export const navBannerClickAndHoverEvents = [
  {
    selector: '.mn_navBanner .mn_navBannerLinkWrap a',
    click: {
      category: 'Explore Stores - Flyout',
      action: 'Conditional banner click',
    },
    label: (element) => {
      const navBannerContentElement = findAncestorByClassName(element, 'mn_navBannerContent');
      const title = navBannerContentElement.getElementsByTagName('h3')[0];
      return title.textContent;
    },
  },
];
