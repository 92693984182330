import {
  MEMBER_POST_INSTORE_CARD_LINK,
  MEMBER_POST_INSTORE_CARD_LINK_SUCCESS,
  MEMBER_POST_INSTORE_CARD_LINK_FAILURE,
} from 'core/actions/member';

import {
  RESET_INSTORE_CARD_LINK_DATA,
} from '../actions/types';

const initialState = {
  isLoading: false,
  isLoaded: false,
  linkedCardResponse: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MEMBER_POST_INSTORE_CARD_LINK: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        linkedCardResponse: [],
      };
    }
    case MEMBER_POST_INSTORE_CARD_LINK_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        linkedCardResponse: action.payload.response,
      };
    }
    case MEMBER_POST_INSTORE_CARD_LINK_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        linkedCardResponse: action.payload.response,
      };
    }
    case RESET_INSTORE_CARD_LINK_DATA: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        linkedCardResponse: [],
      };
    }
    default: {
      return state;
    }
  }
};
