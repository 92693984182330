import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { bonusTiers as orgConfig } from 'org/configs/modules.config';
import { BONUS_TIERS_GET_SUCCESS, BONUS_TIERS_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: BONUS_TIERS_GET_SUCCESS,
        failure: BONUS_TIERS_GET_FAILURE,
        cache: {
          isEnabled: true,
          isPublishedData: true,
        },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
