import dayjs from 'core/utils/dayjs';

export const formatDate = (date, outputFormat, inputFormat) => (
  date && (inputFormat
    ? dayjs(date, inputFormat).format(outputFormat)
    : dayjs(date).format(outputFormat)
  )
);

export const formatDateWithFixedTimeZone = (date, outputFormat = 'MM/DD/YYYY', inputFormat) => (
  formatDate(dayjs.parseZone(date), outputFormat, inputFormat)
);

export const formatDateComma = date => formatDate(date, 'MM DD, YYYY');

export const formatDateDashed = date => formatDate(date, 'M/D/YYYY');

export const addLeadingZero = (num) => {
  const number = parseInt(num, 10);
  if (Number.isNaN(number)) {
    return num;
  }
  if (number < 10) {
    return `0${number}`;
  }
  return number;
};
