import { SUCCESS_SUFFIX, FAILURE_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/StickyFooter';

export const STICKY_FOOTER_GET_SUCCESS = `${rootActionPath}/GET_${SUCCESS_SUFFIX}`;
export const STICKY_FOOTER_GET_FAILURE = `${rootActionPath}/GET_${FAILURE_SUFFIX}`;

export const STICKY_FOOTER_DESKTOP_TOGGLE_TRIGGER = `${rootActionPath}/DESKTOP/TOGGLE_TRIGGER`;
export const STICKY_FOOTER_DESKTOP_VIEW = `${rootActionPath}/DESKTOP/VIEW`;

export const STICKY_FOOTER_MOBILE_VIEW = `${rootActionPath}/MOBILE/VIEW`;
