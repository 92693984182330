import { SUCCESS_SUFFIX, FAILURE_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/hybridQuickSearch';

export const HYBRYD_QUICK_SEARCH_MERCHANTS_GET_SUCCESS = `${rootActionPath}/merchants/GET_${SUCCESS_SUFFIX}`;
export const HYBRYD_QUICK_SEARCH_MERCHANTS_GET_FAILURE = `${rootActionPath}/merchants/GET_${FAILURE_SUFFIX}`;

export const HYBRYD_QUICK_SEARCH_GSP_MERCHANTS_GET_SUCCESS = `${rootActionPath}/gspMerchants/GET_${SUCCESS_SUFFIX}`;
export const HYBRYD_QUICK_SEARCH_GSP_MERCHANTS_GET_FAILURE = `${rootActionPath}/gspMerchants/GET_${FAILURE_SUFFIX}`;
export const HYBRYD_QUICK_SEARCH_GSP_MERCHANTS_CLEAR = `${rootActionPath}/gspMerchants/CLEAR`;

export const HYBRYD_QUICK_SEARCH_GSP_PRODUCTS_GET_SUCCESS = `${rootActionPath}/gspProducts/GET_${SUCCESS_SUFFIX}`;
export const HYBRYD_QUICK_SEARCH_GSP_PRODUCTS_GET_FAILURE = `${rootActionPath}/gspProducts/GET_${FAILURE_SUFFIX}`;
export const HYBRYD_QUICK_SEARCH_GSP_PRODUCTS_CLEAR = `${rootActionPath}/gspProducts/CLEAR`;
