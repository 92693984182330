import config from '../configs';
import { TRENDING_AND_SEASONAL_OFFERS_GET_SUCCESS, TRENDING_AND_SEASONAL_OFFERS_GET_FAILURE } from '../actions/types';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  offers: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TRENDING_AND_SEASONAL_OFFERS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        offers: action.payload.response,
      };
    }
    case TRENDING_AND_SEASONAL_OFFERS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        offers: [],
      };
    }
    default: {
      const { options = {} } = action;

      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === TRENDING_AND_SEASONAL_OFFERS_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};
