import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { simpleBannerList as orgConfig } from 'org/configs/modules.config';
import {
  SIMPLE_BANNER_LIST_GET_SUCCESS,
  SIMPLE_BANNER_LIST_GET_FAILURE,
} from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: SIMPLE_BANNER_LIST_GET_SUCCESS,
        failure: SIMPLE_BANNER_LIST_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
      params: {
        sort_by: 'random',
        fields: [
          'dealId',
          'assets',
          'clickUrl',
          'contentItemTypeId',
          'merchant.id',
          'merchant.logoUrls',
          'merchant.showRebate',
          'merchant.rebate',
          'merchant.name',
          'offerTitle',
          'offerDetails',
          'couponCode',
        ].join(','),
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
