import { takeEvery, put } from 'redux-saga/effects';

import { FEATURED_STORES_MODAL_TRIGGER } from 'core/modules/Modal/components/FeaturedStoresModal/actions/types';
import { CATEGORY_TILE_CLICK } from '../actions/types';

function* putFeaturedStoresModalAction({ data }) {
  yield put({
    type: FEATURED_STORES_MODAL_TRIGGER,
    data,
  });
}

export default function* popularCategoriesSaga() {
  yield takeEvery(CATEGORY_TILE_CLICK, putFeaturedStoresModalAction);
}
