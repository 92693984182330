import { getDisplayPageName } from 'core/utils/analytics/pageInfo';
import { OFFER_HUB_LINKED } from '../../OffersSection/actions/types';

export const instoreOffersLinkingActionEvents = {
  [OFFER_HUB_LINKED]: ({ data }) => {
    const instoreAdOffers = document.getElementsByClassName('mn_instoreAdOffers')[0];
    const { viewType } = instoreAdOffers.dataset;

    return {
      category: 'Offer Linking',
      action: 'Linked offer from offer hub',
      label: `${getDisplayPageName()} | ${viewType || 'logo'} version | ${data}`,
    };
  },
};

export const instoreOffersLinkingClickAndHoverEvents = [
  {
    selector: '.mn_intoreLinkOfferWrapper .mn_button.instoreLinkOffer',
    click: {
      category: 'Offer Linking',
      action: 'Linked offer from overlay',
    },
    label: element => element.dataset.gmidAndMerchantName,
  },
  {
    selector: '.mn_instoreOverlayButtonWrap .mn_button.mn_instoreCardLinkOffer',
    click: {
      category: 'Offer Linking',
      action: 'Offer linked',
    },
    label: element => element.dataset.gmidAndMerchantName,
  },
];
