import { FAILURE_SUFFIX, SUCCESS_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/instoreOffers';

export const INSTORE_OFFERS_GET_SUCCESS = `${rootActionPath}/GET_${SUCCESS_SUFFIX}`;
export const INSTORE_OFFERS_GET_FAILURE = `${rootActionPath}/GET_${FAILURE_SUFFIX}`;
export const INSTORE_OFFERS_CLICK = `${rootActionPath}/CLICK`;
export const NEAR_ME_MAP_CLICK = `${rootActionPath}Map/CLICK`;
export const INSTORE_LOCATION_SET_LOCATION = `${rootActionPath}_SET_LOCATION`;
export const INSTORE_LOCATION_SET_USER_LOCATION = `${rootActionPath}_SET_USER_LOCATION`;
export const INSTORE_LOCATION_SET_ZIPCODE = `${rootActionPath}_SET_ZIPCODE`;
export const INSTORE_MANAGE_CARDS_CLICK = 'core/instoreCards/CLICK';
export const INSTORE_ADD_NEW_CARD_CLICK = 'core/instoreAddCard/CLICK';
export const INSTORE_SMS_NUMBER_CLICK = 'core/instoreSMSNumber/CLICK';
export const INSTORE_MULTI_LINK_CLICK = 'core/instoreMultiLink/CLICK';

export const OFFER_MODAL_VIEW = `${rootActionPath}/VIEW`;
export const OFFER_HUB_LINKED = `${rootActionPath}/LINKED`;
