import {
  MEMBER_POST_PHONE_NUMBER,
  MEMBER_POST_PHONE_NUMBER_FAILURE,
  MEMBER_POST_PHONE_NUMBER_SUCCESS,

} from 'core/actions/member';
import {
  INSTORE_SMS_NUMBER_MODAL_CLEAR,
  INSTORE_SMS_NUMBER_MODAL_TRIGGER,
} from '../actions/types';

const initialState = {};

const instoreSMSNumberModal = (state = initialState, action) => {
  switch (action.type) {
    case INSTORE_SMS_NUMBER_MODAL_TRIGGER: {
      return { ...state };
    }
    case INSTORE_SMS_NUMBER_MODAL_CLEAR: {
      return initialState;
    }

    case MEMBER_POST_PHONE_NUMBER: {
      return { ...state, isLoading: true };
    }

    case MEMBER_POST_PHONE_NUMBER_SUCCESS: {
      return { ...state, isSuccess: true, isLoading: false };
    }

    case MEMBER_POST_PHONE_NUMBER_FAILURE: {
      return {
        ...state,
        isSuccess: false,
        isLoading: false,
        loadFailureMessage: action.payload.name,
      };
    }

    default:
      return state;
  }
};

export default instoreSMSNumberModal;
