import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { infoBarMarketing as orgConfig } from 'org/configs/modules.config';
import { INFO_BAR_MARKETING_GET_SUCCESS, INFO_BAR_MARKETING_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        content_group_id: 1068,
      },
      options: {
        success: INFO_BAR_MARKETING_GET_SUCCESS,
        failure: INFO_BAR_MARKETING_GET_FAILURE,
        cache: {
          isEnabled: true,
          isPublishedData: true,
        },
      },
    },
    tagHelper: () => {},
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
