import { TRANSACTION_AD_UNIT_VIEW } from '../actions/types';

export const transactionAdUnitGaActionEvents = {
  [TRANSACTION_AD_UNIT_VIEW]: ({ merchantId }) => (
    {
      category: 'My Account In-Line Transactions Ad Unit',
      action: 'View',
      label: merchantId,
    }
  ),
};

export const transactionAdUnitClickAndHoverEvents = [
  {
    selector: '.mn_transactionAdUnit .mn_shopNow',
    click: {
      category: 'My Account In-Line Transactions Ad Unit',
      action: 'Click',
    },
    label: (element) => {
      const { merchantId } = element.dataset;

      return merchantId;
    },
  },
];
