import { BONUS_GET_INFORMATION_MOCK_SUCCESS } from 'core/actions/bonus';
import config from '../configs';
import {
  BONUS_OFFERS_GET_SUCCESS,
  BONUS_OFFERS_GET_FAILURE,
  BONUS_PENDING_OFFERS_GET_SUCCESS,
  BONUS_PENDING_OFFERS_GET_FAILURE,
} from '../actions/types';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  bonus: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BONUS_OFFERS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        bonus: action.payload.response,
      };
    }
    case BONUS_OFFERS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        bonus: [],
      };
    }
    case BONUS_PENDING_OFFERS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        pendingBonus: action.payload.response,
      };
    }
    case BONUS_PENDING_OFFERS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        pendingBonus: [],
      };
    }
    case BONUS_GET_INFORMATION_MOCK_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        bonus: action.payload.response,
      };
    }
    default: {
      const { options = {} } = action;

      if (
        options.success === BONUS_OFFERS_GET_SUCCESS
        || options.success === BONUS_GET_INFORMATION_MOCK_SUCCESS
        || options.success === BONUS_PENDING_OFFERS_GET_SUCCESS
      ) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};
