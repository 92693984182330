import { findAncestorByClassName, findDataAttrInAncestorTree } from 'core/utils/helpers/dom';
import { FEATURED_STORES_MODAL_TRIGGER } from '../actions/types';

export const featuredStoresModalGaActionEvents = {
  [FEATURED_STORES_MODAL_TRIGGER]: (action) => {
    const { categoryTitle } = action.data;
    return {
      category: 'Category Modal',
      action: 'Display',
      label: categoryTitle,
    };
  },
};

export const featuredStoresClickAndHoverEvents = [
  {
    selector: '.mn_featuredStoresModal .mn_merchantClickUrlLink',
    click: {
      category: 'Category Modal',
      action: 'Tap store name or shop now',
    },
    label: (element) => {
      const featuredStoresModal = findAncestorByClassName(element, 'mn_featuredStoresModal');
      const categoryTitle = featuredStoresModal.getElementsByClassName('mn_categoryTitle')[0].innerText;
      const merchantName = findDataAttrInAncestorTree(element, 'merchantName');

      return `${categoryTitle}|${merchantName}`;
    },
  },
  {
    selector: '.mn_featuredStoresModal .mn_viewAllLink',
    click: {
      category: 'Category Modal',
      action: 'Tap view all stores',
    },
    label: (element) => {
      const featuredStoresModal = findAncestorByClassName(element, 'mn_featuredStoresModal');
      const categoryTitle = featuredStoresModal.getElementsByClassName('mn_categoryTitle')[0].innerText;

      return categoryTitle;
    },
  },
];
