import {
  MARKETING_TEXT_GET_SUCCESS,
  MARKETING_TEXT_GET_FAILURE,
} from '../actions/types';

import marketingTextConfig from '../configs';

/** @type {{config: object, placements: array.<object>}} */
const initialState = {
  config: marketingTextConfig,
  namespaceDefaults: {
    isLoaded: false,
    isLoading: false,
    data: {},
    responseDate: null,
  },
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const marketingText = (state = initialState, action) => {
  const { context = {} } = action;
  const { namespace = 'default' } = action.namespace ? action : context.options || {};
  const { namespaceDefaults } = state;
  const { [namespace]: namespaceState = namespaceDefaults } = state;

  switch (action.type) {
    case MARKETING_TEXT_GET_SUCCESS: {
      const { response, metadata } = action.payload;
      return {
        ...state,
        [`namespace-${namespace}`]: {
          ...namespaceState,
          data: response[0] || {},
          isLoading: false,
          isLoaded: true,
          responseDate: metadata.date,
        },
      };
    }
    case MARKETING_TEXT_GET_FAILURE: {
      return {
        ...state,
        [`namespace-${namespace}`]: {
          ...namespaceState,
          data: {},
          isLoading: false,
          isLoaded: true,
          responseDate: null,
        },
      };
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === MARKETING_TEXT_GET_SUCCESS) {
        return {
          ...state,
          [`namespace-${namespace}`]: {
            ...namespaceState,
            isLoading: true,
          },
        };
      }

      return state;
    }
  }
};

export default marketingText;
