import React from 'react';
import { useSelector } from 'react-redux';

import { selectSiteUrl } from 'core/selectors/app';

import FlipTile from 'core/modules/FlipTile/FlipTile';
import SectionTitle from 'core/modules/SectionTitle/SectionTitle';
import orgConfig from 'org/configs/org.config';
import config from './configs';

import './HeaderExtraRewards.scss';

function HeaderExtraRewards({ merchants }) {
  const { earnType } = orgConfig;
  const { viewAllLanguage, getTitle } = config;
  const siteUrl = useSelector(selectSiteUrl);
  const title = getTitle(earnType);

  return (
    <div className="mn_headerExtraRewards">
      <SectionTitle title={title} />
      <div className="mn_sectContainer">
        {merchants.map(merchant => (
          <FlipTile key={merchant.id} merchant={merchant} />
        ))}
      </div>
      <a href={`${siteUrl}/p/extra-rewards.htm`} className="mn_linkBottom">
        {viewAllLanguage}
      </a>
    </div>
  );
}

export default HeaderExtraRewards;
