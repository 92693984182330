import { takeEvery, put } from 'redux-saga/effects';
import { showModal } from 'core/modules/Modal/actions';

import { MODAL_TYPE_INSTORE_MANAGE_CARDS } from 'core/modules/Modal/types';
import { INSTORE_MANAGE_CARDS_MODAL_TRIGGER } from '../actions/types';

function* showInstoreManageCardsModal() {
  yield put(showModal(MODAL_TYPE_INSTORE_MANAGE_CARDS));
}

export default function* instoreManageCardsModalSaga() {
  yield takeEvery(INSTORE_MANAGE_CARDS_MODAL_TRIGGER,
    showInstoreManageCardsModal);
}
