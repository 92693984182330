import React from 'react';

import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { profile as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    profileDetailsConfig: {
      getTitleText: (isMobileMq) => (isMobileMq ? <>Profile</> : <>My profile</>),
      getButtonText: (isMobileMq) => (isMobileMq ? <>Edit</> : <>Edit profile</>),
      editButtonLink: '',
      programName: '',
    },
    emailDetailsConfig: {
      getTitleText: () => <>Email and alerts</>,
      getButtonText: (isMobileMq) => (isMobileMq ? <>Edit</> : <>Edit email settings</>),
      getSubscribedText: (gdprDisplayName) => <>You are currently opted-in to all {gdprDisplayName} emails.</>,
      getUnsubscribedText: (gdprDisplayName) => <>You are currently opted out from {gdprDisplayName}.</>,
    },
    accountSnapshotConfig: {
      getUserInfoText: (userName) => (<>Member: {userName}</>),
      getRegistrationDateText: (isMobileMQ, formattedCreateDate) => (
        <>Member since <span className="mn_noWrap">{formattedCreateDate}</span></>
      ),
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
