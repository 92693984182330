import { createSelector } from 'reselect';
import get from 'lodash/get';

export const selectInquiryOrder = state => state.contactUsInquiryOrder;

// Selected merchant
export const selectSelectedMerchant = createSelector(selectInquiryOrder, inquiryOrder => inquiryOrder.selectedMerchant);
export const selectSelectedMerchantId = createSelector(selectSelectedMerchant, selectedMerchant => selectedMerchant.id);
export const selectSelectedMerchantName = createSelector(
  selectSelectedMerchant,
  selectedMerchant => selectedMerchant.name,
);

export const selectSelectedMerchantIsTravelMerchant = createSelector(
  selectSelectedMerchant,
  selectedMerchant => selectedMerchant.flags?.isTravelMerchant,
);

export const selectSelectedMerchantSpecialConsiderationText = createSelector(
  selectSelectedMerchant,
  selectedMerchant => selectedMerchant.specialConsiderationText,
);

// Transactions
export const selectTransactions = createSelector(selectInquiryOrder, inquiryOrder => inquiryOrder.transactions);
export const selectTransactionsConfig = createSelector(selectTransactions, transactions => transactions.config);
export const selectTransactionsIsLoading = createSelector(selectTransactions, transactions => transactions.isLoading);
export const selectTransactionsIsLoaded = createSelector(selectTransactions, transactions => transactions.isLoaded);
export const selectTransactionsRecords = createSelector(selectTransactions, transactions => transactions.records);

export const selectTransactionsRecordsByMerchantId = createSelector(
  [selectTransactionsRecords, selectSelectedMerchantId],
  (records, selectedMerchantId) => records.filter((record) => {
    const gmid = get(record, 'click.globalMerchantId');
    return gmid && selectedMerchantId && (gmid === selectedMerchantId);
  }),
);

// Click History
export const selectClickHistory = createSelector(selectInquiryOrder, inquiryOrder => inquiryOrder.clickHistory);
export const selectClickHistoryConfig = createSelector(selectClickHistory, clickHistory => clickHistory.config);
export const selectClickHistoryIsLoading = createSelector(selectClickHistory, clickHistory => clickHistory.isLoading);
export const selectClickHistoryIsLoaded = createSelector(selectClickHistory, clickHistory => clickHistory.isLoaded);
export const selectClickHistoryRecords = createSelector(selectClickHistory, clickHistory => clickHistory.records);

export const selectClickHistoryRecordsByMerchantId = createSelector(
  [selectClickHistoryRecords, selectSelectedMerchantId],
  (records, selectedMerchantId) => records.filter(({ globalMerchantId }) => (
    globalMerchantId && selectedMerchantId && (globalMerchantId === selectedMerchantId)
  )),
);
