import config from '../configs';
import { FLYOUT_CATEGORIES_GET_SUCCESS, FLYOUT_CATEGORIES_GET_FAILURE } from '../actions/types';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  categories: [],
};

/**
 * @param {object} offer
 * @return {object}
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case FLYOUT_CATEGORIES_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        categories: action.payload.response,
      };
    }
    case FLYOUT_CATEGORIES_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        categories: [],
      };
    }
    default: {
      const { options = {} } = action;

      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === FLYOUT_CATEGORIES_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};
