import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

export const selectRecentStoresFlyout = state => state.recentStoresFlyout;

// Click history
export const selectClickHistory = createSelector(
  selectRecentStoresFlyout, recentStoresFlyout => recentStoresFlyout.clickHistory,
);

export const selectClickHistoryConfig = createSelector(
  selectClickHistory,
  clickHistory => clickHistory.config,
);

export const selectClickHistoryIsLoading = createSelector(
  selectClickHistory,
  clickHistory => clickHistory.isLoading,
);

export const selectClickHistoryIsLoaded = createSelector(
  selectClickHistory,
  clickHistory => clickHistory.isLoaded,
);

export const selectClickHistoryGmids = createSelector(
  selectClickHistory,
  clickHistory => clickHistory.gmids,
);

// Recent stores
export const selectAllMerchants = createSelector(
  selectRecentStoresFlyout, recentStoresFlyout => recentStoresFlyout.allMerchants,
);

export const selectAllMerchantsConfig = createSelector(
  selectAllMerchants,
  allMerchants => allMerchants.config,
);

export const selectAllMerchantsIsLoading = createSelector(
  selectAllMerchants,
  allMerchants => allMerchants.isLoading,
);

export const selectAllMerchantsIsLoaded = createSelector(
  selectAllMerchants,
  allMerchants => allMerchants.isLoaded,
);

export const selectAllMerchantsPlacements = createSelector(
  selectAllMerchants,
  allMerchants => allMerchants.merchants,
);

export const selectRecentStoresMerchantsToDisplay = createSelector(
  [selectClickHistoryGmids, selectAllMerchantsPlacements],
  (clickHistoryGmids, merchants) => {
    if (isEmpty(clickHistoryGmids) || isEmpty(merchants)) {
      return [];
    }

    return clickHistoryGmids.map((gmid) => merchants[gmid])
      .filter((item) => !!item)
      .slice(0, 5);
  },
);
