import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { bonusOffers as orgConfig } from 'org/configs/modules.config';
import { BONUS_OFFERS_GET_SUCCESS, BONUS_OFFERS_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    rebateOptions: {
      additionalRebatePrefix: 'Plus,',
      tiered: {
        additionalRebatePrefix: 'Plus,',
      },
    },
    useAddToFavorite: false,
    usePeriod: false,
    useDateInBonusTag: false,
    seeDetailPrefix: null,
    api: {
      params: {
        include_offers: 1,
      },
      options: {
        success: BONUS_OFFERS_GET_SUCCESS,
        failure: BONUS_OFFERS_GET_FAILURE,
        cache: {
          isEnabled: true,
          isPublishedData: true,
        },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
