import { DELETED_MEMBER_GET_SUCCESS, DELETED_MEMBER_GET_FAILURE } from '../actions/types';

export const defaultConfig = {
  api: {
    params: {
      limit: 1,
      content_group_id: 14670,
    },
    options: {
      success: DELETED_MEMBER_GET_SUCCESS,
      failure: DELETED_MEMBER_GET_FAILURE,
      cache: { isEnabled: true },
    },
  },
};

export const deletedMemberConfig = defaultConfig;
