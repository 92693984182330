import dayjs from 'core/utils/dayjs';
import { SPONSORED_OFFERS_GET_FAILURE, SPONSORED_OFFERS_GET_SUCCESS } from '../actions/types';

import sponsoredOffersConfig from '../configs';

const { stopDateTimeFormat } = sponsoredOffersConfig;

const initialState = {
  config: sponsoredOffersConfig,
  namespaceDefaults: {
    isLoaded: false,
    isLoading: false,
    data: [],
  },
};

const handleResponse = (response) => (
  response.map((item) => ({
    ...item,
    expiresToDisplay: dayjs.parseZone(item.stopDatetime).format(stopDateTimeFormat),
  }))
);

const sponsoredOffers = (state = initialState, action) => {
  const { context = {} } = action;
  const { namespace = 'default' } = action.namespace ? action : context.options || {};
  const { namespaceDefaults } = state;
  const { [namespace]: namespaceState = namespaceDefaults } = state;

  switch (action.type) {
    case SPONSORED_OFFERS_GET_SUCCESS: {
      return {
        ...state,
        [`namespace-${namespace}`]: {
          ...namespaceState,
          data: handleResponse(action.payload.response),
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case SPONSORED_OFFERS_GET_FAILURE: {
      return {
        ...state,
        [`namespace-${namespace}`]: {
          ...namespaceState,
          data: [],
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === SPONSORED_OFFERS_GET_SUCCESS) {
        return {
          ...state,
          [`namespace-${namespace}`]: {
            ...namespaceState,
            isLoading: true,
          },
        };
      }

      return state;
    }
  }
};

export default sponsoredOffers;
