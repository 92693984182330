import jsonp from 'jsonp';
import axios from 'axios';
import max from 'lodash/max';

/**
 * Filter out invalid parameters
 * @param {object} params
 * @param {array.<string>} validParams
 * @return {object}
 */
export const filterInvalidParams = (params = {}, validParams = []) => (
  Object.keys(params).reduce((result, key) => {
    if (validParams.includes(key)) {
      result[key] = params[key]; // eslint-disable-line no-param-reassign
    } else {
      console.log(`${key} param is not valid`);
    }
    return result;
  }, {})
);

/**
 * Extract response code from api response
 * @param {object} response
 * @return {boolean}
 */
export const isErrorResponse = (response = {}) => {
  const { status = 200, data = {} } = response;
  const { responseCode = 200 } = data;
  return max([status, responseCode]) >= 400;
};

export default class BaseApi {
  /**
   * @type {string}
   * @static
   */
  static defaultBaseUrl = '';

  /**
   * @type {number}
   * @static
   */
  static defaultTimeout = 10000;

  /**
   * @type {object}
   * @static
   */
  static defaultHeaders = {
    'Content-Type': 'application/json',
  };

  /**
   * @param {object} options
   * @returns {Promise}
   */
  makeRequest(options) {
    return this.request(options);
  }

  /**
   *
   * @param url
   * @returns {*}
   */
  makeJsonpRequest({ url, params = {} }) { // eslint-disable-line class-methods-use-this
    return new Promise((resolve, reject) => {
      jsonp(url, params, (err, data) => {
        if (err) {
          reject(err.message);
        } else {
          resolve({ data });
        }
      });
    });
  }

  setParams({
    baseUrl = BaseApi.defaultBaseUrl,
    timeout = BaseApi.defaultTimeout,
    headers = BaseApi.defaultHeaders,
  }) {
    if (!this.request) {
      this.request = axios.create({ baseURL: baseUrl, timeout, headers });
    } else {
      Object.assign(this.request.defaults, { baseURL: baseUrl, timeout, headers });
    }

    Object.assign(this, { baseUrl, timeout, headers });
  }

  /**
   *
   * @param params
   * @returns {string}
   */
  serialize(params) { // eslint-disable-line class-methods-use-this
    const paramsList = [];
    Object.keys(params).forEach((paramKey) => {
      paramsList.push(`${encodeURIComponent(paramKey)}=${encodeURIComponent(params[paramKey])}`);
    });

    return paramsList.join('&');
  }
}
