import React from 'react';
import { ShowOnMQ } from 'core/modules/MediaQueries/MediaQueries';

import Navigation from 'org/modules/Navigation/Navigation';
import SidebarWelcomeMessage from '../SidebarWelcomeMessage/SidebarWelcomeMessage';

import './HeaderSecondaryBar.scss';

function HeaderSecondaryBar() {
  return (
    <ShowOnMQ mobile tablet l>
      <div className="mn_headerSecondaryBar">
        <ShowOnMQ mobile><SidebarWelcomeMessage /></ShowOnMQ>
        <Navigation />
      </div>
    </ShowOnMQ>
  );
}

export default HeaderSecondaryBar;
