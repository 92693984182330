import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { dynamicRewardsTabContent as orgConfig } from 'org/configs/modules.config';
import {
  DYNAMIC_REWARDS_TAB_CONTENT_GET_SUCCESS,
  DYNAMIC_REWARDS_TAB_CONTENT_GET_FAILURE,
} from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: DYNAMIC_REWARDS_TAB_CONTENT_GET_SUCCESS,
        failure: DYNAMIC_REWARDS_TAB_CONTENT_GET_FAILURE,
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
