import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { referralTerms as orgConfig } from 'org/configs/modules.config';
import { REFERRAL_TERMS_GET_SUCCESS, REFERRAL_TERMS_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        content_group_id: 34379,
      },
      options: {
        success: REFERRAL_TERMS_GET_SUCCESS,
        failure: REFERRAL_TERMS_GET_FAILURE,
        cache: {
          isEnabled: true,
          isPublishedData: true,
        },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
