import { getDisplayPageName } from 'core/utils/analytics/pageInfo';
import { OFFER_HUB_LINKED } from '../../OffersSection/actions/types';

export const instoreMapActionEvents = {
  [OFFER_HUB_LINKED]: ({ data }) => {
    const instoreAdOffers = document.getElementsByClassName('mn_instoreAdOffers')[0];
    const { viewType } = (instoreAdOffers && instoreAdOffers.dataset) || {};

    return {
      category: 'Offer Linking',
      action: 'Linked offer from offer hub',
      label: `${getDisplayPageName()} | ${viewType || 'logo'} version | ${data}`,
    };
  },
};

export const instoreMapClickAndHoverEvents = [
  {
    selector: '.mn_instoreOfferSection .mn_offerHubNearMePin',
    click: {
      category: 'Map',
      action: 'Open map',
    },
  },
  {
    selector: '.mn_instoreSearchTheArea .mn_mobileOfferHubMapRedoSearch',
    click: {
      category: 'Map',
      action: 'Re-did search in map',
    },
  },
  {
    selector: '.mn_offerHubMapListItemInfo .mn_offerHubMapResultsDirection',
    click: {
      category: 'Map',
      action: 'Clicked get directions button',
    },
    label: element => element.dataset.merchantAddress,
  },
];
