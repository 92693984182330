import merge from 'lodash/merge';
import { formatDateWithFixedTimeZone } from './commonParsers/formatDateParser';

export default response => response.map(click =>
  merge(click, {
    clickDateRaw: click.clickDate,
    clickDate: formatDateWithFixedTimeZone(click.clickDate),
    id: click.globalMerchantId,
    name: click.merchantName,
    orders: click.orders.map(order => ({
      transactionDate: formatDateWithFixedTimeZone(order.transactionDate),
      transactionAmount: `$${parseFloat(order.transactionAmount).toFixed(2)}`,
    })),
  }));
