import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { merchantTileList as orgConfig } from 'org/configs/modules.config';

/** @type {object} */
const defaultConfig = {
  defaults: {
    slick: {
      slidesToShow: 1,
      centerMode: true,
      centerPadding: '88px',
      arrows: false,
      dots: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2.5,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 413,
          settings: {
            centerPadding: '68px',
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 374,
          settings: {
            centerPadding: '43px',
            slidesToShow: 1,
          },
        },
      ],
    },
  },
};

export const merchantTileListConfig = mergeModuleConfigs(defaultConfig, orgConfig);
