import React from 'react';

import './SearchBarTrigger.scss';

function SearchBarTrigger({ ariaExpanded, onClick }) {
  return (
    <div className="mn_searchTriggerWrapper">
      <button
        className={`fa mn_searchTrigger ${ariaExpanded ? 'mn_unitedIconClose' : 'mn_unitedIconSearch'}`}
        aria-label="Display search field"
        onClick={onClick}
        aria-expanded={ariaExpanded}
      />
    </div>
  );
}

export default SearchBarTrigger;
