import { createSelector } from 'reselect';

export const selectNavSeasonalFlyout = state => state.navSeasonalFlyout;
export const selectNavSeasonalFlyoutConfig = createSelector(
  selectNavSeasonalFlyout,
  navSeasonalFlyout => navSeasonalFlyout.config,
);

export const selectNavSeasonalFlyoutLoading = createSelector(
  selectNavSeasonalFlyout,
  navSeasonalFlyout => navSeasonalFlyout.isLoading,
);

export const selectNavSeasonalFlyoutIsLoaded = createSelector(
  selectNavSeasonalFlyout,
  navSeasonalFlyout => navSeasonalFlyout.isLoaded,
);

export const selectNavSeasonalFlyoutPlacements = createSelector(
  selectNavSeasonalFlyout,
  navSeasonalFlyout => navSeasonalFlyout.placements,
);
