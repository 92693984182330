import {
  MEMBER_DELETE_INSTORE_PAYMENT_CARD,
  MEMBER_DELETE_INSTORE_PAYMENT_CARD_FAILURE,
  MEMBER_DELETE_INSTORE_PAYMENT_CARD_SUCCESS,
  MEMBER_GET_INSTORE_PAYMENT_CARDS,
  MEMBER_GET_INSTORE_PAYMENT_CARDS_FAILURE,
  MEMBER_GET_INSTORE_PAYMENT_CARDS_SUCCESS,
} from 'core/actions/member';
import {
  INSTORE_MANAGE_CARDS_MODAL_CLEAR,
  INSTORE_MANAGE_CARDS_MODAL_TRIGGER,
} from '../actions/types';

const initialState = {};

const instoreManageCardsModal = (state = initialState, action) => {
  switch (action.type) {
    case INSTORE_MANAGE_CARDS_MODAL_TRIGGER: {
      return { ...state };
    }
    case INSTORE_MANAGE_CARDS_MODAL_CLEAR: {
      return initialState;
    }
    case MEMBER_GET_INSTORE_PAYMENT_CARDS: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        paymentCards: [],
      };
    }
    case MEMBER_GET_INSTORE_PAYMENT_CARDS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        paymentCards: action.payload.response,
      };
    }

    case MEMBER_GET_INSTORE_PAYMENT_CARDS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        paymentCards: action.payload.response,
        loadFailureMessage: action.payload.name,
      };
    }

    case MEMBER_DELETE_INSTORE_PAYMENT_CARD: {
      return {
        ...state,
        cardDeleted: action.data,
        isDeleted: false,
      };
    }

    case MEMBER_DELETE_INSTORE_PAYMENT_CARD_SUCCESS: {
      return {
        ...state,
        isDeleted: true,
      };
    }

    case MEMBER_DELETE_INSTORE_PAYMENT_CARD_FAILURE: {
      return {
        ...state,
        isDeleted: false,
      };
    }

    default:
      return state;
  }
};

export default instoreManageCardsModal;
