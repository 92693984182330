import { createSelector } from 'reselect';

export const selectMediaQueries = state => state.mediaQueries;
export const selectActiveMQ =
  createSelector(selectMediaQueries, mediaQueries => mediaQueries.activeMQ);
export const selectActiveDeviceMQ =
  createSelector(selectMediaQueries, mediaQueries => mediaQueries.activeDeviceMQ);
export const selectWidthMQ =
  createSelector(selectMediaQueries, mediaQueries => mediaQueries.width);
export const selectIsMobileMQ =
  createSelector(selectMediaQueries, mediaQueries => mediaQueries.isMobileMQ);
export const selectIsTabletMQ =
  createSelector(selectMediaQueries, mediaQueries => mediaQueries.isTabletMQ);
export const selectIsDesktopMQ =
  createSelector(selectMediaQueries, mediaQueries => mediaQueries.isDesktopMQ);
export const selectIsMobileOrTabletMQ =
  createSelector(selectMediaQueries, mediaQueries => mediaQueries.isMobileOrTabletMQ);
export const selectIsTabletOrDesktoMQ =
  createSelector(selectMediaQueries, mediaQueries => mediaQueries.isTabletOrDesktopMQ);
