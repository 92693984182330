import React from 'react';
import FavoriteIcon from 'core/modules/FavoriteIcon/FavoriteIcon';
import MerchantRebate from 'core/modules/MerchantRebate/MerchantRebate';

export function SynonymsMatches({
  localConfig, synonymsMatches, keywords, localMerchantClickHandler, getTitle,
}) {
  const { showFavoriteIcon, directMerchantName } = localConfig;

  if (synonymsMatches.length === 0) {
    return null;
  }

  return (
    <div className="mn_quickSearchItemsWrapper">
      {synonymsMatches.map((match) => {
        const { merchant } = match;
        const {
          id, name, href, rebate, showRebate, isDeactivated, isDirect,
        } = merchant;

        return (
          <div className="mn_quickSearchItem" key={`${name}-online`}>
            {showFavoriteIcon && <FavoriteIcon merchantId={id} merchantName={name} />}
            <a
              href={`${href}&keywords=${keywords}`}
              key={`${name}-online`}
              className="mn_quickSearchMatch mn_onlineMatch"
              data-merchant-id={id}
              data-merchant-name={name}
              data-merchant-type="online"
              onClick={localMerchantClickHandler}
              title={getTitle(name)}
            >
              <div className="mn_quickSearchContent">
                <span className="mn_matchText" key={id}>{name}</span>
                <span className="mn_dash">&nbsp;-&nbsp;</span>
                {(showRebate || isDeactivated) && <MerchantRebate {...rebate} />}
              </div>
              {isDirect && <span className="mn_directMerchantName">{directMerchantName}</span>}
            </a>
          </div>
        );
      })}
    </div>
  );
}

export default SynonymsMatches;
