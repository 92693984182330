import { RELATED_STORES_GET_SUCCESS, RELATED_STORES_GET_FAILURE } from '../actions/types';
import relatedStoresConfig from '../configs';

const initialState = {
  config: relatedStoresConfig,
  defaults: {
    isLoaded: false,
    isLoading: false,
    merchants: [],
  },
};

const relatedStores = (state = initialState, action) => {
  const namespace = action?.options?.namespace || action?.context?.options?.namespace || 'default';
  const { defaults } = state;
  const { [namespace]: namespaceState = defaults } = state;

  switch (action.type) {
    case RELATED_STORES_GET_SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        [`namespace-${namespace}`]: {
          ...namespaceState,
          merchants: response,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case RELATED_STORES_GET_FAILURE: {
      return {
        ...state,
        [`namespace-${namespace}`]: {
          ...namespaceState,
          merchants: [],
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === RELATED_STORES_GET_SUCCESS) {
        return {
          ...state,
          [`namespace-${namespace}`]: {
            ...namespaceState,
            isLoading: true,
          },
        };
      }

      return state;
    }
  }
};

export default relatedStores;
