export function addCommas(num) {
  const numberToReturn = typeof num !== 'string' ? num.toString() : num;
  return numberToReturn.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function stripNonDecimals(num) {
  const numberToReturn = typeof num !== 'string' ? num.toString() : num;
  return numberToReturn.replace(/[^0-9]/g, '');
}

export function convertDollarAmountToNumber(dollarAmount) {
  return parseInt(dollarAmount.replace('$', ''), 10);
}

export function addTwoDecimalsIfNeeded(value) {
  const number = typeof value === 'string' ? parseInt(value, 10) : value;
  return number % 1 === 0 ? number.toString() : number.toFixed(2);
}
