/*
  CLPL-18995. United global email opt-out confirmation page uses a specific org-level language
*/

import React from 'react';
import ContentWrap from 'core/modules/Partials/ContentWrap/ContentWrap';
import SectionTitle from 'core/modules/SectionTitle/SectionTitle';

function Confirmed({ config, data }) {
  const {
    profileLoginUrl, streamTitle, emailDealOfTheWeekTitle, emailMemberNewsTitle, emailSpecialOffersTitle, stream,
  } = data;
  const { getConfirmedText } = config;
  const confirmedText = getConfirmedText(data);
  const isGlobal = stream === 'global';

  if (!isGlobal) {
    // Continue using default config text for non-global unsubscribe
    return (
      <div className="mn_emailUnsubscribe">
        <ContentWrap>{confirmedText}</ContentWrap>
      </div>
    );
  }

  return (
    <div className="mn_emailUnsubscribe">
      <ContentWrap>
        <SectionTitle title="Thank you!" />
        <p>
          You've been opted out of receiving {emailDealOfTheWeekTitle}, {emailMemberNewsTitle} &{' '}
          {emailSpecialOffersTitle} marketing emails. Please allow up to 10 business days for unsubscribe
          requests to be processed.
        </p>
        <p>
          If you just joined {streamTitle}, you may receive additional new member onboarding emails. You may also
          log in at any time to manage your <a href={profileLoginUrl}>communication preferences</a>.
        </p>
      </ContentWrap>
    </div>
  );
}

export default Confirmed;
