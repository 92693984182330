import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { marketingBanner as orgConfig } from 'org/configs/modules.config';
import {
  MARKETING_BANNER_MOBILE_GET_SUCCESS, MARKETING_BANNER_MOBILE_GET_FAILURE,
  MARKETING_BANNER_DESKTOP_GET_SUCCESS, MARKETING_BANNER_DESKTOP_GET_FAILURE,
} from '../actions/types';

const defaultConfig = {
  defaults: {
    shouldUseOnlyMobileBanner: false,
    shouldUseOnlyDesktopBanner: false,
    mobileBannerConfig: {
      api: {
        params: {
          content_group_id: 1030,
          content_type_id: 40,
          limit: 1,
          fields: ['id', 'assets', 'clickUrl'],
        },
        options: {
          success: MARKETING_BANNER_MOBILE_GET_SUCCESS,
          failure: MARKETING_BANNER_MOBILE_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
      },
    },
    desktopBannerConfig: {
      api: {
        params: {
          content_group_id: 1028,
          content_type_id: 40,
          limit: 1,
          fields: ['id', 'assets', 'clickUrl'],
        },
        options: {
          success: MARKETING_BANNER_DESKTOP_GET_SUCCESS,
          failure: MARKETING_BANNER_DESKTOP_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
