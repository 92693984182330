import {
  CLICK_HISTORY_TAB_RECORDS_GET_FAILURE, CLICK_HISTORY_TAB_RECORDS_GET_SUCCESS,
} from '../actions/types';

const initialState = {
  isLoading: false,
  isLoaded: false,
  data: [],
};

const handleResponse = (payload) => {
  const updatedResponse = payload.response.map((item) => {
    const updatedItem = {
      ...item,
      order: item?.orders[0] || {},
    };

    delete updatedItem.orders;
    return updatedItem;
  });

  return {
    metadata: payload.metadata,
    response: updatedResponse,
  };
};

const records = (state = initialState, action) => {
  switch (action.type) {
    case CLICK_HISTORY_TAB_RECORDS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: handleResponse(action.payload),
      };
    }
    case CLICK_HISTORY_TAB_RECORDS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: {},
      };
    }
    default: {
      const { options = {} } = action;

      if (options.success === CLICK_HISTORY_TAB_RECORDS_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};

export default records;
