import 'core/utils/publicPath';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import RoutesWrap from 'core/routes';

import { Provider } from 'react-redux';
import { isClickLink } from 'core/utils/helpers/url';
import { addIsTouchDeviceClassName } from 'core/utils/helpers/isTouchDevice';
import ccpaDeletedMemberRedirect from 'core/utils/ccpaDeletedMemberRedirect';
import init from 'core/utils/interstitial';

import store from './store';
import './styles/style.scss';

import Wrapper from './modules/Wrapper/Wrapper';
import { routesConfig } from './routes';

addIsTouchDeviceClassName();
ccpaDeletedMemberRedirect();

const render = () => {
  const isClick = isClickLink(window.location.href);

  if (isClick) {
    init();
    return;
  }

  const container = document.getElementById('app');
  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <Wrapper>
        <BrowserRouter>
          <RoutesWrap orgRoutesConfig={routesConfig} />
        </BrowserRouter>
      </Wrapper>
    </Provider>,
  );
};

render();
