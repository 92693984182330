import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { favoriteStores as orgConfig } from 'org/configs/modules.config';
import { FAVORITE_STORES_GET_SUCCESS, FAVORITE_STORES_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        fields: [
          'logoUrls',
          'type',
          'name',
          'id',
          'clickUrl',
          'showRebate',
          'rebate',
        ],
      },
      options: {
        success: FAVORITE_STORES_GET_SUCCESS,
        failure: FAVORITE_STORES_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    getSecTitle: (isMobileMQ) => (isMobileMQ ? 'Shop your favorites' : 'Shop your favorite stores'),
    tagHelper: () => {},
    slick: {
      slidesToShow: 4,
      slidesToScroll: 1,
      centerMode: false,
      arrows: true,
      dots: false,
      infinite: false,
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            arrows: false,
            dots: true,
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            variableWidth: true,
            arrows: false,
            dots: false,
            slidesToShow: 1,
          },
        },
      ],
    },
    useSliderCounterOnMediaQueriesOnly: ['XS', 'S'],
    manageFavoritesLanguage: 'Manage your favorite stores',
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
