import { getParamsFromSearch } from 'core/utils/helpers/getParamsFromSearch';
import { HYBRID_SEARCH_RESULTS_VIEW } from '../actions/types';

export const hybridSearchResultsGaActionEvents = {
  [HYBRID_SEARCH_RESULTS_VIEW]: () => {
    const { keywords } = getParamsFromSearch(window.location.search);
    return {
      category: 'Hybrid Search Results Page',
      action: 'View',
      label: keywords,
      value: '1',
    };
  },
};
