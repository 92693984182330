import onboardingButtonConfig from '../configs';

/** @type {{config: object}} */
const initialState = {
  config: onboardingButtonConfig,
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const button = (state = initialState) => state;

export default button;
