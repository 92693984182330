import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { navPopularStores as orgConfig } from 'org/configs/modules.config';
import { NAV_POPULAR_STORES_GET_SUCCESS, NAV_POPULAR_STORES_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: NAV_POPULAR_STORES_GET_SUCCESS,
        failure: NAV_POPULAR_STORES_GET_FAILURE,
      },
      params: {
        personalized: 'rec',
        limit: 14,
        sort_by: 'random',
        fields: [
          'id',
          'name',
        ].join(','),
      },
    },
    secTitle: 'Shop popular stores',
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
