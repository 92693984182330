import {
  NAV_POPULAR_STORES_GET_SUCCESS,
  NAV_POPULAR_STORES_GET_FAILURE,
} from '../actions/types';

import navPopularStoresConfig from '../configs';

const initialState = {
  config: navPopularStoresConfig,
  isLoaded: false,
  isLoading: false,
  merchants: [],
};

const navPopularStores = (state = initialState, action) => {
  switch (action.type) {
    case NAV_POPULAR_STORES_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        merchants: action.payload.response,
      };
    }
    case NAV_POPULAR_STORES_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        merchants: [],
      };
    }
    default: {
      const { options = {} } = action;

      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === NAV_POPULAR_STORES_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};

export default navPopularStores;
