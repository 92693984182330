import { HOME_SIGN_IN_NAG_OPEN_LARGE_WITH_ANIMATION } from 'org/modules/HomeSignInNag/actions/types';

export const signInNagLargeGaActionEvents = {
  [HOME_SIGN_IN_NAG_OPEN_LARGE_WITH_ANIMATION]: () => ({
    category: 'Big nag',
    action: 'Display',
    label: 'none',
  }),
};

export const signInNagLargeClickAndHoverEvents = [
  {
    selector: '.mn_largeNagSignIn button.mn_signInLaterButton',
    click: {
      category: 'Big nag',
      action: 'Tap "No thanks"',
    },
    label: 'none',
  },
  {
    selector: '.mn_signInNagLarge button.mn_closeSignInNagX',
    click: {
      category: 'Big nag',
      action: 'Tap "X"',
    },
    label: 'none',
  },
  {
    selector: '.mn_signInNagLarge button.mn_hiwWatchButton ',
    click: {
      category: 'Big nag',
      action: 'Tap "watch how it works video"',
    },
    label: 'none',
  },
];
