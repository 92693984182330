import {
  HYBRYD_QUICK_SEARCH_GSP_PRODUCTS_CLEAR, HYBRYD_QUICK_SEARCH_GSP_MERCHANTS_CLEAR,
} from './types';

export const clearGspProducts = () => ({
  type: HYBRYD_QUICK_SEARCH_GSP_PRODUCTS_CLEAR,
});

export const clearGspMerchants = () => ({
  type: HYBRYD_QUICK_SEARCH_GSP_MERCHANTS_CLEAR,
});
