import {
  select, takeEvery, put, call,
} from 'redux-saga/effects';
import { isErrorResponse } from 'core/utils/apis/base';
import gsp from 'core/utils/apis/gsp';
import * as actions from 'core/actions/gsp';
import { generateFailureAction, generateSuccessAction } from 'core/actions/apis';
import gspRequestor from 'core/utils/apis/gspRequestor';

function* makeServerRequest({ caller, requestArgList, defaultValue }, action = {}) {
  const state = yield select(state => state);
  const response = yield call([gspRequestor, gspRequestor.getAPIResponse], {
    caller,
    requestArgList,
    shouldParseResponse: false,
    action,
    state,
  });
  if (!isErrorResponse(response)) {
    yield put(generateSuccessAction(action, response));
  } else {
    yield put(generateFailureAction(action, response, defaultValue));
  }
}

const takeEveryGsp = (actionType, caller, requestArgList, defaultValue) =>
  takeEvery(actionType, makeServerRequest, { caller: caller.bind(gsp), requestArgList, defaultValue });

/**
 * Watch for any an all ocapi requests
 */
export default function* gspSaga() {
  yield takeEveryGsp(actions.GSP_GET_STORES, gsp.getGSPMerchants, ['url']);
  yield takeEveryGsp(actions.GSP_GET_PRODUCTS, gsp.getGSPProducts, ['url']);
}
