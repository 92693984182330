import React from 'react';

export const renderDirectMerchantDescription = () => (
  <>
    We work closely with our MileagePlus partners to surface the best savings
    and miles-earning opportunities for our customers.
    MileagePlus Shopping doesn't handle the fulfillment of earned miles for these types of stores.
    Miles earned with this partner will appear in your MileagePlus account
    directly and do not count towards MileagePlus Shopping bonus promotions.
  </>
);
