export const becauseYouShoppedClickAndHoverEvents = [
  {
    selector: '.mn_becauseYouShopped .mn_merchantWithOfferCountTile',
    click: {
      category: 'Because you shopped at',
      action: 'Tap merchant',
    },
    label: element => element.dataset.merchantName,
  },
];
