import { requestActionGeneratorByType } from './apis';

export const GSP_ROOT_ACTION_PATH = 'core/apis/gsp';

// action names
export const GSP_GET_STORES = `${GSP_ROOT_ACTION_PATH}/stores/GET`;
export const GSP_GET_PRODUCTS = `${GSP_ROOT_ACTION_PATH}/products/GET`;

// exported methods
export const generateGetGspStores = requestActionGeneratorByType(GSP_GET_STORES);
export const generateGetGspProducts = requestActionGeneratorByType(GSP_GET_PRODUCTS);

export const fetchGspStores = (url, params = {}, options = {}) => (
  generateGetGspStores(params, options, { url })
);

export const fetchGspProducts = (url, params = {}, options = {}) => (
  generateGetGspProducts(params, options, { url })
);
