import React from 'react';
import { useSelector } from 'react-redux';
import BackToTopBtn from 'core/modules/BackToTopBtn/BackToTopBtn';
import { addExternalAttributesIfNeeded } from 'core/utils/helpers/externalLink';
import { setHasPreviouslyLoggedIn } from 'core/utils/helpers/hasPreviouslyLoggedIn';
import { ShowOnDevice } from 'core/modules/MediaQueries/MediaQueries';

import {
  selectSiteUrl,
  selectTermsAndConditionsURL,
  selectToolbarConfig,
  selectPrivacyPolicyUrl,
  selectPrivacyLinkEnabled,
} from 'core/selectors/app';
import { selectUserIsIdentified } from 'core/selectors/user';
import { selectIsMobileMQ } from 'core/modules/MediaQueries/selectors';

import './Footer.scss';

function Footer() {
  const siteUrl = useSelector(selectSiteUrl);
  const userIsIdentified = useSelector(selectUserIsIdentified);
  const termsAndConditionsURL = useSelector(selectTermsAndConditionsURL);
  const privacyPolicyUrl = useSelector(selectPrivacyPolicyUrl);
  const enablePrivacyNoticeLink = useSelector(selectPrivacyLinkEnabled);
  const isMobileMQ = useSelector(selectIsMobileMQ);
  const { enableButtonMarketing } = useSelector(selectToolbarConfig) || {};

  // Set hasPreviouslyLoggedIn flag in Local Storage if user is loggedin
  userIsIdentified && setHasPreviouslyLoggedIn();

  return (
    <div className={`mn_brandFooter mn_bottomStickyPadding ${!userIsIdentified ? 'mn_isAnonymous' : ''}`}>
      {(userIsIdentified || isMobileMQ) &&
        <BackToTopBtn
          additionalClassName={userIsIdentified ? '' : 'mn_isAnonymous'}
          content={<i className="mn_unitedIconArrowUp" aria-hidden="true" />}
        />
      }
      <div className="mn_brandFooterInnerWrap">
        <div className="mn_contentSection">
          <div className="mn_footerNavigation">
            <ul>
              <li><a href={`${siteUrl}/hiw____.htm`}>How it works</a></li>
              <li><a href={`${siteUrl}/faq____.htm`}>FAQs</a></li>
              <li>
                <a
                  href="http://www.MileagePlus.com/"
                  target="_blank"
                  title="Opens in new window"
                  rel="external"
                >
                  united.com/mileageplus <i className="mn_unitedIconExternalLink" aria-hidden="true" />
                </a>
              </li>
              {enableButtonMarketing &&
                <ShowOnDevice desktop>
                  <li><a href={`${siteUrl}/button`}>Get the button</a></li>
                </ShowOnDevice>
              }
              <li><a href={`${siteUrl}/contact____.htm`}>Contact us</a></li>
              <li>
                <a
                  href={`${privacyPolicyUrl}`}
                  {...addExternalAttributesIfNeeded(privacyPolicyUrl, siteUrl)}
                >
                  Privacy policy
                </a>
              </li>
              <li>
                <a
                  href={`${termsAndConditionsURL}`}
                  {...addExternalAttributesIfNeeded(termsAndConditionsURL, siteUrl)}
                >
                  Terms
                </a>
              </li>
              {enablePrivacyNoticeLink && (
                <li>
                  <a href={`${siteUrl}/p/privacy-notice.htm`}>
                    MileagePlus<sup>&reg;</sup> Shopping terms and privacy notice
                  </a>
                </li>
              )}
            </ul>
          </div>
          <p className="mn_footerDisclaimer">
            MileagePlus: Miles accrued, awards, and benefits issued are subject to the rules of the
            United MileagePlus<sup>&reg;</sup> program. For details, see&nbsp;
            <a
              href="http://www.united.com"
              target="_blank"
              title="Opens in new window"
              rel="external"
              className="mn_externalLink"
            >
              www.united.com <i className="mn_unitedIconExternalLink" aria-hidden="true" />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
