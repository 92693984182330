import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { letterboxTOF as orgConfig } from 'org/configs/modules.config';
import {
  LETTERBOX_TOF_MERCHANT_GET_SUCCESS,
  LETTERBOX_TOF_MERCHANT_GET_FAILURE,
  LETTERBOX_TOF_MARKETING_GET_SUCCESS,
  LETTERBOX_TOF_MARKETING_GET_FAILURE,
} from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    showRebateTierName: true,
    useSliderCounterOnMediaQueriesOnly: [],
    shouldClientUseExtraPillTileView: true,
    shouldShowPlayPauseButton: false,
    dateFormat: 'MMM DD, YYYY',
    expiresPrefix: 'Valid through ',
    labelText: '',
    getCouponCodeLabelText: () => 'Use code: ',
    tagHelper: () => {},
    rebateOptions: {
      showAdditionalElevationPrefix: false,
      showRebateInline: true,
      hideElevationCurrency: true,
    },
    slick: {
      arrows: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: true,
      centerMode: true,
      swipeToSlide: true,
      centerPadding: '0px',
      dots: true,
      infinite: true,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 610,
          settings: {
            variableWidth: true,
            centerMode: true,
            centerPadding: '10px',
            dots: false,
            arrows: false,
            focusOnSelect: false,
          },
        },
        {
          breakpoint: 440,
          settings: {
            variableWidth: false,
            centerMode: true,
            centerPadding: '20px',
            dots: false,
            arrows: false,
            focusOnSelect: false,
          },
        },
      ],
    },
    marketingContent: {
      isEnabled: false,
      api: {
        params: {
          content_group_id: 15142,
          content_type_id: [89, 90].join(','),
          sort_by: 'random',
          fields: [
            'id',
            'dealId',
            'assets',
            'clickUrl',
            'couponCode',
            'merchant',
            'disclaimer',
            'rebate',
            'contentItemTypeId',
            'highlightedMerchantTierId',
          ],
        },
        options: {
          success: LETTERBOX_TOF_MARKETING_GET_SUCCESS,
          failure: LETTERBOX_TOF_MARKETING_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
      },
    },
    merchantContent: {
      api: {
        params: {
          content_group_id: 6454,
          content_type_id: 80,
          limit: 15,
          sort_by: 'random',
          fields: [
            'id',
            'dealId',
            'assets',
            'clickUrl',
            'couponCode',
            'merchant',
            'disclaimer',
            'rebate',
            'isOngoing',
            'stopDatetime',
            'highlightedMerchantTierId',
          ],
        },
        options: {
          success: LETTERBOX_TOF_MERCHANT_GET_SUCCESS,
          failure: LETTERBOX_TOF_MERCHANT_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
      },
    },
  },
  h: {
    useSliderCounterOnMediaQueriesOnly: ['XS', 'S'],
    marketingContent: {
      isEnabled: true,
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
