import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchFlyoutCategories } from 'core/actions/ocapi';

import {
  selectNavSeasonalFlyoutConfig,
  selectNavCategoriesIsLoading,
  selectNavCategoriesIsLoaded,
  selectNavCategoriesPlacements,
} from '../selectors';

export const useGetCategories = () => {
  const dispatch = useDispatch();
  const categories = useSelector(selectNavCategoriesPlacements);
  const config = useSelector(selectNavSeasonalFlyoutConfig);
  const isLoaded = useSelector(selectNavCategoriesIsLoaded);
  const isLoading = useSelector(selectNavCategoriesIsLoading);

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      const { api: apiConfig } = config;
      const { params, options } = apiConfig;

      dispatch(fetchFlyoutCategories(params, options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    categories,
  };
};
