import getPageName from 'core/utils/getPageName';
import { QUICK_LINKS_TRAY_VIEW } from '../actions/types';

const getCustomPageName = () => {
  const pageName = getPageName();
  return pageName === 'h' ? 'mhp_mobile' : pageName;
};

export const quickLinksTrayGaActionEvents = {
  [QUICK_LINKS_TRAY_VIEW]: () => ({
    category: 'Mobile Nav Tray',
    action: 'Display',
    label: getCustomPageName(),
  }),
};

export const quickLinksTrayClickAndHoverEvents = [
  {
    selector: '.mn_quickLinks .mn_quickLinksTrayNav a',
    click: {
      category: 'Mobile Nav Tray',
      action: 'Click',
    },
    label: (element) => {
      const { name } = element.dataset;
      return name;
    },
  },
  {
    selector: '.mn_quickLinks .mn_quickLinksTrayToggle',
    click: {
      category: 'Mobile Nav Tray',
      action: 'Open',
    },
    label: getCustomPageName(),
  },
  {
    selector: '.mn_quickLinks .mn_searchIcon',
    click: {
      category: 'Mobile Nav Tray',
      action: 'Quick Links Search Click',
    },
    label: getCustomPageName(),
  },
];
