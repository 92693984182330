import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import OnlineMatches from './partials/OnlineMatches';
import InstoreMatches from './partials/InstoreMatches';
import ProductGspMatches from './partials/ProductGspMatches';
import NoMatches from './partials/NoMatches';
import RecentOrSimilarStoresMatches from './partials/RecentOrSimilarStoresMatches';

import {
  selectQuickSearchGspProductsMatches, selectInstoreQuickSearchMatches,
  selectOnlineQuickSearchMatches, selectQuickSearchMerchantsIsLoaded,
  selectQuickSearchGspProductsisLoaded,
} from '../selectors';

export function QuickSearchMatchesGsp({
  config, keywords, quickSearchResultsRef, merchantClickHandler, isPopUpView,
}) {
  const isMerchantsLoaded = useSelector(selectQuickSearchMerchantsIsLoaded);
  const onlineMatches = useSelector(state => selectOnlineQuickSearchMatches(state, keywords, config));
  const instoreMatches = useSelector(state => (selectInstoreQuickSearchMatches(state, keywords, config)));
  const productGspMatches = useSelector(selectQuickSearchGspProductsMatches);
  const isproductGspLoaded = useSelector(selectQuickSearchGspProductsisLoaded);

  const hasMatchesToShow = !isEmpty(onlineMatches) || !isEmpty(instoreMatches) || !isEmpty(productGspMatches);
  const isMinChars = keywords.length >= config.minChars;

  if (!hasMatchesToShow) {
    return (
      <>
        { isMerchantsLoaded && isMinChars && isproductGspLoaded &&
          <div className="mn_quickSearchResults mn_quickSearchResultsGsp">
            <NoMatches text={`We're sorry we couldn't find any results for "${keywords}".`} />
          </div>}
        { productGspMatches && <RecentOrSimilarStoresMatches config={config} keywords={keywords} /> }
      </>
    );
  }

  return (
    <>
      <div className="mn_quickSearchResults mn_quickSearchResultsGsp" ref={quickSearchResultsRef}>
        <OnlineMatches
          config={config}
          onlineMatches={onlineMatches}
          instoreMatches={instoreMatches}
          keywords={keywords}
          merchantClickHandler={merchantClickHandler}
          isPopUpView={isPopUpView}
        />
        <InstoreMatches
          config={config}
          instoreMatches={instoreMatches}
          merchantClickHandler={merchantClickHandler}
          isPopUpView={isPopUpView}
        />
        <ProductGspMatches
          keywords={keywords}
          productGspMatches={productGspMatches}
          isPopUpView={isPopUpView}
        />
      </div>
      <RecentOrSimilarStoresMatches
        config={config}
        keywords={keywords}
        productGspMatches={productGspMatches}
      />
    </>
  );
}

export default (props) => <ComponentErrorBoundary><QuickSearchMatchesGsp {...props} /></ComponentErrorBoundary>;
