import Cookies from 'js-cookie';
import { dyEvents } from 'core/utils/analytics/dyAnalytics';
import { trackInsiderEmailOptinEvent } from 'core/utils/analytics/insiderAnalytics';

const cookieName = 'mn_email_popup';

/**
 * Get the decoded value of the email popup cookie
 * @return {object|null}
 */
export const getEmailPopupCookie = () => {
  const emailPopupCookieRaw = Cookies.get(cookieName);

  if (emailPopupCookieRaw) {
    try {
      return JSON.parse(emailPopupCookieRaw);
    } catch (e) {
      // something went wrong
    }
  }

  return null;
};

/**
 * Update the value of the email popup cookie
 * @param {object} value
 * @param {string} domain
 */
export const setEmailPopupCookie = ({ emailPopupCookie = {}, cookieDomain, isHttps }) => {
  Cookies.set(cookieName, JSON.stringify(emailPopupCookie), {
    path: '/',
    domain: cookieDomain,
    secure: isHttps,
  });

  dyEvents.sendEvent(dyEvents.sendEmailOptinEvent, { emailPopupCookie });
  trackInsiderEmailOptinEvent(emailPopupCookie);
};
