import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { quickSearch as orgConfig } from 'org/configs/modules.config';
import { CACHE_STORAGE } from 'core/utils/cache/base';
import {
  QUICK_SEARCH_GET_SUCCESS, QUICK_SEARCH_GET_FAILURE,
  QUICK_SEARCH_GSP_SUGGESTIONS_GET_SUCCESS, QUICK_SEARCH_GSP_SUGGESTIONS_GET_FAILURE,
} from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    minChars: 2,
    maxAnchorFront: 3,
    maxMatchesOnTabletAndDesktop: 10,
    maxMatchesOnMobile: 5,
    showFavoriteIcon: true,
    directMerchantName: '',
    isPremierRebate: false,
    quickSearchMerchantsConfig: {
      api: {
        params: {
          limit: 2000,
          sort_by: 'name',
          fields: [
            'logoUrls._88x31',
            'type',
            'name',
            'id',
            'clickUrl',
            'showRebate',
            'rebate',
            'offers',
            'synonyms',
            'relatedActiveMerchants',
            'isDirect',
            'specialConsiderationText',
            'flags.isTravelMerchant',
            'flags.storesPageApproved',
          ],
        },
        options: {
          success: QUICK_SEARCH_GET_SUCCESS,
          failure: QUICK_SEARCH_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true, type: CACHE_STORAGE },
        },
      },
    },
    quickSearchGspSuggestionsConfig: {
      api: {
        params: {
          sid: 'us_cartera_001',
          sortby: 'relevancy',
          column: 'storeId,storeName',
          hits: 20,
        },
        options: {
          success: QUICK_SEARCH_GSP_SUGGESTIONS_GET_SUCCESS,
          failure: QUICK_SEARCH_GSP_SUGGESTIONS_GET_FAILURE,
          cache: { isEnabled: false, isPublishedData: false },
        },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
