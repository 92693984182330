import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { favoritedStores as orgConfig } from 'org/configs/modules.config';
import { FAVORITED_STORES_GET_SUCCESS, FAVORITED_STORES_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        limit: 4,
        personalized: 'fav',
      },
      options: {
        success: FAVORITED_STORES_GET_SUCCESS,
        failure: FAVORITED_STORES_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
