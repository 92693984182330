import merge from 'lodash/merge';
import {
  LETTERBOX_TOF_MERCHANT_GET_SUCCESS,
  LETTERBOX_TOF_MERCHANT_GET_FAILURE,
  LETTERBOX_TOF_MARKETING_GET_SUCCESS,
  LETTERBOX_TOF_MARKETING_GET_FAILURE,
} from '../actions/types';
import letterboxTOFConfig from '../configs';

const { marketingContent, merchantContent } = letterboxTOFConfig;

const initialState = {
  config: letterboxTOFConfig,
  marketingContent: {
    config: marketingContent, placements: [], isLoading: false, isLoaded: false,
  },
  merchantContent: {
    config: merchantContent, placements: [], isLoading: false, isLoaded: false,
  },
};

// CLPL-5039: content item type 90 may or may not be returned in the group, if it is returned
// we will check to see if there is a matching content item type 89 item returned with the same dealId.
// If there is a matching item we will display the image from content item type 90
// on tablet/desktop and we will display the image from content item type 89 on mobile.
// If there is no matching 89 we will simply display 90 across all screen sizes.
const merchantIndependentParser = (placements = []) => {
  const MARKETING_MOBILE = 89;
  const MARKETING_DESKTOP = 90;

  return placements.reduce((result, placement) => {
    if (placement.contentItemTypeId === MARKETING_DESKTOP) {
      const sameOfferWithMobileImage = placements.filter(offer => (
        offer.contentItemTypeId === MARKETING_MOBILE && placement.dealId === offer.dealId
      ))[0];
      if (sameOfferWithMobileImage) {
        // eslint-disable-next-line no-param-reassign
        placement.assets._420x295 = sameOfferWithMobileImage.assets._420x295;
      }

      result.push(placement);
    }

    return result;
  }, []);
};

const letterboxTOF = (state = initialState, action) => {
  switch (action.type) {
    case LETTERBOX_TOF_MARKETING_GET_SUCCESS: {
      return merge({}, state, {
        marketingContent: {
          isLoading: false,
          isLoaded: true,
          placements: merchantIndependentParser(action.payload.response),
        },
      });
    }
    case LETTERBOX_TOF_MARKETING_GET_FAILURE: {
      return merge({}, state, {
        marketingContent: {
          isLoading: false,
          isLoaded: true,
          placements: [],
        },
      });
    }
    case LETTERBOX_TOF_MERCHANT_GET_SUCCESS: {
      return merge({}, state, {
        merchantContent: {
          isLoading: false,
          isLoaded: true,
          placements: action.payload.response,
        },
      });
    }
    case LETTERBOX_TOF_MERCHANT_GET_FAILURE: {
      return merge({}, state, {
        merchantContent: {
          isLoading: false,
          isLoaded: true,
          placements: [],
        },
      });
    }
    default: {
      // Handle setting isLoading to true for raw OCAPI action
      const { options = {} } = action;
      if (options.success === LETTERBOX_TOF_MARKETING_GET_SUCCESS) {
        return merge({}, state, {
          marketingContent: {
            isLoading: true,
          },
        });
      }
      if (options.success === LETTERBOX_TOF_MERCHANT_GET_SUCCESS) {
        return merge({}, state, {
          merchantContent: {
            isLoading: true,
          },
        });
      }
    }
  }

  return state;
};

export default letterboxTOF;
