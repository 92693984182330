import {
  select, takeEvery, takeLatest, put, call,
} from 'redux-saga/effects';
import merge from 'lodash/merge';
import { isErrorResponse } from 'core/utils/apis/base';
import memberApi, { MemberApi } from 'core/utils/apis/member';
import { selectMemberConfig } from 'core/selectors/services';
import { selectUserIsAuthenticated } from 'core/selectors/user';
import { selectIsFirstFavorite } from 'core/selectors/favorites';
import { selectCookieDomain, selectIsHttps, selectIsRavenCookied } from 'core/selectors/env';
import { selectBrandId } from 'core/selectors/app';
import {
  generateFailureAction,
  generateSuccessAction,
} from 'core/actions/apis';
import { showModal } from 'core/modules/Modal/actions';
import { MODAL_TYPE_FIRST_FAVORITE } from 'core/modules/Modal/types';
import { invalidateFavoritesCache, updateFavoriteCache, dropFavoriteCache } from 'core/utils/favorites/favoritesCache';
import * as actions from 'core/actions/member';
import memberRequestor from 'core/utils/apis/memberRequestor';

function* makeMemberApiRequest(
  { caller, requestArgList, defaultValue }, action = {},
) {
  const state = yield select(state => state);
  const response = yield call([memberRequestor, memberRequestor.getAPIResponse],
    {
      caller,
      requestArgList,
      shouldParseResponse: true,
      action,
      state,
    });

  if (!isErrorResponse(response)) {
    yield put(generateSuccessAction(action, response));
  } else {
    // Todo - Autodetect a 498 error and replay the original action after dropping the authorization token
    //        Keep in mind that we need to avoid infinite loops
    yield put(generateFailureAction(action, response, defaultValue));
  }
}

/**
 * @yields {object}
 */
function* triggerFirstFavoriteExperience(action) {
  const isFirstFavorite = yield select(selectIsFirstFavorite);

  // skip First Favorites Popup during Onboarding
  const { forceSkipFirstFavoritesPopup } = action.context.data;

  if (isFirstFavorite && !forceSkipFirstFavoritesPopup) {
    yield put(showModal(MODAL_TYPE_FIRST_FAVORITE));
  }
}

/**
 * @yields {object}
 */
function* updateFavoritesCookie() {
  const cookieDomain = yield select(selectCookieDomain);
  const isHttps = yield select(selectIsHttps);
  invalidateFavoritesCache({ cookieDomain, isHttps });
}

/**
 * @param {string} actionType
 * @param {Function} caller
 * @param {array} requestArgs
 * @param {*} defaultValue
 */
const takeEveryMemberApi = (actionType, caller, requestArgList, defaultValue) => (
  takeEvery(actionType, makeMemberApiRequest, { caller: caller.bind(memberApi), requestArgList, defaultValue })
);

export function* getMemberApiInstance() {
  const config = yield select(selectMemberConfig);
  const brandId = yield select(selectBrandId);
  const isRavenCookied = yield select(selectIsRavenCookied);
  const memberConfig = merge({}, config, {
    headers: {
      ...isRavenCookied && { 'X-MNRaven': 1 },
    },
  });

  memberApi.setParams({ memberConfig, brandId });
}

function* checkIfUserFullyAuthenticated() {
  const userIsInitiallyAuthenticated = yield select(selectUserIsAuthenticated);
  let isAuthenticated;

  // Verification if user is authenticated proceeds in the following order:
  // 1. Check if user initially is not authenticated
  // 2. If not make authorization API request and check if user has authentication AccessToken

  if (!userIsInitiallyAuthenticated) {
    isAuthenticated = false;
  } else {
    const apiAccessToken = yield (memberApi.authorize(MemberApi.memberAuthenticatedScope));
    isAuthenticated = !!apiAccessToken;
  }

  yield put({
    type: actions.MEMBER_AUTHENTICATION_COMPLETE,
    payload: { userIsAuthenticated: isAuthenticated },
  });
}

/**
 * Watch for any an all member requests
 */
export default function* memberSaga() {
  yield getMemberApiInstance();

  yield takeEveryMemberApi(actions.MEMBER_GET_FAVORITES,
    memberApi.getFavorites.bind(memberApi));
  yield takeEveryMemberApi(
    actions.MEMBER_POST_FAVORITES,
    memberApi.addFavoriteById.bind(memberApi),
    ['merchantId'],
    {},
  );
  yield takeEveryMemberApi(
    actions.MEMBER_DELETE_FAVORITES,
    memberApi.deleteFavoriteById.bind(memberApi),
    ['merchantId'],
    {},
  );
  yield takeEveryMemberApi(
    actions.MEMBER_POST_UI_COMPONENT_METADATA,
    memberApi.postUIComponentMetadata.bind(memberApi),
    ['component', 'metadata'],
  );

  yield takeEveryMemberApi(
    actions.MEMBER_POST_INSTORE_CARD_LINK,
    memberApi.postInstoreCardLink.bind(memberApi),
    ['offerId', 'paymentCard'],
  );

  yield takeEveryMemberApi(
    actions.MEMBER_POST_INSTORE_MULTI_OFFER_CARD_LINK,
    memberApi.postInstoreMultiOfferCardLink.bind(memberApi),
    ['paymentCard'],
  );

  yield takeEveryMemberApi(
    actions.MEMBER_GET_INSTORE_PAYMENT_CARDS,
    memberApi.getInstorePaymentCards.bind(memberApi),
    [],
  );

  yield takeEveryMemberApi(
    actions.MEMBER_DELETE_INSTORE_PAYMENT_CARD,
    memberApi.getInstorePaymentDeleteCards.bind(memberApi),
    ['cardId'],
  );

  yield takeEveryMemberApi(
    actions.MEMBER_REGISTER_TOKEN,
    memberApi.postInstoreRegisterToken.bind(memberApi),
    [],
  );

  yield takeEveryMemberApi(
    actions.MEMBER_POST_PHONE_NUMBER,
    memberApi.postInstoreMobileNumber.bind(memberApi),
    ['mobileNumber'],
  );

  yield takeEveryMemberApi(
    actions.MEMBER_SET_ONBOARDING_PROPERTY,
    memberApi.setInstoreAddCardOnbordingProperty.bind(memberApi),
    [],
  );

  // First favorite handler
  yield takeLatest(actions.MEMBER_POST_FAVORITES_SUCCESS, triggerFirstFavoriteExperience);

  yield takeEvery(
    [actions.MEMBER_POST_FAVORITES, actions.MEMBER_DELETE_FAVORITES],
    updateFavoriteCache,
  );

  // Drop cache in case of any issues with the database update
  yield takeEvery(
    [actions.MEMBER_POST_FAVORITES_FAILURE, actions.MEMBER_DELETE_FAVORITES_FAILURE],
    dropFavoriteCache,
  );

  yield takeEvery(
    [actions.MEMBER_POST_FAVORITES, actions.MEMBER_DELETE_FAVORITES],
    updateFavoritesCookie,
  );

  yield takeEvery(actions.MEMBER_AUTHENTICATION_CHECK, checkIfUserFullyAuthenticated);
}
