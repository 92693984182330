import { takeEvery, put } from 'redux-saga/effects';
import { showModal } from 'core/modules/Modal/actions';
import { MODAL_TYPE_HIW_VIDEO } from 'core/modules/Modal/types';

import {
  HIW_VIDEO_MODAL_TRIGGER,
} from '../actions/types';

function* showHiwVideoModal() {
  yield put(showModal(MODAL_TYPE_HIW_VIDEO));
}

export default function* hiwVideoModalSaga() {
  yield takeEvery(HIW_VIDEO_MODAL_TRIGGER, showHiwVideoModal);
}
