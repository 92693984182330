import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { featuredStoresConfig as orgConfig } from 'org/configs/modules.config';

import { FEATURED_STORES_GET_SUCCESS, FEATURED_STORES_GET_FAILURE } from '../actions/types';

export const defaultConfig = {
  defaults: {
    maxMerchants: 5,
    api: {
      params: {
        limit: 20,
        sort_by: 'featured',
      },
      options: {
        success: FEATURED_STORES_GET_SUCCESS,
        failure: FEATURED_STORES_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
  },
};

export const featuredStoresConfig = mergeModuleConfigs(defaultConfig, orgConfig);
