import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { trendingStores as orgConfig } from 'org/configs/modules.config';
import { TRENDING_STORES_GET_SUCCESS, TRENDING_STORES_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    maxMerchants: 12,
    checkShouldShowSliderCounter: (activeMQ, isUserIdentified) => (
      ['XS', 'S'].includes(activeMQ) && isUserIdentified
    ),
    slick: {
      variableWidth: true,
      centerMode: false,
      infinite: false,
      arrows: false,
      dots: true,
      slidesToShow: 4,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            dots: false,
          },
        },
      ],
    },
    slickVhpOverwriting: {
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: true,
          },
        },
      ],
    },
    api: {
      params: {
        with_offers: 1,
        fields: [
          'logoUrls',
          'type',
          'name',
          'id',
          'clickUrl',
          'showRebate',
          'rebate',
          'offers',
        ],
      },
      options: {
        success: TRENDING_STORES_GET_SUCCESS,
        failure: TRENDING_STORES_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
