import React from 'react';
import PropTypes from 'prop-types';
import './ErrorBoundaries.scss';

export default class ComponentErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, error, errorInfo });

    console.log(error, errorInfo);
  }

  render() {
    const { render, children } = this.props;

    if (this.state.hasError) {
      return render ? render(this.state.error, this.state.errorInfo) : null;
    }

    return children;
  }
}

ComponentErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  render: PropTypes.func,
};

ComponentErrorBoundary.defaultProps = {
  render: null,
};
