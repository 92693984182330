import merge from 'lodash/merge';

import { APP_UPDATE } from '../actions/types';

const initialState = {};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const app = (state = initialState, action) => {
  switch (action.type) {
    case APP_UPDATE: {
      return merge({}, state, action.payload);
    }
    default: {
      return state;
    }
  }
};

export default app;
