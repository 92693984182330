import { SUCCESS_SUFFIX, FAILURE_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/infoBar';

export const INFOBAR_DOTW_GET_SUCCESS = `${rootActionPath}DOTW/GET_${SUCCESS_SUFFIX}`;
export const INFOBAR_DOTW_GET_FAILURE = `${rootActionPath}DOTW/GET_${FAILURE_SUFFIX}`;
export const INFOBAR_SALES_GET_SUCCESS = `${rootActionPath}Sales/GET_${SUCCESS_SUFFIX}`;
export const INFOBAR_SALES_GET_FAILURE = `${rootActionPath}Sales/GET_${FAILURE_SUFFIX}`;

export const INFO_BAR_VIEW = `${rootActionPath}Offer/VIEW`;
