import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { profileInstoreTab as orgConfig } from 'org/configs/modules.config';
import { defineRequestStartDate, defineRequestEndDate } from 'core/modules/Profile/utils/defineRequestDate';

import {
  INSTORE_TAB_GET_SUCCESS,
  INSTORE_TAB_GET_FAILURE,
  INSTORE_TAB_MERCHANTS_GET_SUCCESS,
  INSTORE_TAB_MERCHANTS_GET_FAILURE,
} from '../actions/types';

const defaultConfig = {
  defaults: {
    recordsConfig: {
      getInitialLimit: (isMobileMQ) => (isMobileMQ ? 5 : 10),
      api: {
        options: {
          success: INSTORE_TAB_GET_SUCCESS,
          failure: INSTORE_TAB_GET_FAILURE,
        },
        params: {
          // limit value is handled from getInitialLimit function
          offset: 0,
          with_offers: 1,
          start_date: defineRequestStartDate(90),
          end_date: defineRequestEndDate(),
          sort_by: 'instoreTransactionId',
          sort_type: 'desc',
          transaction_state: [
            'CLIENT_POSTED',
            'CLIENT_HARD_REJECT',
            'AUTH_VERIFIED',
            'SCORED',
            'ACCRUAL_PENDING',
            'ACCRUAL_SENT',
          ].join(','),
          transaction_type: [
            'INSTORE_SETTLEMENT',
            'INSTORE_AUTH',
          ].join(','),
        },
      },
    },
    merchantsConfig: {
      api: {
        options: {
          success: INSTORE_TAB_MERCHANTS_GET_SUCCESS,
          failure: INSTORE_TAB_MERCHANTS_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
        params: {
          fields: [
            'id',
            'clickUrl',
            'logoUrls',
            'type',
            'name',
          ],
        },
      },
    },
    getPageLimits: (isMobileMQ) => {
      const mobilePageLimits = [
        { value: 5, label: '5 per page' },
        { value: 10, label: '10 per page' },
        { value: 25, label: '25 per page' },
        { value: 50, label: '50 per page' },
      ];

      const desktopPageLimits = [
        { value: 10, label: '10 per page' },
        { value: 25, label: '25 per page' },
        { value: 50, label: '50 per page' },
      ];

      return isMobileMQ ? mobilePageLimits : desktopPageLimits;
    },
    pastDateLimits: [
      { value: 30, label: 'Past 30 days' },
      { value: 60, label: 'Past 60 days' },
      { value: 90, label: 'Past 90 days', isDefaultValue: true },
      { value: 120, label: 'Past 120 days' },
      { value: 720, label: 'Past 2 years' },
    ],
    useFavorites: true,
    showDisclaimerOutsideTheBox: false,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
