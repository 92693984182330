import { RELATED_CATEGORY_FEATURED_STORES_VIEW } from '../actions/types';

export const relatedCategoryFeaturedStoresGaActionEvents = {
  [RELATED_CATEGORY_FEATURED_STORES_VIEW]: ({ category, suspendedMerchantName }) => {
    const { name: categoryName, merchants } = category;
    const merchantNames = merchants.map(merchant => merchant.name);

    return {
      category: 'Tombstone page',
      action: 'Category stores view',
      label: `${suspendedMerchantName}|${categoryName}|${merchantNames.join(',')}`,
      value: merchantNames.length,
      custom: {
        merchant: suspendedMerchantName,
      },
    };
  },
};
