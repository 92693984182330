import { SUCCESS_SUFFIX, FAILURE_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/LetterboxTOF';

export const LETTERBOX_TOF_MERCHANT_GET_SUCCESS = `${rootActionPath}Merchant/GET_${SUCCESS_SUFFIX}`;
export const LETTERBOX_TOF_MERCHANT_GET_FAILURE = `${rootActionPath}Merchant/GET_${FAILURE_SUFFIX}`;
export const LETTERBOX_TOF_MARKETING_GET_SUCCESS = `${rootActionPath}Marketing/GET_${SUCCESS_SUFFIX}`;
export const LETTERBOX_TOF_MARKETING_GET_FAILURE = `${rootActionPath}Marketing/GET_${FAILURE_SUFFIX}`;

export const LETTERBOX_TOF_VIEWED = `${rootActionPath}/VIEWED`;
