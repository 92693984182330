import get from 'lodash/get';
import {
  INSTORE_ALL_OFFERS_MAP_MODAL_CLEAR,
  INSTORE_ALL_OFFERS_MAP_MODAL_TRIGGER, INSTORE_CURRENT_STORE_DATA,
} from '../actions/types';

const initialState = {};

const instoreAllOffersMapModal = (state = initialState, action) => {
  switch (action.type) {
    case INSTORE_ALL_OFFERS_MAP_MODAL_TRIGGER: {
      return { ...state, offerId: get(action, 'data.offerId', null) };
    }
    case INSTORE_ALL_OFFERS_MAP_MODAL_CLEAR: {
      return initialState;
    }
    case INSTORE_CURRENT_STORE_DATA: {
      return {
        ...state,
        currentStore: action.data,
      };
    }
    default:
      return state;
  }
};

export default instoreAllOffersMapModal;
