import { EXPLORE_CATEGORIES_VIEW } from '../actions/types';

export const exploreCategoriesGaActionEvents = {
  [EXPLORE_CATEGORIES_VIEW]: () => ({
    category: 'Mobile MHP: Explore Categories',
    action: 'Explore Categories View',
    label: '',
    value: 1,
  }),
};

export const exploreCategoriesClickAndHoverEvents = [
  {
    selector: '.mn_exploreCategories ul li a',
    click: {
      category: 'Mobile MHP: Explore Categories',
      action: 'Explore Categories Click',
    },
    label: (element) => {
      const categoryName = element.getElementsByClassName('mn_categoryName')[0]?.innerText || '';
      return categoryName;
    },
    value: 1,
  },
];
