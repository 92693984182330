export const buyOnlinePickupInstoreClickAndHoverEvents = [
  {
    selector: '.mn_buyOnlinePickupInstore .mn_merchantTile',
    click: {
      category: 'BOPUIS',
      action: 'Tap merchant',
    },
    label: element => element.dataset.merchantName,
  },
];
