import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { buttonLanding as orgConfig } from 'org/configs/modules.config';

/** @type {object} */
const defaultConfig = {
  defaults: {
    mobileView: null,
    chromeMobileView: null,
    viewOptions: {
      shouldRedirectToChromeStoreOnUnsupportedBrowser: false,
      rewardsType: 'points',
    },
    mobileViewOptions: {
      errorIconClass: 'fa fa-exclamation',
      unSupportedHeaderTitle: 'The button is only supported on ' +
        'Google Chrome for desktop - please revisit this page on your Chrome browser to access.',
      imgUrl: '',
      imgAltText: '',
      title: 'Never miss out on rewards.',
      description: 'Install the button to your Chrome browser when shopping on desktops or laptops.',
    },
    chromeMobileViewOptions: {
      title: '',
      imgUrl: '',
      description: '',
      compareSearchResultsType: 'mileage',
      ctaText: 'Add to desktop',
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
