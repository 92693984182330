const GA_EVENT_CATEGORY = 'Header Navigation';

export const navigationClickAndHoverEvents = [
  {
    selector: '.mn_mainNav .mn_instoreLinkWrap a',
    click: {
      category: GA_EVENT_CATEGORY,
      action: 'In-store icon click',
      value: 1,
    },
  },
];
