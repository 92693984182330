import { STICKY_CTA_BAR_VIEW } from '../actions/types';

export const stickyCtaBarGaActionEvents = {
  [STICKY_CTA_BAR_VIEW]: () => (
    {
      category: 'VHP CTA',
      action: 'View',
      label: 'Sign in - sticky footer',
    }
  ),
};

export const stickyCtaBarClickAndHoverEvents = [
  {
    selector: '.mn_VHP .mn_stickyCtaBarWrap .mn_button',
    click: {
      category: 'VHP CTA',
      action: 'Click',
    },
    label: 'Sign in - sticky footer',
  },
];
