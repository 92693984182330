import { createSelector } from 'reselect';

export const selectNavCategories = state => state.navCategories;
export const selectNavSeasonalFlyoutConfig = createSelector(
  selectNavCategories,
  navCategories => navCategories.config,
);

export const selectNavCategoriesIsLoading = createSelector(
  selectNavCategories,
  navCategories => navCategories.isLoading,
);

export const selectNavCategoriesIsLoaded = createSelector(
  selectNavCategories,
  navCategories => navCategories.isLoaded,
);

export const selectNavCategoriesPlacements = createSelector(
  selectNavCategories,
  navCategories => navCategories.categories,
);
