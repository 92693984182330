import React from 'react';
import './MerchantRebateV2.scss';

function rebatePrefix({
  hideRebatePrefix, prefix, additionalRebatePrefix, elevation,
}) {
  if (hideRebatePrefix || (!prefix && !additionalRebatePrefix) || elevation) {
    return (null);
  }

  if (additionalRebatePrefix) {
    return (
      <span className="mn_rebatePrefix">
        {`${additionalRebatePrefix} ${prefix} `}
      </span>
    );
  }

  return (
    <span className="mn_rebatePrefix">
      {`${prefix} `}
    </span>
  );
}

function buildSuffixTierNameRebate(suffixTierName, showRebateTierName) {
  return (showRebateTierName && suffixTierName) ? <span className="mn_tierName"> on {suffixTierName}</span> : null;
}

function rebateSuffix({
  hideRebateSuffix, suffix, suffixTierName, showRebateTierName,
}) {
  if (hideRebateSuffix || !suffix) {
    return buildSuffixTierNameRebate(suffixTierName, showRebateTierName);
  }

  return (
    <span className="mn_rebateSuffix">
      {' '}{suffix} {buildSuffixTierNameRebate(suffixTierName, showRebateTierName)}
    </span>
  );
}

function rebateValue(props) {
  const { valueWithCurrency, rebateAdditionalPrefix, hideNewValuePrefix } = props;
  const newValuePrefix = hideNewValuePrefix ? '' : 'Earn';

  return (
    <span className="mn_rebateValue mn_rebateNonElevatedMerchant" data-test="merchant-rebateV2-non-elevated">
      <div className="mn_elevationOldValue">
        {rebateAdditionalPrefix || 'Shop and'}{' '}
      </div>
      <div className="mn_elevationNewValue">
        {` ${newValuePrefix} ${valueWithCurrency}`}
        {rebateSuffix(props)}
      </div>
    </span>
  );
}

function tieredRebateValue(props) {
  const {
    valueWithCurrency, tieredPrefix, suffixTierName, rebateAdditionalPrefix,
  } = props;
  const tieredPrefixToShow = suffixTierName ? '' : tieredPrefix;
  return (
    <span className="mn_rebateValue mn_rebateTiered" data-test="merchant-rebateV2-tiered-non-elevation">
      <div className="mn_elevationOldValue">
        {rebateAdditionalPrefix || 'Shop and'} earn{' '}
      </div>
      <div className="mn_elevationNewValue">
        {`${tieredPrefixToShow} ${valueWithCurrency}`}
        {rebateSuffix(props)}
      </div>
    </span>
  );
}

function rebateValueWithElevation(props) {
  const {
    originalValueWithCurrency, valueWithCurrency, originalValue, hideElevationCurrency,
  } = props;

  const originalValueToShow = hideElevationCurrency ? originalValue : originalValueWithCurrency;

  return (
    <span className="mn_rebateValue mn_rebateElevated" data-test="merchant-rebateV2-elevated">
      <div className="mn_elevationOldValue">
        {`Was ${originalValueToShow} `}
      </div>
      <div className="mn_elevationNewValue">
        {`Now ${valueWithCurrency}`}
        {rebateSuffix(props)}
      </div>
    </span>
  );
}

function tieredRebateValueWithElevation(props) {
  const {
    originalValueWithCurrency, valueWithCurrency, originalValue,
    hideElevationCurrency, tieredPrefix, suffixTierName,
  } = props;

  const tieredPrefixToShow = suffixTierName ? '' : tieredPrefix;
  const originalValueToShow = hideElevationCurrency ? originalValue : originalValueWithCurrency;

  return (
    <span className="mn_rebateValue mn_rebateTiered mn_rebateElevated" data-test="merchant-rebateV2-tiered-elevation">
      <div className="mn_elevationOldValue">
        {`Was ${tieredPrefixToShow} ${originalValueToShow} `}
      </div>
      <div className="mn_elevationNewValue">
        {`Now ${tieredPrefixToShow} ${valueWithCurrency}`}
        {rebateSuffix(props)}
      </div>
    </span>
  );
}

function rebateValueWithCurrency(props) {
  const { elevation, isTiered } = props;
  const elevatedRebate = () => (isTiered ? tieredRebateValueWithElevation(props) : rebateValueWithElevation(props));
  const rebate = () => (isTiered ? tieredRebateValue(props) : rebateValue(props));

  return (
    <span className="mn_rebateValueWithCurrency">
      { elevation ? elevatedRebate() : rebate() }
    </span>
  );
}

function MerchantRebateV2(props) {
  return (
    <div className="mn_merchantRebateV2" data-test="merchant-rebateV2">
      <div className={props.showRebateInline ? 'mn_inlineRebate' : 'mn_blockRebate'}>
        {rebatePrefix(props)}
        <span className="mn_rebateValueAndSuffixWrap">{rebateValueWithCurrency(props)}</span>
      </div>
    </div>
  );
}

export default MerchantRebateV2;
