import { createSelector } from 'reselect';

export const selectOfferOverlayLink = state => state.offerOverlayLink;

export const selectMerchantOfferModalConfig = createSelector(
  selectOfferOverlayLink,
  offerOverlayLink => offerOverlayLink.config,
);

export const selectAssociatedDealOfferIds = createSelector(
  selectOfferOverlayLink,
  offerOverlayLink => offerOverlayLink.associatedDealOfferIds,
);
