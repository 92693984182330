import { getParamsFromSearch } from 'core/utils/helpers/getParamsFromSearch';
import {
  PRODUCT_SEARCH_RESULTS_CLEAR_FILTER, PRODUCT_SEARCH_RESULTS_VIEW,
  PRODUCT_SEARCH_RESULTS_APPLY_FILTER, PRODUCT_SEARCH_RESULTS_APPLY_SORT,
} from '../actions/types';

const facetMap = {
  merchants: 'Store',
  categories: 'Category',
  brands: 'Brand',
  price: 'Price',
  sale: 'Promotions',
  'extra-rewards': 'Promotions',
};

export const productSearchResultsGaActionEvents = {
  [PRODUCT_SEARCH_RESULTS_VIEW]: ({ keywords }) => (
    {
      category: 'Product Search Results Page',
      action: 'PSR page view',
      label: keywords,
      value: '1',
    }
  ),
  [PRODUCT_SEARCH_RESULTS_CLEAR_FILTER]: () => {
    const { keywords } = getParamsFromSearch(window.location.search);
    return {
      category: 'Product Search Results Page',
      action: 'Filter cleared',
      label: keywords,
      value: '1',
    };
  },
  [PRODUCT_SEARCH_RESULTS_APPLY_FILTER]: ({ facet, isAddedToFilter }) => {
    const { keywords } = getParamsFromSearch(window.location.search);
    return {
      category: 'Product Search Results Page',
      action: isAddedToFilter ? 'Filter applied' : 'Filter cleared',
      label: isAddedToFilter ? `${keywords} | ${facetMap[facet]}` : keywords,
      value: '1',
    };
  },
  [PRODUCT_SEARCH_RESULTS_APPLY_SORT]: ({ sortType }) => {
    const { keywords } = getParamsFromSearch(window.location.search);
    return {
      category: 'Product Search Results Page',
      action: 'Sort applied',
      label: `${keywords} | ${sortType}`,
      value: '1',
    };
  },
};

export const productSearchResultsClickAndHoverEvents = [
  {
    selector: '.mn_prodSearchResultWrap .mn_productOverlayLink',
    click: {
      category: 'Product Search Results Page',
      action: 'Product click',
    },
    label: (element) => {
      const productName = element.getElementsByClassName('mn_productLogo')[0].alt || '';
      const { keywords } = getParamsFromSearch(window.location.search);

      return `${productName} | ${keywords} | Product overlay shown`;
    },
    value: 1,
  },
  {
    selector: '.mn_prodSearchResultWrap .mn_prodDetails',
    click: {
      category: 'Product Search Results Page',
      action: 'Product click',
    },
    label: (element) => {
      const productName = element.getElementsByClassName('mn_productLogo')[0].alt || '';
      const { keywords } = getParamsFromSearch(window.location.search);

      return `${productName} | ${keywords} | Product overlay not shown`;
    },
    value: 1,
  },
  {
    selector: '.mn_productSearchResults .mn_seeMoreButton',
    click: {
      category: 'Product Search Results Page',
      action: 'See more click',
    },
    label: getParamsFromSearch(window.location.search).keywords,
    value: 1,
  },
  {
    selector: '.mn_prodSearchResultWrap .mn_clearFilter',
    click: {
      category: 'Product Search Results Page',
      action: 'Filter cleared',
    },
    label: getParamsFromSearch(window.location.search).keywords,
    value: 1,
  },
];
