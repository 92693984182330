import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { onboardingFavorites as orgConfig } from 'org/configs/modules.config';
import { FAVORITES_GET_SUCCESS, FAVORITES_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        /* eslint-disable-next-line camelcase */
        offer_tag: 'featured-favorites',
        sort_by: 'display_order',
        fields: [
          'merchant.id',
          'merchant.name',
          'merchant.logoUrls._120x60',
          'merchant.logoUrls._88x31',
          'merchant.type',
        ],
      },
      options: {
        success: FAVORITES_GET_SUCCESS,
        failure: FAVORITES_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    subscribeOnFavoritesOptions: {
      formName: 'UpdateFavoriteAlerts',
      emailOptions: { email_favorites_optin: 1 },
    },
    shouldSubscribeMemberOnFavorites: false,
    favoritesHeading: 'Pick 5 or more favorite stores',
    turnOffText: 'To turn alerts off, visit My Account page.',
    getFavoritesText: (earnType) => (`We'll email you when they offer extra ${earnType}.`),
    checkShouldUseCarousel: (activeMQ) => (['L', 'XL', 'XXL'].includes(activeMQ)),
    favoritesCTAText: 'Next',
    slick: {},
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
