import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { featuredOffers as orgConfig } from 'org/configs/modules.config';
import {
  FEATURED_OFFERS_150X150_GET_SUCCESS,
  FEATURED_OFFERS_150X150_GET_FAILURE,
} from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: FEATURED_OFFERS_150X150_GET_SUCCESS,
        failure: FEATURED_OFFERS_150X150_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
      params: {
        content_type_id: 49, // A default group for 150X150 content item type
        group_by_merchant: 0,
        with_text_offers: 1,
        sort_by: 'random',
        fields: [
          'id',
          'assets',
          'clickUrl',
          'merchant',
          'offerTitle',
          'offerDetails',
          'offerText',
        ].join(','),
        section_id: 10223,
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
