import {
  INQUIRY_ORDER_TRANSACTIONS_GET_SUCCESS,
  INQUIRY_ORDER_TRANSACTIONS_GET_FAILURE,
} from '../actions/types';
import config from '../configs';

const { transactionsConfig } = config;

const initialState = {
  config: transactionsConfig,
  records: [],
  isLoading: false,
  isLoaded: false,
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const transactions = (state = initialState, action) => {
  switch (action.type) {
    case INQUIRY_ORDER_TRANSACTIONS_GET_SUCCESS: {
      return {
        ...state,
        records: action.payload.response,
        isLoading: false,
        isLoaded: true,
      };
    }
    case INQUIRY_ORDER_TRANSACTIONS_GET_FAILURE: {
      return {
        ...state,
        records: [],
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === INQUIRY_ORDER_TRANSACTIONS_GET_SUCCESS) {
        return {
          ...initialState,
          isLoading: true,
        };
      }

      return state;
    }
  }
};

export default transactions;
