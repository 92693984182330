import { PRODUCT_DETAILS_GET_SUCCESS, PRODUCT_DETAILS_GET_FAILURE } from '../actions/types';
import { productDetailsConfig as config } from '../configs';

const { sortParams } = config;

const initialState = {
  config,
  sortParams,
  isLoading: false,
  isLoaded: false,
  product: {},
};

const productDetails = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_DETAILS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        product: action.payload.response[0],
      };
    }
    case PRODUCT_DETAILS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === PRODUCT_DETAILS_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};

export default productDetails;
