import { INSTORE_AD_OFFERS_VIEW } from '../actions/types';

export const instoreAdOffersSectionActionEvents = {
  [INSTORE_AD_OFFERS_VIEW]: ({ data }) => {
    const { merchantNames, is502X272View } = data;
    const type = is502X272View ? 'image' : 'logo';

    return {
      category: 'Flex page in-store content',
      action: `Content view (${type} treatment)`,
      label: merchantNames.join(','),
      value: merchantNames.length,
    };
  },
};
