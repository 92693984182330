import {
  INSTORE_LOCATION_SET_LOCATION,
  INSTORE_LOCATION_SET_USER_LOCATION,
  INSTORE_LOCATION_SET_ZIPCODE,
  INSTORE_OFFERS_CLICK, NEAR_ME_MAP_CLICK,
  INSTORE_ADD_NEW_CARD_CLICK,
  INSTORE_MANAGE_CARDS_CLICK,
  INSTORE_SMS_NUMBER_CLICK, INSTORE_MULTI_LINK_CLICK,
} from './types';

export const instoreOfferOverlayClick = data => ({
  type: INSTORE_OFFERS_CLICK,
  data,
});

export const instoreOfferNearMeMapOverlayClick = data => ({
  type: NEAR_ME_MAP_CLICK,
  data,
});

export const instoreOfferSetLocation = (data) => ({
  type: INSTORE_LOCATION_SET_LOCATION,
  data,
});

export const instoreUserLocation = (data) => ({
  type: INSTORE_LOCATION_SET_USER_LOCATION,
  data,
});

export const instoreUserSetZipcode = (data) => ({
  type: INSTORE_LOCATION_SET_ZIPCODE,
  data,
});
export const instoreManageCardsOverlayClick = data => ({
  type: INSTORE_MANAGE_CARDS_CLICK,
  data,
});

export const instoreAddNewCardOverlayClick = data => ({
  type: INSTORE_ADD_NEW_CARD_CLICK,
  data,
});

export const instoreSMSNumberOverlayClick = data => ({
  type: INSTORE_SMS_NUMBER_CLICK,
  data,
});

export const instoreMultiLinkOverlayClick = data => ({
  type: INSTORE_MULTI_LINK_CLICK,
  data,
});
