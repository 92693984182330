import {
  merchantsAllParser,
  merchantsInstoreParser,
  merchantsOnlineParser,
} from './merchantsParser';
import placementsParser from './placementsParser';
import clickHistoryParser from './clickHistoryParser';
import memberOrdersParser from './memberOrdersParser';
import memberAccountSummaryParser from './memberAccountSummaryParser';
import memberTransactionsParser from './memberTransactionsParser';
import searchParser from './searchParser';
import searchProductsParser from './searchProductsParser';
import searchProductsByIdParser from './searchProductsByIdParser';
import navigationParser from './navParser';
import categoriesParser from './categoriesParser';
import contentPageParser from './contentPageParser';
import flyoutCategoriesParser from './flyoutCategoriesParser';
import bonusParser from './bonusParser';
import instoreOffersParser from './instoreOffersParser';
import gspParser from './gspParser';
import serverParser from './serverParser';
import instoreTransactionsParser from './instoreTransactionsParser';

// Member Services Parsers
import { favoritesParser } from './favoritesParser';

export default {
  merchantsOnlineParser,
  merchantsInstoreParser,
  merchantsAllParser,
  placementsParser,
  clickHistoryParser,
  memberOrdersParser,
  memberTransactionsParser,
  memberAccountSummaryParser,
  searchParser,
  searchProductsParser,
  searchProductsByIdParser,
  navigationParser,
  categoriesParser,
  contentPageParser,
  favoritesParser,
  flyoutCategoriesParser,
  bonusParser,
  instoreOffersParser,
  gspParser,
  serverParser,
  instoreTransactionsParser,
};
