import { findAncestorByClassName } from 'core/utils/helpers/dom';
import { getPageName } from 'core/utils/analytics/pageInfo';

export const storeBundlesClickAndHoverEvents = [
  {
    selector: '.mn_storeBundle .mn_merchantLogoTile .mn_favoriteIcon',
    click: {
      category: getPageName() === 'favorites' ? 'Favorite Bundles Empty State' : 'Favorite Bundles',
      action: element => (element.classList.contains('mn_favorited') ? 'Remove favorite' : 'Add favorite'),
    },
    label: (element) => {
      const merchantElement = findAncestorByClassName(element, 'mn_merchantLogoTile') || {};
      const { merchantName } = merchantElement.dataset;
      const storeBundle = findAncestorByClassName(element, 'mn_storeBundle') || {};
      const categoryTitle = storeBundle.querySelector('h3')?.innerText || '';
      return `${categoryTitle} | ${merchantName}`;
    },
  },
  {
    selector: '.mn_storeBundle .mn_favoriteAllButton',
    click: {
      category: getPageName() === 'favorites' ? 'Favorite Bundles Empty State' : 'Favorite Bundles',
      action: 'Favorite all',
    },
    label: (element) => {
      const storeBundle = findAncestorByClassName(element, 'mn_storeBundle') || {};
      const { bundleId } = storeBundle.dataset;
      const categoryTitle = storeBundle.querySelector('h3')?.innerText || '';
      return `${categoryTitle} | ${bundleId}`;
    },
  },
];
