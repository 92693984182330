import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { storeBundles as orgConfig } from 'org/configs/modules.config';
import {
  STORE_BUNDLE_MERCHANTS_GET_SUCCESS, STORE_BUNDLE_MERCHANTS_GET_FAILURE,
  STORE_BUNDLE_ASSETS_GET_SUCCESS, STORE_BUNDLE_ASSETS_GET_FAILURE,
} from '../actions/types';

const defaultConfig = {
  defaults: {
    maxPerBundle: 8,
    favoriteTooltipEnabled: false,
    getShouldUseCarousel: (isDesktopMQ) => !isDesktopMQ,
    merchantsConfig: {
      api: {
        contentGroupIds: [5964, 5965, 5966],
        params: {
          limit: 9,
          sort_by: 'earn_rate',
          sort_type: 'asc',
          fields: [
            'merchant.id',
            'merchant.name',
            'merchant.logoUrls',
          ],
        },
        options: {
          success: STORE_BUNDLE_MERCHANTS_GET_SUCCESS,
          failure: STORE_BUNDLE_MERCHANTS_GET_FAILURE,
        },
      },
    },

    assetsConfig: {
      api: {
        contentGroupIds: [18054, 18055, 18056],
        params: {
          limit: 1,
          fields: [
            'assets.imageAlternateText',
            'assets._290x146',
          ],
        },
        options: {
          success: STORE_BUNDLE_ASSETS_GET_SUCCESS,
          failure: STORE_BUNDLE_ASSETS_GET_FAILURE,
        },
      },
    },
    slick: {
      variableWidth: true,
      infinite: false,
      arrows: false,
      dots: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            infinite: true,
            centerMode: true,
            centerPadding: '30px',
          },
        },
      ],
    },
  },
  favorites: {
    favoriteTooltipEnabled: true,
  },
  foryou: {
    title: 'Pick your favorite stores',
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
