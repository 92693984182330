import {
  PRODUCT_DETAILS_MODAL_TRIGGER,
  PRODUCT_DETAILS_GET_SUCCESS,
  PRODUCT_DETAILS_GET_FAILURE,
  PRODUCT_DETAILS_MODAL_CLEAR,
} from '../actions/types';

import { productDetailsModal as config } from '../configs';

const initialState = {
  config,
  clickData: null,
  isLoading: false,
  isLoaded: false,
  product: {},
};

const productDetailsModal = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_DETAILS_MODAL_TRIGGER: {
      return { ...state, clickData: action.data };
    }
    case PRODUCT_DETAILS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        product: action.payload.response[0],
      };
    }
    case PRODUCT_DETAILS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    }
    case PRODUCT_DETAILS_MODAL_CLEAR: {
      return initialState;
    }
    default: {
      const { options = {} } = action;
      if (options.success === PRODUCT_DETAILS_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default productDetailsModal;
