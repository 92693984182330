import {
  TRENDING_STORES_GET_SUCCESS, TRENDING_STORES_GET_FAILURE, TRENDING_STORES_STORE_DY_DATA,
} from '../actions/types';
import trendingStoresConfig from '../configs';

const initialState = {
  config: trendingStoresConfig,
  dyData: {},
  merchants: [],
  isLoading: false,
  isLoaded: false,
};

const filterMerchants = (merchants) => (
  merchants.filter(({ type }) => (type === 'online'))
);

const trendingStores = (state = initialState, action) => {
  switch (action.type) {
    case TRENDING_STORES_STORE_DY_DATA: {
      return {
        ...state,
        dyData: action.dyData,
      };
    }
    case TRENDING_STORES_GET_SUCCESS: {
      const placements = action.payload.response;
      return {
        ...state,
        merchants: filterMerchants(placements),
        isLoading: false,
        isLoaded: true,
      };
    }
    case TRENDING_STORES_GET_FAILURE: {
      return {
        ...state,
        merchants: [],
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === TRENDING_STORES_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default trendingStores;
