import { getPageName } from 'core/utils/analytics/pageInfo';
import { getParamsFromSearch } from 'core/utils/helpers/getParamsFromSearch';
import { HYBRID_STORES_VIEW } from '../actions/types';

const isHsrPage = getPageName() === 'hsr';

export const hybridStoresGaActionEvents = {
  [HYBRID_STORES_VIEW]: ({ keywords }) => (
    {
      category: 'Multi-Merchant Search Page',
      action: 'Multi-Merchant Search Page view',
      label: keywords,
      value: '1',
    }
  ),
};

export const hybridStoresClickAndHoverEvents = [
  {
    selector: '.mn_hybridStores .mn_viewAll',
    click: {
      category: 'Hybrid Search Results Page',
      action: 'See all stores click',
    },
    label: getParamsFromSearch(window.location.search).keywords,
    value: 1,
  },
  {
    selector: '.mn_hybridStores .mn_merchantClickUrlLink',
    click: {
      category: isHsrPage ? 'Hybrid Search Results Page' : 'Multi-Merchant Search Page',
      action: 'Store click',
    },
    label: (element) => {
      const { merchantName } = element.dataset || {};
      const { keywords } = getParamsFromSearch(window.location.search);

      return `${merchantName} | ${keywords}`;
    },
    value: 1,
  },
  {
    selector: '.mn_hybridStores .mn_onlineInStoreOfferOverlayLink',
    click: {
      category: isHsrPage ? 'Hybrid Search Results Page' : 'Multi-Merchant Search Page',
      action: 'Store click (in-store)',
    },
    label: (element) => {
      const { merchantName } = element.dataset || {};
      const { keywords } = getParamsFromSearch(window.location.search);

      return `${merchantName} | ${keywords}`;
    },
    value: 1,
  },
  {
    selector: '.mn_hybridStores .mn_hybridStoresButtonContainer',
    click: {
      category: 'Multi-Merchant Search Page',
      action: 'View all stores click',
    },
    label: getParamsFromSearch(window.location.search).keywords,
    value: 1,
  },
];
