import { EMAIL_OPTIN_SECTION_VIEW, EMAIL_OPTIN_STATUS_CHANGED } from '../actions/types';

export const emailOptInSectionGaActionEvents = {
  [EMAIL_OPTIN_SECTION_VIEW]: () => ({
    category: 'Mobile MHP: Email Opt-in Module',
    action: 'Email Opt-in View',
    label: '',
    value: 1,
  }),

  [EMAIL_OPTIN_STATUS_CHANGED]: ({ isUserCheckedOptIn }) => {
    const actionText = isUserCheckedOptIn ? 'Email Opt-in Module Opt-in Click' : 'Email Opt-in Module Opt-out Click';
    return ({
      category: 'Mobile MHP: Email Opt-in Module',
      action: actionText,
      label: '',
      value: 1,
    });
  },
};
