import { takeEvery, put } from 'redux-saga/effects';
import { showModal } from 'core/modules/Modal/actions';
import { MODAL_TYPE_EMAIL_OPT_IN_SUBSCRIBE } from 'core/modules/Modal/types';
import { EMAIL_OPT_IN_MODAL_SUBSCRIBE_TRIGGER } from '../actions/types';

function* showEmailOptInSubscribeModal() {
  yield put(showModal(MODAL_TYPE_EMAIL_OPT_IN_SUBSCRIBE));
}

export default function* emailOptInSubscribeModalSaga() {
  yield takeEvery(EMAIL_OPT_IN_MODAL_SUBSCRIBE_TRIGGER, showEmailOptInSubscribeModal);
}
