import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { dyEvents } from 'core/utils/analytics/dyAnalytics';
import { trackInsiderKeywordSearchEvent } from 'core/utils/analytics/insiderAnalytics';
import keyCodes from 'core/utils/helpers/keyCodes';
import {
  selectSearchFormKeywords, selectSearchFormRedirectUrl, selectSearchFormRedirectType,
  selectSearchFormDestinationMerchants, selectSearchFormConfig,
} from 'core/modules/HybridSearchForm/selectors';

import HybridQuickSearch from 'core/modules/HybridQuickSearch/HybridQuickSearch';

import {
  selectQuickSearchMatches, selectQuickSearchMerchantsIsLoaded, selectQuickSearchConfig,
} from 'core/modules/HybridQuickSearch/selectors';

import {
  searchSubmit, triggerSearchRedirectGaEvent, triggerSearchActivationGaEvent,
} from './actions';

import './HybridSearchForm.scss';

function HybridSearchForm({
  placeholder, getFocusWasRemoved, isPopUpView, searchFormRef, hasFocusOnInit,
}) {
  const dispatch = useDispatch();

  const searchFormInputRef = useRef();
  const keywords = useSelector(selectSearchFormKeywords);
  const { searchButtonClassName } = useSelector(selectSearchFormConfig);
  const { minChars } = useSelector(selectQuickSearchConfig);
  const quickSearchMatches = useSelector(
    state => selectQuickSearchMatches(state, keywords, { shouldUseGsp: true, minChars }),
  );
  const quickSearchMerchantsIsLoaded = useSelector(selectQuickSearchMerchantsIsLoaded);
  const searchFormRedirectUrl = useSelector(selectSearchFormRedirectUrl);
  const searchFormRedirectType = useSelector(selectSearchFormRedirectType);
  const searchFormDestinationMerchants = useSelector(selectSearchFormDestinationMerchants);

  const [hasFocus, setHasFocus] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const removeFocusTimeout = useRef();
  const quickSearchRef = useRef();

  const setFocus = () => setHasFocus(true);
  const removeFocus = ({ currentTarget }) => {
    removeFocusTimeout.current = setTimeout(() => {
      if (!currentTarget.contains(document.querySelector(':focus'))) {
        setHasFocus(false);
        getFocusWasRemoved && getFocusWasRemoved();
      }
    }, 100);
  };

  useEffect(() => () => removeFocusTimeout.current && clearTimeout(removeFocusTimeout.current), []);

  useEffect(() => {
    hasFocus && dispatch(triggerSearchActivationGaEvent({ quickSearchMatches, keywords }));
  }, [hasFocus]);

  useEffect(() => {
    if (searchFormRedirectUrl && quickSearchMerchantsIsLoaded && isSubmitted) {
      dispatch(triggerSearchRedirectGaEvent({
        searchFormRedirectType,
        quickSearchMatches,
        searchFormDestinationMerchants,
        keywords,
        searchFormRedirectUrl,
      }));
      setIsSubmitted(false); // iPhone real device fix (CLPL-18048).
      window.location.href = searchFormRedirectUrl;
    }
  }, [searchFormRedirectUrl, quickSearchMerchantsIsLoaded, isSubmitted]);

  // Focus search input, when pop up search is opened
  useEffect(() => {
    if (hasFocusOnInit && searchFormInputRef.current) {
      setTimeout(() => { searchFormInputRef.current.getRenderedComponent().focus(); }, 100);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (keywords.length > 0) {
      const encodedKeywords = encodeURIComponent(keywords);
      dispatch(searchSubmit({ keywords: encodedKeywords }));
      dyEvents.sendEvent(dyEvents.sendKeyWordSearchEvent, { keywords });
      trackInsiderKeywordSearchEvent(encodedKeywords);
      setIsSubmitted(true);
    }
  };

  const onKeyDownHandler = (event) => {
    const pressedKey = event.keyCode;
    const quickSearchFirstFocusedElement =
      quickSearchRef?.current?.querySelector('.mn_onlineMatch, .mn_quickSearchMatch');

    if (pressedKey === keyCodes.ARW_DOWN && quickSearchFirstFocusedElement) {
      event.preventDefault();
      quickSearchFirstFocusedElement.focus();
    }
  };

  const renderPopUpView = () => (
    <div className="mn_hybridSearchForm" ref={searchFormRef}>
      <div className="mn_dropDownWrap">
        <HybridQuickSearch keywords={keywords} innerRef={quickSearchRef} isPopUpView />
      </div>
      <form
        action="/hsr____.htm"
        onFocus={setFocus}
        onBlur={removeFocus}
        onSubmit={handleSubmit}
        data-test="search-form"
      >
        <label htmlFor="mn_searchInput">
          <span className="mn_sr-only">{placeholder}</span>
          <Field
            className="mn_searchInput"
            id="mn_searchInput"
            name="keywords"
            component="input"
            type="text"
            placeholder={placeholder}
            required
            autoComplete="off"
            forwardRef
            ref={searchFormInputRef}
            onKeyDown={onKeyDownHandler}
          />
          <button className="mn_searchSubmit" type="submit" aria-label="Search">
            <i className={searchButtonClassName} aria-hidden="true" />
          </button>
        </label>
      </form>
    </div>
  );

  const renderDropDownView = () => (
    <form
      className={`mn_hybridSearchForm ${hasFocus ? 'mn_searchOnFocus' : ''}`}
      action="/hsr____.htm"
      onFocus={setFocus}
      onBlur={removeFocus}
      onSubmit={handleSubmit}
      data-test="search-form"
    >
      <label htmlFor="mn_searchInput">
        <span className="mn_sr-only">{placeholder}</span>
        <Field
          className="mn_searchInput"
          id="mn_searchInput"
          name="keywords"
          component="input"
          type="text"
          placeholder={placeholder}
          required
          autoComplete="off"
          forwardRef
          ref={searchFormInputRef}
          onKeyDown={onKeyDownHandler}
        />
      </label>
      <button className="mn_searchSubmit" type="submit" aria-label="Search">
        <i className={searchButtonClassName} aria-hidden="true" />
      </button>
      {hasFocus &&
        <div className="mn_dropDownWrap">
          <HybridQuickSearch keywords={keywords} innerRef={quickSearchRef} />
        </div>
      }
    </form>
  );

  return isPopUpView ? renderPopUpView() : renderDropDownView();
}

HybridSearchForm.defaultProps = {
  placeholder: 'Search',
};

export default reduxForm({ form: 'hybridSearchForm' })(HybridSearchForm);
