import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { productDetailsModal as orgConfig } from 'org/configs/modules.config';

import { PRODUCT_DETAILS_GET_SUCCESS, PRODUCT_DETAILS_GET_FAILURE } from '../actions/types';

export const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: PRODUCT_DETAILS_GET_SUCCESS,
        failure: PRODUCT_DETAILS_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    rebateOptions: {
      hideElevationCurrency: true,
      showRebateInline: true,
    },
  },
};

export const productDetailsModal = mergeModuleConfigs(defaultConfig, orgConfig);
