import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { offerOverlayLink as orgConfig } from 'org/configs/modules.config';
import { OFFER_OVERLAY_LINK_GET_SUCCESS, OFFER_OVERLAY_LINK_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        limit: 15,
        content_type_id: 6,
      },
      options: {
        success: OFFER_OVERLAY_LINK_GET_SUCCESS,
        failure: OFFER_OVERLAY_LINK_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
