import { SIMILAR_STORES_VIEW } from '../actions/types';

export const similarStoresGaActionEvents = {
  [SIMILAR_STORES_VIEW]: ({ similarStores, merchantMatchName }) => {
    const storeNames = similarStores.map(item => item.name);

    return {
      category: 'Searchbox-Header',
      action: 'Similar stores view',
      label: `${merchantMatchName}|${storeNames.join(',')}`,
      value: storeNames.length,
    };
  },
};

export const similarStoresClickAndHoverEvents = [
  {
    selector: '.mn_pageHeader .mn_similarStores .mn_similarStoreTile',
    click: {
      category: 'Searchbox-Header',
      action: 'Similar stores click',
    },
    label: (element) => {
      const { merchantName, merchantId } = element.dataset;
      return `${merchantId}|${merchantName}`;
    },
    value: 1,
  },
];
