import { findAncestorByClassName } from 'core/utils/helpers/dom';
import { getDisplayPageName } from 'core/utils/analytics/pageInfo';

export const featuredOffers150x150ClickAndHoverEvents = [
  {
    selector: '.mn_offerInfoWrap .mn_favoriteIcon',
    click: {
      category: `${getDisplayPageName()} | offer`,
      action: element => `${element.classList.contains('mn_favorited') ? 'Remove' : 'Add'} favorite`,
    },
    label: (element) => {
      const featuredOffer = findAncestorByClassName(element, 'mn_featuredOffer150x150') || {};
      const { merchantName, sectionType } = featuredOffer.dataset;
      const featuredOffers = findAncestorByClassName(element, 'mn_featuredOffers150x150') || {};
      const { title } = featuredOffers.dataset;

      return `${merchantName} | ${title} | ${sectionType}`;
    },
    custom: element => ({
      merchant: element.dataset.merchantName,
    }),
  },
  {
    selector: '.mn_featuredOffer150x150 .mn_featuredOffer150x150LinkOrOverlayWrap',
    click: {
      category: `${getDisplayPageName()} | offer`,
      action: 'Click',
    },
    label: (element) => {
      const featuredOffers = findAncestorByClassName(element, 'mn_featuredOffers150x150') || {};
      const featuredOffer = findAncestorByClassName(element, 'mn_featuredOffer150x150') || {};

      const shortOfferCopy = featuredOffer.getElementsByClassName('mn_shortSummary')[0].innerText || '';
      const { merchantName, sectionType } = featuredOffer.dataset;
      const { title } = featuredOffers.dataset;

      return `${merchantName} | ${shortOfferCopy} | ${title} | ${sectionType}`;
    },
  },
];
