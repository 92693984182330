import { SUCCESS_SUFFIX, FAILURE_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/MarketingBanner';

export const MARKETING_BANNER_MOBILE_GET_SUCCESS = `${rootActionPath}/Mobile/GET${SUCCESS_SUFFIX}`;
export const MARKETING_BANNER_MOBILE_GET_FAILURE = `${rootActionPath}/Mobile/GET${FAILURE_SUFFIX}`;

export const MARKETING_BANNER_DESKTOP_GET_SUCCESS = `${rootActionPath}/Desktop/GET${SUCCESS_SUFFIX}`;
export const MARKETING_BANNER_DESKTOP_GET_FAILURE = `${rootActionPath}/Desktop/GET${FAILURE_SUFFIX}`;

export const MARKETING_BANNER_VIEW = `${rootActionPath}/VIEW`;
