import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchPlacements } from 'core/actions/ocapi';
import { isImageAssetVideo } from 'core/utils/helpers/checkIfImageAssetVideo';
import AutomaticVideoPlayer from 'core/modules/AutomaticVideoPlayer/AutomaticVideoPlayer';

import {
  selectHeaderHolidayImageConfig,
  selectHeaderHolidayImageIsLoaded,
  selectHeaderHolidayImageIsLoading,
  selectHeaderHolidayImagePlacement,
  selectHeaderHolidayImageAlternateText,
  selectHeaderHolidayImageClickUrl,
} from './selectors';

import './HeaderHolidayImage.scss';

function HeaderHolidayImage() {
  const videoRef = useRef(null);

  const config = useSelector(selectHeaderHolidayImageConfig);
  const isLoading = useSelector(selectHeaderHolidayImageIsLoaded);
  const isLoaded = useSelector(selectHeaderHolidayImageIsLoading);
  const imageUrl = useSelector(selectHeaderHolidayImagePlacement);
  const imageAlternateText = useSelector(selectHeaderHolidayImageAlternateText);
  const clickUrl = useSelector(selectHeaderHolidayImageClickUrl);

  const dispatch = useDispatch();

  const imageIsVideo = isImageAssetVideo(imageUrl);

  useEffect(() => {
    const { shouldShowHolidayImage } = config;

    if (!shouldShowHolidayImage) {
      return;
    }

    if (!isLoaded && !isLoading) {
      const { api: { params, options } } = config;

      dispatch(fetchPlacements(params, options));
    }
  }, []);

  useEffect(() => {
    if (imageUrl) {
      document.documentElement.classList.add('mn_holiday');
    } else {
      document.documentElement.classList.remove('mn_holiday');
    }

    return () => {
      document.documentElement.classList.remove('mn_holiday');
    };
  }, [imageUrl]);

  useEffect(() => {
    const videoElement = videoRef?.current;
    const handleVideoLoaded = () => {
      videoElement.style.display = 'block';
    };

    if (imageIsVideo && videoElement) {
      videoElement.addEventListener('loadeddata', handleVideoLoaded);

      return () => {
        videoElement.removeEventListener('loadeddata', handleVideoLoaded);
      };
    }

    return () => {};
  }, [imageIsVideo, imageUrl]);

  const renderHolidayImage = () => (
    <div className="mn_holidayImage" aria-hidden="true">
      {imageIsVideo ?
        <AutomaticVideoPlayer ref={videoRef} className="mn_holidayVideo" src={imageUrl} title={imageAlternateText} /> :
        <div className="mn_imageWrap" style={{ backgroundImage: `url(${imageUrl})` }} />
      }
    </div>
  );

  if (!imageUrl) return null;

  return (
    clickUrl ? <a href={clickUrl}>{renderHolidayImage()}</a> : renderHolidayImage()
  );
}

export default HeaderHolidayImage;
