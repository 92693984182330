import {
  HIW_VIDEO_MODAL_CLEAR,
  HIW_VIDEO_MODAL_TRIGGER,
} from '../actions/types';

const initialState = {};

const hiwVideoModal = (state = initialState, action) => {
  switch (action.type) {
    case HIW_VIDEO_MODAL_TRIGGER: {
      return { ...state, data: action.data };
    }
    case HIW_VIDEO_MODAL_CLEAR: {
      return initialState;
    }
    default:
      return state;
  }
};

export default hiwVideoModal;
