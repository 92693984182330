import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { adBlockWarning as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    getWarningMessage: (brandDisplayName) => (
      `The use of ad blockers may prevent you from earning miles on your shopping.
      While shopping with ${brandDisplayName}, turn off any ad blockers or use a different browser
      to ensure you earn miles as expected.`
    ),
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
