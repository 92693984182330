import config from '../configs';
import {
  INSTORE_OFFERS_GET_SUCCESS,
  INSTORE_OFFERS_GET_FAILURE,
  INSTORE_LOCATION_SET_LOCATION,
  INSTORE_LOCATION_SET_USER_LOCATION,
  INSTORE_LOCATION_SET_ZIPCODE,
} from '../actions/types';
import {
  INSTORE_OFFERS_SORT,
} from '../components/SortBy/actions/types';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  offers: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INSTORE_OFFERS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        offers: action.payload.response,
      };
    }
    case INSTORE_OFFERS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        offers: [],
      };
    }
    case INSTORE_OFFERS_SORT: {
      return {
        ...state,
        sortType: action.data,
      };
    }

    case INSTORE_LOCATION_SET_LOCATION: {
      return {
        ...state,
        locationData: action.data,
      };
    }
    case INSTORE_LOCATION_SET_ZIPCODE: {
      return {
        ...state,
        currentZipcode: action.data,
      };
    }
    case INSTORE_LOCATION_SET_USER_LOCATION: {
      return {
        ...state,
        userLocation: action.data,
      };
    }
    default: {
      const { options = {} } = action;

      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === INSTORE_OFFERS_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};
