import { PICK_FAVORITES_VIEW } from '../actions/types';

export const pickFavoritesGaActionEvents = {
  [PICK_FAVORITES_VIEW]: () => ({
    category: 'Mobile MHP: Pick Favorites',
    action: 'Pick Favorites View',
    label: '',
    value: 1,
  }),
};

export const pickFavoritesClickAndHoverEvents = [
  {
    selector: '.mn_pickFavorites .mn_merchantLogoTile',
    click: {
      category: 'Mobile MHP: Pick Favorites',
      action: 'Pick Favorites Click',
    },
    label: (element) => element.dataset.merchantId,
    value: 1,
  },
];
