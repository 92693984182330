import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';

export function NoMatches({ text = '' }) {
  return (
    <div className="mn_noResults">
      { text }
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><NoMatches {...props} /></ComponentErrorBoundary>;
