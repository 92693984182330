import { selectSiteUrl } from 'core/selectors/app';
import { formatDateComma } from './commonParsers/formatDateParser';

const getMerchantPageLink = (merchantLink, state) => {
  const siteUrl = selectSiteUrl(state);
  return siteUrl + merchantLink.slice(merchantLink.indexOf('/me____.htm'), merchantLink.length);
};

export default (response, state) =>
  response.map(click => {
    const { createDatetime, merchantLink, merchantId } = click;

    if (createDatetime) {
      click.date = formatDateComma(createDatetime);
    }

    if (merchantLink) {
      click.merchantLink = getMerchantPageLink(merchantLink, state);
    }

    if (merchantId) {
      click.id = merchantId;
    }

    return click;
  });
