import React from 'react';

import './SkipHeader.scss';

function SkipHeader() {
  return (
    <a className="mn_sr-only mn_skipHeaderContentLink" href="#mn_pageBody">Skip header content</a>
  );
}

export default SkipHeader;
