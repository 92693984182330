import * as actions from 'core/actions/member';
import responseParsers from './responseParsers';
import BaseApiRequestor from './baseRequestor';

export class MemberRequestor extends BaseApiRequestor {
  responseParsersForAPI = {
    [actions.MEMBER_GET_FAVORITES]: responseParsers.favoritesParser,
    [actions.MEMBER_POST_FAVORITES]: responseParsers.updateFavoriteParser,
    [actions.MEMBER_DELETE_FAVORITES]: responseParsers.updateFavoriteParser,
  };
}

export default new MemberRequestor();
