import { INSTORE_CURATED_OFFERS_GET_SUCCESS, INSTORE_CURATED_OFFERS_GET_FAILURE } from '../actions/types';

const initialState = {
  isLoading: false,
  isLoaded: false,
  offers: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INSTORE_CURATED_OFFERS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        offers: action.payload.response,
      };
    }
    case INSTORE_CURATED_OFFERS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        offers: [],
      };
    }
    default: {
      const { options = {} } = action;

      if (options.success === INSTORE_CURATED_OFFERS_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};
