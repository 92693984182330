import {
  select, takeEvery, put, call,
} from 'redux-saga/effects';
import { isErrorResponse } from 'core/utils/apis/base';
import server from 'core/utils/apis/server';
import * as actions from 'core/actions/server';
import { generateFailureAction, generateServerSuccessAction } from 'core/actions/apis';
import serverRequestor from 'core/utils/apis/serverRequestor';

function* makeServerRequest({ caller, requestArgList, defaultValue }, action = {}) {
  const state = yield select(state => state);
  const response = yield call([serverRequestor, serverRequestor.getAPIResponse], {
    caller,
    requestArgList,
    shouldParseResponse: false,
    action,
    state,
  });

  if (!isErrorResponse(response)) {
    yield put(generateServerSuccessAction(action, response));
  } else {
    yield put(generateFailureAction(action, response, defaultValue));
  }
}

const takeEveryServer = (actionType, caller, requestArgList, defaultValue) =>
  takeEvery(actionType, makeServerRequest, { caller: caller.bind(server), requestArgList, defaultValue });

/**
 * Watch for any an all ocapi requests
 */
export default function* serverSaga() {
  yield takeEveryServer(actions.SERVER_GET_INFORMATION_MERCHANTS_LIST, server.getMerchantsAll);
  yield takeEveryServer(actions.SERVER_SALESFORCE_CASE_VALIDATION, server.salesforceCaseIdValidation);
}
