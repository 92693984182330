/* eslint-disable max-len */

import { requestActionGeneratorByType, SUCCESS_SUFFIX, FAILURE_SUFFIX } from './apis';

const rootActionPath = 'core/apis/bonus';

// action names
export const BONUS_GET_INFORMATION = `${rootActionPath}/information/GET`;
export const BONUS_GET_INFORMATION_SUCCESS = `${BONUS_GET_INFORMATION}_${SUCCESS_SUFFIX}`;
export const BONUS_GET_INFORMATION_FAILURE = `${BONUS_GET_INFORMATION}_${FAILURE_SUFFIX}`;

export const BONUS_GET_INFORMATION_MEMBER_RELATED = `${BONUS_GET_INFORMATION}_MEMBER_RELATED`;
export const BONUS_GET_INFORMATION_MEMBER_RELATED_EXPIRED = `${BONUS_GET_INFORMATION}_MEMBER_RELATED_EXPIRED`;

export const BONUS_GET_INFORMATION_MOCK = `${BONUS_GET_INFORMATION}_MOCK`;
export const BONUS_GET_INFORMATION_MOCK_SUCCESS = `${BONUS_GET_INFORMATION_MOCK}_${SUCCESS_SUFFIX}`;

// exported methods
export const fetchBonus = requestActionGeneratorByType(BONUS_GET_INFORMATION);
export const fetchMerchantRelatedBonus = requestActionGeneratorByType(BONUS_GET_INFORMATION_MEMBER_RELATED);
export const fetchMerchantRelatedExpiredBonus = requestActionGeneratorByType(BONUS_GET_INFORMATION_MEMBER_RELATED_EXPIRED);
export const fetchBonusMock = requestActionGeneratorByType(BONUS_GET_INFORMATION_MOCK);
