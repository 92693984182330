import merge from 'lodash/merge';

import * as actions from 'core/actions/ocapi';
import { selectPreviewDateTime } from 'core/selectors/env';
import responseParsers from './responseParsers';
import BaseApiRequestor from './baseRequestor';

const extendActionWithDefaultAPIParameters = (action, state) => {
  const previewDateTime = selectPreviewDateTime(state);
  return merge({}, action, {
    params: {
      ...previewDateTime && { preview_datetime: previewDateTime },
    },
  });
};

export class OCAPIRequestor extends BaseApiRequestor {
  responseParsersForAPI = {
    [actions.OCAPI_GET_CATEGORIES]: responseParsers.categoriesParser,
    [actions.OCAPI_GET_FLYOUT_CATEGORIES]: responseParsers.flyoutCategoriesParser,
    [actions.OCAPI_GET_CLICK_HISTORY]: responseParsers.clickHistoryParser,
    [actions.OCAPI_GET_CONTENT_PAGE_BY_ID]: responseParsers.contentPageParser,
    [actions.OCAPI_GET_MEMBER_ACCOUNT_SUMMARY]: responseParsers.memberAccountSummaryParser,
    [actions.OCAPI_GET_MEMBER_ORDERS]: responseParsers.memberOrdersParser,
    [actions.OCAPI_GET_MEMBER_TRANSACTIONS]: responseParsers.memberTransactionsParser,
    [actions.OCAPI_GET_MERCHANTS]: responseParsers.merchantsOnlineParser,
    [actions.OCAPI_GET_MERCHANTS_ALL]: responseParsers.merchantsAllParser,
    [actions.OCAPI_GET_MERCHANTS_BY_IDS]: responseParsers.merchantsOnlineParser,
    [actions.OCAPI_GET_MERCHANT_BY_ID]: responseParsers.merchantsOnlineParser,
    [actions.OCAPI_GET_SIMILAR_MERCHANTS_BY_ID]: responseParsers.merchantsOnlineParser,
    [actions.OCAPI_GET_NAVIGATION]: responseParsers.navigationParser,
    [actions.OCAPI_GET_PLACEMENTS]: responseParsers.placementsParser,
    [`${actions.OCAPI_GET_PLACEMENTS}instore`]: responseParsers.instoreOffersParser,
    [actions.OCAPI_GET_PLACEMENTS_BY_ID]: responseParsers.placementsParser,
    [actions.OCAPI_GET_SEARCH]: responseParsers.searchParser,
    [actions.OCAPI_GET_SEARCH_PRODUCTS]: responseParsers.searchProductsParser,
    [actions.OCAPI_GET_SEARCH_PRODUCTS_BY_ID]: responseParsers.searchProductsByIdParser,
    [actions.OCAPI_GET_INSTORE_OFFERS]: responseParsers.instoreOffersParser,
    [actions.OCAPI_GET_INSTORE_TRANSACTIONS]: responseParsers.instoreTransactionsParser,
  };

  async getAPIResponse({
    caller, parser, shouldParseResponse = false, enableCache, requestArgList, action, state,
  }) {
    const extendedAction = extendActionWithDefaultAPIParameters(action, state);
    return super.getAPIResponse({
      caller, parser, shouldParseResponse, enableCache, requestArgList, action: extendedAction, state,
    });
  }
}

export default new OCAPIRequestor();
