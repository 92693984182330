import {
  RECENT_STORES_FLYOUT_VIEW, RECENT_STORES_FLYOUT_TOGGLE,
  CLICK_HISTORY_FROM_CACHE_GET_SUCCESS, ALL_MERCHANTS_FROM_CACHE_GET_SUCCESS,
  CLICK_HISTORY_GET, ALL_MERCHANTS_GET,
} from './types';

export const triggerRecentStoresFlyoutIsViewed = (merchantsNumber) => ({
  type: RECENT_STORES_FLYOUT_VIEW,
  merchantsNumber,
});

export const triggerRecentStoresFlyoutIsToggled = (isOpen) => ({
  type: RECENT_STORES_FLYOUT_TOGGLE,
  isOpen,
});

export const getClickHistoryDataFromCache = (gmids) => ({
  type: CLICK_HISTORY_FROM_CACHE_GET_SUCCESS,
  gmids,
});

export const getAllMerchantsDataFromCache = (merchants) => ({
  type: ALL_MERCHANTS_FROM_CACHE_GET_SUCCESS,
  merchants,
});

export const getClickHistory = () => ({
  type: CLICK_HISTORY_GET,
});

export const getAllMerchants = () => ({
  type: ALL_MERCHANTS_GET,
});
