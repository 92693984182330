import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { storeRelatedMerchants as orgConfig } from 'org/configs/modules.config';
import { getId } from 'core/utils/analytics/sectionInfo';
import { RELATED_STORES_GET_SUCCESS, RELATED_STORES_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        section_id: getId('store-related-merchants'),
        with_offers: 1,
        limit: 12,
      },
      options: {
        success: RELATED_STORES_GET_SUCCESS,
        failure: RELATED_STORES_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    useAddToFavorite: false,
    slick: {
      slidesToShow: 5,
      centerMode: true,
      infinite: false,
      arrows: true,
      dots: false,
      responsive: [
        {
          breakpoint: 527,
          settings: {
            slidesToShow: 2,
            centerMode: false,
            arrows: false,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
            centerMode: false,
            arrows: false,
            dots: false,
          },
        },
        {
          breakpoint: 932,
          settings: {
            slidesToShow: 4,
            centerMode: false,
            arrows: true,
          },
        },
      ],
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
