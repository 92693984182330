import {
  STICKY_FOOTER_DESKTOP_TOGGLE_TRIGGER, STICKY_FOOTER_DESKTOP_VIEW, STICKY_FOOTER_MOBILE_VIEW,
} from '../actions/types';

export const stickyFooterGaActionEvents = {
  /* Desktop footer */
  [STICKY_FOOTER_DESKTOP_TOGGLE_TRIGGER]: ({ isExpanded, placement, config }) => {
    const { merchant, assets, id } = placement;
    const { api: { contentParams } } = config;
    const contentGroupIds = contentParams.map(({ content_group_id }) => content_group_id).join(',');

    return {
      category: 'Sticky Footer',
      action: isExpanded ? 'Dismiss' : 'Expand',
      label: `${merchant.name} | ${assets.rolloverCopy} | ${merchant.id} | ${contentGroupIds} | ${id}`,
    };
  },
  [STICKY_FOOTER_DESKTOP_VIEW]: ({ placement, config }) => {
    const { merchant, assets, id } = placement;
    const { api: { contentParams } } = config;
    const contentGroupIds = contentParams.map(({ content_group_id }) => content_group_id).join(',');

    return {
      category: 'Sticky Footer',
      action: 'View',
      label: `${merchant.name} | ${assets.rolloverCopy} | ${merchant.id} | ${contentGroupIds} | ${id}`,
    };
  },
  /* Mobile footer */
  [STICKY_FOOTER_MOBILE_VIEW]: () => ({
    category: 'Mobile MHP: Sales Sticky Footer',
    action: 'Sales Sticky Footer View',
    label: '',
    value: 1,
  }),
};

export const stickyFooterClickAndHoverEvents = [
  /* Desktop footer */
  {
    selector: '.mn_stickyFooterDesktop .mn_stickyFooterContent',
    click: {
      category: 'Sticky Footer',
      action: 'Offer Click - Full',
    },
    label: (element) => {
      const {
        merchantId, contentGroupIds, offerId, merchantName,
      } = element.dataset;

      const rolloverCopyElement = element.getElementsByClassName('mn_rolloverCopy')[0] || {};
      const rolloverCopyText = rolloverCopyElement.textContent;

      return `${merchantName} | ${rolloverCopyText} | ${merchantId} | ${contentGroupIds} | ${offerId}`;
    },
  },
  /* Mobile footer */
  {
    selector: '.mn_stickyFooterMobile .mn_stickyFooterMobileInner',
    click: {
      category: 'Mobile MHP: Sales Sticky Footer',
      action: 'Sales Sticky Footer Click',
    },
    label: (element) => element.dataset.merchantId,
    value: 1,
  },
];
