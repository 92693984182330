import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { hybridProducts as orgConfig } from 'org/configs/modules.config';

import { HYBRID_PRODUCTS_GET_SUCCESS, HYBRID_PRODUCTS_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        limit: 4,
        fields: [
          'id',
          'clickUrl',
          'images',
          'name',
          'merchantName',
          'price',
          'merchantRebates',
          'rebate',
          'hasTiered',
          'merchantCount',
          'salePrice',
          'onSale',
        ],
      },
      options: {
        success: HYBRID_PRODUCTS_GET_SUCCESS,
        failure: HYBRID_PRODUCTS_GET_FAILURE,
        cache: { isEnabled: true },
      },
    },
    slick: {
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1384,
          settings: { slidesToShow: 3 },
        },
        {
          breakpoint: 1048,
          settings: { slidesToShow: 2.2 },
        },
        {
          breakpoint: 1023,
          settings: { slidesToShow: 3 },
        },
        {
          breakpoint: 984,
          settings: { slidesToShow: 2 },
        },
        {
          breakpoint: 767,
          settings: { slidesToShow: 1, infinite: true, centerMode: true },
        },
      ],
    },
    rebateOptions: {
      hideElevationCurrency: true,
    },
    extraLabel: '',
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
