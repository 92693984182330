import { getActiveMQ } from 'core/utils/helpers/dom';
import { MEDIA_QUERIES_UPDATE } from '../actions/types';
import {
  MQ_XS, MQ_S, MQ_M, MQ_L, MQ_XL, MQ_XXL,
} from '../utils/mediaQueriesValues';

const initialState = {
};

const getActiveDeviceMQ = (activeMQ) => {
  switch (activeMQ) {
    case MQ_XS:
    case MQ_S:
      return 'mobile';
    case MQ_M:
      return 'tablet';
    case MQ_L:
    case MQ_XL:
    case MQ_XXL:
      return 'desktop';
    default: return '';
  }
};

const calculateWindowSize = (windowWidth) => {
  const activeMQ = getActiveMQ();

  return {
    activeMQ: getActiveMQ(),
    activeDeviceMQ: getActiveDeviceMQ(activeMQ),
    width: windowWidth,
    isMobileMQ: [MQ_XS, MQ_S].includes(activeMQ),
    isTabletMQ: [MQ_M].includes(activeMQ),
    isDesktopMQ: [MQ_L, MQ_XL, MQ_XXL].includes(activeMQ),
    isMobileOrTabletMQ: [MQ_XS, MQ_S, MQ_M].includes(activeMQ),
    isTabletOrDesktopMQ: [MQ_M, MQ_L, MQ_XL, MQ_XXL].includes(activeMQ),
  };
};

const mediaQueries = (state = initialState, action) => {
  switch (action.type) {
    case MEDIA_QUERIES_UPDATE: {
      const mediaQueries = calculateWindowSize(action.windowWidth);
      return {
        ...mediaQueries,
      };
    }
    default: {
      return state;
    }
  }
};

export default mediaQueries;
