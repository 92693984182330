import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { sweepsMerchantLogos as orgConfig } from 'org/configs/modules.config';
import { SWEEPS_MERCHANT_LOGOS_GET_SUCCESS, SWEEPS_MERCHANT_LOGOS_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        offer_tag: 'sweeps-merchant-module-logo',
        content_type_id: 58,
        limit: 12,
        fields: [
          'assets._120x60',
          'merchant.name',
        ],
      },
      options: {
        success: SWEEPS_MERCHANT_LOGOS_GET_SUCCESS,
        failure: SWEEPS_MERCHANT_LOGOS_GET_FAILURE,
        cache: {
          isEnabled: true,
          isPublishedData: true,
        },
      },
    },
    useCarouselOnMediaQueriesOnly: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
    slick: {
      swipeToSlide: true,
      arrows: false,
      slidesToShow: 6,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            swipeToSlide: true,
            centerMode: true,
            arrows: false,
            centerPadding: '30px',
            infinite: true,
            dots: true,
          },
        },
      ],
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
