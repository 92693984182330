import { takeEvery, put } from 'redux-saga/effects';
import { showModal } from 'core/modules/Modal/actions';

import { MODAL_TYPE_FEATURED_STORES } from 'core/modules/Modal/types';
import { FEATURED_STORES_MODAL_TRIGGER } from '../actions/types';

function* showFeaturedStoresModal() {
  yield put(showModal(MODAL_TYPE_FEATURED_STORES));
}

export default function* featuredStoresModalSaga() {
  yield takeEvery(FEATURED_STORES_MODAL_TRIGGER, showFeaturedStoresModal);
}
