import {
  MARKETING_MERCHANTS_HOME_TITLE_GET_SUCCESS,
  MARKETING_MERCHANTS_HOME_TITLE_GET_FAILURE,
} from '../actions/types';
import config from '../configs';

const { titlesConfig } = config;

/** @type {{config: object, placements: array.<object>}} */
const initialState = {
  config: titlesConfig,
  titles: {},
  isLoading: false,
  isLoaded: false,
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const marketingMerchantsHomeTitles = (state = initialState, action) => {
  switch (action.type) {
    case MARKETING_MERCHANTS_HOME_TITLE_GET_SUCCESS: {
      return {
        ...state,
        titles: action.payload.response[0] || {},
        isLoading: false,
        isLoaded: true,
      };
    }
    case MARKETING_MERCHANTS_HOME_TITLE_GET_FAILURE: {
      return {
        ...state,
        titles: {},
        isLoading: false,
        isLoaded: true,
      }; // we'd have to decide what to do with error state
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === MARKETING_MERCHANTS_HOME_TITLE_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default marketingMerchantsHomeTitles;
