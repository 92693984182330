import { findAncestorByClassName } from 'core/utils/helpers/dom';
import { getPageName } from 'core/utils/analytics/pageInfo';
import { getParamsFromSearch } from 'core/utils/helpers/getParamsFromSearch';
import { PRODUCT_DETAILS_MODAL_VIEW } from '../actions/types';

export const productDetailsModalGaActionEvents = {
  [PRODUCT_DETAILS_MODAL_VIEW]: (action) => {
    const { productName } = action;
    const { keywords } = getParamsFromSearch(window.location.search);
    const pageName = getPageName() === 'sr' ? 'PST' : 'HSR';

    return {
      category: 'Product Overlay',
      action: 'Product overlay view',
      label: `${productName} | ${keywords} | ${pageName}`,
      value: 1,
    };
  },
};

export const productDetailsModalClickAndHoverEvents = [
  {
    selector: '.mn_productDetailsModal .mn_merchantClickUrlLink',
    click: {
      category: 'Product Overlay',
      action: 'Product overlay click',
    },
    label: (element) => {
      const productDetails = findAncestorByClassName(element, 'mn_productDetailsModal');
      const productName = productDetails.getElementsByClassName('mn_productName')[0].innerText || '';
      const { keywords } = getParamsFromSearch(window.location.search);
      const { merchantName } = element.dataset || {};
      const pageName = getPageName() === 'sr' ? 'PST' : 'HSR';
      return `${productName} | ${keywords} | ${merchantName} | ${pageName}`;
    },
    value: 1,
  },
];
