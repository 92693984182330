import {
  MARKETING_BANNER_MOBILE_GET_SUCCESS, MARKETING_BANNER_MOBILE_GET_FAILURE,
} from '../actions/types';
import config from '../configs';

const { mobileBannerConfig } = config;

const initialState = {
  config: mobileBannerConfig,
  placements: [],
  isLoading: false,
  isLoaded: false,
};

const mobileBanner = (state = initialState, action) => {
  switch (action.type) {
    case MARKETING_BANNER_MOBILE_GET_SUCCESS: {
      return {
        ...state,
        placements: action.payload.response,
        isLoading: false,
        isLoaded: true,
      };
    }
    case MARKETING_BANNER_MOBILE_GET_FAILURE: {
      return {
        ...state,
        placements: [],
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === MARKETING_BANNER_MOBILE_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default mobileBanner;
