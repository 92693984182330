import { all, call } from 'redux-saga/effects';
import values from 'lodash/values';
import coreSagas from 'core/sagas';
import featuredStoresModalSaga from 'core/modules/Modal/components/FeaturedStoresModal/sagas';
import offerSaga from 'core/modules/Offer/sagas';
import popularCategoriesSaga from 'core/modules/PopularCategories/sagas';
import emailOptInSubscribeModalSaga from 'core/modules/Modal/components/EmailOptInSubscribeModal/sagas';
import emailOptInSuccessModalSaga from 'core/modules/Modal/components/EmailOptInSuccessModal/sagas';
import searchHybridFormSaga from 'core/modules/HybridSearchForm/sagas';
import instoreHiwModalSaga from 'core/modules/Modal/components/InstoreHiwModal/sagas';
import instoreOfferModalSaga from 'core/modules/Modal/components/InstoreOfferModal/sagas';
import instoreAllOffersMapModalSaga from 'core/modules/Modal/components/InstoreAllOffersMapModal/sagas';
import nearMeMapOverlayClickSaga from 'core/modules/Instore/OffersSection/sagas/InstoreAllOffersMapOverlayClick';
import instoreOfferOverlayLinkSaga from 'core/modules/Instore/OffersSection/sagas/instoreOfferOverlayClick';
import editEmailPreferencesModalSaga from 'core/modules/Modal/components/EditEmailPreferencesModal/sagas';
import favoritesAlertModalSaga from 'core/modules/Modal/components/FavoritesAlertModal/sagas';
import instoreManageCardsModal from 'core/modules/Modal/components/InstoreModals/ManageCardsModal/sagas';
import instoreAddNewCardModal from 'core/modules/Modal/components/InstoreModals/AddNewCardModal/sagas';
import instoreSMSNumberModal from 'core/modules/Modal/components/InstoreModals/SMSNumberModal/sagas';
import instoreMultiLinkModal from 'core/modules/Modal/components/InstoreModals/MultiLinkModal/sagas';
import instoreManageCardsOverlayClick from 'core/modules/Instore/OffersSection/sagas/instoreManageCardsOverlayClick';
import instoreAddNewCardOverlayClick from 'core/modules/Instore/OffersSection/sagas/instoreAddNewCardOverlayClick';
import instoreSMSNumberOverlayClick from 'core/modules/Instore/OffersSection/sagas/instoreSMSNumberOverlayClick';
import instoreMultiLinkOverlayClick from 'core/modules/Instore/OffersSection/sagas/instoreMultiLinkOverlayClick';
import hiwVideoModal from 'core/modules/Modal/components/HiwVideoModal/sagas';
import searchModalSaga from 'core/modules/Modal/components/SearchModal/sagas';
import productDetailsModalSaga from 'core/modules/Modal/components/ProductDetailsModal/sagas';
import hybridStoresModalSaga from 'core/modules/Modal/components/HybridStoresOfferModal/sagas';
import favoritesSaga from './favorites';

const combinedSagas = {
  ...coreSagas,
  offerSaga,
  featuredStoresModalSaga,
  popularCategoriesSaga,
  emailOptInSubscribeModalSaga,
  emailOptInSuccessModalSaga,
  searchHybridFormSaga,
  favoritesSaga,
  instoreHiwModalSaga,
  instoreOfferModalSaga,
  instoreManageCardsModal,
  instoreAddNewCardModal,
  instoreSMSNumberModal,
  instoreMultiLinkModal,
  hiwVideoModal,
  instoreOfferOverlayLinkSaga,
  editEmailPreferencesModalSaga,
  favoritesAlertModalSaga,
  instoreAllOffersMapModalSaga,
  nearMeMapOverlayClickSaga,
  instoreManageCardsOverlayClick,
  instoreAddNewCardOverlayClick,
  instoreSMSNumberOverlayClick,
  instoreMultiLinkOverlayClick,
  searchModalSaga,
  productDetailsModalSaga,
  hybridStoresModalSaga,
};

export default function* rootSaga() {
  yield all(values(combinedSagas).map(saga => call(saga)));
}
