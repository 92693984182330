import { REFERRAL_TERMS_GET_SUCCESS, REFERRAL_TERMS_GET_FAILURE } from '../actions/types';
import config from '../configs';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  data: null,
};

const terms = (state = initialState, action) => {
  switch (action.type) {
    case REFERRAL_TERMS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: action.payload.response?.[0]?.termsAndConditions,
      };
    }
    case REFERRAL_TERMS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: [],
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === REFERRAL_TERMS_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }

      return state;
    }
  }
};

export default terms;
