import { HOLIDAY_BORDER_RIGHT_GET_SUCCESS, HOLIDAY_BORDER_RIGHT_GET_FAILURE } from '../actions/types';

import holidayBorderConfig from '../configs';

const initialState = {
  config: holidayBorderConfig.holidayBorderRight,
  isLoading: false,
  isLoaded: false,
  data: {},
};

const holidayBorderRight = (state = initialState, action) => {
  switch (action.type) {
    case HOLIDAY_BORDER_RIGHT_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: action.payload.response[0],
      };
    }
    case HOLIDAY_BORDER_RIGHT_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: {},
      };
    }
    default: {
      const { options = {} } = action;

      if (options.success === HOLIDAY_BORDER_RIGHT_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};

export default holidayBorderRight;
