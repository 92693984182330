import { SUCCESS_SUFFIX, FAILURE_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/featuredOffersLogo';

export const FEATURED_OFFERS_LOGO_GET_SUCCESS = `${rootActionPath}/GET_${SUCCESS_SUFFIX}`;
export const FEATURED_OFFERS_LOGO_GET_FAILURE = `${rootActionPath}/GET_${FAILURE_SUFFIX}`;

export const FEATURED_OFFERS_LOGO_VIEW = `${rootActionPath}/VIEW`;
export const FEATURED_OFFERS_LOGO_SWIPED_TRIGGER = `${rootActionPath}/TRIGGER`;
export const VIEW_ALL_STORES_VIEW = `${rootActionPath}/viewAllStores/VIEW`;
