import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { chromeRating as orgConfig } from 'org/configs/modules.config';

import browserLogoImage from 'core/assets/images/button/chrome.svg';
import storeRatingImage from 'core/assets/images/button/5stars.svg';

const defaultConfig = {
  defaults: {
    browserLogoImage,
    chromeDescription: 'Chrome store rating:',
    storeRatingImage,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
