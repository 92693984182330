import React from 'react';
import PropTypes from 'prop-types';

import NewPill from 'core/modules/NewPill/NewPill';
import ExtraRewardsPill from 'core/modules/ExtraRewardsPill/ExtraRewardsPill';

function MainNavItemWithoutSubnav({
  url, label, title, className, newPillEnabled, ...attrs
}) {
  return (
    <div className={`mn_navigationListItem ${className}`}>
      <a href={url} {...attrs}>
        <span className="mn_navItemLabel">
          {newPillEnabled && <NewPill />}
          <ExtraRewardsPill url={url} label={label} />
          {title}
        </span>
      </a>
    </div>
  );
}

MainNavItemWithoutSubnav.propTypes = {
  url: PropTypes.string,
  title: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  className: PropTypes.string,
  newPillEnabled: PropTypes.bool,
};

MainNavItemWithoutSubnav.defaultProps = {
  className: '',
  newPillEnabled: false,
  url: null,
};

export default MainNavItemWithoutSubnav;
