import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { buyOnlinePickupInstore as orgConfig } from 'org/configs/modules.config';
import { BUY_ONLINE_PICKUP_INSTORE_GET_SUCCESS, BUY_ONLINE_PICKUP_INSTORE_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        limit: 4,
        sort_by: 'random',
        section_id: 10453,
      },
      options: {
        success: BUY_ONLINE_PICKUP_INSTORE_GET_SUCCESS,
        failure: BUY_ONLINE_PICKUP_INSTORE_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
