import { createSelector } from 'reselect';

export const recommendedStores = state => state.recommendedStores;

export const selectRecommendedStoresConfig = createSelector(
  recommendedStores,
  recommendedStores => recommendedStores.config,
);

export const selectRecommendedStoresMerchants = createSelector(
  recommendedStores,
  recommendedStores => recommendedStores.merchants,
);

export const selectRecommendedStoresIsLoading = createSelector(
  recommendedStores,
  recommendedStores => recommendedStores.isLoading,
);

export const selectRecommendedStoresIsLoaded = createSelector(
  recommendedStores,
  recommendedStores => recommendedStores.isLoaded,
);
