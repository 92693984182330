import {
  HYBRID_GSP_STORES_GET_SUCCESS, HYBRID_GSP_STORES_GET_FAILURE,
} from '../actions/types';

import config from '../configs';

const initialState = {
  config: config.gspStores,
  storeIds: [],
  isLoading: false,
  isLoaded: false,
};

const gspStores = (state = initialState, action) => {
  switch (action.type) {
    case HYBRID_GSP_STORES_GET_SUCCESS: {
      const stores = action.payload.response.docs;
      const storeIds = stores.map(({ storeId }) => storeId);
      return {
        ...state,
        storeIds,
        isLoading: false,
        isLoaded: true,
      };
    }
    case HYBRID_GSP_STORES_GET_FAILURE: {
      return {
        ...state,
        storeIds: [],
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === HYBRID_GSP_STORES_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }

      return state;
    }
  }
};

export default gspStores;
