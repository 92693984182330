import React, { useState, useRef, useEffect } from 'react';
import throttle from 'lodash/throttle';
import MoveTo from 'moveto';

import backToTopBtn from './configs';

import './BackToTopBtn.scss';

const SCROLL_THROTTLE_DELAY = 50;
/* scroll animation javascript library https://github.com/hsnaydd/moveTo */
const moveTo = new MoveTo();

const defaultProps = {
  content: 'Back to top',
  additionalClassName: '',
};

function BackToTopBtn({
  content,
  additionalClassName,
}) {
  const [config] = useState(backToTopBtn);
  const [isVisible, setIsVisible] = useState(true);
  const [isSticky, setIsSticky] = useState(true);
  const stickyCloneDiv = useRef();

  const scrollToTop = () => {
    moveTo.move(document.body, { duration: 500 });
  };

  const scrollHandler = () => {
    const { useRelativeBottomPositioning } = config;
    const footer = document.getElementsByClassName('mn_brandFooter')[0];

    setIsVisible(window.pageYOffset > 100);

    if (useRelativeBottomPositioning && stickyCloneDiv.current && footer) {
      const stickyCloneStyles = window.getComputedStyle(stickyCloneDiv.current);
      const buttonBottomOffset = parseInt(stickyCloneStyles.bottom, 10) || 0;
      const isSticky = footer.getBoundingClientRect().top + buttonBottomOffset >=
        (window.innerHeight || document.documentElement.clientHeight);

      setIsSticky(isSticky);
    }
  };
  const updateHeaderOnScroll = throttle(scrollHandler, SCROLL_THROTTLE_DELAY);

  useEffect(() => {
    document.addEventListener('scroll', updateHeaderOnScroll);
    scrollHandler();

    return () => {
      document.removeEventListener('scroll', updateHeaderOnScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`mn_backToTopButtonSticky ${additionalClassName}`}>
      {
        isVisible &&
        <button
          className={`mn_backToTopButton ${isSticky ? 'mn_isSticky' : ''}`}
          onClick={scrollToTop}
          aria-label="Back to top of the page"
        >
          {content}
        </button>
      }
      {/* Always sticky hidden clone, which is used to calculate BackToTop Button offset */}
      <div className="mn_backToTopButton mn_isSticky mn_hiddenClone" ref={stickyCloneDiv} />
    </div>
  );
}

BackToTopBtn.defaultProps = defaultProps;

export default BackToTopBtn;
