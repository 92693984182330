import { getDisplayPageName } from 'core/utils/analytics/pageInfo';
import { POPULAR_OFFERS_VIEW } from '../actions/types';

export const popularOffersGaActionEvents = {
  [POPULAR_OFFERS_VIEW]: ({ offersToDisplay, offersLength }) => {
    const pageName = getDisplayPageName();
    const eventCategoryName = pageName === 'Home' ? 'MHP Right Rail' : `${pageName} - Popular Offers`;

    const namesAndCopies = offersToDisplay.reduce((result, offer) => {
      const { id, merchant, assets } = offer;

      result.push(`${id} | ${merchant.name} | ${assets.imageAlternateText}`);

      return result;
    }, []).join(', ');

    return {
      category: eventCategoryName,
      action: 'View',
      label: namesAndCopies,
      value: offersLength,
    };
  },
};

export const popularOffersClickAndHoverEvents = [
  {
    selector: '.mn_popularOffers .mn_offerWrap a',
    click: {
      category: getDisplayPageName() === 'Home' ? 'MHP Right Rail' : `${getDisplayPageName()} - Popular Offers`,
      action: getDisplayPageName() === 'Home' ? 'Click' : '300x250 Ad Click',
    },
    label: (element) => {
      const { merchantName, offerCopy } = element.dataset;

      return `${merchantName} | ${offerCopy}`;
    },
  },
];
