export const favoritesLandingClickAndHoverEvents = [
  // favorites adoption
  {
    selector: '.mn_favoritesAdoption .mn_closeHIW',
    click: {
      category: 'Favorites',
      action: element => (element.classList.contains('mn_closeHIWIcon') ? 'X' : 'Get started'),
    },
  },
  {
    selector: '.mn_favoritesAdoption .mn_viewFavorites:not(.mn_disabled)',
    click: {
      category: 'Favorites',
      action: 'view my favorites',
    },
  },
  {
    selector: '.mn_favoritesAdoption .mn_backToSiteLink',
    click: {
      category: 'Favorites',
      action: 'no thanks take me to shopping',
    },
  },
  // favorites search
  {
    selector: '.mn_favoritesSearchWrap .mn_addToFavorites',
    click: {
      category: 'Favorites',
      action: 'add-favorite',
      label: '',
    },
  },
];
