import { RECENT_STORES_FLYOUT_VIEW, RECENT_STORES_FLYOUT_TOGGLE } from '../actions/types';

export const recentStoresFlyoutGaActionEvents = {
  [RECENT_STORES_FLYOUT_VIEW]: ({ merchantsNumber }) => ({
    category: 'Recent Stores Module',
    action: 'Recent Stores View',
    label: merchantsNumber,
    value: 1,
  }),
  [RECENT_STORES_FLYOUT_TOGGLE]: ({ isOpen }) => ({
    category: 'Recent Stores Module',
    action: `Recent Stores ${isOpen ? 'Open' : 'Close'}`,
    label: '',
    value: 1,
  }),
};

export const recentStoresFlyoutClickAndHoverEvents = [
  {
    selector: '.mn_recentStoresFlyout .mn_logoItem a',
    click: {
      category: 'Recent Stores Module',
      action: 'Recent Stores Merchant Click',
      value: 1,
    },
    label: '',
  },
];
