import { OFFERS_MARKETING_FLYOUT_GET_SUCCESS, OFFERS_MARKETING_FLYOUT_GET_FAILURE } from '../actions/types';
import config from '../configs';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  placement: [],
};

const offersMarketingFlyout = (state = initialState, action) => {
  switch (action.type) {
    case OFFERS_MARKETING_FLYOUT_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        placement: action.payload.response[0],
      };
    }
    case OFFERS_MARKETING_FLYOUT_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        placement: [],
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === OFFERS_MARKETING_FLYOUT_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }

      return state;
    }
  }
};

export default offersMarketingFlyout;
