import Cookies from 'js-cookie';
import dayjs from 'core/utils/dayjs';
import analytics from 'core/utils/analytics/analytics';
import dyAnalytics from 'core/utils/analytics/dyAnalytics';
import { initiateInsider } from 'core/utils/analytics/insiderAnalytics';
import { gaClickAndHoverEvents } from 'org/utils/analytics/ga-events';
import LocalStorageCache from '../cache/localStorage';
import SessionStorageCache from '../cache/sessionStorage';
import { cacheStorageCache } from '../cache/cacheStorage';

/**
 * Make sure that we clear local/session storage cache on brand/user switch
 *
 * @param {LocalStorageCache} localStorageCache
 * @param {SessionStorageCache} sessionStorageCache
 * @param {object} state
 */
const clearCacheOnIdentityChange = async (storages, state) => {
  const { localStorageCache, sessionStorageCache, cacheStorageCache } = storages;
  const {
    app: { brand },
    env: { revisionHash },
    user: { userIdHashed },
    services: { ocapi: { publishId } },
  } = state;

  const identityToken = `${userIdHashed}::${brand.id}::${revisionHash}`;
  const cachedIdentityToken = localStorageCache.getItem('identityToken');
  // clear cache if brand id or user id or revision version has changed
  if (cachedIdentityToken !== identityToken) {
    localStorageCache.clear();
    sessionStorageCache.clear();
    cacheStorageCache.clear();

    localStorageCache.setItem('publishId', publishId);
    localStorageCache.setItem('identityToken', identityToken);
  }
};

const clearCachedPublishedDataOnPublishIdChange = async (storages, state) => {
  const { localStorageCache, sessionStorageCache, cacheStorageCache } = storages;
  const { services: { ocapi: { publishId } } } = state;

  if (publishId) {
    const cachedPublishId = localStorageCache.getItem('publishId');

    // compare to retrieved publish id and delete any cached published data if different
    if (!cachedPublishId || publishId !== cachedPublishId) {
      localStorageCache.clearPublishedData();
      sessionStorageCache.clearPublishedData();
      cacheStorageCache.clearPublishedData();
    }

    // update cached publish id
    localStorageCache.setItem('publishId', publishId);
  }
};

const initiateDy = () => {
  dyAnalytics.init(window.initialState);
};

const initiateGa = () => {
  const { app: { features: { ga } }, user: { userIdHashed, isIdentified } } = window.initialState;

  if (!ga.isEnabled) {
    return;
  }

  analytics.initializeGA(ga.tag, {
    userId: userIdHashed,
    isUserIdentified: isIdentified,
    mouseHoverTrackDelay: 200,
  }, window.initialState, gaClickAndHoverEvents);
};

const injectThirdParties = () => {
  initiateGa();
  initiateDy();
  initiateInsider();
};

const updateServices = () => {
  const { services: { ocapi }, user } = window.initialState;

  // Inject member token into ocapi credentials
  if (user.memberToken) {
    ocapi.credentials.mem_id = user.memberToken;
  }
};

const updateEnvState = () => {
  const { env } = window.initialState;
  const previewDateTime = Cookies.get('preview');

  // Inject current date
  env.currentDate = env.serverDate;

  // Inject preview date/time
  if (previewDateTime) {
    env.previewDateTime = dayjs(previewDateTime).format();
  }
};

/**
 * @return {object}
 */
const loadInitialState = () => {
  const storages = {
    localStorageCache: new LocalStorageCache(),
    sessionStorageCache: new SessionStorageCache(),
    cacheStorageCache,
  };

  clearCacheOnIdentityChange(storages, window.initialState);
  clearCachedPublishedDataOnPublishIdChange(storages, window.initialState);
  updateEnvState();
  injectThirdParties();
  updateServices();

  return {
    app: window.initialState,
  };
};

export default loadInitialState;
