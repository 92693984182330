import merge from 'lodash/merge';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import getPageName from './getPageName';

/**
 * @param {object} obj
 * @return {object}
 */
const removeNilProps = (obj) => {
  if (isObject(obj)) {
    Object.entries(obj).forEach(([key, value]) => {
      /* eslint-disable no-param-reassign */
      if (isNil(value)) {
        delete obj[key];
      } else if (isObject(value)) {
        obj[key] = removeNilProps(value);
      }
      /* eslint-enable no-param-reassign */
    });
  }
  return obj;
};

export const mergeConfigs = (configA, configB) => (
  merge({}, configA, configB)
);

/**
 * @param {object} coreModuleConfig
 * @param {object} orgModuleConfig
 * @return {object}
 */
export default (coreModuleConfig, orgModuleConfig) => {
  const mergedConfig = mergeConfigs(coreModuleConfig, orgModuleConfig);
  const defaultConfig = mergedConfig.defaults;
  const currentPageConfig = mergedConfig[getPageName()];
  const resultConfig = merge({}, defaultConfig, currentPageConfig);
  const strippedConfig = removeNilProps(resultConfig);
  return strippedConfig;
};
