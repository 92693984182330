import { getDisplayPageName } from 'core/utils/analytics/pageInfo';
import { INFO_BAR_VIEW } from '../actions/types';

export const infoBarGaActionEvents = {
  [INFO_BAR_VIEW]: ({ offer }) => {
    const {
      isDealOfTheWeek,
      assets: { shortSummaryText },
      merchant: { name: merchantName },
    } = offer;

    return {
      category: `${getDisplayPageName()} DOTW`,
      action: `${isDealOfTheWeek ? 'DOTW view' : 'Daily Deal view'}`,
      label: `${merchantName} | ${shortSummaryText}`,
    };
  },
};

export const infoBarClickAndHoverEvents = [
  {
    selector: '.mn_infoBar .mn_collapsed.mn_dealOfTheWeek',
    click: {
      category: `${getDisplayPageName()} DOTW`,
      action: 'DOTW expand',
    },
    label: (element) => {
      const { merchantName } = element.dataset;

      const shortSummaryEl = element.getElementsByClassName('mn_summaryText');
      const shortSummaryText = shortSummaryEl.length > 0 ? shortSummaryEl[0].innerText || '' : '';

      return `${merchantName} | ${shortSummaryText}`;
    },
  },
  {
    selector: '.mn_infoBar .mn_collapsed:not(.mn_dealOfTheWeek)',
    click: {
      category: `${getDisplayPageName()} DOTW`,
      action: 'Daily Deal expand',
    },
    label: (element) => {
      const { merchantName } = element.dataset;

      const shortSummaryEl = element.getElementsByClassName('mn_summaryText');
      const shortSummaryText = shortSummaryEl.length > 0 ? shortSummaryEl[0].innerText || '' : '';

      return `${merchantName} | ${shortSummaryText}`;
    },
  },
  {
    selector: `.mn_infoBar .mn_expanded.mn_dealOfTheWeek,
      .mn_infoBar .mn_offersSliderWrapper .mn_dealOfTheWeek`,
    click: {
      category: `${getDisplayPageName()} DOTW`,
      action: 'DOTW CTA click',
    },
    label: (element) => {
      const { merchantName } = element.dataset;

      const shortSummaryEl = element.getElementsByClassName('mn_summaryText');
      const shortSummaryText = shortSummaryEl.length > 0 ? shortSummaryEl[0].innerText || '' : '';

      const rolloverCopyEl = element.getElementsByClassName('mn_rolloverCopy');
      const rolloverCopyText = rolloverCopyEl.length > 0 ? rolloverCopyEl[0].innerText || '' : '';
      const textToShow = rolloverCopyEl.length > 0 ? rolloverCopyText : shortSummaryText;

      return `${merchantName} | ${textToShow}`;
    },
  },
  {
    selector: `.mn_infoBar .mn_expanded:not(.mn_dealOfTheWeek),
      .mn_infoBar .mn_offersSliderWrapper .mn_offer:not(.mn_dealOfTheWeek)`,
    click: {
      category: `${getDisplayPageName()} DOTW`,
      action: 'Daily Deal CTA click',
    },
    label: (element) => {
      const { merchantName } = element.dataset;

      const shortSummaryEl = element.getElementsByClassName('mn_summaryText');
      const shortSummaryText = shortSummaryEl.length > 0 ? shortSummaryEl[0].innerText || '' : '';

      const rolloverCopyEl = element.getElementsByClassName('mn_rolloverCopy');
      const rolloverCopyText = rolloverCopyEl.length > 0 ? rolloverCopyEl[0].innerText || '' : '';
      const textToShow = rolloverCopyEl.length > 0 ? rolloverCopyText : shortSummaryText;

      return `${merchantName} | ${textToShow}`;
    },
  },
];
