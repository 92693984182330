import React, { useRef } from 'react';

import keyCodes from 'core/utils/helpers/keyCodes';
import NavCategoryFlyout from '../NavCategoryFlyout/NavCategoryFlyout';
import NavCategoryFeatured from '../NavCategoryFeatured/NavCategoryFeatured';

import './NavCategoryTOF.scss';

function NavCategoryTOF({
  url, title, label, featured, isFocused, showDropdown, hideDropdown, flyout,
}) {
  const categoryTitleEl = useRef();
  const onBlurHandler = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      hideDropdown();
    }
  };

  const onCloseButtonHandler = (event) => {
    categoryTitleEl.current.focus();
    hideDropdown(event);
  };

  const onKeyDownHandler = (event) => {
    const pressedKey = event.keyCode;
    switch (pressedKey) {
      case keyCodes.ENTER:
      case keyCodes.SPACEBAR:
        event.preventDefault();
        showDropdown(null, true);
        break;
      case keyCodes.ESCAPE:
        event.preventDefault();
        hideDropdown();
        break;
      default:
        break;
    }
  };

  return (
    <li
      onMouseEnter={showDropdown}
      onMouseLeave={hideDropdown}
      onBlur={onBlurHandler}
      className={`mn_navCategoriesTOF ${isFocused ? 'mn_focused' : ''}`}
    >
      {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
      <a
        onKeyDown={onKeyDownHandler}
        href={url}
        title={label || title}
        ref={categoryTitleEl}
        aria-expanded={isFocused}
        aria-haspopup="true"
      >
        {title}
      </a>
      <div className="mn_dropdownMenuContent mn_categoryContent" data-title={title}>
        <NavCategoryFeatured featured={featured} title={title} url={url} />
        <NavCategoryFlyout flyout={flyout} />
        <button className="mn_closeFlyoutMenu" aria-label="Close Menu" onClick={onCloseButtonHandler} />
      </div>
    </li>
  );
}

export default NavCategoryTOF;
