import { getParamsFromSearch } from 'core/utils/helpers/getParamsFromSearch';

export const hybridProductsClickAndHoverEvents = [
  {
    selector: '.mn_hybridProductsWrap .mn_viewAllLink',
    click: {
      category: 'Hybrid Search Results Page',
      action: 'See all products click',
    },
    label: getParamsFromSearch(window.location.search).keywords,
    value: 1,
  },
  {
    selector: '.mn_hybridProductsWrap .mn_productOverlayLink',
    click: {
      category: 'Hybrid Search Results Page',
      action: 'Product click',
    },
    label: (element) => {
      const productName = element.getElementsByClassName('mn_productLogo')[0].alt || '';
      const { keywords } = getParamsFromSearch(window.location.search);

      return `${productName} | ${keywords} | Product overlay shown`;
    },
    value: 1,
  },
  {
    selector: '.mn_hybridProductsWrap .mn_prodDetails',
    click: {
      category: 'Hybrid Search Results Page',
      action: 'Product click',
    },
    label: (element) => {
      const productName = element.getElementsByClassName('mn_productLogo')[0].alt || '';
      const { keywords } = getParamsFromSearch(window.location.search);

      return `${productName} | ${keywords} | Product overlay not shown`;
    },
    value: 1,
  },
];
