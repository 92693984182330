import { MERCHANT_LOGOS_GET_SUCCESS, MERCHANT_LOGOS_GET_FAILURE } from '../actions/types';
import config from '../configs';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  placement: [],
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const merchantLogos = (state = initialState, action) => {
  switch (action.type) {
    case MERCHANT_LOGOS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        placement: action.payload.response,
      };
    }
    case MERCHANT_LOGOS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        placement: [],
      };
    }
    default: {
      const { options = {} } = action;

      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === MERCHANT_LOGOS_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }

      return state;
    }
  }
};

export default merchantLogos;
