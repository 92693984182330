import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { backToTopBtn as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    useRelativeBottomPositioning: true,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
