import { findAncestorByClassName } from 'core/utils/helpers/dom';
import { NAV_CATEGORIES_TOF_ITEM_IS_VIEWED } from '../actions/types';

const VALUE_NOT_SET = '(not set)';

export const navCategoriesGaActionEvents = {
  [NAV_CATEGORIES_TOF_ITEM_IS_VIEWED]: ({ categoryTitle }) => ({
    category: 'Explore Stores - Flyout',
    action: 'Hover on Category',
    label: categoryTitle,
  }),
};

export const navCategoriesClickAndHoverEvents = [
  {
    selector: '.mn_navCategoriesTOFSection .mn_allStoresLink',
    click: {
      category: 'Explore Stores - Flyout',
      action: 'All stores button click',
    },
    label: VALUE_NOT_SET,
  },
  {
    selector: '.mn_categoryContent .mn_closeFlyoutMenu',
    click: {
      category: 'Explore Stores - Flyout',
      action: 'Close category flyout',
    },
    label: (element) => {
      const navBannerContentElement = findAncestorByClassName(element, 'mn_categoryContent') || {};
      const { title } = navBannerContentElement.dataset || {};
      return title;
    },
  },
  {
    selector: '.mn_navPopularStoresSection li > a',
    click: {
      category: 'Explore Stores - Flyout',
      action: 'Popular Stores Click',
    },
    label: (element) => `${element.dataset.merchantId}|${element.dataset.merchantName}`,
  },
  {
    selector: '.mn_navOffersList li > a',
    click: {
      category: 'Explore Stores - Flyout',
      action: 'Shop by Offers Click',
    },
    label: (element) => `${element.textContent}|${element.href}`,
  },
  {
    selector: '.mn_navCategoryFeaturedFooter .mn_seeAllLink',
    click: {
      category: 'Explore Stores - Flyout',
      action: 'View All Category Click',
    },
    label: element => element.dataset.categoryName,
  },
  {
    selector: '.mn_catList .mn_navCategoriesTOF a',
    click: {
      category: 'Explore Stores - Flyout',
      action: 'Category Click',
    },
    label: (element) => element.innerText || '',
  },
];
