import { MARKETING_MERCHANTS_HOME_VIEW } from '../actions/types';

const VALUE_NOT_SET = '(not set)';

export const marketingMerchantsHomeGaActionEvents = {
  [MARKETING_MERCHANTS_HOME_VIEW]: () => (
    {
      category: 'MHP Marketing Merchant Module',
      action: 'View (after scroll)',
      label: VALUE_NOT_SET,
      value: 1,
    }
  ),
};

export const marketingMerchantsHomeClickAndHoverEvents = [
  {
    selector: '.mn_marketingMerchantsHome .mn_viewAllLink',
    click: {
      category: 'MHP Marketing Merchant Module',
      action: 'Click (Headline CTA)',
    },
    label: VALUE_NOT_SET,
    value: 1,
  },
  {
    selector: '.mn_marketingMerchantsHome .mn_merchantExperienceLink',
    click: {
      category: 'MHP Marketing Merchant Module',
      action: 'Click (any merchant in module)',
    },
    label: (element) => {
      const { merchantName } = element.dataset;

      return `${merchantName}`;
    },
    value: 1,
  },
];
