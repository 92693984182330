import React from 'react';

import mobileLandingImage from '../assets/mobileLandingImage.png';

function ButtonLandingMobileContent({ siteUrl }) {
  return (
    <div className="mn_buttonLandingMobile">
      <div className="mn_buttonLandingMobileHeader">
        <div className="mn_buttonLandingMobileWrap">
          <i className="mn_alertIcon" aria-hidden="true" />
          The United Airlines MileagePlus Shopping<sup>SM</sup> button is only supported on Google Chrome,
          Microsoft Edge or Safari for desktop - please revisit this page on your Chrome,
          Edge or Safari browser to access.
        </div>
      </div>

      <div className="mn buttonLandingMobileContentWrap mn_clearfix">
        <div className="mn_buttonLandingMobileContent">
          <h2>Benefits of adding the <br /> button for desktop:</h2>

          <ul className="mn_benefitsList">
            <li>Never forget to earn miles</li>
            <li>Activate earnings right on store sites</li>
            <li>Compare mileage offers in search results</li>
            <li>Automatically apply coupons at checkout</li>
          </ul>

          <div className="mn_buttonLandingMobileImage">
            <img src={mobileLandingImage} alt="" />
          </div>
        </div>
      </div>

      <div className="mn_buttonLandingMobileFooter">
        <p>You can still earn miles shopping from your phone or tablet now.</p>
        <a href={siteUrl} className="mn_button">Keep shopping</a>
      </div>
    </div>
  );
}

export default ButtonLandingMobileContent;
