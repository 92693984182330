// http://stackoverflow.com/a/15023688/1754241
export default (uri, key, value) => {
  const re = new RegExp(`([?|&])${key}=.*?(&|#|$)`, 'i');
  if (uri.match(re)) {
    return uri.replace(re, `$1${key}=${value}$2`);
  }

  const hasHash = uri.indexOf('#') !== -1;
  const hash = hasHash ? uri.replace(/.*#/, '#') : '';
  const parsedUri = hasHash ? uri.replace(/#.*/, '') : uri;
  const separator = uri.indexOf('?') !== -1 ? '&' : '?';

  return `${parsedUri}${separator}${key}=${value}${hash}`;
};
