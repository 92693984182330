import { ADBLOCK_IS_INSTALLED_CONFIRM, ADBLOCK_IS_INSTALLED_SEND_ANALYTICS } from './types';

export const confirmAdblockIsInstalled = () => ({
  type: ADBLOCK_IS_INSTALLED_CONFIRM,
});

export const sendAnalyticsAdblockIsInstalled = displayableUserId => ({
  type: ADBLOCK_IS_INSTALLED_SEND_ANALYTICS,
  displayableUserId,
});
