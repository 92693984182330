import { combineReducers } from 'redux';
import config from '../configs'; // quick search config
import merchants from './merchants';
import gspMerchants from './gspMerchants';
import gspProducts from './gspProducts';

export default combineReducers({
  config: () => config,
  merchants,
  gspMerchants,
  gspProducts,
});
