export const instoreHIWClickAndHoverEvents = [
  {
    selector: '.mn_instoreHiw .mn_button',
    click: {
      category: 'In-store',
      action: 'Clicked to open HIW popup',
    },
    label: 'See how it works',
  },
  {
    selector: '.mn_instoreHowToEarnContent .mn_instoreHowToEarnCTA',
    click: {
      category: 'In-store',
      action: 'Clicked CTA in HIW popup',
    },
    label: element => (element.dataset.ctaText === 'true' ? 'Got it' : 'Get Started'),
  },
];
