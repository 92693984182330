import { takeEvery, put } from 'redux-saga/effects';

import {
  INSTORE_SMS_NUMBER_CLICK,
} from '../actions/types';
import { INSTORE_SMS_NUMBER_MODAL_TRIGGER } from '../../../Modal/components/InstoreModals/SMSNumberModal/actions/types';

function* putInstoreSMSNumberModalAction(action = {}) {
  const { data } = action;
  yield put({
    type: INSTORE_SMS_NUMBER_MODAL_TRIGGER,
    data,
  });
}

export default function* instoreSMSNumberOverlayClick() {
  yield takeEvery(INSTORE_SMS_NUMBER_CLICK, putInstoreSMSNumberModalAction);
}
