import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { similarStores as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    maxMerchantsInPopUpView: 2,
    maxMerchantsInDropDownView: 4,
    rebateOptions: {
      showRebateInline: true,
      hideElevationCurrency: true,
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
