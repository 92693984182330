import React from 'react';
import PremierMerchantRebate from 'core/modules/PremierMerchantRebate/PremierMerchantRebate';
import FavoriteIcon from 'core/modules/FavoriteIcon/FavoriteIcon';

import './SimilarStoreTile.scss';

function SimilarStoreTile({ merchant, config }) {
  const { rebateOptions } = config;

  const {
    id, name, logoUrls, rebate, clickUrl,
  } = merchant;

  if (!logoUrls || !rebate) {
    return null;
  }

  return (
    <a
      className="mn_similarStoreTile mn_storeWrap"
      href={clickUrl}
      title={`Opens a new window at ${name}`}
      target="_blank"
      data-merchant-name={name}
      data-merchant-id={id}
    >
      <div className="mn_itemContent">
        <div className="mn_favoritesWrap">
          <FavoriteIcon merchantId={id} merchantName={name} tabIndex="-1" />
        </div>
        <div className="mn_logoWrap">
          <img className="mn_merchantLogo" src={logoUrls._88x31} alt={name} />
        </div>
        <div className="mn_rebateWrap">
          <PremierMerchantRebate {...rebate} {...rebateOptions} />
        </div>
      </div>
    </a>
  );
}

export default SimilarStoreTile;
