import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { selectSiteUrl, selectPageName, selectEnableRecentStoresFlyout } from 'core/selectors/app';
import { selectUserIsIdentified } from 'core/selectors/user';
import {
  selectClickHistoryIsLoaded, selectClickHistoryIsLoading,
  selectAllMerchantsIsLoaded, selectAllMerchantsIsLoading,
  selectRecentStoresMerchantsToDisplay,
} from './selectors';

import {
  triggerRecentStoresFlyoutIsViewed, triggerRecentStoresFlyoutIsToggled, getClickHistory, getAllMerchants,
} from './actions';

import config from './configs';

import './RecentStoresFlyout.scss';

function RecentStoresFlyout() {
  const dispatch = useDispatch();
  const siteUrl = useSelector(selectSiteUrl);
  const enableRecentStoresFlyout = useSelector(selectEnableRecentStoresFlyout);
  const [isFlyoutExpanded, setIsFlyoutExpanded] = useState(true);

  const clickHistoryIsLoaded = useSelector(selectClickHistoryIsLoaded);
  const clickHistoryIsLoading = useSelector(selectClickHistoryIsLoading);

  const allMerchantsIsLoaded = useSelector(selectAllMerchantsIsLoaded);
  const allMerchantsIsLoading = useSelector(selectAllMerchantsIsLoading);
  const recentStoresMerchantsToDisplay = useSelector(selectRecentStoresMerchantsToDisplay);

  const userIsIdentified = useSelector(selectUserIsIdentified);
  const pageName = useSelector(selectPageName);

  const { tagHelper, headingText } = config;

  const handleRef = useCallback((ref) => {
    tagHelper(ref); // handle Chase tagging implementation
  }, []);

  const checkIsPageEligible = () => {
    const excludedPages = [
      'click', 'ai', 'eu', 'j', 'ma',
      'button-landing', 'button-uninstall', 'button-chrome-install', 'button-firefox-install', 'button-edge-install',
      'button-safari-tutorial',
    ];

    return !excludedPages.includes(pageName);
  };

  const checkIfShouldRenderSection = () => {
    const isPageEligible = checkIsPageEligible();

    return enableRecentStoresFlyout && userIsIdentified && isPageEligible;
  };

  const shouldRenderSection = checkIfShouldRenderSection();

  useEffect(() => {
    if (shouldRenderSection) {
      !clickHistoryIsLoaded && !clickHistoryIsLoading && dispatch(getClickHistory());
      !allMerchantsIsLoaded && !allMerchantsIsLoading && dispatch(getAllMerchants());
    }
  }, []);

  useEffect(() => {
    if (clickHistoryIsLoaded && allMerchantsIsLoaded && !isEmpty(recentStoresMerchantsToDisplay)) {
      dispatch(triggerRecentStoresFlyoutIsViewed(recentStoresMerchantsToDisplay.length));
    }
  }, [clickHistoryIsLoaded, allMerchantsIsLoaded]);

  if (!shouldRenderSection || isEmpty(recentStoresMerchantsToDisplay)) {
    return null;
  }

  const handleClick = () => {
    dispatch(triggerRecentStoresFlyoutIsToggled(!isFlyoutExpanded));
    setIsFlyoutExpanded(!isFlyoutExpanded);
  };

  return (
    <div
      className={`mn_recentStoresFlyout ${isFlyoutExpanded ? 'mn_flyoutInfoShow' : 'mn_flyoutInfoHide'}`}
      ref={handleRef}
    >
      <button
        className="mn_head"
        title="Recent stores flyout close and open"
        aria-expanded={isFlyoutExpanded}
        onClick={handleClick}
      >
        <h2>{headingText}</h2>
      </button>
      <div className="mn_logoContainer">
        {recentStoresMerchantsToDisplay.map(({
          logoUrls, href, name, id,
        }) => (
          <div className="mn_logoList" key={id}>
            <div className="mn_logoItem">
              <a href={`${siteUrl}${href}`} title={`Opens merchant detail page for ${name}`}>
                <img src={logoUrls._88x31} alt={name} />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><RecentStoresFlyout {...props} /></ComponentErrorBoundary>;
