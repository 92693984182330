import { FEATURED_DEALS_VIEW, MY_ACCOUNT_FEATURED_DEALS_VIEW } from '../actions/types';

export const featuredDealsGaActionEvents = {
  [FEATURED_DEALS_VIEW]: ({ deals }) => {
    const namesAndCopies = deals.reduce((result, deal) => {
      const { merchant, assets } = deal;

      result.push(`${merchant.name} | ${assets.rolloverCopy}`);

      return result;
    }, []).join(', ');

    return {
      category: 'Featured Deals',
      action: 'View',
      label: namesAndCopies,
      value: deals.length,
    };
  },
};

export const myAccountFeaturedDealsGaActionEvents = {
  [MY_ACCOUNT_FEATURED_DEALS_VIEW]: ({ deals }) => {
    const ids = deals.reduce((result, deal) => {
      const { merchant } = deal;

      result.push(`${merchant.id}`);

      return result;
    }, []).join(',');

    return {
      category: 'My Account Featured Deals Placement',
      action: 'My Account Featured Deal View',
      label: ids,
      value: 1,
    };
  },
};

export const featuredDealsClickAndHoverEvents = [
  {
    selector: '#mn_h .mn_featuredDeals .mn_featuredDeal',
    click: {
      category: 'Featured Deals',
      action: 'Click',
    },
    label: (element) => {
      const { merchantName, offerId } = element.getElementsByClassName('mn_offerOverlayLink')[0].dataset;
      const rolloverCopyEl = element.getElementsByClassName('mn_rolloverCopy');
      const rolloverCopyText = rolloverCopyEl.length > 0 ? rolloverCopyEl[0].innerText || '' : '';

      return `${offerId} | ${merchantName} | ${rolloverCopyText}`;
    },
  },
];

export const myAccountFeaturedDealsClickAndHoverEvents = [
  {
    selector: '.mn_profile .mn_featuredDeals .mn_featuredDeal',
    click: {
      category: 'My Account Featured Deals Placement',
      action: 'My Account Featured Deal Click',
    },
    label: (element) => {
      const { merchantId } = element.getElementsByClassName('mn_offerOverlayLink')[0].dataset;
      return `${merchantId}`;
    },
    value: 1,
  },
];
