import {
  FAVORITES_LANDING_MERCHANTS_ADD_GET_SUCCESS,
  FAVORITES_LANDING_MERCHANTS_ADD_GET_FAILURE,
  FAVORITES_LANDING_MERCHANTS_REMOVE,
  FAVORITES_LANDING_MERCHANTS_LOAD,
} from '../actions/types';

import config from '../configs';

const initialState = {
  config,
  isLoaded: false, // Change on initial data load
  isUpdating: false,
  isUpdated: false,
  merchants: [],
};

const favoritesLandingMerchants = (state = initialState, action) => {
  switch (action.type) {
    case FAVORITES_LANDING_MERCHANTS_LOAD: {
      return {
        ...state,
        isLoaded: true,
      };
    }
    case FAVORITES_LANDING_MERCHANTS_REMOVE: {
      const { deletedFavoriteIds } = action.payload;
      return {
        ...state,
        merchants: state.merchants.filter(({ id }) => !deletedFavoriteIds.includes(id)),
      };
    }
    case FAVORITES_LANDING_MERCHANTS_ADD_GET_SUCCESS: {
      // Sort merchants alphabetically on page load and then add new favorited merchants to the end of the list
      const { merchants } = state;
      const { response } = action.payload;

      if (!state.isLoaded) {
        response.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
      }

      return {
        ...state,
        isLoaded: true,
        isUpdating: false,
        isUpdated: true,
        merchants: [
          ...merchants,
          ...response,
        ],
      };
    }
    case FAVORITES_LANDING_MERCHANTS_ADD_GET_FAILURE: {
      return {
        ...state,
        isUpdating: false,
        isUpdated: true,
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === FAVORITES_LANDING_MERCHANTS_ADD_GET_SUCCESS) {
        return {
          ...state,
          isUpdating: true,
          isUpdated: false,
        };
      }
    }
  }

  return state;
};

export default favoritesLandingMerchants;
