import { combineReducers } from 'redux';
import transactionAdUnit from 'core/modules/Profile/components/TransactionAdUnit/reducers';
import transactionsTab
  from 'core/modules/Profile/components/RecentActivity/components/TransactionsTab/reducers';
import clickHistoryTab
  from 'core/modules/Profile/components/RecentActivity/components/ClickHistoryTab/reducers';
import instoreTab
  from 'core/modules/Profile/components/RecentActivity/components/InstoreTab/reducers';
import config from '../configs';

export default combineReducers({
  profileConfig: () => config,
  transactionAdUnit,
  transactionsTab,
  clickHistoryTab,
  instoreTab,
});
