import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { sponsoredTextOffers as orgConfig } from 'org/configs/modules.config';
import {
  SPONSORED_TEXT_OFFERS_GET_SUCCESS,
  SPONSORED_TEXT_OFFERS_GET_FAILURE,
} from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: SPONSORED_TEXT_OFFERS_GET_SUCCESS,
        failure: SPONSORED_TEXT_OFFERS_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
      params: {
        sort_by: 'random',
        group_by_merchant: 0,
        limit: 5,
        fields: [
          'assets',
          'clickUrl',
          'code',
          'couponCode',
          'stopDatetime',
          'id',
          'disclaimer',
          'merchant',
        ].join(','),
        section_id: 10426,
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
