import { SUCCESS_SUFFIX, FAILURE_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/HybridSearchResults';

export const HYBRID_SEARCH_RESULTS_VIEW = `${rootActionPath}/VIEW`;

export const HYBRID_GSP_STORES_GET_SUCCESS = `${rootActionPath}/gspStores/GET_${SUCCESS_SUFFIX}`;
export const HYBRID_GSP_STORES_GET_FAILURE = `${rootActionPath}/gspStores/GET_${FAILURE_SUFFIX}`;

export const MERCHANTS_WITH_OFFERS_GET_SUCCESS = `${rootActionPath}/merchantsWithOffers/GET_${SUCCESS_SUFFIX}`;
export const MERCHANTS_WITH_OFFERS_GET_FAILURE = `${rootActionPath}/merchantsWithOffers/GET_${FAILURE_SUFFIX}`;
