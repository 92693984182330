/**
 * @param {HTMLElement} el
 * @param {string} attr
 * @param {*} defaultValue
 * @return {*}
 */
export const findDataAttrInAncestorTree = (el, attr, defaultValue) => {
  // eslint-disable-next-line no-cond-assign, no-param-reassign, no-empty
  while ((el = el.parentElement) && !el.dataset[attr]) {}
  return el ? el.dataset[attr] : defaultValue;
};

/**
 * @param {HTMLElement} el
 * @param {string} className
 * @return {HTMLElement}
 */
export const findAncestorByClassName = (el, className) => {
  // eslint-disable-next-line no-cond-assign, no-param-reassign, no-empty
  while ((el = el.parentElement) && !el.classList.contains(className)) {}
  return el;
};

/**
 * @param {object} props
 * @return {object}
 */
export const filterDataAttributeProps = (props) => {
  const dataAtributes = Object.keys(props)
    .filter(key => key.substring(0, 5) === 'data-')
    .reduce((obj, key) => ({
      ...obj,
      [key]: props[key],
    }), {});
  return dataAtributes;
};

export const setElementsEqualMaxHeight = (elements) => {
  elements.map((el) => {
    if (el) {
      // eslint-disable-next-line no-param-reassign
      el.style.height = 'auto';
    }
    return el;
  });
  const allHeights = elements.map(el => el && el.offsetHeight);
  // eslint-disable-next-line prefer-spread
  const maxHeight = Math.max.apply(Math, allHeights);
  if (maxHeight) {
    elements.map((el) => {
      if (el) {
        // eslint-disable-next-line no-param-reassign
        el.style.height = `${maxHeight}px`;
      }
      return el;
    });
  }
};

export const getNextSibling = (elem, selector) => {
  let sibling = elem.nextElementSibling;
  if (!selector) return sibling;

  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.nextElementSibling;
  }

  return sibling;
};

export const getPreviousSibling = (elem, selector) => {
  let sibling = elem.previousElementSibling;

  if (!selector) return sibling;

  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.previousElementSibling;
  }

  return sibling;
};

export const getActiveMQ = () => (
  window?.getComputedStyle(document.documentElement, ':after').content.replace(/"|'/g, '')
);
