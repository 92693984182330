import { createSelector } from 'reselect';

const selectCreditCardMarketingBanner = state => state.creditCardMarketingBanner;

export const selectCreditCardMarketingBannerConfig = createSelector(
  selectCreditCardMarketingBanner,
  creditCardMarketingBanner => creditCardMarketingBanner.config,
);

export const selectCreditCardMarketingBannerIsLoading = createSelector(
  selectCreditCardMarketingBanner,
  creditCardMarketingBanner => creditCardMarketingBanner.isLoading,
);

export const selectCreditCardMarketingBannerIsLoaded = createSelector(
  selectCreditCardMarketingBanner,
  creditCardMarketingBanner => creditCardMarketingBanner.isLoaded,
);

export const selectCreditCardMarketingBannerPlacement = createSelector(
  selectCreditCardMarketingBanner,
  creditCardMarketingBanner => creditCardMarketingBanner.placement,
);
