import React from 'react';
import { useSelector } from 'react-redux';

import { selectUserName, selectUserIsIdentified, selectDisplayableUserId } from 'core/selectors/user';
import { selectSiteUrl, selectLoginUrl } from 'core/selectors/app';

import './SidebarWelcomeMessage.scss';

function MessageForIdentifiedUser({ siteUrl, userName, displayableUserId }) {
  return (
    <div className="mn_mobileNavWelcomeMessage">
      <h3>
        <i className="mn_unitedIconUser" aria-hidden="true" />&nbsp;Hi <span>{userName}</span><br />
        <strong className="mn_displayableUserId">Mileageplus number: {displayableUserId}</strong>
      </h3>
      <p><a className="mn_button mn_logoutLink" href={`${siteUrl}/logoutUser.php?lUrl=/`}>Log out</a></p>
    </div>
  );
}

function MessageForNonIdentifiedUser({ loginUrl }) {
  return (
    <div className="mn_mobileNavWelcomeMessage">
      <p><a className="mn_button mn_loginLink" href={loginUrl}>Sign in</a></p>
    </div>
  );
}

function SidebarWelcomeMessage() {
  const siteUrl = useSelector(selectSiteUrl);
  const userName = useSelector(selectUserName);
  const displayableUserId = useSelector(selectDisplayableUserId);
  const userIsIdentified = useSelector(selectUserIsIdentified);
  const loginUrl = useSelector(selectLoginUrl);

  return userIsIdentified ?
    <MessageForIdentifiedUser siteUrl={siteUrl} userName={userName} displayableUserId={displayableUserId} />
    :
    <MessageForNonIdentifiedUser loginUrl={loginUrl} />;
}

export default SidebarWelcomeMessage;
