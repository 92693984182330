import React from 'react';

import './Pill.scss';

function Pill({ label, className }) {
  return (
    <i className={`mn_pill ${className}`}>
      <i className="mn_pillLabel" aria-hidden="true">
        {label}
      </i>
    </i>
  );
}

Pill.defaultProps = {
  className: '',
};

export default Pill;
