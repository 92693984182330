import { PRODUCT_LIST_GET_SUCCESS, PRODUCT_LIST_GET_FAILURE } from '../actions/types';
import { productListConfig as config } from '../configs';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  products: {},
};

const productList = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_LIST_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        products: action.payload.response,
      };
    }
    case PRODUCT_LIST_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === PRODUCT_LIST_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};

export default productList;
