const keyCodes = {
  ARW_UP: 38,
  ARW_DOWN: 40,
  ARW_LEFT: 37,
  ARW_RIGHT: 39,
  BACKSPACE: 8,
  DELETE: 46,
  ENTER: 13,
  ESCAPE: 27,
  RETURN: 8,
  SHIFT: 16,
  SPACEBAR: 32,
  TAB: 9,
};

export default keyCodes;
