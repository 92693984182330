import {
  MEMBER_AUTHENTICATION_CHECK,
  MEMBER_AUTHENTICATION_COMPLETE,
} from 'core/actions/member';

const initialState = {
  isLoading: false,
  isLoaded: false,
  isUserAuthenticated: null,
};

const userAuthentication = (state = initialState, action) => {
  switch (action.type) {
    case MEMBER_AUTHENTICATION_CHECK: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    }
    case MEMBER_AUTHENTICATION_COMPLETE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        isUserAuthenticated: action.payload.userIsAuthenticated,
      };
    }

    default:
      return state;
  }
};

export default userAuthentication;
