import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import FavoriteIcon from 'core/modules/FavoriteIcon/FavoriteIcon';
import PremierMerchantRebate from 'core/modules/PremierMerchantRebate/PremierMerchantRebate';
import isTouchDevice from 'core/utils/helpers/isTouchDevice';
import { selectUserIsIdentified } from 'core/selectors/user';

import config from './configs';

import './FlipTile.scss';

function FlipTile({ merchant, customRebateOptions = {} }) {
  const { rebateOptions } = config;

  const combinedRebateOptions = {
    ...rebateOptions,
    ...customRebateOptions,
  };

  const {
    id, name, logoUrls, rebate, clickUrl,
  } = merchant;
  const shopButtonRef = useRef();
  const isUserIdentified = useSelector(selectUserIsIdentified);

  const [isFocused, setIsFocused] = useState(false);

  const focusTile = () => {
    !isTouchDevice && setIsFocused(true);
  };

  const unfocusTile = (event) => {
    if (event.relatedTarget instanceof Node && !event.currentTarget?.contains(event.relatedTarget)) {
      setIsFocused(false);
    }
  };

  const handleClick = () => {
    if (isTouchDevice) {
      shopButtonRef.current.click();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={`mn_flipTile ${isFocused ? 'mn_focused' : ''}`}
      onFocus={focusTile}
      onBlur={unfocusTile}
      onMouseEnter={focusTile}
      onMouseLeave={unfocusTile}
      onClick={handleClick}
    >
      <div className="mn_flipper">
        <div className="mn_itemContent">
          <div className="mn_front" aria-hidden="true">
            {isUserIdentified &&
              <div className="mn_favoritesWrap">
                <FavoriteIcon merchantId={id} merchantName={name} tabIndex={isTouchDevice ? '0' : '-1'} />
              </div>
            }
            <div className="mn_logoWrap">
              <img className="mn_merchantLogo" src={logoUrls._88x31} alt={name} />
            </div>
            <PremierMerchantRebate {...rebate} {...combinedRebateOptions} />
          </div>
          <div className="mn_back">
            {isUserIdentified &&
              <div className="mn_favoritesWrap">
                <FavoriteIcon merchantId={id} merchantName={name} />
              </div>
            }
            <div className="mn_logoWrap">
              <a
                ref={shopButtonRef}
                className="mn_shopNow"
                href={clickUrl}
                title={`Opens a new window at ${name}`}
                data-merchant-id={id}
                data-merchant-name={name}
                target="_blank"
              >
                <img className="mn_merchantLogo" src={logoUrls._88x31} alt={name} />
              </a>
            </div>
            <div className="mn_buttonWrap">
              <a
                href={clickUrl}
                className="mn_button"
                title={`Opens a new window at ${name}`}
                data-merchant-id={id}
                data-merchant-name={name}
                target="_blank"
              >
                Shop again
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlipTile;
