import { HOW_IT_WORKS_VIEW, HOW_IT_WORKS_SLIDE_CHANGE, WATCH_HOW_IT_WORKS_VIEW } from '../actions/types';

export const howItWorksGaActionEvents = {
  [HOW_IT_WORKS_VIEW]: ({ userIsIdentified }) => {
    if (!userIsIdentified) { return false; }

    return ({
      category: 'Mobile MHP: How it Works',
      action: 'How it Works View',
      label: '',
      value: 1,
    });
  },
  [HOW_IT_WORKS_SLIDE_CHANGE]: ({ slideIndex }) => ({
    category: 'Mobile MHP: How it Works',
    action: 'How it Works Scroll',
    label: slideIndex + 1,
    value: 1,
  }),
  [WATCH_HOW_IT_WORKS_VIEW]: () => (
    {
      category: 'VHP CTA',
      action: 'View',
      label: 'Watch how it works',
    }
  ),
};

export const howItWorksClickEvents = [
  {
    selector: '.mn_VHP .mn_howItWorks .mn_howItWorksVideoLink',
    click: {
      category: 'VHP CTA',
      action: 'Click',
    },
    label: 'Watch how it works',
  },
];
