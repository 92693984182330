import Cookies from 'js-cookie';
import SessionStorageCache from 'core/utils/cache/sessionStorage';
import { MEMBER_POST_FAVORITES, MEMBER_DELETE_FAVORITES } from 'core/actions/member';
import {
  FAVORITES_COOKIE_NAME, FAVORITES_CACHE_KEY, FAVORITES_USAGE_MARKER_MERCHANT_ID,
  expirationTimestamp,
} from './keys';

const sessionStorageCache = new SessionStorageCache();

export const invalidateFavoritesCache = ({ cookieDomain, isHttps }) => {
  const favoritesCookieDecoded = {
    isRegServerCacheUpdated: true,
    isMallServerCacheUpdated: true,
  };

  const favoritesCookieValue = JSON.stringify(favoritesCookieDecoded);

  Cookies.set(FAVORITES_COOKIE_NAME, favoritesCookieValue, {
    domain: cookieDomain,
    path: '/',
    secure: isHttps,
  });
};

export const dropFavoriteCache = () => {
  sessionStorageCache.removeItem(FAVORITES_CACHE_KEY);
};

export const updateFavoriteCache = (action) => {
  if (action.type === MEMBER_POST_FAVORITES) {
    addInCache(action);
  }

  if (action.type === MEMBER_DELETE_FAVORITES) {
    removeFromCache(action);
  }

  function addInCache(action) {
    try {
      const favoritesCache = sessionStorageCache.getItem(FAVORITES_CACHE_KEY);
      const { merchantId } = action.data;

      if (!favoritesCache || !merchantId) return;

      // double-check if id is not cached already
      const isIdCached = favoritesCache.data.response.some((item) => item.merchantId === merchantId);
      if (isIdCached) return;

      // Update information in cache that user already added something to favorites
      // and we don't need to show first time popup anymore
      const favoritesUsageMarkerIdIsCached =
        favoritesCache.data.response.some((item) => item.merchantId === FAVORITES_USAGE_MARKER_MERCHANT_ID);
      if (!favoritesUsageMarkerIdIsCached) {
        favoritesCache.data.response.push({
          merchantId: FAVORITES_USAGE_MARKER_MERCHANT_ID,
          createDate: new Date().getTime(),
        });
      }

      favoritesCache.data.response.push({ merchantId, expirationTimestamp: new Date().getTime() });
      sessionStorageCache.setItem(FAVORITES_CACHE_KEY, favoritesCache, { expirationTimestamp });
    } catch (error) {
      console.error(error);
      // drop cache if having issues with updating
      sessionStorageCache.removeItem(FAVORITES_CACHE_KEY);
    }
  }

  function removeFromCache(action) {
    try {
      const favoritesCache = sessionStorageCache.getItem(FAVORITES_CACHE_KEY);
      const { merchantId } = action.data;

      if (!favoritesCache || !merchantId) return;

      favoritesCache.data.response = favoritesCache.data.response.filter((item) => item.merchantId !== merchantId);
      sessionStorageCache.setItem(FAVORITES_CACHE_KEY, favoritesCache, { expirationTimestamp });
    } catch (error) {
      console.error(error);
      // drop cache if having issues with updating
      sessionStorageCache.removeItem(FAVORITES_CACHE_KEY);
    }
  }
};
