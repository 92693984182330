import BaseApi, { filterInvalidParams } from './base';

/**
 * Auto-convert "fields" param from array to comma-separated string
 * @param {object} params
 * @return {object}
 */
const processFields = (params = {}) => {
  const { fields } = params;

  if (fields) {
    const processedFields = Array.isArray(fields) ? fields.join(',') : String(fields);
    return { ...params, fields: processedFields };
  }

  return params;
};

/**
 * This OCAPI class is a singleton.
 * Class instance is exported.
 * To use it import class instance and call setParams method for initialize it with parameters.
 * @extends BaseApi
 */
export class OCAPI extends BaseApi {
  /** @type OCAPICredentials */
  credentials = {
    brand_id: '',
    app_id: '',
    app_key: '',
  };

  /**
   * @typedef {object} OCAPICredentials
   * @property {number} brand_id
   * @property {string} app_id 3scale application id
   * @property {string} app_key 3scale application key
   */

  /**
   * @typedef {object} CategoriesParams
   * @property {string|Array.<string>} fields (comma-separated list if string type)
   * @property {number} section_id
   * @property {string} type                  (merchant|product) [default=merchant)
   * @property {number} with_flyouts          (1 or 0) [default=0]
   * @property {date} preview_datetime
   */

  /**
   * @typedef {object} ClickHistoryParams
   * @property {string|Array.<string>} fields (comma-separated list if string type)
   * @property {number} limit
   * @property {number} offset
   * @property {date} start_date
   * @property {date} end_date
   */

  /**
   * @typedef {object} ContentPageByIdParams
   * @property {string|Array.<string>} fields (comma-separated list if string type)
   * @property {date} preview_datetime
   */

  /**
   * @typedef {object} InstoreLocationsParams
   * @property {number} limit
   * @property {number} offset
   * @property {string} zipcode
   * @property {number} lat
   * @property {number} lng
   * @property {number} radius
   * @property {number} gmid
   */

  /**
   * @typedef {object} InstoreTransactionsParams
   * @property {date} start_date
   * @property {date} end_date
   * @property {number} limit
   * @property {number} offset
   * @property {string} transaction_state     (comma-separated)
   * @property {string} transaction_type      (comma-separated)
   * @property {string} sort_by
   * @property {string} with_offers           (1 or 0) [default=0]
   */

  /**
   * @typedef {object} InstoreOffersParams
   * @property {string|Array.<string>} fields (comma-separated list if string type)
   * @property {number} limit
   * @property {number} offset
   * @property {number} offer_id
   * @property {string} applicable_cardholder_id
   * @property {number} gmid
   * @property {number} active                (1 or 0) [default=1]
   * @property {string} sort_by               (linked_cards|store_name|expiration_date)
   *                                          [default=linked_cards]
   */

  /**
   * @typedef {object} MemberAccountSummaryParams
   * @property {string|Array.<string>} fields (comma-separated list if string type)
   */

  /**
   * @typedef {object} MemberOrdersParams
   * @property {string|Array.<string>} fields (comma-separated list if string type)
   * @property {date} start_date
   * @property {date} end_date
   * @property {number} limit
   * @property {number} offset
   * @property {string} transaction_state     (comma-separated)
   * @property {string} transaction_type      (comma-separated)
   * @property {string} sort_by
   * @property {string} sort_type             (asc|desc) [default=asc]
   * @property {string} with_offers           (1 or 0) [default=0]
   */

  /**
   * @typedef {object} MemberTransactionsParams
   * @property {string|Array.<string>} fields (comma-separated list if string type)
   * @property {date} transaction_start_date
   * @property {date} create_start_date
   * @property {date} create_end_date
   * @property {number} limit
   * @property {number} offset
   * @property {string} transaction_state     (comma-separated)
   * @property {string} transaction_type      (comma-separated)
   * @property {string} sort_by
   * @property {string} sort_type             (asc|desc) [default=asc]
   */

  /**
   * @typedef {object} MerchantsParams
   * @property {string|Array.<string>} fields (comma-separated list if string type)
   * @property {number} section_id
   * @property {string} name
   * @property {number} category_id
   * @property {date}   updated
   * @property {number} elevated_only
   * @property {number} mobile_only           (1 or 0) [default=0]
   * @property {number} tablet_only           (1 or 0) [default=0]
   * @property {string} sort_by               (name|earn_rate|featured) [default=name]
   * @property {string} sort_type             (asc|desc) [default=asc]
   * @property {number} limit
   * @property {number} offset
   * @property {number} with_offers           (1 or 0) [default=0]
   * @property {number} with_content_groups   (1 or 0) [default=0]
   * @property {number} with_external_brands  (1 or 0) [default=0]
   * @property {number} with_instore          (1 or 0) [default=0]
   * @property {date}   offer_updated
   * @property {date}   offer_expires
   * @property {date}   preview_datetime
   * @property {string} personalized          (rec|fav) [default=<empty>]
   */

  /**
   * @typedef {object} MerchantsByIdsParams
   * @property {string|Array.<string>} fields (comma-separated list if string type)
   * @property {number} section_id
   * @property {number} with_offers           (1 or 0) [default=0]
   * @property {number} use_mids              (1 or 0) [default=0]
   * @property {date}   preview_datetime
   * @property {number} include_content_items (1 or 0) [default=0]
   * @property {number} with_content_groups   (1 or 0) [default=0]
   * @property {number} with_external_brands  (1 or 0) [default=0]
   * @property {number} omit_merchant_info    (1 or 0) [default=0]
   * @property {number} with_instore          (1 or 0) [default=0]
   * @property {number} look_ahead            (>=0)    [default=0]
   */

  /**
   * @typedef {object} SimilarMerchantsParams
   * @property {string|Array.<string>} fields (comma-separated list if string type)
   * @property {number} section_id
   * @property {number} limit
   * @property {number} offset
   * @property {number} with_offers           (1 or 0) [default=0]
   * @property {number} use_mids              (1 or 0) [default=0]
   * @property {number} with_instore          (1 or 0) [default=0]
   * @property {date}   preview_datetime
   */

  /**
   * @typedef {object} MerchantsAllParams
   * @property {string|Array.<string>} fields (comma-separated list if string type)
   * @property {string} sort_by               (1 or 0) [default=0]
   * @property {string} sort_type             (name) [default=name]
   * @property {number} limit
   * @property {number} offset
   */

  /**
   * @typedef {object} NavigationParams
   * @property {string|Array.<string>} fields (comma-separated list if string type)
   * @property {date} preview_datetime
   */

  /**
   * @typedef {object} PlacementsParams
   * @property {string|Array.<string>} fields (comma-separated list if string type)
   * @property {number} section_id
   * @property {string} offer_tag
   * @property {number} content_group_id
   * @property {number} content_group_name
   * @property {number} content_type_id
   * @property {number} category_id
   * @property {string} sort_by               (display_order|featured|name|earn_rate|start_date|
   *                                          stop_date|modified_date|random|create_date)
   *                                          [default=name]
   * @property {string} sort_type             (asc|desc) [default=asc]
   * @property {number} limit
   * @property {number} offset
   * @property {number} with_offers           (1 or 0) [default=0]
   * @property {number} with_content_groups   (1 or 0) [default=0]
   * @property {number} coupons_only          (1 or 0) [default=0]
   * @property {number} skip_ad_supressions   (1 or 0) [default=0]
   * @property {date} preview_datetime
   * @property {number} group_by_merchant     (1 or 0) [default=0]
   * @property {number} look_ahead            (>=0)
   * @property {string} personalized          (rec|fav) [default=<empty>]
   * @property {string} skip_ad_suppressions  (1 or 0) [default=0]
   */

  /**
   * @typedef {object} SearchParams
   * @property {string|Array.<string>} fields (comma-separated list if string type)
   * @property {number} section_id
   * @property {string} q
   * @property {string} f
   * @property {number} limit
   * @property {number} offset
   * @property {string} sort_by               (best_match|lowest_price|highest_price)
   *                                          [default=best_match]
   * @property {number} meta                  (1 or 0) [default=0]
   * @property {number} calculate_earnings    (1 or 0) [default=0]
   * @property {number} with_instore          (1 or 0) [default=0]
   * @property {date} preview_datetime
   */

  /**
   * @typedef {object} SearchProductsByIdParams
   * @property {string|Array.<string>} fields (comma-separated list if string type)
   * @property {number} section_id
   * @property {number} calculate_earnings    (1 or 0) [default=0]
   * @property {date} preview_datetime
   */

  setParams(params = {}) {
    const {
      baseUrl,
      timeout,
      headers = {},
      credentials = {},
    } = params;

    this.credentials = credentials;

    super.setParams({
      baseUrl,
      timeout,
      headers: {
        ...BaseApi.defaultHeaders,
        ...headers,
      },
    });
  }

  static verifyParamValue(paramValue = false, validValues) {
    return paramValue && validValues.includes(paramValue);
  }

  /**
   * @param {object} options - see https://github.com/axios/axios for available options
   * @returns {Promise}
   */
  makeRequest(options = {}) {
    return super.makeRequest({
      validateStatus: status => status !== 500, // Let the consumer handle non-network/fatal errors
      ...options,
    });
  }

  /**
   * @param {CategoriesParams} params
   * @returns {Promise}
   */
  getCategories(params = {}) {
    const validParams = [
      'fields',
      'section_id',
      'type',
      'with_flyouts',
      'preview_datetime',
    ];

    return this.makeRequest({
      url: '/categories',
      params: this.processParams(params, validParams),
    });
  }

  /**
   * @param {FlyoutCategoriesParams} params
   * @returns {Promise}
   */
  getFlyoutCategories(params = {}) {
    const validParams = [
      'fields',
      'preview_datetime',
    ];

    return this.makeRequest({
      url: '/flyout-categories',
      params: this.processParams(params, validParams),
    });
  }

  /**
   * @param {number} contentPageId
   * @param {ContentPageByIdParams} params
   * @returns {Promise}
   */
  getContentPageById(contentPageId, params = {}) {
    const validParams = [
      'fields',
      'preview_datetime',
    ];

    return this.makeRequest({
      url: `/content-pages/${contentPageId}`,
      params: this.processParams(params, validParams),
    });
  }

  /**
   * @param {ClickHistoryParams} params
   * @returns {Promise}
   */
  getClickHistory(params = {}) {
    const addMemberId = true;
    const validParams = [
      'fields',
      'limit',
      'offset',
      'start_date',
      'end_date',
    ];

    return this.makeRequest({
      url: '/click-history',
      params: this.processParams(params, validParams, addMemberId),
    });
  }

  /**
   * @param {InstoreLocationsParams} params
   * @returns {Promise}
   */
  getInstoreLocations(params = {}) {
    const validParams = [
      'limit',
      'offset',
      'zipcode',
      'lat',
      'lng',
      'radius',
      'gmid',
    ];

    return this.makeRequest({
      url: '/in-store/locations',
      params: this.processParams(params, validParams),
    });
  }

  /**
   * @param {InstoreOffersParams} params
   * @returns {Promise}
   */
  getInstoreOffers(params = {}) {
    const validParams = [
      'fields',
      'limit',
      'offset',
      'offer_id',
      'applicable_cardholder_id',
      'gmid',
      'active',
      'sort_by',
      'sort_type',
    ];

    return this.makeRequest({
      url: '/in-store/offers',
      params: this.processParams(params, validParams),
    });
  }

  /**
   * @param {InstoreTransactionsParams} params
   * @returns {Promise}
   */
  getInstoreTransactions(params = {}) {
    const addMemberId = true;
    const validParams = [
      'start_date',
      'end_date',
      'limit',
      'offset',
      'transaction_state',
      'transaction_type',
      'sort_by',
      'sort_type',
      'with_offers',
    ];

    return this.makeRequest({
      url: '/in-store/transactions',
      params: this.processParams(params, validParams, addMemberId),
    });
  }

  /**
   * @param {MemberAccountSummaryParams} params
   * @returns {Promise}
   */
  getMemberAccountSummary(params = {}) {
    const addMemberId = true;
    const validParams = ['fields'];

    return this.makeRequest({
      url: '/member/account-summary',
      params: this.processParams(params, validParams, addMemberId),
    });
  }

  /**
   * @param {MemberOrdersParams} params
   * @returns {Promise}
   */
  getMemberOrders(params = {}) {
    const addMemberId = true;
    const validParams = [
      'fields',
      'start_date',
      'end_date',
      'create_start_date',
      'create_end_date',
      'limit',
      'offset',
      'transaction_state',
      'transaction_type',
      'sort_by',
      'sort_type',
      'with_offers',
    ];

    return this.makeRequest({
      url: '/member-orders',
      params: this.processParams(params, validParams, addMemberId),
    });
  }

  /**
   * @param {MemberTransactionsParams} params
   * @returns {Promise}
   */
  getMemberTransactions(params = {}) {
    const addMemberId = true;
    const validParams = [
      'fields',
      'transaction_start_date',
      'transaction_end_date',
      'create_start_date',
      'create_end_date',
      'limit',
      'offset',
      'transaction_state',
      'transaction_type',
      'sort_by',
      'sort_type',
    ];

    return this.makeRequest({
      url: '/member-transactions',
      params: this.processParams(params, validParams, addMemberId),
    });
  }

  /**
   * @param {MerchantsParams} params
   * @returns {Promise}
   */
  getMerchants(params = {}) {
    const addMemberId = OCAPI.verifyParamValue(params.personalized, ['rec', 'fav', 'all']);
    const validParams = [
      'fields',
      'section_id',
      'name',
      'category_id',
      'updated',
      'elevated_only',
      'mobile_only',
      'tablet_only',
      'sort_by',
      'sort_type',
      'limit',
      'offset',
      'with_offers',
      'with_content_groups',
      'with_external_brands',
      'with_instore',
      'offer_updated',
      'offer_expires',
      'elevation_start_begin',
      'elevation_start_end',
      'preview_datetime',
      'personalized',
      'include_inactive',
      'merchant_tag',
    ];

    return this.makeRequest({
      url: '/merchants',
      params: this.processParams(params, validParams, addMemberId),
    });
  }

  /**
   * @param {number} merchantId
   * @param {MerchantsByIdsParams} params
   * @returns {Promise}
   */
  getMerchantById(merchantId, params = {}) {
    return this.getMerchantsByIds([merchantId], params);
  }

  /**
   * @param {Array.<number>} merchantIds
   * @param {MerchantsByIdsParams} params
   * @returns {Promise}
   */
  getMerchantsByIds(merchantIds = [], params = {}) {
    const validParams = [
      'fields',
      'section_id',
      'with_offers',
      'use_mids',
      'preview_datetime',
      'include_content_items',
      'with_content_groups',
      'with_external_brands',
      'omit_merchant_info',
      'with_instore',
      'look_ahead',
      'include_inactive',
    ];

    return this.makeRequest({
      url: `/merchants/${merchantIds.join(',')}`,
      params: this.processParams(params, validParams),
    });
  }

  /**
   * @param {number} merchantId
   * @param {SimilarMerchantsParams} params
   * @returns {Promise}
   */
  getSimilarMerchantsById(merchantId, params = {}) {
    const validParams = [
      'fields',
      'section_id',
      'limit',
      'offset',
      'with_offers',
      'use_mids',
      'with_instore',
      'preview_datetime',
    ];

    return this.makeRequest({
      url: `/merchants/${merchantId}/similar`,
      params: this.processParams(params, validParams),
    });
  }

  /**
   * @param {MerchantsAllParams} params
   * @returns {Promise}
   */
  getMerchantsAll(params = {}) {
    const validParams = [
      'fields',
      'sort_by',
      'sort_type',
      'limit',
      'offset',
      'include_inactive',
    ];

    return this.makeRequest({
      url: '/merchants/all',
      params: this.processParams(params, validParams),
    });
  }

  /**
   * @param {NavigationParams} params
   * @returns {Promise}
   */
  getNavigation(params = {}) {
    const validParams = [
      'fields',
      'preview_datetime',
    ];

    return this.makeRequest({
      url: '/navigation',
      params: this.processParams(params, validParams),
    });
  }

  /**
   * @param {PlacementsParams} params
   * @returns {Promise}
   */
  getPlacements(params = {}) {
    const validParams = [
      'fields',
      'deal_id',
      'section_id',
      'offer_tag',
      'content_group_id',
      'content_group_name',
      'content_type_id',
      'category_id',
      'sort_by',
      'sort_type',
      'limit',
      'offset',
      'with_offers',
      'with_text_offers',
      'with_content_groups',
      'coupons_only',
      'skip_ad_supressions',
      'preview_datetime',
      'group_by_merchant',
      'look_ahead',
      'personalized',
      'skip_ad_suppressions',
      'letterbox_category_id',
      'applicable_cardholder_id',
      'type',
      'active',
      'offer_tag',
    ];

    return this.makeRequest({
      url: '/placements',
      params: this.processParams(params, validParams),
    });
  }

  getPlacementsById(id, params = {}) {
    const validParams = [
      'fields',
      'deal_id',
      'section_id',
      'offer_tag',
      'content_group_id',
      'content_group_name',
      'content_type_id',
      'category_id',
      'sort_by',
      'sort_type',
      'limit',
      'offset',
      'with_offers',
      'with_text_offers',
      'with_content_groups',
      'coupons_only',
      'skip_ad_supressions',
      'preview_datetime',
      'group_by_merchant',
      'look_ahead',
      'personalized',
    ];

    return this.makeRequest({
      url: `/placements/${id}`,
      params: this.processParams(params, validParams),
    });
  }

  /**
 * @param {SweepsParams} params
 * @returns {Promise}
 */
  getSweeps(params = {}) {
    const validParams = [
      'preview_datetime',
    ];

    return this.makeRequest({
      url: '/sweeps',
      params: this.processParams(params, validParams),
    });
  }

  /**
   * @param {SearchParams} params
   * @returns {Promise}
   */
  search(params = {}) {
    const validParams = [
      'fields',
      'section_id',
      'q',
      'f',
      'limit',
      'offset',
      'sort_by',
      'meta',
      'calculate_earnings',
      'with_instore',
      'preview_datetime',
    ];

    return this.makeRequest({
      url: '/search',
      params: this.processParams(params, validParams),
    });
  }

  /**
   * @param {SearchParams} params
   * @returns {Promise}
   */
  searchProducts(params = {}) {
    const validParams = [
      'fields',
      'section_id',
      'q',
      'f',
      'limit',
      'offset',
      'sort_by',
      'meta',
      'calculate_earnings',
      'with_instore',
      'preview_datetime',
      'page_id',
      'sid',
    ];

    return this.makeRequest({
      url: '/search/products',
      params: this.processParams(params, validParams),
    });
  }

  /**
   * @param {number} productId
   * @param {SearchProductsByIdParams} params
   * @returns {Promise}
   */
  searchProductsById(productId, params = {}) {
    const validParams = [
      'fields',
      'section_id',
      'calculate_earnings',
      'preview_datetime',
      'id',
      'sort_by',
    ];

    return this.makeRequest({
      url: `/search/products/${productId}`,
      params: this.processParams(params, validParams),
    });
  }

  /**
   * @param {object} params
   * @param {array.<string>} validParams
   * @returns {object}
   */
  processParams(params = {}, validParams = [], addMemberId = false) {
    const updatedParams = processFields(params);
    const filteredParams = filterInvalidParams(updatedParams, validParams);

    return this.injectCredentialParams(filteredParams, addMemberId);
  }

  /**
   *
   * @param {object} params
   * @return {object}
   */
  injectCredentialParams(params = {}, addMemberId) {
    const paramsWithCredentials = { ...params };

    // Inject credentials
    Object.keys(this.credentials).forEach((param) => {
      if (!addMemberId && param === 'mem_id') {
        return;
      }

      if (!Object.hasOwnProperty.call(paramsWithCredentials, param)) {
        paramsWithCredentials[param] = this.credentials[param];
      }
    });

    return paramsWithCredentials;
  }
}

export default new OCAPI();
