import { createSelector } from 'reselect';

const selectHeaderHolidayImage = state => state.headerHolidayImage;

export const selectHeaderHolidayImageConfig = createSelector(
  selectHeaderHolidayImage,
  headerHolidayImage => headerHolidayImage.config,
);

export const selectHeaderHolidayImageIsLoading = createSelector(
  selectHeaderHolidayImage,
  headerHolidayImage => headerHolidayImage.isLoading,
);

export const selectHeaderHolidayImageIsLoaded = createSelector(
  selectHeaderHolidayImage,
  headerHolidayImage => headerHolidayImage.isLoaded,
);

export const selectHeaderHolidayImagePlacement = createSelector(
  selectHeaderHolidayImage,
  headerHolidayImage => headerHolidayImage.data?.assets?.image,
);

export const selectHeaderHolidayImageAlternateText = createSelector(
  selectHeaderHolidayImage,
  headerHolidayImage => headerHolidayImage.data?.assets?.imageAlternateText,
);

export const selectHeaderHolidayImageClickUrl = createSelector(
  selectHeaderHolidayImage,
  headerHolidayImage => headerHolidayImage.data?.clickUrl,
);
