import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { emailOptInSuccessModal as orgConfig } from 'org/configs/modules.config';

export const defaultConfig = {
  defaults: {
    earnType: 'bonus points',
    autoCloseTimeout: 5000,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
