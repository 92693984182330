export const signInNagSmallClickAndHoverEvents = [
  {
    selector: '.mn_signInNagSmall button.mn_termsButton ',
    click: {
      category: 'Small nag',
      action: 'Tap "see terms"',
    },
    label: 'none',
  },
];
