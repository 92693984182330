import {
  MEMBER_POST_INSTORE_MULTI_OFFER_CARD_LINK_FAILURE,
  MEMBER_POST_INSTORE_MULTI_OFFER_CARD_LINK_SUCCESS,
  MEMBER_POST_INSTORE_MULTI_OFFER_CARD_LINK,
} from 'core/actions/member';
import {
  INSTORE_MULTI_LINK_MODAL_CLEAR,
  INSTORE_MULTI_LINK_MODAL_TRIGGER,
} from '../actions/types';

const initialState = {};

const instoreMultiLinkModal = (state = initialState, action) => {
  switch (action.type) {
    case INSTORE_MULTI_LINK_MODAL_TRIGGER: {
      const { cardId } = action.data || '';
      return { ...state, cardId, multiOfferLinkResponse: null };
    }
    case INSTORE_MULTI_LINK_MODAL_CLEAR: {
      return initialState;
    }

    case MEMBER_POST_INSTORE_MULTI_OFFER_CARD_LINK: {
      return { ...state, linkSuccess: false };
    }

    case MEMBER_POST_INSTORE_MULTI_OFFER_CARD_LINK_SUCCESS: {
      return { ...state, multiOfferLinkResponse: action.payload.response, linkSuccess: true };
    }
    case MEMBER_POST_INSTORE_MULTI_OFFER_CARD_LINK_FAILURE: {
      return { ...state, linkSuccess: false };
    }
    default:
      return state;
  }
};

export default instoreMultiLinkModal;
