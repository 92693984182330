import { NAVIGATION_TOF_IS_TOGGLED } from '../actions/types';

const VALUE_NOT_SET = '(not set)';

export const navigationTOFGaActionEvents = {
  [NAVIGATION_TOF_IS_TOGGLED]: ({ wasOpen }) => ({
    category: 'Explore Stores - Flyout',
    action: `${wasOpen ? 'Open' : 'Close'} flyout`,
    label: VALUE_NOT_SET,
  }),
};
