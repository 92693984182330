import { ONLINE_INSTORE_OFFER_OVERLAY_LINK_CLICK } from '../actions/types';

const initialState = {
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const onlineInStoreOfferOverlayLink = (state = initialState, action) => {
  switch (action.type) {
    case ONLINE_INSTORE_OFFER_OVERLAY_LINK_CLICK: {
      return state;
    }
    default: {
      return state;
    }
  }
};

export default onlineInStoreOfferOverlayLink;
