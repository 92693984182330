import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { contactUsInquiryOrder as orgConfig } from 'org/configs/modules.config';
import {
  INQUIRY_ORDER_TRANSACTIONS_GET_SUCCESS,
  INQUIRY_ORDER_TRANSACTIONS_GET_FAILURE,
  INQUIRY_ORDER_CLICK_HISTORY_GET_SUCCESS,
  INQUIRY_ORDER_CLICK_HISTORY_GET_FAILURE,
} from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    transactionsConfig: {
      api: {
        params: {
          limit: 100000,
          sort_by: 'carteraPostDate',
          transaction_state: [
            'CLIENT_POSTED',
            'CLIENT_HARD_REJECT',
            'ACCRUAL_PENDING',
            'PAYABLE_PENDING',
            'CLIENT_SOFT_REJECT',
            'ACCRUAL_SENT',
          ].join(','),
        },
        options: {
          success: INQUIRY_ORDER_TRANSACTIONS_GET_SUCCESS,
          failure: INQUIRY_ORDER_TRANSACTIONS_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
      },
    },
    clickHistoryConfig: {
      api: {
        options: {
          success: INQUIRY_ORDER_CLICK_HISTORY_GET_SUCCESS,
          failure: INQUIRY_ORDER_CLICK_HISTORY_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
