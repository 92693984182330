import { CURATED_ACTIVE_MERCHANTS_VIEW } from '../actions/types';

export const curatedActiveMerchantsGaActionEvents = {
  [CURATED_ACTIVE_MERCHANTS_VIEW]: ({ merchants, suspendedMerchantName }) => {
    const merchantNames = merchants.map(merchant => merchant.name);

    return {
      category: 'Tombstone page',
      action: 'Similar stores view',
      label: `${suspendedMerchantName}|${merchantNames.join(',')}`,
      value: merchantNames.length,
    };
  },
};
