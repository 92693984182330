import { SPONSORED_TEXT_VIEW } from '../actions/types';

export const sponsoredTextOffersActionEvents = {
  [SPONSORED_TEXT_VIEW]: ({ length }) => ({
    category: 'Offers for you page',
    action: 'Offers from favorites view',
    label: 'Featured offers from your favorites',
    value: length,
  }),
};

export const sponsoredTextOffersClickAndHoverEvents = [
  {
    selector: '.mn_forYou .mn_sponsoredTextOffers .mn_featuredDeal',
    click: {
      category: 'Offers for you page',
      action: 'Offers from favorites click',
    },
    label: (element) => {
      const { text, merchantName } = element.getElementsByClassName('mn_rolloverCopy')[0].dataset;

      return `${merchantName}|${text}`;
    },
  },
];
