import { SUCCESS_SUFFIX, FAILURE_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/ClickHistoryTab';

export const CLICK_HISTORY_TAB_RECORDS_GET = `${rootActionPath}/records/GET`;
export const CLICK_HISTORY_TAB_RECORDS_GET_SUCCESS = `${CLICK_HISTORY_TAB_RECORDS_GET}_${SUCCESS_SUFFIX}`;
export const CLICK_HISTORY_TAB_RECORDS_GET_FAILURE = `${CLICK_HISTORY_TAB_RECORDS_GET}_${FAILURE_SUFFIX}`;

export const CLICK_HISTORY_TAB_MERCHANTS_GET = `${rootActionPath}/merchants/GET`;
export const CLICK_HISTORY_TAB_MERCHANTS_GET_SUCCESS = `${CLICK_HISTORY_TAB_MERCHANTS_GET}_${SUCCESS_SUFFIX}`;
export const CLICK_HISTORY_TAB_MERCHANTS_GET_FAILURE = `${CLICK_HISTORY_TAB_MERCHANTS_GET}_${FAILURE_SUFFIX}`;
