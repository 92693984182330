import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { productList as orgConfig } from 'org/configs/modules.config';
import { PRODUCT_LIST_GET_FAILURE, PRODUCT_LIST_GET_SUCCESS } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        sort_by: 'random',
        fields: [
          'id',
          'clickUrl',
          'images',
          'name',
          'merchantName',
          'price',
          'merchantRebates',
          'showRebate',
          'offers',
          'description',
          'message',
          'rebate',
          'hasTiered',
          'merchantCount',
          'salePrice',
        ].join(','),
      },
      options: {
        success: PRODUCT_LIST_GET_SUCCESS,
        failure: PRODUCT_LIST_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    slick: {
      centerMode: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1384,
          settings: { slidesToShow: 3, infinite: false, centerMode: false },
        },
        {
          breakpoint: 1048,
          settings: { slidesToShow: 2.2, infinite: false, centerMode: false },
        },
        {
          breakpoint: 1023,
          settings: { slidesToShow: 3 },
        },
        {
          breakpoint: 389,
          settings: { slidesToShow: 1 },
        },
      ],
    },
    rebateOptions: {
      showRebateInline: true,
      hideRebatePrefix: true,
      hideElevationCurrency: true,
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
