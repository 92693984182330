import {
  MERCHANT_EXPERIENCE_GET_SUCCESS,
  MERCHANT_EXPERIENCE_GET_FAILURE,
  MERCHANT_EXPERIENCE_CLEAR,
} from '../actions/types';

import { merchantExperienceConfig as config } from '../configs';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  merchants: [],
};

const merchantExperience = (state = initialState, action) => {
  switch (action.type) {
    case MERCHANT_EXPERIENCE_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        merchants: action.payload.response,
      };
    }
    case MERCHANT_EXPERIENCE_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    }
    case MERCHANT_EXPERIENCE_CLEAR: {
      return initialState;
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === MERCHANT_EXPERIENCE_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};

export default merchantExperience;
