import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { useDispatch } from 'react-redux';

import SimilarStoreTile from './components/SimilarStoreTile/SimilarStoreTile';
import { triggerSimilarStoresIsViewed } from './actions';

import config from './configs';

import './SimilarStores.scss';

function SimilarStores({ merchants = [], merchantMatchName, isPopUpView }) {
  const dispatch = useDispatch();
  const { maxMerchantsInPopUpView, maxMerchantsInDropDownView } = config;
  const maxMerchantsToDisplay = isPopUpView ? maxMerchantsInPopUpView : maxMerchantsInDropDownView;

  const arrangeSimilarStores = merchants => (
    merchants
      .filter(merchant => (!!merchant.rebate))
      .sort((a, b) => (a.rebate.value > b.rebate.value ? -1 : 1))
      .slice(0, maxMerchantsToDisplay)
  );

  const merchantsToDisplay = arrangeSimilarStores(merchants);

  useEffect(() => {
    if (!isEmpty(merchantsToDisplay)) {
      dispatch(triggerSimilarStoresIsViewed(merchantsToDisplay, merchantMatchName));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isEmpty(merchantsToDisplay)) {
    return (null);
  }

  return (
    <ComponentErrorBoundary>
      <div className="mn_similarStores" data-test="similar-stores">
        <div className="mn_sectHead">
          <h3>Similar stores</h3>
        </div>
        <div className="mn_sectContainer">
          {merchantsToDisplay.map(merchant => (
            <SimilarStoreTile
              key={merchant.id}
              merchant={merchant}
              config={config}
            />
          ))}
        </div>
      </div>
    </ComponentErrorBoundary>
  );
}

export default SimilarStores;
