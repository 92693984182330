import { SUCCESS_SUFFIX, FAILURE_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/featuredDeals';
const myAccountRootActionPath = 'core/myAccountFeaturedDeals';

export const FEATURED_DEALS_GET_SUCCESS = `${rootActionPath}/Content/GET_${SUCCESS_SUFFIX}`;
export const FEATURED_DEALS_GET_FAILURE = `${rootActionPath}/Content/GET_${FAILURE_SUFFIX}`;

export const FEATURED_DEALS_VIEW = `${rootActionPath}/VIEW`;
export const MY_ACCOUNT_FEATURED_DEALS_VIEW = `${myAccountRootActionPath}/VIEW`;

export const FEATURED_DEALS_HEADING_FILL = `${rootActionPath}/Heading/Fill`;
export const FEATURED_DEALS_HEADING_GET_SUCCESS = `${rootActionPath}/Heading/GET_${SUCCESS_SUFFIX}`;
export const FEATURED_DEALS_HEADING_GET_FAILURE = `${rootActionPath}/Heading/GET_${FAILURE_SUFFIX}`;
