import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { becauseYouShopped as orgConfig } from 'org/configs/modules.config';
import { BECAUSE_YOU_SHOPPED_GET_SUCCESS, BECAUSE_YOU_SHOPPED_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        limit: 8,
        with_offers: 1,
      },
      options: {
        success: BECAUSE_YOU_SHOPPED_GET_SUCCESS,
        failure: BECAUSE_YOU_SHOPPED_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    slick: {
      slidesToShow: 3,
      arrows: false,
      dots: false,
      variableWidth: true,
      infinite: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    },
    sectionsToRender: 1,
  },
  foryou: {
    sectionsToRender: 2,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
