import { getId } from 'core/utils/analytics/sectionInfo';
import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { merchantLogos as orgConfig } from 'org/configs/modules.config';
import { MERCHANT_LOGOS_GET_SUCCESS, MERCHANT_LOGOS_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        content_group_id: 15954,
        content_type_id: 92,
        fields: [
          'assets._460x250',
          'merchant.name',
        ],
        limit: 8,
        section_id: getId('merchant-logos'),
      },
      options: {
        success: MERCHANT_LOGOS_GET_SUCCESS,
        failure: MERCHANT_LOGOS_GET_FAILURE,
        cache: {
          isEnabled: true,
          isPublishedData: true,
        },
      },
    },
    useCarouselOnMediaQueriesOnly: [],
    slick: {
      swipeToSlide: true,
      centerMode: false,
      infinite: true,
      arrows: false,
    },
  },
  h: {
    useCarouselOnMediaQueriesOnly: [],
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
