import config from '../configs';
import { POPULAR_CATEGORIES_GET_SUCCESS, POPULAR_CATEGORIES_GET_FAILURE } from '../actions/types';

const initialState = {
  config,
  isLoading: false,
  isLoaded: false,
  categories: [],
};

/**
 * @param {string} categoryLink
 * @return {number|null}
 */
const getCategoryId = (categoryLink = '') => {
  const matches = categoryLink.match(/\/bc_(\d+)___.htm$/);
  return matches ? parseInt(matches[1], 10) : null;
};

/**
 * @param {object} assets
 * @return {string}
 */
const getAssetImageKey = assets => (
  Object.keys(assets).reduce((assetImageKey, assetKey) => {
    if (!assetImageKey && assetKey.startsWith('_')) {
      assetImageKey = assetKey; // eslint-disable-line no-param-reassign
    }
    return assetImageKey;
  }, null)
);

const extractCategories = (response) => {
  const categoryData = response.reduce((categoryData, { assets } = {}) => {
    const { categoryTitle, categoryLink } = assets || {};
    const categoryId = getCategoryId(categoryLink);

    if (categoryId) {
      // Create category record if missing
      if (categoryData.categoryIds.indexOf(categoryId) === -1) {
        categoryData.categoryIds.push(categoryId);
        categoryData.categoryIndex[categoryId] = { // eslint-disable-line no-param-reassign
          categoryId,
          categoryTitle,
          categoryLink,
          categoryAssets: {},
        };
      }

      // Merge different asset images from placements into one object
      const assetImageKey = getAssetImageKey(assets);

      if (assetImageKey) {
        // eslint-disable-next-line no-param-reassign
        categoryData.categoryIndex[categoryId].categoryAssets[assetImageKey] = assets[assetImageKey];
      }
    }

    return categoryData;
  }, {
    categoryIds: [],
    categoryIndex: {},
  });

  // Return flattened results
  return categoryData.categoryIds.map(categoryId => (
    categoryData.categoryIndex[categoryId]
  ));
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POPULAR_CATEGORIES_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        categories: extractCategories(action.payload.response),
      };
    }
    case POPULAR_CATEGORIES_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        categories: [],
      };
    }
    default: {
      const { options = {} } = action;

      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === POPULAR_CATEGORIES_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
  }

  return state;
};
