import { takeEvery, put } from 'redux-saga/effects';
import { showModal } from 'core/modules/Modal/actions';

import { MODAL_TYPE_PRODUCT_DETAILS } from 'core/modules/Modal/types';
import { PRODUCT_DETAILS_MODAL_TRIGGER } from 'core/modules/Modal/components/ProductDetailsModal/actions/types';

function* showProductDetailsModal() {
  yield put(showModal(MODAL_TYPE_PRODUCT_DETAILS));
}

export default function* productDetailsModalSaga() {
  yield takeEvery(PRODUCT_DETAILS_MODAL_TRIGGER, showProductDetailsModal);
}
