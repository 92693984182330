import {
  HYBRID_SEARCH_FORM_SUBMIT, HYBRID_SEARCH_FORM_REDIRECT,
  HYBRID_SEARCH_FORM_ACTIVATION, HYBRID_SEARCH_FORM_QUICK_SEARCH_REDIRECT,
} from './types';

export const searchSubmit = data => ({
  type: HYBRID_SEARCH_FORM_SUBMIT,
  data,
});

export const triggerSearchActivationGaEvent = data => ({
  type: HYBRID_SEARCH_FORM_ACTIVATION,
  data,
});

export const triggerSearchRedirectGaEvent = data => ({
  type: HYBRID_SEARCH_FORM_REDIRECT,
  data,
});

export const triggerQuickSearchRedirectGaEvent = data => ({
  type: HYBRID_SEARCH_FORM_QUICK_SEARCH_REDIRECT,
  data,
});
