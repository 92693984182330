import {
  MERCHANT_EXPERIENCE_TYPE_SINGLE,
  MERCHANT_EXPERIENCE_TYPE_MULTIPLE,
  MERCHANT_EXPERIENCE_TYPE_SUSPENDED,
  MERCHANT_EXPERIENCE_TYPE_DIRECT_LINK,
} from '../actions/types';

export const isSuspendedMerchant = merchant => (
  merchant.type === 'Suspended Merchant'
);

export const isDeactivatedMerchant = merchant => (
  merchant.type === 'Deactivated Merchant'
);

export const getActiveMerchants = merchants => (
  (merchants && merchants.length)
    ? merchants.filter(merchant => !isSuspendedMerchant(merchant) && !isDeactivatedMerchant(merchant)) : []
);

export const getActiveAndDeactivatedMerchants = merchants => (
  (merchants && merchants.length) ? merchants.filter(merchant => !isSuspendedMerchant(merchant)) : []
);

// Page view type defining function is placed in the separate utility file not in the MerchantExperience component
// because it is also used to define a page name for GA 'pageview' event in 'utils/analytics/ga-dimensions.js'.
// Since GA does not have access to redux store this function can't be set in component's selector as well.
export const getMerchantExperiencePageViewType = (merchants, keywords) => {
  const activeMerchants = getActiveMerchants(merchants);

  if (merchants && merchants.length > 1) {
    return MERCHANT_EXPERIENCE_TYPE_MULTIPLE;
  }
  if (activeMerchants.length === 1) {
    return keywords ? MERCHANT_EXPERIENCE_TYPE_SINGLE : MERCHANT_EXPERIENCE_TYPE_DIRECT_LINK;
  }
  if (!activeMerchants || activeMerchants.length === 0) {
    return MERCHANT_EXPERIENCE_TYPE_SUSPENDED;
  }
  return null;
};
