export const SUCCESS_SUFFIX = 'SUCCESS';
export const FAILURE_SUFFIX = 'FAILURE';

/**
 * @typedef {object} ApiRequestActionOptions
 * @property {string} success - Action to fire on successful request
 * @property {string} failure - Action to fire on failed request
 */

/**
 * @typedef {object} ApiRequestAction
 * @property {string} type
 * @property {object} params
 * @property {ApiRequestActionOptions} options
 * @property {object} data
 */

/**
 * @param {ApiRequestAction} action
 * @param {object} response
 * @param {boolean} isSuccess
 */
const getRequestContext = (action = {}, response = {}, isSuccess = true) => {
  const {
    type, data, params, options,
  } = action;
  const { request: { responseURL: url } = {} } = response;

  return {
    isSuccess,
    type,
    url,
    data,
    params,
    options,
  };
};

/**
 * Outputs an action creator function based on a type
 * Its purpose is to create a consistent format for api-based "request" actions
 * @param {string} type
 * @returns {function}
 */
export const requestActionGeneratorByType = type => (params = {}, options = {}, data = {}) => ({
  type,
  params,
  options,
  data,
});

/**
 * Generate a success action based on an api request action
 * @param {ApiRequestAction} requestAction
 * @param {object} response
 * @param {*} defaultValue
 */
export const generateSuccessAction = (requestAction, response = {}, defaultValue = []) => {
  const { type, options = {} } = requestAction;
  const { data: payload = { response: defaultValue } } = response;
  const successType = options.success || `${type}_${SUCCESS_SUFFIX}`;

  return {
    type: successType,
    payload,
    context: getRequestContext(requestAction, response, true),
  };
};

/**
 * Generate a server request success action based on an api request action
 * @param {ApiRequestAction} requestAction
 * @param {object} response
 * @param {*} defaultValue
 */
export const generateServerSuccessAction = (requestAction, response = {}) => {
  const { type, options = {} } = requestAction;
  const successType = options.success || `${type}_${SUCCESS_SUFFIX}`;

  return {
    type: successType,
    response,
    context: getRequestContext(requestAction, response, true),
  };
};

/**
 * Generate a failure action based on an api request action
 * @param {ApiRequestAction} requestAction
 * @param {object} response
 */
export const generateFailureAction = (requestAction, response = {}) => {
  const { type, options = {} } = requestAction;
  const { data: payload = { response: {} }, status, statusText } = response;
  const { name, message } = payload;
  const failureType = options.failure || `${type}_${FAILURE_SUFFIX}`;

  return {
    type: failureType,
    payload,
    error: {
      status,
      statusText: statusText.length ? statusText : `${name}: ${message}`,
    },
    context: getRequestContext(requestAction, response, false),
  };
};
