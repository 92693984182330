import {
  SEARCH_FORM_SUBMIT, SEARCH_FORM_REDIRECT, SEARCH_FORM_ACTIVATION, SEARCH_FORM_QUICK_SEARCH_REDIRECT,
} from './types';

export const searchSubmit = data => ({
  type: SEARCH_FORM_SUBMIT,
  data,
});

export const triggerSearchRedirect = data => ({
  type: SEARCH_FORM_REDIRECT,
  data,
});

export const triggerSearchActivation = data => ({
  type: SEARCH_FORM_ACTIVATION,
  data,
});

export const triggerQuickSearchRedirect = data => ({
  type: SEARCH_FORM_QUICK_SEARCH_REDIRECT,
  data,
});
