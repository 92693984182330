import { findAncestorByClassName } from 'core/utils/helpers/dom';
import { getDisplayPageName } from 'core/utils/analytics/pageInfo';
import { OFFER_TABLE_VIEWED } from '../actions/types';

export const offerTableGaActionEvents = {
  [OFFER_TABLE_VIEWED]: ({ length, sectionTitle, pageNumber }) => ({
    category: `${getDisplayPageName()}: ${sectionTitle}`,
    action: 'View',
    label: `${sectionTitle}| Page ${pageNumber} | ${length} per page`,
  }),
};
export const offerTableClickAndHoverEvents = [
  {
    selector: '.mn_offerTable .mn_sponsoredTextOffer',
    click: {
      category: (element) => {
        const offerTable = findAncestorByClassName(element, 'mn_offerTable') || {};
        const { title } = offerTable.dataset;

        return `${getDisplayPageName()}: ${title}`;
      },
      action: 'Offer Click',
    },
    label: (element) => {
      const { merchantName, offerId, offerText } = element.dataset;
      const offerTable = findAncestorByClassName(element, 'mn_offerTable') || {};
      const { title } = offerTable.dataset;

      return `${title} | ${offerId} | ${merchantName} | ${offerText}`;
    },
  },
  {
    selector: '.mn_offerTable .mn_dataTableSort button',
    click: {
      category: (element) => {
        const offerTable = findAncestorByClassName(element, 'mn_offerTable') || {};
        const { title } = offerTable.dataset;

        return `${getDisplayPageName()}: ${title}`;
      },
      action: (element) => {
        const { viewName } = element.dataset;

        return `Sort by - ${viewName}`;
      },
    },
    label: (element) => {
      const offerTable = findAncestorByClassName(element, 'mn_offerTable') || {};
      const { title } = offerTable.dataset;

      return `${title}`;
    },
  },
];
