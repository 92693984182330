import {
  MARKETING_TOP_BANNER_GET_SUCCESS, MARKETING_TOP_BANNER_GET_FAILURE,
} from '../actions/types';
import config from '../configs';

const initialState = {
  config,
  placement: {},
  isLoading: false,
  isLoaded: false,
};

const desktopBanner = (state = initialState, action) => {
  switch (action.type) {
    case MARKETING_TOP_BANNER_GET_SUCCESS: {
      return {
        ...state,
        placement: action.payload.response[0],
        isLoading: false,
        isLoaded: true,
      };
    }
    case MARKETING_TOP_BANNER_GET_FAILURE: {
      return {
        ...state,
        placement: {},
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === MARKETING_TOP_BANNER_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default desktopBanner;
