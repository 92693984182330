import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { offersMarketingFlyout as orgConfig } from 'org/configs/modules.config';
import { OFFERS_MARKETING_FLYOUT_GET_SUCCESS, OFFERS_MARKETING_FLYOUT_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        content_group_id: 3127,
        content_type_id: 68,
        fields: [
          'merchant.name',
          'assets',
          'clickUrl',
        ],
        limit: 1,
      },
      options: {
        success: OFFERS_MARKETING_FLYOUT_GET_SUCCESS,
        failure: OFFERS_MARKETING_FLYOUT_GET_FAILURE,
        cache: {
          isEnabled: true,
          isPublishedData: true,
        },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
