import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { nav as orgConfig } from 'org/configs/modules.config';
import { NAVIGATION_GET_SUCCESS, NAVIGATION_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {},
      options: {
        success: NAVIGATION_GET_SUCCESS,
        failure: NAVIGATION_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
