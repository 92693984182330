import React from 'react';
import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { featuredOffersLogo as orgConfig } from 'org/configs/modules.config';
import { FEATURED_OFFERS_LOGO_GET_SUCCESS, FEATURED_OFFERS_LOGO_GET_FAILURE } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        content_group_id: 15613,
        with_offers: 0,
        sort_by: 'random',
        fields: [
          'merchant.logoUrls',
          'merchant.showRebate',
          'merchant.rebate',
          'merchant.id',
          'merchant.name',
          'merchant.clickUrl',
        ],
      },
      options: {
        success: FEATURED_OFFERS_LOGO_GET_SUCCESS,
        failure: FEATURED_OFFERS_LOGO_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    useAddToFavorite: true,
    useCarousel: false,
    shouldHideIncompleteRow: true,
    getViewAllLinkUrl: (siteUrl) => `${siteUrl}/b____.htm`,
    viewAllLinkTitle: 'View all stores',
    getTitle: (storeCount) => (
      <>Earn at {storeCount}+ stores:</>
    ),
    slick: {
      variableWidth: true,
      slidesToScroll: 2,
      centerMode: true,
      infinite: true,
      arrows: false,
      dots: true,
      responsive: [],
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
