import { findAncestorByClassName } from 'core/utils/helpers/dom';
import { getParamsFromSearch } from 'core/utils/helpers/getParamsFromSearch';
import {
  COUPON_SEARCH_RESULTS_VIEW, COUPON_SEARCH_RESULTS_APPLY_FILTER, COUPON_SEARCH_RESULTS_CLEAR_FILTERS,
} from '../actions/types';

export const couponSearchResultsGaActionEvents = {
  [COUPON_SEARCH_RESULTS_VIEW]: ({ keywords }) => (
    {
      category: 'Coupon Results Page',
      action: 'CR page view',
      label: keywords,
      value: '1',
    }
  ),
  [COUPON_SEARCH_RESULTS_APPLY_FILTER]: ({ merchantName, isAddedToFilter }) => {
    const { keywords } = getParamsFromSearch(window.location.search);
    const action = isAddedToFilter ? 'Filter cleared' : 'Filter applied';
    return {
      category: 'Coupon Results Page',
      action,
      label: `${keywords} | ${merchantName}`,
      value: '1',
    };
  },
  [COUPON_SEARCH_RESULTS_CLEAR_FILTERS]: () => (
    {
      category: 'Coupon Results Page',
      action: 'Filter cleared',
      label: `${getParamsFromSearch(window.location.search).keywords} | All`,
      value: '1',
    }
  ),
};

export const couponSearchResultsClickAndHoverEvents = [
  {
    selector: '.mn_couponSearchResultsContent .mn_coupon .mn_secondaryButton',
    click: {
      category: 'Coupon Results Page',
      action: 'Coupon click',
    },
    label: (element) => {
      const coupon = findAncestorByClassName(element, 'mn_merchantWithCoupons');
      const merchantName = coupon.getElementsByTagName('img')[0].alt || '';
      const { keywords } = getParamsFromSearch(window.location.search);

      return `${keywords} | ${merchantName}`;
    },
    value: 1,
  },
  {
    selector: '.mn_couponSearchResultsContent .mn_viewAllCoupons',
    click: {
      category: 'Coupon Results Page',
      action: 'View all offers click',
    },
    label: getParamsFromSearch(window.location.search).keywords,
    value: 1,
  },
  {
    selector: '.mn_couponSearchResultsContent .mn_seeMoreCoupons',
    click: {
      category: 'Coupon Results Page',
      action: 'See more coupons click',
    },
    label: getParamsFromSearch(window.location.search).keywords,
    value: 1,
  },
];
