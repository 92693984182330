import { takeEvery, put } from 'redux-saga/effects';
import { showModal } from 'core/modules/Modal/actions';

import { MODAL_TYPE_HYBRID_STORES_OFFER } from 'core/modules/Modal/types';
import { HYBRID_STORES_OFFER_MODAL_TRIGGER } from 'core/modules/Modal/components/HybridStoresOfferModal/actions/types';

function* showHybridStoresOfferModal() {
  yield put(showModal(MODAL_TYPE_HYBRID_STORES_OFFER));
}

export default function* hybridStoresOfferModalSaga() {
  yield takeEvery(HYBRID_STORES_OFFER_MODAL_TRIGGER, showHybridStoresOfferModal);
}
