import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

function FavoriteIconTooltip({
  className, isFavorited, buttonRef = {}, favoriteIconWasClicked,
  isTooltipPermanentForUnfavorited, isTooltipPermanentForFavorited,
  labelFavorited, labelUnfavorited, setFavoriteIconWasClicked,
}) {
  const [showBalloon, setShowBalloon] = useState(false);
  const [balloonLocation, setBalloonLocation] = useState({ left: 0, top: 0 });
  const [balloonAlignment, setBalloonAlignment] = useState('up');

  const getBallonAlignment = (screenLeft) => {
    const { clientWidth } = window.document.documentElement;
    const left = screenLeft + window.scrollX;

    let tooltipAlign = 'up';

    if (clientWidth - left < 90) {
      tooltipAlign = 'left';
    } else if (left < 90) {
      tooltipAlign = 'right';
    }

    return tooltipAlign;
  };

  const checkIfKeepTooltipPermanent = () => (
    (isTooltipPermanentForUnfavorited && !isFavorited) || (isTooltipPermanentForFavorited && isFavorited)
  );

  const checkIfShowTooltip = () => (
    (favoriteIconWasClicked || checkIfKeepTooltipPermanent()) && !!buttonRef.current
  );

  const checkIfTooltipLanguageIsEmpty = () => (
    (labelFavorited === '' && isFavorited) || (labelUnfavorited === '' && !isFavorited)
  );

  useEffect(() => {
    const shouldShowTooltip = checkIfShowTooltip();

    if (!shouldShowTooltip) {
      return undefined;
    }

    const shouldKeepTooltipPermanent = checkIfKeepTooltipPermanent();
    const { left = 0, top = 0 } = buttonRef.current?.getBoundingClientRect() || {};
    let showBalloonTimeoutId;

    setBalloonLocation({
      left: left + window.scrollX,
      top: top + window.scrollY,
    });

    setBalloonAlignment(getBallonAlignment(left));
    setShowBalloon(true);
    setFavoriteIconWasClicked(false);

    if (!shouldKeepTooltipPermanent) {
      showBalloonTimeoutId = setTimeout(() => {
        setShowBalloon(false);
      }, 1000);
    }

    return () => clearTimeout(showBalloonTimeoutId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFavorited]);

  const isTooltipLanguageEmpty = checkIfTooltipLanguageIsEmpty();

  if (isTooltipLanguageEmpty) {
    return null;
  }

  return ReactDOM.createPortal(
    <CSSTransition
      in={showBalloon}
      mountOnEnter
      unmountOnExit
      timeout={200}
      className={className}
    >
      <div
        style={{ left: balloonLocation.left, top: balloonLocation.top }}
        data-balloon-pos={balloonAlignment}
        data-balloon={isFavorited ? labelFavorited : labelUnfavorited}
        data-balloon-visible
      />
    </CSSTransition>,
    document.body,
  );
}

export default FavoriteIconTooltip;
