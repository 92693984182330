import { combineReducers } from 'redux';
import hybridProducts from '../components/HybridProducts/reducers';
import hybridStores from '../components/HybridStores/reducers';
import gspStores from './gspStores';
import merchantsWithOffers from './merchantsWithOffers';

export default combineReducers({
  hybridProducts,
  hybridStores,
  gspStores,
  merchantsWithOffers,
});
