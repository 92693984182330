import axios from 'axios';

/**
 * Server side rendering API calls
 * will be made in this file
 */
export class ServerApi {
  /**
   * @returns {Promise<any>}
   */
  // eslint-disable-next-line class-methods-use-this
  getMerchantsAll() {
    return axios.get('/merchantlistreacthtml____.htm')
      .then(resp => resp.data)
      .catch(error => Promise.reject(error));
  }

  // eslint-disable-next-line
  salesforceCaseIdValidation(caseId) {
    return axios.get(`/salesforce.php?caseId=${caseId}`)
      .then(resp => resp.data)
      .catch(error => Promise.reject(error));
  }
}

export default new ServerApi();
