/*
Use this module to check for existence of our browser extension.
Extension injects custom tag into page.
Check every 100ms up to 3500ms after document.ready for it.
If promise resolves, button is installed on current browser. If fail, button is not installed on current browser.
*/

const detectButton = new Promise((resolve) => {
  if (document.querySelector('mntoolbar')) {
    resolve(true);
    return;
  }

  const MAX_DELAY = 3500; // check the button for up to 3.5 seconds
  const INTERVAL = 100;
  let currentDelay = INTERVAL;

  const timerId = setInterval(() => {
    if (document.querySelector('mntoolbar')) {
      resolve(true);
      clearInterval(timerId);
    }

    if (currentDelay >= MAX_DELAY) {
      resolve(false);
      clearInterval(timerId);
    }

    currentDelay += INTERVAL;
  }, INTERVAL);
});

export default detectButton;
