import {
  MEMBER_DELETE_INSTORE_PAYMENT_CARD,
  MEMBER_DELETE_INSTORE_PAYMENT_CARD_FAILURE,
  MEMBER_DELETE_INSTORE_PAYMENT_CARD_SUCCESS,
} from 'core/actions/member';

const initialState = {};

const instoreCardsListModal = (state = initialState, action) => {
  switch (action.type) {
    case MEMBER_DELETE_INSTORE_PAYMENT_CARD: {
      return {
        ...state,
        cardDeleted: action.data,
        isDeleted: false,
      };
    }

    case MEMBER_DELETE_INSTORE_PAYMENT_CARD_SUCCESS: {
      return {
        ...state,
        isDeleted: true,
      };
    }

    case MEMBER_DELETE_INSTORE_PAYMENT_CARD_FAILURE: {
      return {
        ...state,
        isDeleted: false,
      };
    }

    default:
      return state;
  }
};

export default instoreCardsListModal;
