const rootActionPath = 'core/SafariButtonInstallTutorial';

export const SET_PERMISSIONS_VIEW = `${rootActionPath}/SET_PERMISSIONS/VIEW`;

export const NON_SAFARI_VIEW = `${rootActionPath}/NON_SAFARI/VIEW`;

export const ACTIVATION_REMINDER_A_VIEW = `${rootActionPath}/ACTIVATION_REMINDER_A/VIEW`;
export const ACTIVATION_REMINDER_B_VIEW = `${rootActionPath}/ACTIVATION_REMINDER_B/VIEW`;
export const ACTIVATION_REMINDER_C_VIEW = `${rootActionPath}/ACTIVATION_REMINDER_C/VIEW`;

export const COUPON_FINDER_A_VIEW = `${rootActionPath}/COUPON_FINDER_A/VIEW`;
export const COUPON_FINDER_B_VIEW = `${rootActionPath}/COUPON_FINDER_B/VIEW`;
export const COUPON_FINDER_C_VIEW = `${rootActionPath}/COUPON_FINDER_C/VIEW`;

export const PRICE_COMPARISON_VIEW = `${rootActionPath}/PRICE_COMPARISON/VIEW`;

export const SEARCH_INTEGRATION_VIEW = `${rootActionPath}/SEARCH_INTEGRATION/VIEW`;

export const STORE_OFFERS_SETTINGS_VIEW = `${rootActionPath}/STORE_OFFERS_SETTINGS/VIEW`;
