import { BONUS_ROLLING_MHP_BANNER_ITEM_VIEW } from '../actions/types';

export const bonusRollingMHPBannerGaActionEvents = {
  [BONUS_ROLLING_MHP_BANNER_ITEM_VIEW]: ({ bonusItem }) => ({
    category: 'Rolling Bonus: MHP Placement',
    action: 'MHP Placement View',
    label: bonusItem.id,
    value: 1,
  }),
};

export const bonusRollingMHPBannerClickAndHoverEvents = [
  {
    selector: '.mn_bonusRollingMHPBanner .mn_termsButton',
    click: {
      category: 'Rolling Bonus: MHP Placement',
      action: 'MHP Placement terms click',
      value: 1,
    },
  },
];
