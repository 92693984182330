import React from 'react';
import { useSelector } from 'react-redux';
import { selectSiteUrl } from 'core/selectors/app';

export function InstoreSection() {
  const siteUrl = useSelector(selectSiteUrl);

  return (
    <a
      className="mn_navigationItem"
      href={`${siteUrl}/instore`}
      title="Opens instore page"
    >
      <i className="mn_unitedIconLocation" aria-hidden="true" />
    </a>
  );
}

export default InstoreSection;
