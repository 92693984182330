import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { checkIfUrlPointsToExtraRewards } from 'core/utils/helpers';
import { selectExtraRewardsPage } from 'core/selectors/app';
import orgConfig from 'org/configs/org.config';
import Pill from '../Pill/Pill';

const { extraRewardsName } = orgConfig;

function ExtraRewardsPill({ url, label, rebate }) {
  const extraRewardsPage = useSelector(selectExtraRewardsPage);
  const shouldRenderPill = url
    ? checkIfUrlPointsToExtraRewards(url, extraRewardsPage)
    : rebate && rebate.isExtraRewards;

  return shouldRenderPill ?
    <Pill
      className="mn_extraRewardsPill"
      label={label}
      data-test="extra-rewards-pill"
    />
    :
    null;
}

ExtraRewardsPill.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
  rebate: PropTypes.shape({}),
};

ExtraRewardsPill.defaultProps = {
  label: extraRewardsName,
  url: '',
  rebate: {},
};

export default ExtraRewardsPill;
