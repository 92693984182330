import { SUCCESS_SUFFIX, FAILURE_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/MarketingMerchantsHome';

export const MARKETING_MERCHANTS_HOME_STORES_GET_SUCCESS = `${rootActionPath}/MERCHANTS/GET_${SUCCESS_SUFFIX}`;
export const MARKETING_MERCHANTS_HOME_STORES_GET_FAILURE = `${rootActionPath}/MERCHANTS/GET_${FAILURE_SUFFIX}`;
export const MARKETING_MERCHANTS_HOME_VIEW = `${rootActionPath}/VIEW`;

export const MARKETING_MERCHANTS_HOME_TITLE_GET_SUCCESS = `${rootActionPath}/TITLE/GET_${SUCCESS_SUFFIX}`;
export const MARKETING_MERCHANTS_HOME_TITLE_GET_FAILURE = `${rootActionPath}/TITLE/GET_${FAILURE_SUFFIX}`;
