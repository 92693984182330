export const loginLinkClickAndHoverEvents = [
  {
    selector: '[data-ga-track-login]',
    click: {
      category: 'Login',
      action: 'Voluntary login start',
    },
    label: element => element.getAttribute('data-isource') || 'login link',
    value: 1,
    transport: 'beacon',
  },
];
