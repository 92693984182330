import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { recentMemberOrders as orgConfig } from 'org/configs/modules.config';
import {
  RECENT_MEMBER_ORDERS_GET_SUCCESS,
  RECENT_MEMBER_ORDERS_GET_FAILURE,
} from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        sort_by: 'clickDate',
      },
      options: {
        success: RECENT_MEMBER_ORDERS_GET_SUCCESS,
        failure: RECENT_MEMBER_ORDERS_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
