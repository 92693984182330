import { selectOrgConfig } from 'core/selectors/org';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';

const hasRebateElevation = ({ originalValue, value }) =>
  typeof originalValue !== 'undefined' && originalValue !== value;

const chooseRebateCurrencyVersion = (currencyLong, currency = '%', state) => {
  if (!currencyLong || currencyLong.length === 0) {
    return currency;
  }
  const { useCurrencyLong } = selectOrgConfig(state);
  return useCurrencyLong ? currencyLong : currency;
};

const chooseRebateValueCurrencyVersion = ({ currencyLong, currency }, state) =>
  chooseRebateCurrencyVersion(currencyLong, currency, state);

const chooseRebateOriginalValueCurrencyVersion = ({ originalCurrencyLong, originalCurrency }, state) =>
  chooseRebateCurrencyVersion(originalCurrencyLong, originalCurrency, state);

const mergedRebateValueWithCurrency = (value, currency, currencyLong, state) => {
  const { useCurrencyLong } = selectOrgConfig(state);
  const currencyLanguage = useCurrencyLong ? currencyLong : currency;

  if (currencyLanguage === '$') {
    // If current is dollar put sign it before value without whitespace
    // If value is not a whole number make sure two decimals are shown
    return currencyLanguage + (Number.isInteger(value) ? value : value.toFixed(2));
  }

  // Check for whitespace between value and currency
  // for example if currency is miles/$ - whitespace is needed (5 miles/$), but if just % - then it isn't (3%)
  return value + (currencyLanguage === '%' ? '' : ' ') + currencyLanguage;
};

const extractRebateCurrencyPrefix = (currency) => {
  const currencyPieces = currency.split('/');
  return currencyPieces[0] || currency;
};

const extractRebateValueCurrencyPrefix = ({ currency, currencyLong }, state) => {
  const { useCurrencyLong } = selectOrgConfig(state);
  return extractRebateCurrencyPrefix(useCurrencyLong ? currencyLong : currency);
};

const extractRebateOriginalValueCurrencyPrefix = ({ originalCurrency, originalCurrencyLong }, state) => {
  const { useCurrencyLong } = selectOrgConfig(state);
  return extractRebateCurrencyPrefix(useCurrencyLong ? originalCurrencyLong : originalCurrency);
};

const extractRebateCurrencySuffix = ({ currency }) => {
  const currencyPieces = currency.split('/');
  return currencyPieces.length > 1 ? `/${currencyPieces[1]}` || '' : '';
};

// could have some future parsing logic here of OCAPI prefix field - keep it simple and return the 'up to' text for now
const extractTieredPrefix = isTiered => (isTiered ? 'up to' : '');

const extractEarnPrefix = (isTiered, prefix) =>
  (isTiered ? prefix && prefix.substr(0, prefix.indexOf('up to')).trim() : prefix);

const parseBaseRebate = (rebate, state) => {
  if (!rebate) {
    return null;
  }

  const {
    value, originalValue, currency, currencyLong, originalCurrencyLong, originalCurrency,
  } = rebate;

  return {
    elevation: hasRebateElevation(rebate),
    currency: {
      value: {
        current: chooseRebateValueCurrencyVersion(rebate, state),
        original: chooseRebateOriginalValueCurrencyVersion(rebate, state),
      },
      prefix: {
        current: extractRebateValueCurrencyPrefix(rebate, state),
        original: extractRebateOriginalValueCurrencyPrefix(rebate, state),
      },
      suffix: extractRebateCurrencySuffix(rebate),
    },
    originalValueWithCurrency:
      mergedRebateValueWithCurrency(originalValue, originalCurrency, originalCurrencyLong, state),
    valueWithCurrency: mergedRebateValueWithCurrency(value, currency, currencyLong, state),
  };
};

const parseTiers = (tiers, state) => {
  if (isEmpty(tiers)) {
    return [];
  }
  return tiers.map(tier => merge(tier, { rebate: parseBaseRebate(tier.rebate, state) }));
};

export const parseRebate = (rebate, tiers, state, isDeactivated) => {
  if (isDeactivated) {
    const parsedRebate = {
      isDeactivated: true,
      value: 'No reward',
    };
    return parsedRebate;
  }

  if (!rebate) {
    return null;
  }

  const parsedRebate = parseBaseRebate(rebate, state);

  parsedRebate.tiers = parseTiers(tiers, state);
  parsedRebate.isTiered = rebate.prefix && rebate.prefix.indexOf('up to') !== -1;
  parsedRebate.tieredPrefix = extractTieredPrefix(parsedRebate.isTiered);
  parsedRebate.earnPrefix = extractEarnPrefix(parsedRebate.isTiered, rebate.prefix);

  return parsedRebate;
};

export default parseRebate;
