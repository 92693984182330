import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { favoritesIcon as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    FavoriteIconSvg: null,
    favoriteIconViewBox: 'none',
    useSvgIcon: true,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
