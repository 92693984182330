import { findDataAttrInAncestorTree } from 'core/utils/helpers/dom';

export const featuredFavoritesClickAndHoverEvents = [
  {
    selector: '.mn_featuredFavorites .mn_merchantExperienceLink',
    click: {
      category: 'Featured favorites',
      action: 'Tap view all offers',
    },
    label: (element) => {
      const merchantName = findDataAttrInAncestorTree(element, 'merchantName');

      return merchantName;
    },
  },
  {
    selector: '.mn_featuredFavorites .mn_merchantLogo',
    click: {
      category: 'Featured favorites',
      action: 'Tap merchant ',
    },
    label: (element) => {
      const merchantName = findDataAttrInAncestorTree(element, 'merchantName');

      return merchantName;
    },
  },
  {
    selector: '.mn_featuredFavorites .mn_offerRow',
    click: {
      category: 'Featured favorites',
      action: 'Tap arrow',
    },
    label: (element) => {
      const merchantName = findDataAttrInAncestorTree(element, 'merchantName');
      const dealText = element.getElementsByClassName('mn_offerRowHeader')[0].innerText;

      return `${merchantName}|${dealText}`;
    },
  },
];
