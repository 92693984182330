import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { hybridStoresOffer as orgConfig } from 'org/configs/modules.config';
import { INSTORE_OFFERS_GET_FAILURE, INSTORE_OFFERS_GET_SUCCESS } from '../actions/types';

export const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: INSTORE_OFFERS_GET_SUCCESS,
        failure: INSTORE_OFFERS_GET_FAILURE,
        cache: { isEnabled: false, isPublishedData: false },
      },
      params: {
        active: 1,
      },
    },
    rebateOptions: {
      hideRebatePrefix: false,
      hideElevationCurrency: true,
    },
  },
};

export const hybridStoresOffer = mergeModuleConfigs(defaultConfig, orgConfig);
