const rootActionPath = 'core/InstoreAddNewCardModal';

export const INSTORE_ADD_NEW_CARD_MODAL_TRIGGER = `${rootActionPath}/TRIGGER`;
export const INSTORE_ADD_NEW_CARD_MODAL_CLEAR = `${rootActionPath}/CLEAR`;

export const INSTORE_ADD_CARD_SUCCESSFULL = `${rootActionPath}/ADD_SUCCESS`;

export const INSTORE_ADD_CARD_SUCCESS_MESSAGE = 'Successfully registered payment card.';

export const INSTORE_ADD_CARD_VIEW = `${rootActionPath}/VIEW`;

export const INSTORE_ADD_CARD_ADDED = `${rootActionPath}/ADDED`;

export const INSTORE_ADD_CARD_POST = `${rootActionPath}/POST`;

export const INSTORE_ADD_CARD_FAILURE = `${rootActionPath}/FAILURE`;

export const INSTORE_ADD_CARD_ON_CLOSE = `${rootActionPath}/CLOSE`;

export const ADD_NEW_CARD_SOURCE = `${rootActionPath}/source`;
