import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { selectRecommendedStoresMerchants } from 'core/modules/RecommendedStores/selectors';
import { selectFavoritedStoresMerchants } from 'core/modules/FavoritedStores/selectors';

export const selectRecentStores = state => state.recentStores;

export const selectRecentStoresConfig = createSelector(
  selectRecentStores,
  recentStores => recentStores.config,
);

export const selectRecentStoresMerchants = (state, isPopUpView) => {
  const recommendedStoresMerchants = selectRecommendedStoresMerchants(state);
  const favoritedStoresMerchants = selectFavoritedStoresMerchants(state);
  const recentStoresMerchants =
    !isEmpty(favoritedStoresMerchants) ? favoritedStoresMerchants : recommendedStoresMerchants;

  return isPopUpView ? recentStoresMerchants.slice(0, 2) : recentStoresMerchants;
};
