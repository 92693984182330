import { getPageName } from 'core/utils/analytics/pageInfo';
import { findAncestorByClassName } from 'core/utils/helpers/dom';
import { BONUS_TRACKER_VIEW } from '../actions/types';

const getCategory = () => {
  const pageName = getPageName();

  switch (pageName) {
    case 'foryou':
      return 'Bonus Tracker: Offers for you';
    case 'ai':
      return 'Account Page Bonus Tracker';
    default:
      return 'Bonus Tracker';
  }
};

export const bonusTrackerGaActionEvents = {
  [BONUS_TRACKER_VIEW]: ({ labelText, isEnded }) => ({
    category: getCategory(),
    action: isEnded ? 'View Past Tracker' : 'View Active Tracker',
    label: labelText,
  }),
};

export const bonusTrackerClickAndHoverEvents = [
  {
    selector: '.mn_bonusTracker .mn_termsAndCTA .mn_bonusButton',
    click: {
      category: getCategory(),
      action: 'CTA Click',
    },
    label: (element) => {
      const offerTextSectionElement = findAncestorByClassName(element, 'mn_bonusTracker') || {};
      const { label } = offerTextSectionElement.dataset;

      return label;
    },
  },
  {
    selector: '.mn_bonusTracker .mn_termsAndCTA button',
    click: {
      category: getCategory(),
      action: 'View Terms Click',
    },
    label: (element) => {
      const offerTextSectionElement = findAncestorByClassName(element, 'mn_bonusTracker') || {};
      const { label } = offerTextSectionElement.dataset;

      return label;
    },
  },
];
