import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { featuredOffers120x90 as orgConfig } from 'org/configs/modules.config';
import {
  FEATURED_OFFERS_120X90_GET_SUCCESS,
  FEATURED_OFFERS_120X90_GET_FAILURE,
} from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    useAddToFavorite: false,
    api: {
      options: {
        success: FEATURED_OFFERS_120X90_GET_SUCCESS,
        failure: FEATURED_OFFERS_120X90_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
      params: {
        sort_by: 'random',
        with_text_offers: 1,
        group_by_merchant: 0,
        fields: [
          'id',
          'assets',
          'clickUrl',
          'merchant',
          'offerTitle',
          'offerDetails',
          'offerText',
        ].join(','),
        section_id: 9753,
      },
    },
    slick: {
      infinite: false,
      slidesToShow: 2,
      arrows: false,
      dots: true,
      centerMode: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToScroll: 2,
            slidesToShow: 2.3,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToScroll: 2,
            slidesToShow: 2.1,
          },
        },
        {
          breakpoint: 365,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 1.9,
            dots: false,
          },
        },
        {
          breakpoint: 350,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 1.5,
            dots: false,
          },
        },
      ],
    },
  },
  b: {
    useAddToFavorite: true,
    api: {
      params: {
        group_by_merchant: 1,
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
