import { findDataAttrInAncestorTree } from 'core/utils/helpers/dom';
import { TRENDING_AND_SEASONAL_OFFERS_VIEW } from '../actions/types';

export const trendingAndSeasonalOffersGaActionEvents = {
  [TRENDING_AND_SEASONAL_OFFERS_VIEW]: ({ offer, contentGroupId }) => {
    const { id, assets: { imageAlternateText } } = offer;

    return {
      category: 'mktbanner',
      action: 'view',
      label: `${contentGroupId}|${id}|${imageAlternateText}`,
      value: 1,
    };
  },
};

export const trendingAndSeasonalOffersClickAndHoverEvents = [
  {
    selector: '.mn_trendingAndSeasonalOffer a',
    click: {
      category: 'mktbanner',
      action: 'click',
    },
    label: (element) => {
      const contentGroupId = findDataAttrInAncestorTree(element, 'contentGroupId');
      const placementId = findDataAttrInAncestorTree(element, 'placementId');

      const imgEl = element.getElementsByTagName('img');
      const imgAltText = imgEl.length > 0 ? imgEl[0].alt || '' : '';

      return `${contentGroupId}|${placementId}|${imgAltText}`;
    },
  },
];
