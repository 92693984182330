import {
  RECENT_MEMBER_ORDERS_GET_SUCCESS,
  RECENT_MEMBER_ORDERS_GET_FAILURE,
  RECENT_MEMBER_ORDERS_PENDING_GET_SUCCESS,
  RECENT_MEMBER_ORDERS_PENDING_GET_FAILURE,
} from '../actions/types';
import recentMemberOrdersConfig from '../configs';

/** @type {{config: object, placements: array.<object>}} */
const initialState = {
  config: recentMemberOrdersConfig,
  memberOrders: [],
  isLoaded: false,
  isLoading: false,
};

/**
 * @param {object} state
 * @param {object} action
 * @return {object}
 */
const recentMemberOrders = (state = initialState, action) => {
  switch (action.type) {
    case RECENT_MEMBER_ORDERS_GET_SUCCESS: {
      return {
        ...state,
        memberOrders: action.payload.response,
        isLoading: false,
        isLoaded: true,
      };
    }
    case RECENT_MEMBER_ORDERS_GET_FAILURE: {
      return {
        ...state,
        memberOrders: [],
        isLoading: false,
        isLoaded: true,
      };
    }
    case RECENT_MEMBER_ORDERS_PENDING_GET_SUCCESS: {
      return {
        ...state,
        memberOrdersPending: action.payload.response,
        isLoading: false,
        isLoaded: true,
      };
    }
    case RECENT_MEMBER_ORDERS_PENDING_GET_FAILURE: {
      return {
        ...state,
        memberOrdersPending: [],
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      const { options = {} } = action;
      // Handle setting isLoading to true for raw OCAPI action
      if (options.success === RECENT_MEMBER_ORDERS_GET_SUCCESS) {
        return { ...state, isLoading: true };
      }

      return state;
    }
  }
};

export default recentMemberOrders;
