import { ERROR_MESSAGE_FROM_SMARTY_SAVE } from '../actions/types';

import config from '../configs';

const initialState = {
  config,
  errorData: {},
};

const errorMessage = (state = initialState, action) => {
  switch (action.type) {
    case ERROR_MESSAGE_FROM_SMARTY_SAVE: {
      const { payload } = action;
      return {
        ...state,
        errorData: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default errorMessage;
