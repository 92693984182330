import { takeEvery, put } from 'redux-saga/effects';

import { HYBRID_STORES_OFFER_MODAL_TRIGGER } from 'core/modules/Modal/components/HybridStoresOfferModal/actions/types';
import { ONLINE_INSTORE_OFFER_OVERLAY_LINK_CLICK } from 'core/modules/OnlineInStoreOfferOverlayLink/actions/types';

function* putOfferModalAction(action = {}) {
  const { data } = action;
  yield put({
    type: HYBRID_STORES_OFFER_MODAL_TRIGGER,
    data,
  });
}

export default function* onlineInStoreOfferOverlayLinkSaga() {
  yield takeEvery(ONLINE_INSTORE_OFFER_OVERLAY_LINK_CLICK, putOfferModalAction);
}
